import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdditionalsService } from 'src/app/services/additionals.service';
import { EstateService } from 'src/app/services/estate.service';
import { SharingService } from 'src/app/services/sharing.service';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import Auth from '@aws-amplify/auth';
import { Title, Meta } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { AppraisalService } from 'src/app/services/appraisal.service';

@Component({
  selector: 'app-appraisal',
  templateUrl: './appraisal.component.html',
  styleUrls: ['./appraisal.component.css']
})
export class AppraisalComponent implements OnInit {

  map: any
  mapClickListener: any;

  loading: boolean = false
  user: CognitoUserInterface | undefined;
  packagePlanInfo: any | undefined;
  availableAcms: number = 1;
  myPlanAcms: number = 6;
  oldUser: boolean | undefined;
  showAvailableAcmsLoading: boolean | undefined;

  latitude: number = 6.230833;
  longitude: number = -75.590553;
  zoom: number = 13;
  address: string = '';
  private geoCoder!: { geocode: (arg0: { location: { lat: any; lng: any; }; }, arg1: (results: any, status: any) => void) => void; };
  @ViewChild('search')
  public searchElementRef!: ElementRef;

  cities: string[] = [];
  propertyTypes: any[] = [];

  iconHomtyUrl = "assets/images/pins-map/property.png";

  propertyForm = new FormGroup({
    inmueble: new FormControl('', Validators.required),
    municipio: new FormControl('', Validators.required),
    tamano: new FormControl('', Validators.required),
    habitaciones: new FormControl('', Validators.required),
    banos: new FormControl('', Validators.required),
    parqueadero: new FormControl('', Validators.required),
    antiguedad: new FormControl('', Validators.required),
    estrato: new FormControl('', Validators.required),
  });

  constructor(private estateService: EstateService, private additionalsService: AdditionalsService, private appraisalService: AppraisalService, private sharingService: SharingService, private activatedRoute: ActivatedRoute, private router: Router, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Homty - Análisis Comparativo de Mercado');
    this.meta.addTag({ name: 'description', content: 'Homty - ACM análisis comparativo de mercado' });
    this.meta.addTag({ name: 'keywords', content: 'homty, inmobiliario, propiedades, avaluo, acm, avaluo comercial, valorizacion, apreciacion comercial, estimacion' });

    this.getCities();
    this.getPropertyTypes();

    Auth.currentUserInfo().then(user => {
      this.user = user;

      if (user["attributes"]["custom:free_trial"] == 0 && user["attributes"]["custom:active_plan"] == 0) {
        Swal.fire({
          title: 'No tienes un plan activo',
          text: 'Compra una suscripcion o un paquete para generar ACMs',
          timer: 4000,
          showConfirmButton: false,
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            this.router.navigate(['home']);
          }
        });
      }

      if (user["attributes"]["custom:free_trial"] == 1 || (user["attributes"]["custom:active_plan"] >= 1 && user["attributes"]["custom:active_plan"] <= 3)) {
        this.showAvailableAcmsLoading = true;
        this.additionalsService.getAvailableAcms(user!['attributes']['email']).subscribe(resp => {
          // console.log(resp);
          if (resp.answer) {
            this.oldUser = false;
            this.availableAcms = resp.data.acms;
          } else {
            this.oldUser = true;
          }
          this.showAvailableAcmsLoading = false;
        });
      }

      if (user["attributes"]["custom:active_plan"] == 4) {
        this.additionalsService.getNumberAcmsProperties(user["attributes"]["email"]).subscribe(resp => {
          let respJson = JSON.parse(resp.item);
          this.packagePlanInfo = respJson;
        })
      }
    });

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 15;
        });
      });
    });

  }

  getCities() {
    this.estateService.getCities()
      .subscribe(cities => {
        for (let i = 0; i < cities.length; i++) {
          this.cities.push(cities[i].Municipio);
        }
      });
  };

  getPropertyTypes() {
    this.estateService.getPropertyTypes()
      .subscribe(propertyTypes => {
        this.propertyTypes = propertyTypes;
      })
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  public mapClicked(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
      this.ngZone.run(() => {
        this.latitude = e.latLng.lat();
        this.longitude = e.latLng.lng();
        this.getAddress(this.latitude, this.longitude);
      });
    });
  }

  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat()
    this.longitude = $event.latLng.lng()
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 15;
          this.address = results[0].formatted_address;
          this.searchElementRef.nativeElement.value = this.address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  onSubmit() {
    this.propertyForm.markAllAsTouched();

    if (this.propertyForm.valid) {
      let propertyForm = this.propertyForm.value;

      const appraisal: any = {
        'municipio': propertyForm.municipio,
        'antiguedad': propertyForm.antiguedad,
        'banos': propertyForm.banos,
        'estrato': propertyForm.estrato,
        'habitaciones': propertyForm.order,
        'tipo_inmueble': propertyForm.inmueble,
        'parqueadero': propertyForm.parqueadero,
        'tamano': propertyForm.tamano,
        'latitud': this.latitude,
        'longitud': this.longitude
      }

      if ((!this.oldUser && this.availableAcms > 0) || this.oldUser) {
        this.loading = true;
        this.sharingService.sharingValue = appraisal;
        this.router.navigate(['/acp']);
      } else {
        Swal.fire({
          title: '¡Te quedaste sin ACMs!',
          html: '¿Deseas pagar para realizarlo? Tiene un costo <br> de $ 10.000 COP',
          showCancelButton: true,
          confirmButtonColor: '#fc6641',
          confirmButtonText: 'Comprar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.isConfirmed) {
            this.loading = true;
            this.sharingService.sharingValue = appraisal;
            this.router.navigate(['/acp'], { queryParams: { plan: '6' } });
          }
        })
      }
    }
  }
}
