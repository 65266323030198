import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { InventoryModel } from "src/app/models/inventory.model";
import { EstateService } from "src/app/services/estate.service";
import { createMask } from "@ngneat/input-mask";
import { FormControl, FormGroup, Validators, NgForm } from "@angular/forms";
import { InventoryService } from "src/app/services/inventory.service";
import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { SharingService } from "src/app/services/sharing.service";
import { MapsAPILoader } from "@agm/core";
import { AdditionalsService } from "src/app/services/additionals.service";
declare const google: any;

function validatePhone(control: FormControl) {
  const phoneRegex = /^\d{10}$/;
  return !phoneRegex.test(control.value)
    ? { phoneValidator: { value: control.value } }
    : null;
}

@Component({
  selector: "app-descriptions",
  templateUrl: "./descriptions.component.html",
  styleUrls: ["./descriptions.component.css"],
})
export class DescriptionsComponent implements OnInit {
  map: any;
  mapClickListener: any;
  latitude: number = 6.230833;
  longitude: number = -75.590553;
  zoom: number = 13;
  address: string = "";
  private geoCoder!: {
    geocode: (
      arg0: { location: { lat: any; lng: any } },
      arg1: (results: any, status: any) => void
    ) => void;
  };

  @ViewChild("search")
  public searchElementRef!: ElementRef;

  SearchPlacesForm: NgForm | undefined;
  public shippingAddress: string | undefined;

  loading: boolean = false;
  user: CognitoUserInterface | undefined;
  packagePlanInfo: any | undefined;
  formal: boolean = true;
  descripcion: string | undefined;

  propertyForm = new FormGroup({
    inmueble: new FormControl("", Validators.required),
    operacion: new FormControl("", Validators.required),
    precio: new FormControl("", Validators.required),
    administracion: new FormControl("", Validators.required),
    municipio: new FormControl("", Validators.required),
    barrio: new FormControl("", Validators.required),
    descripcion: new FormControl("", Validators.required),
    imagenes: new FormControl("", Validators.required),
    estrato: new FormControl("", Validators.required),
    antiguedad: new FormControl("", Validators.required),
    habitaciones: new FormControl("", Validators.required),
    banos: new FormControl("", Validators.required),
    parqueadero: new FormControl("", Validators.required),
    piso: new FormControl("", Validators.required),
    tamano: new FormControl("", Validators.required),
    vendedor_agencia: new FormControl("", Validators.required),
    whatsapp: new FormControl("", [Validators.required, validatePhone]),
  });

  currencyInputMask = createMask({
    alias: "numeric",
    groupSeparator: ",",
    digits: 0,
    prefix: "$ ",
  });
  images: any[] = [];
  propertyTypes: any[] = [];
  cities: string[] = [];
  propertyToUpdate: InventoryModel | undefined;

  constructor(
    private estateService: EstateService,
    private additionalsService: AdditionalsService,
    private inventoryService: InventoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharingService: SharingService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.getPropertyById();
    Auth.currentUserInfo().then((user) => {
      this.user = user;
      this.additionalsService
        .getNumberAcmsProperties(user["attributes"]["email"])
        .subscribe((resp) => {
          let respJson = JSON.parse(resp.item);
          this.packagePlanInfo = respJson;
        });
    });

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 15;
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  public mapClicked(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener(
      "click",
      (e: google.maps.MouseEvent) => {
        this.ngZone.run(() => {
          this.latitude = e.latLng.lat();
          this.longitude = e.latLng.lng();
          this.getAddress(this.latitude, this.longitude);
        });
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: any, status: any) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 15;
            this.address = results[0].formatted_address;
            this.searchElementRef.nativeElement.value = this.address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  getPropertyById() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.id) {
        this.propertyToUpdate = this.sharingService.sharingValue;
        if (this.propertyToUpdate != undefined) {
          this.autocompleteForm();
        } else {
          this.loading = true;
          this.inventoryService.getProperyById(params.id).subscribe((resp) => {
            this.propertyToUpdate = resp.property;
            this.loading = false;
            this.autocompleteForm();
          });
        }
      } else {
        this.getPropertyTypes();
        this.getCities();
      }
    });
  }

  autocompleteForm() {
    if (this.propertyToUpdate != undefined) {
      // this.toDataURL(this.propertyToUpdate.Imagenes[0])
      if (
        this.propertyToUpdate.Imagenes.length == 1 &&
        this.propertyToUpdate.Imagenes[0] == ""
      ) {
        this.images = [];
      } else {
        this.images = this.propertyToUpdate.Imagenes;
      }
      this.latitude = Number(this.propertyToUpdate.Latitud);
      this.longitude = Number(this.propertyToUpdate.Longitud);
      this.propertyForm.setValue({
        inmueble: this.propertyToUpdate.Inmueble,
        operacion: this.propertyToUpdate.Operacion,
        precio: this.propertyToUpdate.Precio,
        administracion: this.propertyToUpdate.Valor_Admin,
        municipio: this.propertyToUpdate.Municipio,
        barrio: this.propertyToUpdate.Barrio,
        descripcion: this.propertyToUpdate.Descripcion,
        estrato: this.propertyToUpdate.Estrato,
        antiguedad: this.propertyToUpdate.Antiguedad,
        habitaciones: this.propertyToUpdate.Habitaciones,
        banos: this.propertyToUpdate.Banos,
        parqueadero: this.propertyToUpdate.Parking,
        piso: this.propertyToUpdate.Piso,
        tamano: this.propertyToUpdate.Tamano,
        vendedor_agencia: this.propertyToUpdate.Vendedor_Agencia,
        whatsapp: this.propertyToUpdate.Whatsapp,
        imagenes: this.propertyToUpdate.Imagenes,
      });
    }
  }

  getPropertyTypes() {
    this.estateService.getPropertyTypes().subscribe((propertyTypes) => {
      this.propertyTypes = propertyTypes;
    });
  }

  getCities() {
    this.estateService.getCities().subscribe((cities) => {
      for (let i = 0; i < cities.length; i++) {
        this.cities.push(cities[i].Municipio);
      }
    });
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.images.push(event.target.result);
        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  deleteImage(index: number) {
    this.images.splice(index, 1);
  }

  onSubmit() {
    this.propertyForm.markAllAsTouched();
    if (this.propertyForm.valid) {
      this.loading = true;
      let propertyForm = this.propertyForm.value;
      let precio: string = propertyForm.precio.replace(/[^0-9]/g, "");
      let valorAdmin: string = propertyForm.administracion.replace(
        /[^0-9]/g,
        ""
      );

      const newProperty: any = {
        antiguedad: propertyForm.antiguedad,
        banos: propertyForm.banos,
        barrio: propertyForm.barrio,
        descripcion: propertyForm.descripcion,
        estrato: propertyForm.estrato,
        habitaciones: propertyForm.habitaciones,
        tipo_inmueble: propertyForm.inmueble,
        municipio: propertyForm.municipio,
        usuario: this.user!["attributes"]["email"],
        operacion: propertyForm.operacion,
        parqueadero: propertyForm.parqueadero,
        piso: propertyForm.piso,
        precio: precio,
        tamano: propertyForm.tamano,
        valor_admin: valorAdmin,
        vendedor: propertyForm.vendedor_agencia,
        whatsapp: propertyForm.whatsapp,
        imagenes: this.images,
        latitud: this.latitude,
        longitud: this.longitude,
      };

      if (this.propertyToUpdate) {
        newProperty.id = this.propertyToUpdate.id;
        this.inventoryService.updateProperty(newProperty).subscribe(
          (resp) => {
            let terms = Swal.fire({
              title: "Inmueble actualizado satisfactoriamente!",
              showCancelButton: false,
              confirmButtonColor: "#ff653f",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(["/inventory"]);
              }
            });
          },
          (error) => {
            this.loading = false;
            let terms = Swal.fire({
              title: "¿Quieres comprar otro paquete?",
              text: error.error.answer,
              showCancelButton: true,
              confirmButtonColor: "#ff653f",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(["/pay-subscription"]);
              }
            });
          }
        );
      } else {
        this.inventoryService.createProperty(newProperty).subscribe(
          (resp) => {
            this.loading = false;
            let terms = Swal.fire({
              title: "Inmueble creado satisfactoriamente!",
              showCancelButton: false,
              confirmButtonColor: "#ff653f",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(["/inventory"]);
              }
            });
          },
          (error) => {
            this.loading = false;
            let terms = Swal.fire({
              title: "¿Quieres comprar otro paquete?",
              text: error.error.answer,
              showCancelButton: true,
              confirmButtonColor: "#ff653f",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(["/pay-subscription"]);
              }
            });
          }
        );
      }
    }
  }

  generateDescriptionFormal(event: any) {
    event.preventDefault();
    this.loading = true;
    let propertyForm = this.propertyForm.value;
    let precio: string = propertyForm.precio.replace(/[^0-9]/g, "");
    let valorAdmin: string = propertyForm.administracion.replace(/[^0-9]/g, "");
    this.additionalsService
      .getDescription(
        propertyForm.inmueble,
        propertyForm.municipio,
        propertyForm.habitaciones,
        propertyForm.banos,
        propertyForm.barrio,
        precio,
        valorAdmin,
        propertyForm.tamano,
        propertyForm.parqueadero,
        propertyForm.piso,
        propertyForm.vendedor_agencia,
        propertyForm.whatsapp,
        propertyForm.operacion,
        "1"
      )
      .subscribe((resp) => {
        let respJson = resp;
        this.propertyForm.controls["descripcion"].setValue(resp);
        this.loading = false;
      });
  }

  generateDescriptionDivertida(event: any) {
    event.preventDefault();
    this.loading = true;
    let propertyForm = this.propertyForm.value;
    let precio: string = propertyForm.precio.replace(/[^0-9]/g, "");
    let valorAdmin: string = propertyForm.administracion.replace(/[^0-9]/g, "");
    this.additionalsService
      .getDescription(
        propertyForm.inmueble,
        propertyForm.municipio,
        propertyForm.habitaciones,
        propertyForm.banos,
        propertyForm.barrio,
        precio,
        valorAdmin,
        propertyForm.tamano,
        propertyForm.parqueadero,
        propertyForm.piso,
        propertyForm.vendedor_agencia,
        propertyForm.whatsapp,
        propertyForm.operacion,
        "0"
      )
      .subscribe((resp) => {
        let respJson = resp;
        this.propertyForm.controls["descripcion"].setValue(resp);
        this.loading = false;
      });
  }
}
