<div class="modal-header">
    <h2 class="modal-title">Hola agente!!!</h2>
</div>

<div class="modal-body">
    <p class="modal-text">
        <b>Crea tu cuenta gratis y accede a:</b>
    </p>
    <ul class="modal-list">
        <li>4 días de acceso ilimitado</li>
        <li>Ver más de 20 resultados x búsqueda</li>
      <li>Acceso a más de 800.000 propiedades</li>
       <li>Eliminar duplicados</li>
       <li>Descarga (1) reporte búsqueda en excel</li>
       <li>Sube tus primeros 50 inmuebles</li>
    </ul>

    <br>

    <button type="button" class="submit-btn" [routerLink]="['/login', 'signUp']" (click)="closeModal()">
       Crear mi cuenta
    </button>
</div>

