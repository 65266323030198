<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>
<div class="container-fluid" *ngIf="!loading">
    <div class="container mt-20">
        <div class="row">
            <div class="col-lg-9">
                <h3 class="title">
                    Mi inventario
                </h3>
                <h5 *ngIf='user && user["attributes"]["custom:active_plan"] == 4 && packagePlanInfo'
                    style="color: black;">Tienes un paquete de {{packagePlanInfo.properties}} Propiedades activo</h5>

            </div>
            <div class="col-lg-3 text-right">
                <button class="btn" (click)="crearInmueble()">
                    Crear un inmueble
                </button>
            </div>
        </div>
    </div>
    <div class="container mt-10">
        <div class="row">
            <div class="col-12">
                <div class="table-responsive table-scroll mat-elevation-z8 border-radius-top">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <!-- ID Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                        </ng-container>

                        <!-- City Column -->
                        <ng-container matColumnDef="city">
                            <th mat-header-cell *matHeaderCellDef> Ciudad </th>
                            <td mat-cell *matCellDef="let element"> {{element.Municipio}} </td>
                        </ng-container>


                        <!-- Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Estado </th>
                            <td mat-cell *matCellDef="let element">
                                <fa-icon [icon]="faCircle"
                                    [ngClass]="{'fa-success': element.Verificado == 'si'}"></fa-icon>
                                {{element.Verificado == 'si' ? 'Disponible': 'No disponible'}}
                            </td>
                        </ng-container>

                        <!-- Verified Column -->
                        <ng-container matColumnDef="verified">
                            <th mat-header-cell *matHeaderCellDef> Verificado </th>
                            <td mat-cell *matCellDef="let element">
                                SI
                            </td>
                        </ng-container>

                        <!-- Estate Type Column -->
                        <ng-container matColumnDef="estateType">
                            <th mat-header-cell *matHeaderCellDef> Tipo de inmueble </th>
                            <td mat-cell *matCellDef="let element"> {{element.Inmueble}} </td>
                        </ng-container>

                        <!-- Operation Type Column -->
                        <ng-container matColumnDef="operationType">
                            <th mat-header-cell *matHeaderCellDef> Operación </th>
                            <td mat-cell *matCellDef="let element"> {{element.Operacion}} </td>
                        </ng-container>

                        <!-- Price Column -->
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef> Precio </th>
                            <td mat-cell *matCellDef="let element"> {{element.Precio}} </td>
                        </ng-container>

                        <!-- Admin Column -->
                        <ng-container matColumnDef="admin">
                            <th mat-header-cell *matHeaderCellDef> Administración </th>
                            <td mat-cell *matCellDef="let element"> {{element.Valor_Admin}} </td>
                        </ng-container>

                        <ng-container matColumnDef="url">
                            <th mat-header-cell *matHeaderCellDef> Url </th>
                            <td mat-cell *matCellDef="let element"> <button class="btn-share" (click)="openUrl(element)"
                                    mat-button><fa-icon [icon]="faShare" class="mr-10"></fa-icon>Compartir</button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element">
                                <button class="btn-edit" (click)="edit(element)" mat-button>Editar</button>
                                <button class="btn-delete" (click)="delete(element)" mat-button>Eliminar</button>
                            </td>

                        </ng-container>

                        <ng-container matColumnDef="loading">
                            <td mat-footer-cell *matFooterCellDef colspan="19">
                                Cargando información...
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="noData">
                            <td mat-footer-cell *matFooterCellDef colspan="19" style="text-align: center">
                                No hay inmuebles creados
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide': dataSource!=null}"></tr>
                        <tr mat-footer-row *matFooterRowDef="['noData']"
                            [ngClass]="{'hide': !(dataSource!=null && dataSource.data.length == 0) }"></tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-paginator #paginator [length]="length" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
                    [pageSize]="pageSize" class="mat-elevation-z8 border-radius-bottom" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
<!-- Start of HubSpot Embed Code -->
<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8998298.js"></script>
<!-- End of HubSpot Embed Code -->