import { Component, OnInit, ViewChild } from '@angular/core';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { CardsChart, IBarChart } from 'src/app/models/charts.interface';
import { Auth } from "aws-amplify";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { InventoryService } from 'src/app/services/inventory.service';
import { AdditionalsService } from 'src/app/services/additionals.service';
import { Router } from '@angular/router';
import { InventoryModel } from 'src/app/models/inventory.model';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { faCircle, faShare } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { FormControl, FormGroup } from '@angular/forms';
import { AgenciesService } from 'src/app/services/agencies.service';

@Component({
  selector: 'app-property-statistics',
  templateUrl: './property-statistics.component.html',
  styleUrls: ['./property-statistics.component.css']
})
export class PropertyStatisticsComponent implements OnInit {

  user: CognitoUserInterface | undefined;

  usersAgency: any[] = []
  allProperties: any[] | undefined;

  formGroupSelect = new FormGroup({
    viewPropertiesOf: new FormControl('Todos'),
  });


  // Table
  displayedColumns: string[] = ['select', '#', 'id', 'city', 'barrio', 'estateType', 'operationType', 'totalViews']
  dataSource!: MatTableDataSource<InventoryModel>
  length: number | undefined
  pageSize: number = 10
  pageIndex: number = 0
  pageSizeOptions: number[] = [5, 10, 25, 100]
  maxSelections = 5;
  loading: boolean = true;
  loadingTable: boolean = true;
  faCircle = faCircle;
  faShare = faShare;
  packagePlanInfo: any | undefined;
  selection = new SelectionModel<any>(true, []);

  // LineCharts
  colorSchema = 'fire';
  dataLineChart: IBarChart[] = [];
  legend: boolean = true;
  legendTitle: string = "Leyenda"
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Fecha';
  yAxisLabel: string = 'Visualizaciones';
  timeline: boolean = true;

  // Cards
  cardColor: string = '#232837';
  dataCards: CardsChart[] = [];

  // Visualizations
  propertyViews: any[] = [];

  constructor(private inventoryService: InventoryService, private agenciesService: AgenciesService, private additionalsService: AdditionalsService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then(user => {
      this.user = user;
    });

    this.additionalsService.getPropertyViews().subscribe(resp => {
      this.propertyViews = resp.items;
    });

    if (this.user?.attributes?.nickname == 'Agencia Inmobiliaria') {
      this.getInventory('Todos');
      this.getUsersAgency();
    } else {
      this.getInventory(this.user?.attributes.email);
    }

    this.formGroupSelect.get('viewPropertiesOf')?.valueChanges.subscribe((emailUser: any) => {
      this.dataSource = new MatTableDataSource();
      this.selection = new SelectionModel<any>(true, []);
      this.getInventory(emailUser);
    });
  }

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  getInventory(userEmail: string) {
    this.loadingTable = true;
    if (userEmail != 'Todos') {
      this.inventoryService.getInventory(this.pageSize.toString(), (this.pageIndex + 1).toString(), userEmail).subscribe((resp: any) => {
        if (resp.items.length == 0) {
          this.dataSource = new MatTableDataSource()
          this.length = 0
        } else {
          this.length = resp.items.length;

          //Agregar informacion de visualizacion a las propiedades si el ID coincide
          for (var i = 0; i < resp.items.length; i++) {
            var obj1Id = resp.items[i].id;
            var obj2Match = this.propertyViews.find(function (obj) {
              return obj.id === obj1Id;
            });
            if (obj2Match) {
              Object.assign(resp.items[i], obj2Match);
            }
          }
          this.calculatedData(resp.items);
          this.dataSource = new MatTableDataSource(resp.items);
          // Por defecto seleccionar el maximo de elementos
          resp.items.forEach((row: any) => {
            const selectedData = this.selection.selected.length;
            if (selectedData < this.maxSelections) {
              this.selection.select(row)
            }
          });
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loadingTable = false;
        this.loading = false;

      }, error => {
        this.dataSource = new MatTableDataSource();
        this.loadingTable = false;
        this.loading = false;
        console.log(error);
        Swal.fire({
          title: "Error",
          text: 'Ha ocurrido un error al cargar las propiedades',
          confirmButtonColor: '#ff653f',
          confirmButtonText: 'Ok',
        })
      })

    } else {
      if (this.allProperties != undefined) {
        this.dataSource = new MatTableDataSource(this.allProperties);
        this.calculatedData(this.allProperties);
        this.allProperties.forEach((row: any) => {
          const selectedData = this.selection.selected.length;
          if (selectedData < this.maxSelections) {
            this.selection.select(row)
          }
        });
        this.loadingTable = false;
        this.loading = false;
      } else {
        let agencyName = this.user?.attributes?.family_name;
        this.inventoryService.getInventoryAgency(this.pageSize.toString(), (this.pageIndex + 1).toString(), agencyName).subscribe((resp: any) => {
          if (resp.items.length == 0) {
            this.dataSource = new MatTableDataSource()
            this.length = 0
          } else {
            this.length = resp.items.length;

            //Agregar informacion de visualizacion a las propiedades si el ID coincide
            for (var i = 0; i < resp.items.length; i++) {
              var obj1Id = resp.items[i].id;
              var obj2Match = this.propertyViews.find(function (obj) {
                return obj.id === obj1Id;
              });
              if (obj2Match) {
                Object.assign(resp.items[i], obj2Match);
              }
            }
            this.allProperties = resp.items;
            this.calculatedData(resp.items);
            this.dataSource = new MatTableDataSource(resp.items);
            // Por defecto seleccionar el maximo de elementos
            resp.items.forEach((row: any) => {
              const selectedData = this.selection.selected.length;
              if (selectedData < this.maxSelections) {
                this.selection.select(row)
              }
            });
          }
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loadingTable = false;
          this.loading = false;
        }, error => {
          this.dataSource = new MatTableDataSource();
          this.loadingTable = false;
          this.loading = false;
          console.log(error);
          Swal.fire({
            title: "Error",
            text: 'Ha ocurrido un error al cargar los ACMs',
            confirmButtonColor: '#ff653f',
            confirmButtonText: 'Ok',
          })
        });
      }
    }
  }

  getUsersAgency() {
    let agencyName = this.user?.attributes?.family_name;
    this.agenciesService.getUsers(agencyName).subscribe(resp => {
      this.usersAgency = resp.users;
    });
  }

  calculatedData(dataArray: any[]) {
    const totalOverallViews = dataArray.reduce((acum, currentItem) => {
      if (currentItem.hasOwnProperty('totalViews')) {
        return acum + currentItem.totalViews;
      } else {
        return acum;
      }
    }, 0)
    let higherVisits = 0
    let dayMaxVisitsCount = 0
    let dateMaxVisits = new Date();
    const arrayVisualizations = dataArray.map(item => {
      if (item.totalViews > higherVisits) {
        higherVisits = item.totalViews
      }
      return {
        'name': item.id,
        'totalViews': item.totalViews,
        'series': item.visualizations.map((vis: any) => {
          if (vis.count > dayMaxVisitsCount) {
            dateMaxVisits = new Date(vis.date);
            dayMaxVisitsCount = vis.count
          }
          const timeOptions: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
          return { 'name': new Date(vis.date).toLocaleString('es-CO', timeOptions), 'value': vis.count }
        })
      }
    })
    
    let dayMaxVisits = ''
    if(dayMaxVisitsCount != 0) {
      dayMaxVisits = `${dateMaxVisits.getDate()} ${dateMaxVisits.toLocaleString('es-CO', { month: 'long' })}`
    }

    this.dataLineChart = arrayVisualizations;
    this.dataCards =  [
      {
        name: "Visualizaciones Totales",
        value: totalOverallViews
      },
      {
        name: "Propiedad con mas vistas",
        value: higherVisits
      },
      {
        name: "Dia más visitado",
        value: dayMaxVisits
      }
    ]
    
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === this.maxSelections || numSelected === numRows;
  }

  onSelectionChange(event: any, row?: any) {
    if (event.source.name === 'selectAll') {
      if (event.checked) {
        // seleccionar todas las casillas individuales
        // limitar la selección a `maxSelections` elementos
        this.dataSource.data.forEach(row => {
          const selectedData = this.selection.selected.length;
          if (selectedData < this.maxSelections) {
            this.selection.select(row)
          }
        });
      } else {
        // deseleccionar todas las casillas individuales
        this.dataSource.data.forEach(row => this.selection.deselect(row));
      }
    } else {
      if (event.checked) {
        // realizar seleccion individual
        const selectedCount = this.selection.selected.length;

        if (selectedCount >= this.maxSelections) {
          event.source.checked = false;
          Swal.fire({
            text: `Solo se permite seleccionar un máximo de ${this.maxSelections} elementos.`,
            icon: 'error',
            confirmButtonColor: '#FF653F',
          })
        } else {
          this.selection.select(row);
        }
      } else {
        // realizar deseleccion individual
        this.selection.deselect(row)
      }
    }
    const selectedRows = this.selection.selected;
    this.calculatedData(selectedRows)
  }

  openUrl(element: any) {
    window.open("https://www.homty.co/propiedad?id=" + element.id);
  }
}
