<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>

<section class="pricing pt-4 pb-4">
  <div *ngIf="!loading" class="container">

    <div class="row">
      <h1>
        Historial de ACMs
      </h1>

      <ng-container *ngIf="user?.attributes?.nickname != 'Agencia Inmobiliaria'">
        <p>Visualiza nuevamente los ACMs generados </p>
      </ng-container>

      <ng-container *ngIf="user?.attributes?.nickname == 'Agencia Inmobiliaria'">
        <p>Visualiza nuevamente los ACMs generados de todos tus usuarios. <br> Selecciona <strong>Todos</strong> para
          ver todos los ACMs generados por los usuarios o selecciona un <strong>usuario especifico</strong> para ver los
          ACMs de solo ese usuario. </p>

        <div class="form-group mb-4 col-sm-12 col-md-6 col-lg-6" [formGroup]="formGroupSelect">
          <label for="rol">Ver ACMs de:</label>
          <div class="select-container">
            <select id="viewAcmsOf" formControlName="viewAcmsOf" class="form-control select-view-acms">
              <option value="Todos">Todos</option>
              <option *ngFor="let user of usersAgency" [value]="user.email">{{user.nombre}} - {{user.email}}</option>
              <option *ngIf="usersAgency.length == 0" value="info" disabled>No hay usuarios</option>
            </select>
            <i class="fas fa-chevron-down"></i>
          </div>
        </div>

      </ng-container>

    </div>

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive table-scroll" data-mdb-perfect-scrollbar="true">

              <table mat-table [dataSource]="dataSource" matSort class="table-hover mat-elevation-z8 border-radius-top">

                <!-- Index Column -->
                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> <strong>#</strong> </th>
                  <td mat-cell *matCellDef="let acm; let i = index;"> {{i + 1}} </td>
                </ng-container>

                <!-- Municipio Column -->
                <ng-container matColumnDef="municipio">
                  <th mat-header-cell *matHeaderCellDef> Municipio </th>
                  <td mat-cell *matCellDef="let acm">{{acm.municipio | titlecase}}
                  </td>
                </ng-container>

                <!-- Barrio Column -->
                <ng-container matColumnDef="barrio">
                  <th mat-header-cell *matHeaderCellDef> Barrio </th>
                  <td mat-cell *matCellDef="let acm">{{acm.location.barrio | titlecase}}
                  </td>
                </ng-container>

                <!-- Inmueble Column -->
                <ng-container matColumnDef="inmueble">
                  <th mat-header-cell *matHeaderCellDef> Tipo de inmueble </th>
                  <td mat-cell *matCellDef="let acm">{{acm.inmueble | titlecase}}
                  </td>
                </ng-container>

                <!-- Tamano Column -->
                <ng-container matColumnDef="tamano">
                  <th mat-header-cell *matHeaderCellDef> Tamaño m² </th>
                  <td mat-cell *matCellDef="let acm">{{acm.tamano}}
                  </td>
                </ng-container>

                <!-- Precio Column -->
                <ng-container matColumnDef="precio">
                  <th mat-header-cell *matHeaderCellDef> Precio Avaluado </th>
                  <td mat-cell *matCellDef="let acm">{{acm.ACP.precio | currency: 'USD': 'symbol' : '1.0-0'}}
                  </td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef> Realizado el: </th>
                  <td mat-cell *matCellDef="let acm"> {{acm.date}} </td>
                </ng-container>

                <ng-container matColumnDef="loading">
                  <td mat-footer-cell *matFooterCellDef colspan="19" style="text-align: center">
                    Cargando información &nbsp; <i class="fa-solid fa-spinner fa-spin" style="font-size: 1.3rem; color: #FF653F"></i> 
                  </td>
                </ng-container>

                <ng-container matColumnDef="noData">
                  <td mat-footer-cell *matFooterCellDef colspan="19" style="text-align: center">
                    No se encontraron ACMs
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let acm; columns: displayedColumns;" (click)="selectAcm(acm)"></tr>
                <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide': !loadingTable}"></tr>
                <tr mat-footer-row *matFooterRowDef="['noData']"
                  [ngClass]="{'hide': !(!loadingTable && dataSource!=null && dataSource.data.length == 0)  }"></tr>

              </table>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>