<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>

<section class="pricing pt-4">
  <div *ngIf="!loading" class="container">
    <div class="row">
      <h1>
        Estadísticas de visualización
      </h1>

      <ng-container *ngIf="user?.attributes?.nickname != 'Agencia Inmobiliaria'">
        <p>Conoce el número de visitas de tus propiedades publicadas. <br> Selecciona en la tabla las propiedades que quieras
          visualizar en el gráfico (<strong>Máximo 5</strong>)</p>
      </ng-container>

      <ng-container *ngIf="user?.attributes?.nickname == 'Agencia Inmobiliaria'">
        <p>Conoce el número de visitas de tus propiedades publicadas. <br> Selecciona <strong>Todos</strong> para
          ver todas las propiedades creadas por los usuarios o selecciona un <strong>usuario especifico</strong> para ver las
          propiedades creadas de solo ese usuario. <br> Selecciona en la tabla las propiedades que quieras
          visualizar en el gráfico (<strong>Máximo 5</strong>).</p>

        <div class="form-group mb-4 col-sm-12 col-md-6 col-lg-6" [formGroup]="formGroupSelect">
          <label for="rol">Ver propiedades creadas por:</label>
          <div class="select-container">
            <select id="viewPropertiesOf" formControlName="viewPropertiesOf" class="form-control select-view-acms">
              <option value="Todos">Todos</option>
              <option *ngFor="let user of usersAgency" [value]="user.email">{{user.nombre}} - {{user.email}}</option>
              <option *ngIf="usersAgency.length == 0" value="info" disabled>No hay usuarios</option>
            </select>
            <i class="fas fa-chevron-down"></i>
          </div>
        </div>

      </ng-container>

    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive table-scroll mat-elevation-z8 border-radius-top">
          <table mat-table [dataSource]="dataSource!" matSort>

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox name="selectAll" (change)="$event ? onSelectionChange($event) : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="onSelectionChange($event, row)"
                  [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </td>
              <!-- onSelectionChange($event, row) -->
            </ng-container>

            <!-- #Index Column -->
            <ng-container matColumnDef="#">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
              <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element"><button class="btn-share" (click)="openUrl(element)"
                  mat-button>{{element.id}}</button> </td>
            </ng-container>

            <!-- City Column -->
            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef> Ciudad </th>
              <td mat-cell *matCellDef="let element"> {{element.Municipio | titlecase}} </td>
            </ng-container>

            <!--  Column -->
            <ng-container matColumnDef="barrio">
              <th mat-header-cell *matHeaderCellDef> Barrio </th>
              <td mat-cell *matCellDef="let element"> {{element.Barrio | titlecase}} </td>
            </ng-container>

            <!-- Estate Type Column -->
            <ng-container matColumnDef="estateType">
              <th mat-header-cell *matHeaderCellDef> Inmueble </th>
              <td mat-cell *matCellDef="let element"> {{element.Inmueble}} </td>
            </ng-container>

            <!-- Operation Type Column -->
            <ng-container matColumnDef="operationType">
              <th mat-header-cell *matHeaderCellDef> Operación </th>
              <td mat-cell *matCellDef="let element"> {{element.Operacion}} </td>
            </ng-container>

            <!-- Total Views Column -->
            <ng-container matColumnDef="totalViews">
              <th mat-header-cell *matHeaderCellDef> Visualizaciones </th>
              <td mat-cell *matCellDef="let element"> {{ element.totalViews !== undefined ? element.totalViews : 0 }}
              </td>
            </ng-container>

            <ng-container matColumnDef="loading">
              <td mat-footer-cell *matFooterCellDef colspan="19" style="text-align: center">
                Cargando información &nbsp; <i class="fa-solid fa-spinner fa-spin"
                  style="font-size: 1.3rem; color: #FF653F"></i>
              </td>
            </ng-container>

            <ng-container matColumnDef="noData">
              <td mat-footer-cell *matFooterCellDef colspan="19" style="text-align: center">
                No hay datos para mostrar
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide': !loadingTable}"></tr>
            <tr mat-footer-row *matFooterRowDef="['noData']"
              [ngClass]="{'hide': !(!loadingTable && dataSource!=null && dataSource.data.length == 0) }"></tr>
          </table>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <mat-paginator #paginator [length]="length" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize" class="mat-elevation-z8 border-radius-bottom" showFirstLastButtons>
        </mat-paginator>
      </div>

    </div>

    <div class="row mt-4 mb-5" style="height: 10rem;">
      <ngx-charts-number-card [scheme]="colorSchema" [results]="dataCards" [cardColor]="cardColor">
      </ngx-charts-number-card>
    </div>
    <div class="row mt-4">
      <div class="col-lg-12 col-md-12 col-sm-12" style="height: 500px;">
        <ngx-charts-line-chart [scheme]="colorSchema" [legend]="legend" [legendTitle]="legendTitle"
          [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis"
          [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="dataLineChart">
        </ngx-charts-line-chart>
      </div>
    </div>

  </div>
</section>