<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>
<div class="container mt-30">
    <div class="row">
        <div class="col-lg-8">
            <h1>Subir inmueble</h1>
        </div>
        <div class="col-lg-4 ">
            <h5 *ngIf='user && user["attributes"]["custom:active_plan"] == 4 && packagePlanInfo' style="color: black;">
                Tienes un paquete de {{packagePlanInfo.properties}} Propiedades activo</h5>
        </div>
    </div>

    <p>Ingresa la información del inmueble que deseas publicar. Todos los campos son obligatorios. Asegúrate de subir imágenes de buena calidad para captar la atención de los interesados.</p>
    <h4>Información básica del inmueble</h4>
    <h6>Disponible</h6>
    <label class="switch">
        <input type="checkbox" [checked]="habilitado" [(ngModel)]="habilitado">
        <span class="slider round"></span>
    </label>
    <form class="mt-20" [formGroup]="propertyForm" (ngSubmit)="onSubmit()">

        <div class="row mb-3">
            <div class="mb-3 col-md-6">
                <label for="inmueble" class="form-label required">Tipo de inmueble</label>
                <select class="form-select shadow-none homty-color" name="inmueble" formControlName="inmueble">
                    <option *ngIf="propertyToUpdate">
                        {{propertyForm.value.inmueble}}
                    </option>
                    <option [value]="propertyType.Inmueble" *ngFor="let propertyType of propertyTypes">
                        {{propertyType.Inmueble}}
                    </option>
                </select>
                <div *ngIf="propertyForm.controls['inmueble']?.errors?.required  && (propertyForm.controls['inmueble'].dirty || propertyForm.controls['inmueble'].touched)"
                    class="mt-2 alert alert-danger">
                    El tipo de inmueble es obligatorio
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="operacion" class="form-label required">Operación</label>
                <select class="form-select shadow-none homty-color" name="operacion" formControlName="operacion">
                    <option value="Venta">Venta</option>
                    <option value="Arriendo">Arriendo</option>
                </select>
                <div *ngIf="propertyForm.controls['operacion']?.errors?.required  && (propertyForm.controls['operacion'].dirty || propertyForm.controls['operacion'].touched)"
                    class="mt-2 alert alert-danger">
                    El tipo de operación es obligatorio
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="mb-3 col-md-6">
                <label for="precio" class="form-label required">Precio de venta / Alquiler</label>
                <input name="precio" [inputMask]="currencyInputMask" class="form-control shadow-none" id="precio"
                    formControlName="precio">
                <div *ngIf="propertyForm.controls['precio']?.errors?.required  && (propertyForm.controls['precio'].dirty || propertyForm.controls['precio'].touched)"
                    class="mt-2 alert alert-danger">
                    El precio es obligatorio
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="administracion" class="form-label required">
                    Gastos de administración
                    <mat-icon class="help-icon" matTooltip="Los gastos de administración de un inmueble son los costos que se incurren en la gestión , mantenimiento y conservación de dicho inmueble. Estos gastos pueden incluir el pago de empleados del equipo de administración, como gerentes y conserjes, así como también los costos asociados con la limpieza, mantenimiento, reparación y mejora de las instalaciones del inmueble." matTooltipClass="my-tooltip">help_outline</mat-icon>
                </label>
                <input name="valor_admin" [inputMask]="currencyInputMask" class="form-control shadow-none"
                    id="administracion" formControlName="administracion">
                <div *ngIf="propertyForm.controls['administracion']?.errors?.required  && (propertyForm.controls['administracion'].dirty || propertyForm.controls['administracion'].touched)"
                    class="mt-2 alert alert-danger">
                    Gastos de administración es obligatorio
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="mb-3 col-md-6">
                <label for="ciudad" class="form-label required">Municipio</label>
                <input class="form-control shadow-none" list="cities" name="municipio" id="municipio"
                    formControlName="municipio">
                <datalist id="cities">
                    <option *ngFor="let city of cities" [value]="city">
                </datalist>
                <div *ngIf="propertyForm.controls['municipio']?.errors?.required  && (propertyForm.controls['municipio'].dirty || propertyForm.controls['municipio'].touched)"
                    class="mt-2 alert alert-danger">
                    El municipio es obligatorio
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="barrio" class="form-label required">Barrio</label>
                <input type="text" class="form-control shadow-none" id="barrio" name="barrio" formControlName="barrio">
                <div *ngIf="propertyForm.controls['barrio']?.errors?.required  && (propertyForm.controls['barrio'].dirty || propertyForm.controls['barrio'].touched)"
                    class="mt-2 alert alert-danger">
                    El barrio es obligatorio
                </div>
            </div>

        </div>
        <div class="row mb-3">
            <div class="form-group">
                <label>
                    Dirección
                    <mat-icon class="help-icon" matTooltip="Formato esperado: Calle/Carretera/Vía [nombre de la vía] [número] [opcional: - piso/depto], [ciudad], [provincia/estado], [país]. Ejemplo: Carrera 50 #43-19, Medellín, Antioquia, Colombia" matTooltipClass="my-tooltip">help_outline</mat-icon>
                </label>
                <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                placeholder="Ejemplo: Carrera 50 #43-19, Medellín, Antioquia, Colombia" autocorrect="off" autocapitalize="off" spellcheck="off"
                    type="text" #search>
            </div>

            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (mapReady)="mapClicked($event)">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                    (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map>

        </div>

        <div>
            <label class="image-upload-container btn btn-bwm inline-block">
                <span>Adjuntar imagen</span>
                <input class="upload_images" #imageInput type="file" accept="image/*" multiple="" id="imagenes"
                    name="imagenes" formControlName="imagenes" (change)="onFileChange($event)">
            </label>
            <div *ngIf="propertyForm.controls['imagenes']?.errors?.required  && (propertyForm.controls['imagenes'].dirty || propertyForm.controls['imagenes'].touched)"
                class="mt-2 alert alert-danger">
                Debe adjuntar por lo menos una imagen
            </div>
        </div>

        <div class="row mt-20 animate__animated animate__fadeIn">
            <div *ngFor='let url of images; let i=index;' class="col-md-3">
                <img [src]="url" style="margin: 3px;">
                <span class="position-absolute pointer top-20 ml--2 translate-middle bg-danger badge rounded-pill"
                    (click)="deleteImage(i)">
                    x
                </span>
            </div>


        </div>

        <h4 class="mt-30">Características del inmueble</h4>
        <div class="row mb-3">
            <div class="mb-3 col-md-6">
                <label for="estrato" class="form-label required">Estrato</label>
                <input type="number" class="form-control shadow-none" id="estrato" name="estrato"
                    formControlName="estrato">
                <div *ngIf="propertyForm.controls['estrato']?.errors?.required  && (propertyForm.controls['estrato'].dirty || propertyForm.controls['estrato'].touched)"
                    class="mt-2 alert alert-danger">
                    El estrato es obligatorio
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="antiguedad" class="form-label required">Antigüedad</label>
                <select class="form-select shadow-none homty-color" name="antiguedad" formControlName="antiguedad">
                    <option value="sin especificar">Sin especificar</option>
                    <option value="menor a 1 año">Menor a 1 año</option>
                    <option value="1 a 8 años">1 a 8 años</option>
                    <option value="9 a 15 años">9 a 15 años</option>
                    <option value="16 a 30 años">16 a 30 años</option>
                    <option value="más de 30 años">más de 30 años</option>
                </select>
                <div *ngIf="propertyForm.controls['antiguedad']?.errors?.required  && (propertyForm.controls['antiguedad'].dirty || propertyForm.controls['antiguedad'].touched)"
                    class="mt-2 alert alert-danger">
                    La antigüedad es obligatoria
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="mb-3 col-md-6">
                <label for="habitaciones" class="form-label required">Habitaciones</label>
                <input type="number" class="form-control shadow-none" id="habitaciones" name="habitaciones"
                    formControlName="habitaciones" placeholder="Número de habitaciones">
                <div *ngIf="propertyForm.controls['habitaciones']?.errors?.required  && (propertyForm.controls['habitaciones'].dirty || propertyForm.controls['habitaciones'].touched)"
                    class="mt-2 alert alert-danger">
                    El número de habitaciones es obligatorio
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="banos" class="form-label required">Baños</label>
                <input type="number" class="form-control shadow-none" id="banos" name="banos" formControlName="banos" placeholder="Número de baños">
                <div *ngIf="propertyForm.controls['banos']?.errors?.required  && (propertyForm.controls['banos'].dirty || propertyForm.controls['banos'].touched)"
                    class="mt-2 alert alert-danger">
                    El número de baños es obligatorio
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="mb-3 col-md-6">
                <label for="parqueadero" class="form-label required">Parqueadero</label>
                <input type="number" class="form-control shadow-none" id="parqueadero" name="parqueadero"
                    formControlName="parqueadero" placeholder="Número de parqueaderos">
                <div *ngIf="propertyForm.controls['parqueadero']?.errors?.required  && (propertyForm.controls['parqueadero'].dirty || propertyForm.controls['parqueadero'].touched)"
                    class="mt-2 alert alert-danger">
                    El número de parqueaderos es obligatorio
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="piso" class="form-label required">Piso</label>
                <input type="number" class="form-control shadow-none" id="piso" name="piso" formControlName="piso" placeholder="Número de piso">
                <div *ngIf="propertyForm.controls['piso']?.errors?.required  && (propertyForm.controls['piso'].dirty || propertyForm.controls['piso'].touched)"
                    class="mt-2 alert alert-danger">
                    El número de piso es obligatorio
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="mb-3 col-md-6">
                <label for="area_construida" class="form-label required">Área en m2</label>
                <input type="number" class="form-control shadow-none" id="area_construida" name="tamano"
                    formControlName="tamano">
                <div *ngIf="propertyForm.controls['tamano']?.errors?.required  && (propertyForm.controls['tamano'].dirty || propertyForm.controls['tamano'].touched)"
                    class="mt-2 alert alert-danger">
                    El tamaño es obligatorio
                </div>
            </div>

        </div>
        <h4 class="mt-30">Datos de contacto</h4>
        <div class="mb-3">
            <label for="nombre_contacto" class="form-label required">Nombre del contacto</label>
            <input type="text" class="form-control shadow-none" id="nombre_contacto" name="vendedor_agencia"
                formControlName="vendedor_agencia">
            <div *ngIf="propertyForm.controls['vendedor_agencia']?.errors?.required  && (propertyForm.controls['vendedor_agencia'].dirty || propertyForm.controls['vendedor_agencia'].touched)"
                class="mt-2 alert alert-danger">
                El nombre del contacto es obligatorio
            </div>
        </div>
        <div class="mb-3">
            <label for="whatsapp_contacto" class="form-label required">Número de WhatsApp</label>
            <input type="number" class="form-control shadow-none" id="whatsapp_contacto" name="whatsapp"
                formControlName="whatsapp">
            <div *ngIf=" propertyForm.controls['whatsapp'].invalid && propertyForm.controls['whatsapp'].touched"
                class="mt-2 alert alert-danger">
                <div *ngIf="propertyForm.controls['whatsapp'].errors?.required">El WhatsApp es obligatorio</div>
                <div *ngIf="propertyForm.controls['whatsapp'].errors?.phoneValidator">
                    El número de WhatsApp debe tener 10 dígitos
                </div>
            </div>

        </div>

        <div class="row mb-3">
            <div>
                <label for="descripcion" class="form-label space-between">
                    <span class="required">Descripción</span>
                    <div class="d-flex align-items-end">
                        <button type="button" class="btn btn-action shadow-none mr-5"
                            (click)="generateDescriptionFormal()" matTooltip="Generar una descripción formal" matTooltipClass="my-tooltip">
                            <mat-icon>description</mat-icon>
                        </button>
                        <button type="button" class="btn btn-action shadow-none"
                            (click)="generateDescriptionDivertida()" matTooltip="Generar una descripción informal" matTooltipClass="my-tooltip">
                            <mat-icon>note_alt</mat-icon>
                        </button>
                    </div>
                </label>
                <textarea class="form-control shadow-none" id="descripcion" rows="5" name="descripcion"
                    formControlName="descripcion"></textarea>
                <div *ngIf="propertyForm.controls['descripcion']?.errors?.required  && (propertyForm.controls['descripcion'].dirty || propertyForm.controls['descripcion'].touched)"
                    class="mt-2 alert alert-danger">
                    La descripcion es obligatoria
                </div>
            </div>

        </div>

        <!-- <button class="btn shadow-none" type="button">Adjuntar imagen</button> -->

        <button type="submit" class="btn btn-submit shadow-none">Guardar
            inmueble</button>
    </form>



    <app-footer></app-footer>

</div>