import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Notification } from 'src/app/models/notification.model';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private subject: AnonymousSubject<MessageEvent> | null = null;
  public notifications: Subject<Notification> | undefined;
  public received: Notification[] = [];
  public viewedNotifications: any[] = [];
  public sent: Object[] = [];
  private ws: WebSocket | null = null;  
  private emailUser: string = "";
  public showBadgeNotifications = true;
  public loading: boolean = true;

  constructor(private http: HttpClient) {
  }

  public connect(email: string): void {
    if (!this.subject) {
      this.emailUser = email;
      this.subject = this.create();
      // console.log("Successfully connected: " + environment.webSocketUrl);
    }
    this.notifications = <Subject<Notification>>this.subject.pipe(
      map(
        (response: MessageEvent): Notification => {
          this.showBadgeNotifications = true;
          let data = JSON.parse(response.data)
          this.received.push(data);
          return data;
        }
      )
    );
    this.loadInitialNotifications();
  }

  public loadInitialNotifications() {
    const url = `${environment.base_url}/getnotifications?email=${this.emailUser}`;

    this.http.get<any>(url)
    .subscribe((response) => {
      // console.log(response);
      if(response.answer) {
        this.received = response.notifications;
        this.viewedNotifications = response.viewedNotifications;
      }
      this.loading = false;
      // this.received = notifications
      // notifications.forEach((notification) => {
      //   this.notifications?.next(notification);
      // });
    }, (error) => {
      console.error('Error loading initial notifications', error);
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'Ha ocurrido un error al cargar las notificaciones',
        showCancelButton: false,
        confirmButtonColor: '#fc6641',
        confirmButtonText: 'Aceptar'
      })
    });
  }

  public disconnect(): void {
    if (this.ws) {
      this.ws.close();
      this.subject = null;
      this.ws = null;
      // console.log("Disconnected from: " + environment.webSocketUrl);
    }
  }

  public isConnected(): boolean {    
    return this.ws != null && this.ws.readyState === WebSocket.OPEN;
  }

  public sendMessage(notification: any): void {
    if (this.isConnected()) {
      this.notifications!.next(notification);
    } else {
      console.warn('WebSocket is not connected');
    }
  }

  private create(): AnonymousSubject<MessageEvent> {
    this.ws = new WebSocket(`${environment.webSocketUrl}?email=${this.emailUser}`);
    
    let observable = Observable.create((obs: Observer<MessageEvent>) => {
      this.ws!.onmessage = obs.next.bind(obs);
      this.ws!.onerror = obs.error.bind(obs);
      this.ws!.onclose = obs.complete.bind(obs);
      return this.ws!.close.bind(this.ws!);
    });
    let observer = {
      error: (err: any) => console.error('WebSocket error: ', err),
      complete: () => console.log('WebSocket connection closed'),
      next: (data: Object) => { 
        // console.log('Message sent to websocket: ', data);
        if (this.ws!.readyState === WebSocket.OPEN) {
          this.sent.push(data);
          this.ws!.send(JSON.stringify(data));
        }
      }
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }
}
