import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Observable, throwError } from "rxjs";
import { catchError, map, startWith } from "rxjs/operators";
import { Estate } from "src/app/models/estate.model";
import { EstateService } from "src/app/services/estate.service";
import { PopupEstateService } from "src/app/services/popup-estate.service";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import {
  faFilter,
  faLink,
  faList,
  faSpinner,
  faStream,
  faTags,
  faHeart,
  faDownload,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { EstatePage } from "src/app/models/estatepage.model";
import {
  onAuthUIStateChange,
  AuthState,
  CognitoUserInterface,
} from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { createMask } from "@ngneat/input-mask";
import Swal from "sweetalert2";
import { AuthenticatorService } from "@aws-amplify/ui-angular";

import { RegisterModalComponent } from "./register-modal/register-modal.component";
import { DownloadPrivateComponent } from "./../components/download-private/download-private.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MdePopoverTrigger } from "@material-extended/mde";
import { HttpParams } from "@angular/common/http";
import { SharingService } from "src/app/services/sharing.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Meta, Title } from "@angular/platform-browser";

declare var jQuery: any;

function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === "string") {
      return null;
    }
    return null; /* valid option selected */
  };
}

@Component({
  selector: "app-estate",
  templateUrl: "./estate.component.html",
  providers: [PopupEstateService],
  styleUrls: ["./estate.component.css"],
})
export class EstateComponent implements OnInit, AfterViewInit {
  @ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger | undefined;
  @ViewChild("detallesModal") detallesModal: ElementRef | undefined;

  user: CognitoUserInterface | undefined;
  currentPlan: string | undefined;
  viewType: number = 0;
  viewTypeLabels: Array<any> = [
    { label: "Tarjetas", value: 0, restricted: false },
    { label: "Mapa", value: 1, restricted: true },
    { label: "Tabla", value: 2, restricted: false },
  ];
  renovationDate: any | undefined;
  todayDate: any | undefined;
  remainingDays: number | undefined;
  remainingDaysFree: number | undefined;
  remainingDaysAnual: number | undefined;
  remainingDaysSemestral: number | undefined;
  changeText = false;
  authState: AuthState | undefined;
  activePlan: boolean = true;
  errorMessage: string = "";
  selectedMinMax: any;

  faFilter = faFilter;
  faList = faList;
  faStream = faStream;
  faLink = faLink;
  faTags = faTags;
  faWhatsapp = faWhatsapp;
  faSpinner = faSpinner;
  faHeart = faHeart;
  faDownload = faDownload;
  faChevronDown = faChevronDown;
  barrio = new FormControl(""); //,Validators.required);

  displayedColumns: string[] = [
    "Barrio",
    "Inmueble",
    "Operacion",
    "Precio",
    "Habitaciones",
    "Banos",
    "Parking",
    "Estrato",
    "Valor_Admin",
    "Tamano",
    "Antiguedad",
    "Piso",
    "Miguel",
  ];

  dataSource: MatTableDataSource<EstatePage> | undefined;
  data: any | undefined;
  viewOnMap: boolean = false;
  length: number | undefined;
  totalLength: number | undefined;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  latitude: number = 6.230833;
  longitude: number = -75.590553;
  zoom: number = 13;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  searchKey: string = "";

  filterEntity: Estate = new Estate(
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    []
  );

  currencyInputMask = createMask({
    alias: "numeric",
    groupSeparator: ",",
    digits: 0,
    prefix: "$ ",
  });
  precioMin = new FormControl("", Validators.required);
  precioMax = new FormControl("");
  valorAdmin = new FormControl("");
  valorAdminMax = new FormControl("");

  mtmin: string = "";
  mtmax: string = "";

  public loading: boolean = true;
  loadingSort: boolean = false;

  sortState: Sort | undefined;

  // Select de municipios
  municipioAutocompleteControl = new FormControl("", {
    validators: [autocompleteObjectValidator(), Validators.required],
  });
  selectedMunicipio: string = "";
  selectedBarrio: string = "";
  selectedPropertyType: string = "";
  selectedOperation: string = "";
  selectedHabitaciones: string = "";
  selectedBanos: string = "";
  selectedParqueadero: string = "";
  selectedEstrato: string = "";
  selectedEstratoMax: string = "";
  selectedPiso: string = "";
  selectedAntiguedad: string = "--";
  filteredCities: Observable<string[]> | undefined;
  propertyTypes: any[] = [];
  cities: string[] = [];
  buttonText: string = "";
  origins: any[] = [];
  selectedOrigin: string = "";
  showAllFilters: boolean = false;
  selectedPropertyTypeFixed: string = "";
  selectedOperationFixed: string = "";
  selectedMunicipioFixed: string = "";
  showModal: boolean = false;
  dontShowAgain: boolean = false;

  public validation_msgs = {
    municipioAutocompleteControl: [
      {
        type: "required",
        message:
          "Municipio no reconocido, por favor selecciona uno de la lista",
      },
    ],
    precioMin: [{ type: "required", message: "Se debe ingresar un valor " }],
  };

  selectedPropertyToModal: Estate = new Estate(
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    []
  );

  constructor(
    private estateService: EstateService,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private router: Router,
    public authenticator: AuthenticatorService,
    public matDialog: MatDialog,
    private sharingService: SharingService,
    private popupEstateService: PopupEstateService,
    private title: Title,
    private meta: Meta
  ) {}

  ngAfterViewInit(): void {}

  openModal() {
    /* const modelDiv = document.getElementById("myModal"); */
    /* if (modelDiv != null) {
      modelDiv.style.display = "block";
      
    } */
    Swal.fire({
      title: "¿Te gustaría descargar las propiedades en excel?",
      iconHtml: '<img src="./assets/images/fav.png">',
      customClass: {
        icon: "no-border",
      },
      html:
        "Te explicamos: <br><br>" +
        '<ol><li style="padding-bottom:10px">Tienes que registrarte.</li><li style="padding-bottom:10px">Ver los diferentes precios que Homty maneja</li>',
      confirmButtonText: "Quiero registrarme",

      confirmButtonColor: "#FF653F",
      focusConfirm: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.router.navigate(["/login", "signUp"]);
      }
    });
  }

  closeModal() {
    const modelDiv = document.getElementById("myModal");
    if (modelDiv != null) {
      modelDiv.style.display = "none";
    }
  }

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then((user) => {
      this.user = user;
    });
    this.getCities();
    this.getPropertyTypes();
    this.getOrigins();
    // this.showModal = true;

    this.dontShowAgain = this.popupEstateService.getDontShowAgain();

    // Mostrar Popup Informativo
    /* setTimeout(() => {
      if (!this.dontShowAgain) {
        this.showInfoPopup();
      }
    }, 1 * 60 * 1000); */

    this.activatedRoute.queryParams.subscribe((params) => {
      this.precioMin.setValue(params.preciomin);
      this.precioMax.setValue(params.preciomax);
      this.selectedMunicipio = params.municipio;
      this.selectedOperation = params.operacion;
      this.municipioAutocompleteControl.setValue(this.selectedMunicipio);
      this.selectedPropertyType = params.inmueble;
      const filtro = localStorage.getItem("filtro");
      if (filtro) {
        this.selectedMinMax = filtro;
      } else {
        this.selectedMinMax = "Precio";
      }

      if (typeof params.estratomax !== "undefined")
        this.selectedEstratoMax = params.estratomax;
      else this.selectedEstratoMax = "";

      if (typeof params.estrato !== "undefined")
        this.selectedEstrato = params.estrato;
      else this.selectedEstrato = "";

      if (typeof params.barrio !== "undefined")
        this.selectedBarrio = params.barrio;
      else this.selectedBarrio = "";

      if (typeof params.habitaciones !== "undefined")
        this.selectedHabitaciones = params.habitaciones;
      else this.selectedHabitaciones = "";

      if (typeof params.banos !== "undefined")
        this.selectedBanos = params.banos;
      else this.selectedBanos = "";

      if (typeof params.mtmin !== "undefined") this.mtmin = params.mtmin;
      else this.mtmin = "";

      if (typeof params.mtmax !== "undefined") this.mtmax = params.mtmax;
      else this.mtmax = "";

      if (typeof params.parqueadero !== "undefined")
        this.selectedParqueadero = params.parqueadero;
      else this.selectedParqueadero = "";

      if (typeof params.antiguedad !== "undefined")
        this.selectedAntiguedad = params.antiguedad;
      else this.selectedAntiguedad = "--";

      if (typeof params.piso !== "undefined") this.selectedPiso = params.piso;
      else this.selectedPiso = "";

      if (typeof params.valorAdminmax == "undefined")
        this.valorAdminMax = new FormControl("");
      else this.valorAdminMax.setValue(params.valorAdminmax);

      if (typeof params.valorAdmin == "undefined")
        this.valorAdmin = new FormControl("");
      else this.valorAdmin.setValue(params.valorAdmin);

      //SEO
      this.title.setTitle(
        `Homty - ${this.selectedPropertyType} en ${this.selectedOperation} en ${this.selectedMunicipio}`
      );
      this.meta.addTag({
        name: "description",
        content: `Homty contacto inmobiliario, ${this.selectedPropertyType} en ${this.selectedOperation} en ${this.selectedMunicipio}`,
      });
      this.meta.addTag({
        name: "keywords",
        content: `homty, inmobiliario, propiedades, ${this.selectedPropertyType}, ${this.selectedOperation}, ${this.selectedMunicipio}`,
      });

      this.getPropertiesFilteredAndPaginated2(
        params.inmueble,
        params.operacion,
        this.selectedMinMax,
        params.municipio,
        params.preciomin,
        params.preciomax,
        this.selectedBarrio,
        this.selectedHabitaciones,
        params.banos,
        this.pageSize.toString(),
        "1",
        params.mtmin,
        params.mtmax,
        params.parqueadero,
        params.estrato,
        params.estratomax,
        params.valorAdmin,
        params.valorAdminmax,
        params.antiguedad,
        params.piso,
        "Todos"
      );
    });
    // console.log(this.data);
  }

  enableDisablePopover() {
    this.trigger?.togglePopover();
  }

  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = "./assets/images/placeholder-image2.jpg";
  }

  openProperty() {
    let origenHomty = this.selectedPropertyToModal.Origenes.find(
      (origen: any) => origen.Origen == "Homty"
    );

    if (origenHomty) {
      // window.open(origenHomty.URL, "_blank");

      const id = origenHomty.URL.split("=")[1];
      this.router.navigate(["propiedad"], { queryParams: { id: id } });
    } else {
      jQuery(this.detallesModal?.nativeElement).modal("show");
    }
  }

  changeViewType(viewType: number) {
    this.setLoading(true);
    this.viewType = viewType;
    this.setLoading(false);
  }

  getCurrentPlan() {
    if (this.user) {
      if (
        this.user["attributes"]["custom:active_plan"] == 1 ||
        this.user["attributes"]["custom:active_plan"] == 2 ||
        this.user["attributes"]["custom:active_plan"] == 3 ||
        this.user["attributes"]["custom:free_trial"] == 1
      ) {
        let renovationDateStrig =
          this.user["attributes"]["custom:renovation_date"].split("/");
        renovationDateStrig[0]++;
        this.renovationDate = new Date(
          `${renovationDateStrig[2]}-${renovationDateStrig[1]}-${renovationDateStrig[0]}`
        );
        this.todayDate = new Date();
        this.remainingDays = Math.abs(this.todayDate - this.renovationDate);
        this.remainingDaysFree = Math.round(
          4 - this.remainingDays / (1000 * 3600 * 24)
        );
        this.remainingDaysAnual = Math.round(
          365 - this.remainingDays / (1000 * 3600 * 24)
        );
        this.remainingDaysSemestral = Math.round(
          180 - this.remainingDays / (1000 * 3600 * 24)
        );
        this.remainingDays = Math.round(
          30 - this.remainingDays / (1000 * 3600 * 24)
        );
      }
    }
  }

  getPropertiesFilteredAndPaginated2(
    inmueble: string,
    operacion: string,
    order: string,
    municipio: string,
    preciomin: string,
    preciomax: string,
    barrio: string,
    habitaciones: string,
    banos: string,
    pageSize: string,
    pageNumber: string,
    mtmin: string,
    mtmax: string,
    parqueadero: string,
    estrato: string,
    estratoMax: string,
    valorAdmin: string,
    valorAdminMax: string,
    antiguedad: string,
    piso: string,
    origin: string,
    sortState?: Sort
  ) {
    this.selectedPropertyTypeFixed = inmueble;
    this.selectedMunicipioFixed = municipio;
    this.selectedOperationFixed = operacion;

    let emailHistory: string = "NA";

      if (this.user && this.user["attributes"]["custom:active_plan"] != 0) {
        this.getCurrentPlan();
        this.estateService
          .getPropertiesFilteredAndPaginatedLogin2(
            inmueble,
            operacion,
            order,
            municipio,
            preciomin,
            preciomax,
            barrio,
            habitaciones,
            banos,
            pageSize,
            pageNumber,
            mtmin,
            mtmax,
            parqueadero,
            estrato,
            estratoMax,
            valorAdmin,
            valorAdminMax,
            antiguedad,
            piso,
            this.user ? this.user["attributes"]["email"] : "NA",
            origin,
            sortState
          )
          .subscribe(
            (resp) => {
              this.dataSource = new MatTableDataSource<EstatePage>(resp.items);
              this.data = resp.items;

              if (resp.meta.totalItems > 0) {
                this.length = resp.meta.totalItems;
              } else {
                this.length = 0;
              }

              this.pageIndex = parseInt(pageNumber) - 1;

              this.setLoading(false);
              this.loadingSort = false;
            },
            (error) => {
              console.log("Error");
              console.log(error);
              console.log(error.status);
              this.setLoading(false);
              this.loadingSort = false;
              this.dataSource = new MatTableDataSource();
              this.data = [];
              // if (error.status == 403) {
              //   this.activePlan = false;
              // }

              this.errorMessage =
                "Opps! Ha ocurrido un error al realizar la búsqueda!";
              this.buttonText = "Registrate";
            }
          );
      } else {
        if(this.user && (this.user["attributes"]["custom:free_trial"] == 1 || this.user["attributes"]["custom:active_plan"] == 0)){
          this.setLoading(false);
          this.loadingSort = false;
          Swal.fire({
            title: "Opción no disponible",
            text: "Debes tener una membresía activa para usar Homty. Solicita una demostración y 4 días gratis de uso.",
            showCancelButton: true,
            confirmButtonColor: "#fc6641",
            confirmButtonText: "Comprar",
            cancelButtonText: "Solicitar una demostración",
          }).then((result) => {
             
            if (result.isConfirmed) {
              this.router.navigate(["pay-subscription"]);
            }else{
               if(result.isDismissed){
                window.open("https://calendly.com/miguelabadia/30minutos", "_blank");
               }
            }
          });
        }else{
          this.setLoading(false);
          this.loadingSort = false;
          Swal.fire({
            title: "Opción no disponible",
            text: "Debes registrarte, ¿Deseas Registrarte?",
            showCancelButton: true,
            confirmButtonColor: "#fc6641",
            confirmButtonText: "Registrate",
            cancelButtonText: "Cancelar",
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(["/login/signUp"]);
            }
          });
        }
      }
    
  }

  onPageChange(event: PageEvent) {
    this.setLoading(true);
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.selectedMinMax = localStorage.getItem("filtro");

    this.getPropertiesFilteredAndPaginated2(
      this.selectedPropertyType,
      this.selectedOperation,
      this.selectedMinMax,
      this.selectedMunicipio,
      this.precioMin.value.replace(/[^0-9]/g, ""),
      this.precioMax.value.replace(/[^0-9]/g, ""),
      this.selectedBarrio,
      this.selectedHabitaciones,
      this.selectedBanos,
      this.pageSize.toString(),
      this.pageIndex.toString(),
      this.mtmin,
      this.mtmax,
      this.selectedParqueadero,
      this.selectedEstrato,
      this.selectedEstratoMax,
      this.valorAdmin.value.replace(/[^0-9]/g, ""),
      this.valorAdminMax.value.replace(/[^0-9]/g, ""),
      this.selectedAntiguedad,
      this.selectedPiso,
      this.selectedOrigin,
      this.sortState
    );
  }

  applyFilter() {
    if (this.municipioAutocompleteControl.valid && this.precioMin.valid) {
      this.activePlan = true;
      this.setLoading(true);
      this.length = 0;
      let params = this.getParams();

      this.selectedPropertyTypeFixed = this.selectedPropertyType;
      this.selectedMunicipioFixed = this.selectedMunicipio;

      this.selectedOperationFixed = this.selectedOperation;
      this.selectedMinMax = localStorage.getItem("filtro");

      this.router.navigate(["/propiedades"], {
        queryParams: params,
      });

      this.getPropertiesFilteredAndPaginated2(
        this.selectedPropertyType,
        this.selectedOperation,
        this.selectedMinMax,
        this.selectedMunicipio,
        this.precioMin.value.replace(/[^0-9]/g, ""),
        this.precioMax.value.replace(/[^0-9]/g, ""),
        this.selectedBarrio,
        this.selectedHabitaciones,
        this.selectedBanos,
        this.pageSize.toString(),
        "1",
        this.mtmin,
        this.mtmax,
        this.selectedParqueadero,
        this.selectedEstrato,
        this.selectedEstratoMax,
        this.valorAdmin.value.replace(/[^0-9]/g, ""),
        this.valorAdminMax.value.replace(/[^0-9]/g, ""),
        this.selectedAntiguedad,
        this.selectedPiso,
        this.selectedOrigin
      );
    }
  }

  getParams(): any {
    let params: any = {
      inmueble: this.selectedPropertyType,
      operacion: this.selectedOperation,
      municipio: this.selectedMunicipio,
      preciomin: this.precioMin.value.replace(/[^0-9]/g, ""),
      preciomax: this.precioMax.value.replace(/[^0-9]/g, ""),
      barrio: this.selectedBarrio,
      habitaciones: this.selectedHabitaciones,
      banos: this.selectedBanos,
      pagesize: this.pageSize.toString(),
      page: this.pageIndex.toString(),
      mtmin: this.mtmin,
      mtmax: this.mtmax,
      parqueadero: this.selectedParqueadero,
      estrato: this.selectedEstrato,
      estratomax: this.selectedEstratoMax,
      valorAdmin: this.valorAdmin.value.replace(/[^0-9]/g, ""),
      valorAdminmax: this.valorAdminMax.value.replace(/[^0-9]/g, ""),
      antiguedad: this.selectedAntiguedad,
      piso: this.selectedPiso,
    };

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        if (
          params[key] === null ||
          params[key] === undefined ||
          params[key] === "" ||
          params[key] === "0"
        ) {
          delete params[key];
        }
      }
    }

    return params;
  }

  download() {
    this.setLoading(true);
    Auth.currentSession().then(
      (resp) => {
        Auth.currentUserInfo().then(
          (user) => {
            this.user = user;
            if (
              this.user!["attributes"]["custom:free_trial"] == 1 ||
              user["attributes"]["custom:active_plan"] == 1 ||
              user["attributes"]["custom:active_plan"] == 2 ||
              user["attributes"]["custom:active_plan"] == 3
            ) {
              this.estateService
                .downloadPropertiesLogin(
                  this.selectedPropertyType,
                  this.selectedOperation,
                  this.selectedMunicipio,
                  this.precioMin.value.replace(/[^0-9]/g, ""),
                  this.precioMax.value.replace(/[^0-9]/g, ""),
                  this.selectedBarrio,
                  this.selectedHabitaciones,
                  this.selectedBanos,
                  this.mtmin,
                  this.mtmax,
                  this.selectedParqueadero,
                  this.selectedEstrato,
                  this.selectedEstratoMax,
                  this.valorAdmin.value.replace(/[^0-9]/g, ""),
                  this.valorAdminMax.value.replace(/[^0-9]/g, ""),
                  this.selectedAntiguedad,
                  this.selectedPiso,
                  this.user!["attributes"]["email"],
                  this.selectedOrigin
                )
                .subscribe(
                  (resp) => {
                    window.open(resp.url);
                    this.setLoading(false);
                  },
                  (error) => {
                    if (error.status == 403) {
                      this.setLoading(false);
                      this.matDialog.open(DownloadPrivateComponent, {
                        width: "510px",
                        data: { message: error.error.error },
                      });
                    }
                  }
                );
            } else {
              this.setLoading(false);
              this.loadingSort = false;
              this.activePlan = false;
              this.errorMessage =
                "Opps! No tienes un plan activo,Te invitamos a adquirir uno de nuestros planes!";
              this.buttonText = "Ver planes";
            }
          },
          (error) => {
            this.setLoading(false);
            this.activePlan = false;
            this.errorMessage =
              "Opps! para realizar la descarga,Te invitamos a registrarte y obtener 4 días gratuitos!";
            this.buttonText = "Regístrate";
          }
        );
      },
      (error) => {
        this.setLoading(false);
        this.activePlan = false;
        this.errorMessage =
          "Opps! para realizar la descarga,Te invitamos a registrarte y obtener 4 días gratuitos!";
        this.buttonText = "Regístrate";
      }
    );
  }
  // Metodos para el select de municipio
  updateMunicipioSelection(value: string) {
    this.selectedMunicipio = value;
    this.applyFilter();
  }

  updateMaxMin() {
    localStorage.removeItem("filtro");
    localStorage.setItem("filtro", this.selectedMinMax);
  }

  getCities() {
    this.estateService.getCities().subscribe((cities) => {
      for (let i = 0; i < cities.length; i++) {
        this.cities.push(cities[i].Municipio);
      }
      this.filteredCities = this.municipioAutocompleteControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    });
  }

  getPropertyTypes() {
    this.estateService.getPropertyTypes().subscribe((resp) => {
      this.propertyTypes = resp;
    });
  }

  getOrigins() {
    this.estateService.getOrigins().subscribe((resp) => {
      this.origins = resp;
      this.selectedOrigin = "Todos";
    });
  }

  updateFavorite(value: string, propertyId: string) {
    this.estateService
      .updateFavorite(this.user!["attributes"]["email"], propertyId, value)
      .subscribe((resp) => {});
  }

  getProperty(element: any) {
    this.sharingService.sharingValue = element;
    let url = this.router.createUrlTree(["/propiedad"], {
      queryParams: {
        id: element.id,
      },
    });

    window.open(url.toString(), "_blank");

    // this.router.navigate(['/propiedad'],
    //   {
    //     queryParams: {
    //       id: element.id,
    //     },
    //     state: {
    //       currentElement: element
    //     }
    //   },
    // );
  }

  showRegisterModal() {
    let terms = Swal.fire({
      title: "Si quieres guardar tus inmuebles favoritos ¡Regístrate!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Regístrate",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["login"]);
      }
    });
  }

  /* showInfoPopup() {
    Swal.fire({
      title: '¿Te gustaría encontrar la propiedad que buscas más rápido?',
      iconHtml: '<img src="./assets/images/fav.png">',
      customClass: {
        icon: 'no-border'
      },
      html:
        'Te ayudamos: <br><br>' +
        '<ol><li style="padding-bottom:10px">Nosotros contactamos directamente a los propietarios o agencias.</li><li style="padding-bottom:10px">Espera que te contacten solamente para propiedades <b>disponibles</b>.</li><li style="padding-bottom:10px">No pierdas tiempo llamando a propiedades que ya no están disponibles.</li></ol> ' +
        '<div class="form-check text-center"><input class="form-check-input" type="checkbox" id="dontShowAgainCheck"><label class="form-check-label" for="dontShowAgainCheck">No volver a mostrar este aviso</label></div>',
      showCloseButton: true,
      confirmButtonText: "Quiero encontrar más rápido",

      confirmButtonColor: "#FF653F",
      focusConfirm: false,
    }).then((result: any) => {
      const checkbox = document.querySelector('#dontShowAgainCheck') as HTMLInputElement;
      this.popupEstateService.setDontShowAgain(checkbox.checked);
      if (result.isConfirmed) {
        this.router.navigate(['/owners']);
      }
    })
  } */

  updatePrices(event: any) {
    if (event.value == "arriendo") {
      this.precioMin.setValue("400000");
      this.precioMax.setValue("200000000");
    } else {
      this.precioMin.setValue("20000000");
      this.precioMax.setValue("70000000000");
    }
  }

  sortPropertiesByColumn(sortState: Sort) {
    this.loadingSort = true;
    this.dataSource = undefined;
    this.data = undefined;
    this.sortState = sortState;
    this.selectedMinMax = localStorage.getItem("filtro");

    this.getPropertiesFilteredAndPaginated2(
      this.selectedPropertyType,
      this.selectedOperation,
      this.selectedMinMax,
      this.selectedMunicipio,
      this.precioMin.value.replace(/[^0-9]/g, ""),
      this.precioMax.value.replace(/[^0-9]/g, ""),
      this.selectedBarrio,
      this.selectedHabitaciones,
      this.selectedBanos,
      this.pageSize.toString(),
      (this.pageIndex + 1).toString(),
      this.mtmin,
      this.mtmax,
      this.selectedParqueadero,
      this.selectedEstrato,
      this.selectedEstratoMax,
      this.valorAdmin.value.replace(/[^0-9]/g, ""),
      this.valorAdminMax.value.replace(/[^0-9]/g, ""),
      this.selectedAntiguedad,
      this.selectedPiso,
      this.selectedOrigin,
      sortState
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  openRegisterModal() {
    if (!this.loading) {
      if (this.authenticator.route !== "authenticated") {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = "modal-component";
        dialogConfig.height = "510px";
        dialogConfig.width = "500px";
        const modalDialog = this.matDialog.open(
          RegisterModalComponent,
          dialogConfig
        );
      }
    }
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
    if (this.showModal) {
      this.openRegisterModal();
    }
    this.showModal = false;
  }

  homty360() {
    this.router.navigate(["/homty360"]);
  }
}
