import { Component, OnInit } from '@angular/core';
import { WebsocketService } from '../services/websocket.service';
import { ToastrService } from 'ngx-toastr';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from 'src/aws-exports';
import { notificationConfig } from '../pages/notifications/notificationConfig';
import { AgenciesService } from '../services/agencies.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: [
  ]
})

export class PagesComponent implements OnInit {

  user: CognitoUserInterface | undefined;

  constructor(private websocketService: WebsocketService, private agenciesServices: AgenciesService, private toastr: ToastrService) {
    try {
      Amplify.configure(awsExports);
    } catch {
    }

  }

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then(user => {
      this.user = user;
      let email = user?.attributes?.email;
      if (email) {
        this.websocketService.connect(email);
      }
    });
    this.receiveNotifications();
    if (this.user?.attributes.nickname == 'Agencia Inmobiliaria') {
      this.agenciesServices.getAgencyDetails(this.user!.attributes.family_name).subscribe((resp: any) => {
        // console.log(resp);
        if(resp.answer == false) { 
          this.openModalAgencyData();
        }
      }, error => {
        this.openModalAgencyData();
      });
    }
  }

  ngOnDestroy() {
    this.websocketService.disconnect();
  }

  async receiveNotifications() {

    this.websocketService.notifications?.subscribe((notification) => {
      // console.log(notification);
      let message = notification.message.length > 40 ? notification.message.substring(0,40).concat(" ...") : notification.message;
      switch (notification.type) {
        case 'success':
          this.toastr.success(message, notification.title, notificationConfig);
          break;
        case 'info':
          this.toastr.info(message, notification.title, notificationConfig);
          break;
        case 'warning':
          this.toastr.warning(message, notification.title, notificationConfig);
          break;
        case 'error':
          this.toastr.error(message, notification.title, notificationConfig);
          break;
      }
    });

  }

  openModalAgencyData() {
    setTimeout(() => {
      const boton = document.getElementById('btnModalAgencyData');
      boton!.click();
    }, 10);
  }

  closeModalAgencyData() {
    const boton = document.getElementById('closeModalAgencyData');
    boton?.click();
  }

}
