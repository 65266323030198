import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

/* import AmplifyUIAngularModule  */
/*import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';*/

import {Amplify, Analytics } from 'aws-amplify';

import awsconfig from "../aws-exports";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PagesModule } from './pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputMaskModule } from '@ngneat/input-mask';
import { NgxHotjarModule } from 'ngx-hotjar';
import { MetaModule } from '@ngx-meta/core';

const analyticsConfig = {
  AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: '6f964cc6197244c98727fb21185a663f',
        // Amazon service region
        region: 'us-east-1',
        mandatorySignIn: false,
  }
}

Analytics.configure(analyticsConfig);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    /* configure app with AmplifyUIAngularModule */
    /*AmplifyUIAngularModule,*/
    BrowserAnimationsModule,
    InputMaskModule,
    NgxHotjarModule.forRoot('2345467'),
    MetaModule.forRoot()
   
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
