<app-loading
  *ngIf="loading"
  class="animate__animated animate__fadeIn vertical-center"
>
</app-loading>

<section *ngIf="user" style="background-color: #f4f5f7">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-lg-8 mb-4 mb-lg-0">
        <div class="card mb-3" style="border-radius: 0.5rem">
          <div class="row g-0">
            <div
              class="col-md-4 gradient-custom text-center text-white"
              style="
                border-top-left-radius: 0.5rem;
                border-bottom-left-radius: 0.5rem;
              "
            >
              <picture>
                <source
                  srcset="assets/images/profile/user.webp"
                  type="image/webp"
                />
                <source
                  srcset="assets/images/profile/user.png"
                  type="image/png"
                />
                <img
                  src="assets/images/profile/user.webp"
                  alt="Avatar"
                  class="img-fluid my-5"
                  style="width: 80px"
                />
              </picture>
              <h5>{{ user["attributes"]["family_name"] }}</h5>
              <p>{{ user["attributes"]["nickname"] }}</p>
              <button
                class="btn btn-secondary m-2"
                routerLink="/change-password"
                style="font-size: 0.8rem; border-radius: 1rem"
              >
                Cambiar Contraseña <i class="far fa-edit ml-1"></i>
              </button>
            </div>
            <div class="col-md-8">
              <div class="card-body p-4">
                <h5 class="color-orange">Información</h5>
                <hr class="mt-0 mb-4" />
                <div class="row pt-1">
                  <div class="col-7 mb-1">
                    <h6>Email</h6>
                    <p class="text-muted">{{ user["attributes"]["email"] }}</p>
                  </div>
                  <div class="col-5 mb-1">
                    <h6>Celular</h6>
                    <p class="text-muted">
                      {{ user["attributes"]["phone_number"] }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <h5 class="col-6 color-orange">Mi Suscripción</h5>
                  <div class="col-6">
                    <a
                      routerLink="/pay-subscription"
                      class="form-control btn btn-custom hvr-grow"
                      type="button"
                      >Cambiar suscripción</a
                    >
                  </div>
                </div>

                <hr class="mt-0 mb-4" />
                <div class="row pt-1">
                  <div class="col-6 mb-3">
                    <h6>Plan Actual</h6>
                    <p class="text-muted">
                      {{
                        user["attributes"]["custom:free_trial"] == 1
                          ? "Prueba gratuita"
                          : user["attributes"]["custom:active_plan"] == 1
                          ? "Plan Bronce activo"
                          : user["attributes"]["custom:active_plan"] == 2
                          ? "Plan Plata activo"
                          : user["attributes"]["custom:active_plan"] == 3
                          ? "Plan Oro activo"
                          : user["attributes"]["custom:active_plan"] == 4
                          ? "Plan por paquete activo"
                          : user["attributes"]["custom:active_plan"] == 7
                          ? "Plan paquete Agencia Inmobiliaria"
                          : "No tienes un plan activo"
                      }}
                      <span
                        *ngIf="
                          user && user['attributes']['custom:active_plan'] == 4
                        "
                        ><br /><strong>ACMs:</strong>
                        {{ packagePlanInfo?.acms }} &nbsp;
                        <strong>Propiedades:</strong>
                        {{ packagePlanInfo?.properties }}</span
                      >
                    </p>
                  </div>
                  <div class="col-6 mb-3">
                    <h6>Renovación</h6>
                    <p class="text-muted">
                      {{
                        user["attributes"]["custom:free_trial"] == 1
                          ? "Ninguna"
                          : user["attributes"]["custom:active_plan"] == 1
                          ? "Mensual"
                          : user["attributes"]["custom:active_plan"] == 2
                          ? "Mensual"
                          : user["attributes"]["custom:active_plan"] == 3
                          ? "Mensual"
                          : user["attributes"]["custom:active_plan"] == 4
                          ? "Propiedades activas por: " +
                            packagePlanInfo?.propertiesdays +
                            " días"
                          : user["attributes"]["custom:active_plan"] == 7
                          ? "Anual"
                          : "No tienes un plan activo"
                      }}
                    </p>
                  </div>
                </div>
                <div class="row pt-1">
                  <div class="col-6 mb-3">
                    <h6>Valor</h6>
                    <p class="text-muted">
                      {{
                        user["attributes"]["custom:free_trial"] == 1
                          ? "$0"
                          : user["attributes"]["custom:active_plan"] == 1
                          ? "$50.000"
                          : user["attributes"]["custom:active_plan"] == 2
                          ? "$150.000"
                          : user["attributes"]["custom:active_plan"] == 3
                          ? "$500.000"
                          : user["attributes"]["custom:active_plan"] == 4
                          ? "Pago individual por ACMs y Propiedades "
                          : user["attributes"]["custom:active_plan"] == 7
                          ? "$250.000 X Usuario"
                          : "No tienes un plan activo"
                      }}
                    </p>
                  </div>
                  <div class="col-6 mb-3">
                    <h6>Fecha de Compra</h6>
                    <p class="text-muted">
                      {{ user["attributes"]["custom:renovation_date"] }}
                    </p>
                  </div>
                </div>
                <!-- <div class="d-flex justify-content-start">
                                    <a href="#!"><i class="fab fa-facebook-f fa-lg me-3"></i></a>
                                    <a href="#!"><i class="fab fa-twitter fa-lg me-3"></i></a>
                                    <a href="#!"><i class="fab fa-instagram fa-lg"></i></a>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Start of HubSpot Embed Code -->
<script
  type="text/javascript"
  id="hs-script-loader"
  async
  defer
  src="//js.hs-scripts.com/8998298.js"
></script>
<!-- End of HubSpot Embed Code -->
