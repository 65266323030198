import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

const base_url = environment.base_url;

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  createPayment(
    email: string,
    amount: string,
    vendor: string = "",
    acms: string = "0",
    properties: string = "0",
    numberUsers: string = "0"
  ) {
    const url = `${base_url}/create_payment`;

    let params = new HttpParams()
      .set("email", email)
      .set("amount", amount)
      .set("vendor", vendor)
      .set("acms", acms)
      .set("propiedades", properties)
      .set("usuariosagencia", numberUsers);

    return this.http.get<any>(url, { params });
  }

  createPaymentContactOwners(
    email: string,
    whatsapp: string,
    amount: string,
    guest: boolean = false
  ) {
    const url = `${base_url}/create_payment`;

    let params = new HttpParams()
      .set("email", email)
      .set("whatsapp", whatsapp)
      .set("amount", amount);

    let dict = guest ? { params, headers: { skip: "true" } } : { params };

    return this.http.get<any>(url, dict);
  }

  getPaymentState(ordenId: string, email: string, guest: boolean = false) {
    const url = `${base_url}/getpaymentstate`;

    let params = new HttpParams().set("order", ordenId).set("email", email);

    let dict = guest ? { params, headers: { skip: "true" } } : { params };

    return this.http.get<any>(url, dict);
  }

  getOrderInformation(ordenId: string, checksum: string) {
    const url = `https://api-test.payvalida.com/api/v3/porders/${ordenId}`;
    let params = new HttpParams()
      .set("merchant", "homtysas")
      .set("checksum", checksum);

    return this.http.get<any>(url, { params });
  }
}
