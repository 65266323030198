export const prices = {
  packages: {
    acms: 10000,
    properties: 5000,
    agencyUsers: 250000,
  },
  suscriptions: {
    mensual_Bronce: 50000,
    mensual_Plata: 150000,
    mensual_Oro: 500000,
    semestral: 240000,
    anual: 360000,
  },
};
