<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Homty - La base de datos más completa del sector inmobiliario</title>
    <link href="/info/styles.css" rel="stylesheet" type="text/css" />
    <link rel="stylesheet" href="https://use.typekit.net/biv3flv.css" />
  </head>

  <body>
    <main>
      <section class="homty-connect">
        <div class="container">
          <div class="uxers__gridRow alg-center">
            <div class="uxers__gridCol-6a">
              <h2>
                Invierte tu tiempo en crecer tu negocio, no en buscar
                propiedades
              </h2>
              <ul>
                <li>
                  Información en tiempo real de todas las plataformas
                  inmobiliarias.
                </li>
                <li>Filtros especializados de búsqueda</li>
                <li>Búsquedas ilimitadas</li>
              </ul>
              <a routerLink="login"
                ><button>Prueba gratis por 4 días</button></a
              >
            </div>
            <div class="uxers__gridCol-6b">
              <picture>
                <source
                  srcset="assets/images/img/homty-connect.webp"
                  type="image/webp"
                />
                <source
                  srcset="assets/images/img/homty-connect.png"
                  type="image/png"
                />
                <img src="assets/images/img/homty-connect.webp" alt="" />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section class="homty-time">
        <div class="container">
          <div class="uxers__gridRow alg-center">
            <div class="uxers__gridCol-6a">
              <picture>
                <source
                  srcset="assets/images/img/homty-success.webp"
                  type="image/webp"
                />
                <source
                  srcset="assets/images/img/homty-success.png"
                  type="image/png"
                />
                <img src="assets/images/img/homty-success.webp" alt="" />
              </picture>
            </div>
            <div class="uxers__gridCol-6b">
              <h2>
                El éxito de tu negocio depende de lo que haces con tu tiempo
              </h2>
              <p>
                ¿Sabías que un agente inmobiliario promedio tarda entre 4 a 5
                horas diarias buscando propiedades?
              </p>
              <p>¡ESO SON CASI 100 HORAS AL MES EN BUSCADORES!</p>
            </div>
          </div>
        </div>
      </section>
      <section class="homty-success">
        <div class="container">
          <div class="uxers__gridRow">
            <div class="uxers__gridCol-6a">Imagen</div>
            <div class="uxers__gridCol-6b">
              <h2 class="blue-title">
                Aprovechar bien tu tiempo es el camino al éxito
              </h2>
              <p>
                Con Homty tendrás más tiempo para aprender, fidelizar a tus
                clientes o compartir con tu familia.
              </p>
              <a routerLink="login"><button>Empieza ya</button></a>
            </div>
          </div>
        </div>
      </section>
      <section class="homty-productive">
        <div class="container text-center">
          <h2>Sé 3X más productivo a partir de hoy</h2>
          <p>
            Para sacar el gigante que llevas dentro, solo necesitas la
            tecnología correcta.
          </p>
          <a routerLink="login"><button>Crea tu cuenta</button></a>
        </div>
      </section>
      <section class="homty-academy">
        <div class="container">
          <div class="uxers__gridRow alg-center">
            <div class="uxers__gridCol-6a">
              <picture>
                <source
                  srcset="assets/images/img/homty-academia.webp"
                  type="image/webp"
                />
                <source
                  srcset="assets/images/img/homty-academia.jpg"
                  type="image/jpg"
                />
                <img src="assets/images/img/homty-academia.webp" alt="" />
              </picture>
            </div>
            <div class="uxers__gridCol-6b">
              <h2>¡Un buen agente nunca deja de aprender!</h2>
              <p>
                Únete gratis a Homty Academy y aprende cómo duplicar tus
                resultados trabajando inteligentemente.
              </p>
              <a href="https://t.me/+oZScRZLvOdZhNjdh"
                ><button>Ingresa a Homty Academy</button></a
              >
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div class="container">
          <div class="uxers__gridRow">
            <div class="uxers__gridCol-4a">
              <p>
                <picture>
                  <source
                    srcset="assets/images/img/homty-logo.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="assets/images/img/homty-logo.png"
                    type="image/png"
                  />
                  <img src="assets/images/img/homty-logo.webp" alt="Homty" />
                </picture>
              </p>
              <p>
                +57 305 4064684<br />
                Medellín, Colombia
              </p>
            </div>
            <div class="uxers__gridCol-4b">
              <ul>
                <li><a href="#">Sobre Homty</a></li>
                <li><a routerLink="pay-subscription">Precios</a></li>
                <li><a routerLink="contactenos">Contacto</a></li>
              </ul>
            </div>
            <div class="uxers__gridCol-4c">
              <ul>
                <li>
                  <a href="https://t.me/+oZScRZLvOdZhNjdh">Homty Academy</a>
                </li>
                <li><a routerLink="blog">Blog</a></li>
                <li><a href="#">Partners</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div class="copywrite">
        <div class="container">Homty 2023. Todos los derechos reservados</div>
      </div>
    </main>
  </body>
</html>
