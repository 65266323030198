import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { InventoryData } from '../models/inventory.data';
import { catchError } from 'rxjs/operators';
import { InventoryModel } from '../models/inventory.model';


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http: HttpClient) { }

  getInventory(pageSize: string, pageNumber: string, userEmail: string): Observable<InventoryData> {
    let url = `${base_url}/getownproperties`;

    let params = new HttpParams();
    params = params.set("pagesize", pageSize)
    params = params.set("page", pageNumber)
    params = params.set("usuario", userEmail)

    return this.http.get<InventoryData>(url, { params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    )
  }

  getInventoryAgency(pageSize: string, pageNumber: string, agencyName: string): Observable<InventoryData> {
    let url = `${base_url}/get_inventory_agency`;

    let params = new HttpParams();
    params = params.set("pagesize", pageSize)
    params = params.set("page", pageNumber)
    params = params.set("agencia", agencyName)

    return this.http.get<InventoryData>(url, { params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    )
  }

  createProperty(newProperty: any) {
    let url = `${base_url}/createproperty`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(url, newProperty, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    )
  }

  updateProperty(propertyToUpdate: any) {
    let url = `${base_url}/updateproperty`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(url, propertyToUpdate, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    )
  }

  getProperyById(id: string) {
    let url = `${base_url}/getpropbyid`;
    let params = new HttpParams()
    params = params.set("id", id);
    return this.http.get<any>(url, { params, headers: { skip: "true" } }).pipe(
      catchError(error => {
        return throwError(error);
      })
    )

  }

  deleteProperty(id: string, operacion: string, email: string) {
    let url = `${base_url}/delete_property`;
    let params = new HttpParams()
    params = params.set("id", id);
    params = params.set("operacion", operacion);
    params = params.set("usuario", email);

    return this.http.get<any>(url, { params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    )

  }
}