import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {

  constructor(private http: HttpClient) { }

  createAgency(agencia: any) {
    let url = `${base_url}/create_agency`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body = {
      nombre: agencia.nombre,
      direccion: agencia.direccion,
      representante: agencia.representante,
      celular: agencia.celular,
      ciudad: agencia.ciudad,
      email: agencia.email
    }

    return this.http.post<string>(url, body, httpOptions).pipe(
      catchError(error => throwError(error))
    )
  }

  createUser(agencyName: string, renovationDate: string, user: any) {
    let url = `${base_url}/create_agency_user`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body = {
      agencia: agencyName,
      nombre: user.nombre,
      email: user.email,
      rol: user.rol,
      ciudad: user.ciudad,
      celular: user.celular,
      fecha: renovationDate
    }

    return this.http.post<string>(url, body, httpOptions).pipe(
      catchError(error => throwError(error))
    )
  }

  getAgencyDetails(agencyName: string) {
    let url = `${base_url}/get_agency_details`;

    const params = new HttpParams()
      .set("agencia", agencyName)

    return this.http.get<any>(url, { params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getUsers(agencyName: string) {
    let url = `${base_url}/get_agency_users`;

    const params = new HttpParams()
      .set("agencia", agencyName)

    return this.http.get<any>(url, { params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  deleteUser(agencia: string, userEmail: any) {
    let url = `${base_url}/delete_agency_user`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body = {
      agencia: agencia,
      email: userEmail
    }

    return this.http.post<string>(url, body, httpOptions).pipe(
      catchError(error => throwError(error))
    )
  }
}
