<section class="pricing py-5">
  <div class="container">
    <mat-accordion [multi]="true">

      <mat-expansion-panel id="1" [expanded]="currentRoute == blogs[1]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            We Work:
          </mat-panel-title>
          <mat-panel-description>
            La Trágica salida de WeWork a la Bolsa de Nueva York
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <mat-card style="display: flex-root;">
            <mat-card-header style="display: contents;">
              <picture>
                <source srcset="./assets/images/WeWork-1100x200.webp" type="image/webp" />
                <source srcset="./assets/images/WeWork-1100x200.jpeg" type="image/jpeg" />
                <img src="./assets/images/WeWork-1100x200.webp" alt="1100x200" />
              </picture>
            </mat-card-header>
            <div style="margin-left:50px; margin-right: 50px;margin-top: 50px;">
              <mat-card-title>
                <h2 class="orange">La Trágica salida de WeWork a la Bolsa de Nueva York</h2>
              </mat-card-title>
              <mat-card-content>
                <p> Las ideas de negocio están al alcance de nuestras manos; que digo manos, de nuestras
                  mentes.</p>
                <p>Wework inició con un edificio de oficinas desocupadas, situación que llamó la atención de Adam
                  Neumann, un israelí que había migrado a Estados Unidos, y Miguel McKelvey, un arquitecto de una
                  empresa
                  mediana. Y encontraron la manera de proveer un sitio de reuniones y de trabajo a pequeños
                  empresarios.</p>
                <p>Luego de 13 años, Wework tiene más de 230 edificios en 22 países del mundo y es una de las compañías
                  más ricas del mundo. Pero, ¿realmente vale todo lo que dicen?</p>
                <p>Alguna vez, un amigo que trabajaba en el sector minero me dijo: “Una mina de oro es un hueco en la
                  tierra con un mentiroso afuera”; al no entender el porqué de su comentario, explicó: “Para esa persona
                  afuera del hueco, su mina es la mejor del mundo y tiene más oro que cualquiera en la región; solo
                  necesita ayuda de alguien, que con una pequeña inversión podría hacerse multimillonario explotando esa
                  mina; finalmente, “el mentiroso del hueco” consigue financiación de un inversionista ilusionado, quien
                  encontrará después de mucho dinero invertido y mucho esfuerzo de por medio, que dicha mina si tenía
                  oro…
                  pero no tanto como el mentiroso decía”.</p>
                <p>Espero que este relato resuma un poco mi opinión de WeWork: una gran idea puede convertirse en un
                  gran proyecto, en un gran negocio. Pero su sostenibilidad hará que sea una gran empresa. A veces la
                  visión que tienes de tu idea de negocio es mayor que la propia idea, y eso es grandioso!! Pero tú
                  debes
                  hacerla sostenible en el tiempo, para que quien quiera invertir realmente encuentre el oro que le
                  prometiste. WeWork tiene un concepto a todas luces novedoso (y exitoso si miras los resultados), pero
                  creo que sus creadores eran los “mentirosos” afuera del hueco en la tierra, e hicieron ver su negocio,
                  más grande que la idea. Hoy, en medio de escándalos y promesas incumplidas, sale a la venta en el
                  mercado accionario en la Bolsa de New York. Y allí, los inversionistas esperan sacar el oro que les
                  permita salvar su dinero, aunque no sea el tesoro que alguna vez les prometieron.</p>
                <p>Tus ideas pueden volar como una cometa, pero no olvides que el piloto de dicha cometa debe seguir
                  con sus pies bien firmes en la tierra. Crea, innova, invita a que crean en ti, pero no prometas un
                  tesoro que realmente no van a encontrar.</p>
              </mat-card-content>
              <mat-card-footer>Autor: Ivan Muñoz</mat-card-footer>
            </div>
          </mat-card>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel id="2" [expanded]="currentRoute === blogs[2]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Proptech 2022
          </mat-panel-title>
          <mat-panel-description>
            Las 8 tendencias en Proptech 2022
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <mat-card style="display: flex-root;">
            <mat-card-header style="display: contents;">
              <picture>
                <source srcset="./assets/images/proptech.webp" type="image/webp" />
                <source srcset="./assets/images/proptech.jpeg" type="image/jpeg" />
                <img src="./assets/images/proptech.webp" alt="proptech" />
              </picture>
            </mat-card-header>
            <div style="margin-left:50px; margin-right: 50px;margin-top: 50px;">
              <mat-card-title>
                <h2 class="orange">Las 8 tendencias en Proptech 2022</h2>
              </mat-card-title>
              <mat-card-content>
                <p>Proptech hace referencia a cualquier tipo de negocio que utilice tecnología dentro del sector
                  inmobiliario, es por eso que esta industria viene consolidándose como una de las más
                  innovadoras y con mayor número de soluciones tecnológicas, dado su tamaño y a la gran
                  cantidad de retos que afronta.
                  CBRE, una de las consultoras más importantes en el mundo, lanzó esta semana las nuevas tendencias en
                  Proptech para el 2022 ¿Te gustaría conocerlas?.<br>
                  Bueno, comencemos.</p>
                <p>
                  <b>1. Experiencia en los inquilinos:</b><br> Aplicaciones integradas entre arrendatarios,
                  arrendadores,
                  administradores y otras personas que interactúan en la propiedad horizontal. ¿Te
                  imaginas que con un solo clic podamos hacer juntas, pagar administraciones o reservar
                  un parqueadero?<br><br>
                  <b>2. Análisis de datos:</b> <br>Conocer cuál es el valor de metro cuadrado por zona, las zonas más
                  vendidas y tipos de propiedades con mayor demanda, son uno de los retos en la
                  información para el sector. ¿Te gustaría tener esa información a la mano?<br><br>
                  <b>3. IoT y 5G:</b> <br>El internet de las cosas ayuda a que las propiedades sean inteligentes. Si,
                  ahora imagínate una conexión en tiempo real de todos los componentes con los que
                  interactúas en tu hogar, desde que entras hasta que sales. ¡Fantastico!<br><br>
                  <b>4. Predicciones:</b><br> ¿Esa casa o ese apartamento que estás vendiendo, sabes cuanto va a
                  costar en diez años? Todo es posible por medio de la tecnología.<br><br>
                  <b>5. iBuyers:</b> Son empresas que por medio de datos pueden encontrar “ofertas” en el
                  mercado, comprarlas en tiempo récord y tener una utilidad en su venta. Quienes tienen
                  la información, tienen el poder.<br><br>
                  <b>6. Movilidad:</b> <br>Con el incremento en la población y la densidad del tráfico, muchas
                  empresas se enfrentan al reto de incluir soluciones en movilidad dentro de las ciudades
                  y sus propiedades.<br><br>
                  <b>7. Realidad virtual:</b> Las soluciones de VR o virtual reality cada vez van más en auge,
                  visualizar un proyecto o venderlo sin tener que asistir de manera presencial, hoy ya es
                  un hecho.<br><br>
                  <b>8. Digital Twins:</b><br> Esta tecnología brinda la capacidad de descomponer un proyecto
                  inmobiliario en múltiples componentes, para así jugar con posibles escenarios del
                  mismo y predecir la adaptabilidad del activo en distintos contextos. <br>
                  ¡Impresionante!<br><br>
                  Si no conocías estas tecnologías, es hora de estudiar. Con Homty Academy puedes aprender
                  este y otros temas. Ingresa ahora a https://homty.club/academy y registrate.
                  Además, para utilizar la tecnología a tu favor y hacer crecer tu negocio, registrate en Homty.co
                  y aprovecha los 4 días grátis que te regalamos. Encontrarás información exclusiva y seguro
                  crearás más negocios<br>.
                </p>
              </mat-card-content>
              <mat-card-footer>Autor: Miguel<br>
                Fuente: https://proptech.cbre.es/2022/01/27/2022-trends-in-proptech</mat-card-footer>
            </div>
          </mat-card>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel id="3" [expanded]="currentRoute === blogs[3]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ACM Inmobiliario:
          </mat-panel-title>
          <mat-panel-description>
            ¿Qué es un ACM inmobiliario?
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <mat-card style="display: flex-root;">
            <mat-card-header style="display: contents;">
              <picture>
                <source srcset="./assets/images/acm-blog.webp" type="image/webp" />
                <source srcset="./assets/images/acm-blog.png" type="image/png" />
                <img src="./assets/images/acm-blog.webp" alt="acm-blog" />
              </picture>
            </mat-card-header>
            <div style="margin-left:50px; margin-right: 50px;margin-top: 50px;">
              <mat-card-title>
                <h2 class="orange">¿Qué es un ACM inmobiliario?</h2>
              </mat-card-title>
              <mat-card-content>
                <p>Un análisis comparativo de mercado o estudio de mercado inmobiliario busca encontrar el precio más
                  cercano por el cual deberías comercializar una propiedad, bien sea en venta o en
                  arriendo.</p>
                <p>Ejemplo: Si un cliente te dice que quiere vender una propiedad, pero no sabe cual es el precio por
                  la que debería venderla, tú cómo inmobiliario puedes realizar un análisis comparativo de mercado o ACM
                  para saber cual es el precio y así publicarlo en Homty o portales.</p>
                <br>
                <h4>¿Cómo se hace un análisis comparativo de mercado o ACM inmobiliario?</h4>
                <p>El análisis comparativo de mercado o ACM inmobiliario busca propiedades con características similares
                  en
                  la zona a la propiedad que quieres estudiar, como número de habitaciones, número de baños,
                  dimensiones,
                  número de garajes, entre otros. Luego, compara los precios de la zona y por medio de estadística y
                  matemática avanzada encuentra cuál debería ser el precio de la propiedad.</p>
                <br>
                <h4>Razones por las cuales no se vende una propiedad:</h4>
                <p>La razón número uno por la que no se vende una propiedad es el precio.
                  <br>Usualmente por desconocimiento o por no tener las herramientas correctas como inmobiliarios,
                  publicamos una propiedad con el precio que nos ha brindado nuestro cliente o con el que creemos que
                  podemos vender más rápido.
                  <br>¡Error! Antes de publicar una propiedad debes conocer el precio promedio de la zona y realizar un
                  análisis comparativo de mercado, esto va a hacer que las probabilidades de vender o arrendar la
                  propiedad sean más altas y en un menor tiempo.
                </p><br><br><br>
                <h4 class="orange text-center">Guía práctica para realizar un análisis comparativo de mercado
                  inmobiliario con Homty:</h4>

                <div class="text-center video-responsive">
                  <iframe title="vimeo-player" src="https://player.vimeo.com/video/767492415?h=d24d6275a4" width="800"
                    height="600" frameborder="0" allowfullscreen></iframe>
                </div>
                <div class="col text-center">
                  <button class="custom-button mt-4" type="button" (click)="goToAvaluo()">Comenzar a hacer mi primer ACM
                    Gratis</button>
                </div>
              </mat-card-content>
              <mat-card-footer>Autor: Ivan Muñoz</mat-card-footer>
            </div>
          </mat-card>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel id="4" [expanded]="currentRoute === blogs[4]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Ciudades para invertir en Colombia:
          </mat-panel-title>
          <mat-panel-description>
            Las mejores ciudades para invertir en Colombia
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <mat-card style="display: flex-root;">

            <div style="margin-left:20px; margin-right: 20px;margin-top: 20px;">
              <mat-card-title>
                <h2 class="orange">Las mejores ciudades para invertir en Colombia</h2>
              </mat-card-title>
              <mat-card-content>
                <p>¿Te gustaría conocer cuales son las mejores ciudades para invertir en Colombia?
                  Los datos que son recopilados por <strong>Homty</strong> permiten conocer el estado actual del
                  mercado inmobiliario. <strong>Homty</strong> recopila cientos de miles de propiedades de distintas
                  fuentes de información, portales publicitarios, web inmobiliarias individuales y otros, para
                  entregar toda y la mejor información en un solo lugar.</p>
                <p>Aquí está lo que encontramos:</p>
                <p>Primero se tomaron las publicaciones para venta de apartamentos en estratos 3, 4, 5 y 6 en las
                  ciudades de <strong>Bogotá,Cali y Medell ín</strong> con un <strong>Precio de venta</strong> mayor
                  a COP $300.000.000 (Trescientos millones de pesos Colombianos), al día de hoy 60.000 dólares.</p>
                <p>La cantidad de publicaciones halladas se muestra en la siguiente tabla discriminadas por municipio y
                  por estrato. </p>

                <div class="table-responsive">
                  <table mat-table [dataSource]="dataTableCities1" class="table1 mat-elevation-z8">

                    <ng-container matColumnDef="municipio">
                      <th mat-header-cell *matHeaderCellDef> Municipio </th>
                      <td mat-cell *matCellDef="let element"> {{element.municipio}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estrato_3">
                      <th mat-header-cell *matHeaderCellDef> Estrato 3 </th>
                      <td mat-cell *matCellDef="let element"> {{element.estrato_3}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estrato_4">
                      <th mat-header-cell *matHeaderCellDef> Estrato 4 </th>
                      <td mat-cell *matCellDef="let element"> {{element.estrato_4}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estrato_5">
                      <th mat-header-cell *matHeaderCellDef> Estrato 5 </th>
                      <td mat-cell *matCellDef="let element"> {{element.estrato_5}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estrato_6">
                      <th mat-header-cell *matHeaderCellDef> Estrato 6 </th>
                      <td mat-cell *matCellDef="let element"> {{element.estrato_6}} </td>
                    </ng-container>

                    <ng-container matColumnDef="total">
                      <th mat-header-cell *matHeaderCellDef> Total </th>
                      <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableCities1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableCities1;"></tr>
                  </table>
                </div>

                <br><br>
                <h4>Qué método utilizamos:</h4>
                <p>Usando un método estadístico de tendencia central con corrección por área se estimó el
                  <strong>Precio anual de renta</strong> PR de cada apartamento. <strong>Esto quiere decir, los ingresos
                    futuros que
                    puede tener una propiedad si es arrendada.</strong>
                </p>
                <p>La siguiente figura muestra una nube de puntos, discriminada por municipio, con el Precio anual de
                  renta
                  estimado PR y el Precio de venta publicado PV de los inmuebles mencionados.</p>
                <br>
                <div class="text-center">
                  <picture>
                    <source srcset="assets/images/blog/ciudades_para_invertir_img1.webp" type="image/webp" />
                    <source srcset="assets/images/blog/ciudades_para_invertir_img1.png" type="image/png" />
                    <img src="assets/images/blog/ciudades_para_invertir_img1.webp" alt="Ciudades para invertir" />
                  </picture>
                </div>
                <br>
                <p>El mercado en las tres ciudades muestra la tendencia esperada, a un mayor precio de renta un mayor
                  precio de
                  venta. Se puede observar que los precios anuales de renta en Cali se encuentran concentrados bajo los
                  COP$10.000.000,
                  mientras en Medellín los precios anuales de renta alcanzan los COP$15.000.000 y en Bogotá los
                  COP$25.000.000
                  aproximadamente.</p>
                <br>
                <h4>PRR, ¿Qué es?:</h4>
                <p>El indicador <strong>Price-to-Rent Ratio PRR</strong> es la razón entre el valor comercial de un
                  inmueble y la renta anual de un
                  inmueble equivalente. Este indicador es utilizado por inversionistas para estimar el tiempo aproximado
                  de retorno
                  de su inversión al comprar un inmueble y darlo en arrendamiento. Se estimó el indicador PRR para los
                  inmuebles
                  seleccionados considerando la aproximación del valor publicado de venta PV sobre el valor anual de
                  renta PR. </p>
                <p><strong> Esto indicaría el número de años que un inmueble debe estar alquilado para recuperar el
                    valor pagado en su compra.</strong> <br><br>
                  La distribución del indicador PRR para las tres ciudades estudiadas se muestra en la figura siguiente.
                  En todas las ciudades se observa una distribución muy aproximada a la normalidad con valores promedio
                  y mediano inferiores para
                  <strong>Medellín</strong> y superiores para <strong>Bogotá.</strong>
                </p>
                <div class="text-center">
                  <picture>
                    <source srcset="assets/images/blog/ciudades_para_invertir_img2.webp" type="image/webp" />
                    <source srcset="assets/images/blog/ciudades_para_invertir_img2.png" type="image/png" />
                    <img src="assets/images/blog/ciudades_para_invertir_img2.webp" alt="Ciudades para invertir" />
                  </picture>
                </div>
                <br>
                Los estadísticos de tendencia central asociados a la distribución de PRR se listan en la tabla
                siguiente: <br><br>
                <div class="table-responsive m-auto">
                  <table mat-table [dataSource]="dataTableCities2" class="table2 mat-elevation-z8">

                    <ng-container matColumnDef="municipio">
                      <th mat-header-cell *matHeaderCellDef> Municipio </th>
                      <td mat-cell *matCellDef="let element"> {{element.municipio}} </td>
                    </ng-container>

                    <ng-container matColumnDef="prr_promedio">
                      <th mat-header-cell *matHeaderCellDef> PRR Promedio </th>
                      <td mat-cell *matCellDef="let element"> {{element.prr_promedio}} </td>
                    </ng-container>

                    <ng-container matColumnDef="prr_mediana">
                      <th mat-header-cell *matHeaderCellDef> PRR Mediana </th>
                      <td mat-cell *matCellDef="let element"> {{element.prr_mediana}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableCities2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableCities2;"></tr>
                  </table>
                </div>

                <br>
                <p><strong>A partir de la evidencia presentada, y aunque no existan diferencias
                    estadísticamente significativas, un inversionista podría inclinarse inicialmente a
                    comprar inmuebles en Medellín para recuperar su inversión un año más rápido que de
                    haber comprado un inmueble en Cali o Bogotá.</strong></p>
                <p>Sin embargo, debe tenerse en cuenta que en este análisis se han mezclado las
                  propiedades de los estratos 3, 4, 5 y 6, lo cual podría mostrar diferencias en el mercado.</p>
                <p>El comportamiento medio del indicador PRR discriminado por Estrato en cada una de
                  las tres ciudades estudiadas se muestra en la figura siguiente.</p>
                <div class="text-center">
                  <picture>
                    <source srcset="assets/images/blog/ciudades_para_invertir_img3.webp" type="image/webp" />
                    <source srcset="assets/images/blog/ciudades_para_invertir_img3.png" type="image/png" />
                    <img src="assets/images/blog/ciudades_para_invertir_img3.webp" alt="Ciudades para invertir" />
                  </picture>
                </div>
                <br>
                <p>Como conclusión, en esta gráfica se observa que:</p>
                <ul>
                  <li>
                    <p>En las tres ciudades analizadas se observa un comportamiento de disminución del PRR
                      a medida que aumenta el <strong>Estrato</strong> a partir del 4. <strong> Es decir, para el
                        ejercicio es mejor
                        invertir en propiedades por encima del estrato 4.</strong></p>
                  </li>
                  <li>
                    <p>El estrato 3 en <strong>Medellín</strong> muestra un PRR promedio menor a 15.5, siendo el menor
                      indicador promedio en la ciudad.</p>
                  </li>
                  <li>
                    <p>El estrato 6 de todas las ciudades analizadas muestra un PRR promedio entre 15.5 y 16
                      aproximadamente, haciendo
                      de estos inmuebles objetivos interesantes para inversión. <strong> Sin embargo, los inmuebles en
                        estrato 3 de Medellín muestran un
                        comportamiento promedio del PRR, incluso mejor al del estrato 6. </strong> </p>
                  </li>
                  <li>
                    <p>En el estrato 3 de <strong>Cali</strong> se observa un PRR promedio mayor al resto de estratos de
                      la ciudad.</p>
                  </li>
                </ul>
                <p>
                  ¿Te gustaría saber cuales son las mejores propiedades para invertir o encontrar las mejores
                  oportunidades para tus clientes inmobiliarios?
                </p>
                <p>Si eres agente inmobiliario, avaluador o inversor ingresa ahora a <a href="https://www.homty.co"
                    target="_blank" rel="noopener noreferrer"><strong>Homty</strong></a>
                  y encuentra toda la oferta inmobiliaria en un solo lugar. </p>
                <p>Quieres una información más profunda del mercado, escríbenos a <a href="https://wa.link/nnfs6p"
                    target="_blank" rel="noopener noreferrer"><strong>+57 316 525 9710</strong></a> </p>

              </mat-card-content>
              <mat-card-footer>
                <div class="d-flex justify-content-center mt-2">
                  <button class="custom-button" type="button" [routerLink]="['/login', 'signUp']">QUIERO HOMTY</button>
                </div>
              </mat-card-footer>
            </div>
          </mat-card>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel id="5" [expanded]="currentRoute === blogs[5]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Anuncios inmobiliarios en elecciones:
          </mat-panel-title>
          <mat-panel-description>
            Bajada drástica de los anuncios inmobiliarios en Colombia
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <mat-card style="display: flex-root;">

            <div style="margin-left:20px; margin-right: 20px;margin-top: 20px;">
              <mat-card-title>
                <h2 class="orange">Bajada drástica de los anuncios inmobiliarios en Colombia dado el cambio de gobierno
                </h2>
              </mat-card-title>
              <mat-card-content>
                <p>Los cambios siempre nos generan incertidumbre como seres humanos, especialmente cuando son cambios
                  que impactan de manera drástica a millones de personas como lo son un cambio de gobierno. Las
                  elecciones de segunda vuelta en Colombia el pasado 19 de Junio de 2022, no fueron la excepción. <br>
                  Homty condensa todas las fuentes de información de anuncios inmobiliarios, páginas webs individuales y
                  CRMS en un solo lugar, obteniendo datos y comportamientos de la industria relevantes. Así, hemos
                  logrado crear la base de datos más limpia y completa de bienes raíces, con más de 800 mil propiedades
                  en 870 municipios de Colombia, casi el 80% del total del inventario disponible. <br> Es por esto, que
                  hemos venido monitoreando detalladamente el comportamiento de nuestros datos, especialmente para los
                  análisis que entregamos a nuestros clientes y revisando lo ocurrido meses atrás nos llevamos una gran
                  sorpresa. </p>

                <p><strong>¡Más de 20 mil anuncios inmobiliarios fueron dados de baja de las principales plataformas del
                    país en medio de la segunda vuelta presidencial!</strong></p>
                <p>Nuestro equipo de ciencia de datos, liderado por <strong>Jean Pierre Díaz</strong> doctor en
                  Ingeniería de datos nos entregó así esta información tan valiosa:</p>
                <br>
                <div class="text-center">
                  <picture>
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img1.webp" type="image/webp" />
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img1.png" type="image/png" />
                    <img src="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img1.webp" alt="Anuncios inmobiliarios en elecciones" />
                  </picture>
                </div>
                <br>
                <p>Como se puede detallar en la gráfica, la cantidad de anuncios, en este caso de casas y apartamentos
                  disminuye notablemente y progresivamente entre la semana 0 que va desde Junio 5 a Junio 11 del 2022,
                  una semana antes de la segunda vuelta presidencial.</p>
                <p><strong>Si vemos detalladamente la semana 1 que va de Junio 12 a Junio 18, momento en el cual se
                    posesiona Gustavo Petro como presidente electo de Colombia hay una reducción de alrededor de 20 mil
                    anuncios inmobiliarios en todo el país la cual no se recupera en las dos semanas siguientes de
                    manera general.</strong> ¿Cual es el motivo principal por el cual las personas bajaron los anuncios?
                  ¿Incertidumbre o confianza frente al nuevo gobierno?</p>
                <p>Otra pregunta que nos hicimos en Homty fue ¿Qué tipo de propiedades o de personas se sintieron más
                  afectados frente a este cambio? La respuesta es esta:</p>
                <br>
                <div class="text-center">
                  <picture>
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img2.webp" type="image/webp" />
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img2.png" type="image/png" />
                    <img src="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img2.webp" alt="Anuncios inmobiliarios en elecciones" />
                  </picture>
                </div>
                <br>
                <p>Al separar la cantidad de anuncios de casas y apartamentos por estrato socioeconómico, se nota que la
                  disminución se relaciona con comportamientos entre los estratos 3, 4, 5 y 6 donde los cambios más
                  fuertes se dan en los anuncios de propiedades de estrato 4. ¿Alguna relación con la ideología política
                  o económica del nuevo gobierno? </p>
                <p>Hasta aquí mucha gente estará confundida con los datos anteriores, como nosotros redactando esta
                  publicación, así que nos hicimos la pregunta del comportamiento por ciudad de los anuncios
                  inmobiliarios, y encontramos esto. Si bien en ciudades como Medellín o Cali los anuncios que se dieron
                  de baja en medio de las elecciones presidenciales no fueron recuperados en la semanas siguientes, en
                  <strong>Bogotá se publicaron más de 5 mil anuncios inmobiliarios, superando la promoción una semana
                    antes de las elecciones.</strong>
                </p>

                <br>
                <p><strong>Así se ve en las siguientes gráficas:</strong></p>
                <br>
                <div class="text-center">
                  <picture>
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img3.webp" type="image/webp" />
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img3.png" type="image/png" />
                    <img src="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img3.webp" alt="Anuncios inmobiliarios en elecciones" />
                  </picture>
                </div>
                <br>
                <div class="text-center">
                  <picture>
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img4.webp" type="image/webp" />
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img4.png" type="image/png" />
                    <img src="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img4.webp" alt="Anuncios inmobiliarios en elecciones" />
                  </picture>
                </div>
                <br>
                <div class="text-center">
                  <picture>
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img5.webp" type="image/webp" />
                    <source srcset="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img5.png" type="image/png" />
                    <img src="assets/images/blog/anuncios_inmobiliarios_en_elecciones_img5.webp" alt="Anuncios inmobiliarios en elecciones" />
                  </picture>
                </div>
                <br>

                <p>Creemos que es aún más importante revisar el pasado de los datos para concluir estos hallazgos y
                  además, compararlo con la situación actual de lo que está pasando en torno a la comercialización de
                  propiedades en el país. </p>
                <p>¿Cual es tu opinión frente a este comportamiento? ¿Cuál crees que es el motivo? Dejanos tus
                  comentarios y que otro tipo de información te gustaría acceder del sector de real estate en Colombia.
                </p>

              </mat-card-content>
              <mat-card-footer>
                <br>
                <a style="color:#FF653F;" href="http://www.homty.co" target="_blank" rel="noopener noreferrer">
                  <h4 class="orange">Homty</h4>
                </a>
                <strong>Jean Pierre Diaz - Equipo inicial de Homty y líder del equipo de datos.</strong>
              </mat-card-footer>
            </div>
          </mat-card>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<!-- Start of HubSpot Embed Code -->
<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8998298.js"></script>
<!-- End of HubSpot Embed Code -->