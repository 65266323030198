import { Component, Input, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { AdditionalsService } from 'src/app/services/additionals.service';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  // providers: [AdditionalsService]
})
export class ProfileComponent implements OnInit {

  loading: boolean = true;
  user: CognitoUserInterface | undefined;
  currentPlan: string | undefined;
  renovationDate: any | undefined;
  todayDate: any | undefined;
  remainingDays: number | undefined;
  remainingDaysFree: number | undefined;
  remainingDaysAnual: number | undefined;
  packagePlanInfo: any | undefined;

  constructor(private additionalsService: AdditionalsService, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    //SEO
    this.title.setTitle(`Homty - Mi Perfil`);
    this.meta.addTag({ name: 'description', content: 'Homty - Mi Perfil'});
    this.meta.addTag({ name: 'keywords', content: 'homty, inmobiliario, perfil, suscripciones, informacion personal, pagos, planes'});

    Auth.currentUserInfo().then(user => {
      this.user = user
      this.getCurrentPlan();
      this.additionalsService.getNumberAcmsProperties(user["attributes"]["email"]).subscribe(resp => {
        let respJson= JSON.parse(resp.item);
        this.packagePlanInfo = respJson;
      })
      this.loading = false;
    })
    
  }

  changePassword() {
    console.log("Change Password");
  }

  getCurrentPlan() {

    if (this.user) {
      if (this.user["attributes"]["custom:active_plan"] == 1 || this.user["attributes"]["custom:active_plan"] == 2 || this.user["attributes"]["custom:free_trial"] == 1) {
        let renovationDateStrig = this.user["attributes"]["custom:renovation_date"].split('/')
        renovationDateStrig[0]++;
        this.renovationDate = new Date(`${renovationDateStrig[2]}-${renovationDateStrig[1]}-${renovationDateStrig[0]}`);
        this.todayDate = new Date();
        this.remainingDays = Math.abs(this.todayDate - this.renovationDate)
        this.remainingDaysFree = Math.round(7 - (this.remainingDays / (1000 * 3600 * 24)));
        this.remainingDaysAnual = Math.round(365 - (this.remainingDays / (1000 * 3600 * 24)));
        this.remainingDays = Math.round(30 - (this.remainingDays / (1000 * 3600 * 24)));
      }
    }
  }

}
