import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { prices } from '../configPrices';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { CognitoUserInterface } from '@aws-amplify/ui-components';

@Component({
  selector: 'app-pay-subscription-agency',
  templateUrl: './pay-subscription-agency.component.html',
  styleUrls: ['./pay-subscription-agency.component.css']
})
export class PaySubscriptionAgencyComponent implements OnInit {

  user: CognitoUserInterface | undefined;

  numberUsers = new FormControl(10);
  total: number | undefined;

  constructor(private router: Router) { }

  async ngOnInit(): Promise<void> {
    
    this.total = this.numberUsers.value * prices.packages.agencyUsers;
    this.numberUsers.valueChanges.subscribe(value => {
      this.validate();
    });

    await Auth.currentUserInfo().then(user => {
      this.user = user;
      console.log(user);
    });

    if(this.user?.attributes?.nickname != 'Agencia Inmobiliaria') {
      Swal.fire({
        title: 'Este paquete es para Agencias Inmobiliarias',
        text: 'Si quieres usar Homty como una Agencia Inmobiliaria registrate usando el correo electrónico de tu agencia y no olvides seleccionar el rol de "Agencia Inmobiliaria" ',
        timer: 10000,
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          this.router.navigate(['pay-subscription']);
        }
      });
    }
  }

  openCheckout() {
    if (this.numberUsers.value >= 2) {
      this.router.navigate(['/checkout'], { queryParams: { plan: '7', numberUsers: this.numberUsers.value } })
    } else {
      Swal.fire({
        icon: 'error',
        text: "La cantidad debe ser de 2 usuarios como mínimo",
        confirmButtonColor: '#ff653f'
      });
    }
  }

  validate() {
    let newTotal = this.numberUsers.value * prices.packages.agencyUsers;

    if (this.numberUsers.value <= 1) {
      Swal.fire({
        icon: 'error',
        text: "La cantidad debe ser de 2 usuarios como mínimo",
        confirmButtonColor: '#ff653f'
      });
    }
    this.total = newTotal
  }

}
