<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>

<div class="container mt-30" *ngIf="!loading">

  <div class="row justify-content-center">
    <div class="col-lg-8">
      <h3>Datos del comprador *</h3>
      <div class="card" style="width: 100%;">
        <div class="card-body">
          <!-- <h5 class="card-title">Card title</h5> -->
          <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
          <p class="card-text">Continuar pago como:</p>

          <div *ngFor="let option of options">
            <input type="radio" name="options" [(ngModel)]="selectedOption" [value]="option">
            <label>&nbsp;&nbsp;{{ option }}</label>
          </div>

          <form [formGroup]="form" *ngIf="selectedOption === 'Como invitado'">

            <div class="mt-3">
              <label for="email" class="form-label">Correo electrónico</label>
              <input type="email" class="form-control shadow-none" id="email" name="email" formControlName="email">
              <div *ngIf=" form.controls['email'].invalid && form.controls['email'].touched"
                class="mt-2 alert alert-danger">
                <div *ngIf="form.controls['email'].errors?.required">El correo es obligatorio</div>
              </div>
            </div>

            <div class="mt-2">
              <label for="whatsapp_phone" class="form-label">Número de whatsapp</label>
              <input type="number" class="form-control shadow-none" id="whatsapp_phone" name="phone"
                formControlName="phone">
              <div *ngIf=" form.controls['phone'].invalid && form.controls['phone'].touched"
                class="mt-2 alert alert-danger">
                <div *ngIf="form.controls['phone'].errors?.required">El whatsapp es obligatorio</div>
                <div *ngIf="form.controls['phone'].errors?.phoneValidator">
                  El número de whatsapp debe tener 10 dígitos
                </div>
              </div>
            </div>

          </form>

          <div class="text-center mt-3">
            <button (click)="onSubmit()" [disabled]="this.selectedOption === 'Como invitado' && form.invalid"
              [ngClass]="{invalid: this.selectedOption !== 'Con tu usuario' && !form.valid}" class="btn btn-primary text-uppercase text-center">¡Comprar
              ya!</button>
          </div>

        </div>
      </div>
    </div>
    <!-- <div class="col-lg-5">

    </div> -->
  </div>


</div>