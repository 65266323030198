import { Component, OnInit } from '@angular/core';
import { AdditionalsService } from 'src/app/services/additionals.service';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { ShareAcmService } from 'src/app/services/share-acm.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  loading: boolean = false;
  user: CognitoUserInterface | undefined;
  activeLink: number = 0;

  constructor( private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const tabIndex = +params['tab'] || 1;
      this.activeLink = tabIndex - 1;
    });
  }

}
