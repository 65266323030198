<app-loading *ngIf="authenticator.isPending" class="animate__animated animate__fadeIn vertical-center">
</app-loading>

<div class="rs-team-Single pt-30 pb-25 md-pt-20 md-pb-15">
  <ng-container>

    <amplify-authenticator *ngIf="initialState == 'signIn'" [services]="services"
      [signUpAttributes]="['phone_number','family_name','address']" [initialState]="'signIn'" stat
      [formFields]="formFields">

      <ng-template amplifySlot="sign-up-form-fields" let-validationErrors="validationErrors">
        <!-- Re-use default `Authenticator.SignUp.FormFields` -->
        <div>
          <h6>Crea una cuenta ahora para empezar a mejorar tu productividad con Homty</h6>
        </div>
        <!--  <amplify-sign-up-form-fields></amplify-sign-up-form-fields> -->
        <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Nombre:
        </label>

        <input class="amplify-input" id="amplify-field-0Xupuu2DtnA3" type="text" name="family_name"
          placeholder="Ingresar Nombre" required="" autocomplete="family-name" aria-invalid="true">

        <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Email:
        </label>
        <input class="amplify-input" id="amplify-field-FPCk1oNijtzi" type="text" name="username"
          placeholder="Ingresar correo electrónico" required="" autocomplete="username" aria-invalid="true">

        <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Contraseña:
        </label>
        <div class="amplify-flex amplify-field-group">
          <input class="amplify-input amplify-field-group__control"
            id="amplify-field-0eiKuWhBR-3x" type="password" name="password" placeholder="Ingresar contraseña"
            required="" autocomplete="new-password" aria-invalid="true">
          <div class="amplify-field-group__outer-end">
            <button amplify-button=""
              class="amplify-field-group__control amplify-field__show-password amplify-button amplify-button--default amplify-button--medium"
              aria-label="Show password" type="button" data-fullwidth="false" data-size="medium"
              data-variation="default" style="font-weight: normal;"><svg xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 24 24" class="amplify-icon" >
                <path
                  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z">
                </path>
              </svg><!--bindings={
          "ng-reflect-ng-if": "true"
        }--><!--bindings={
          "ng-reflect-ng-if": "false"
        }--></button>
          </div>
        </div>
        <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Repetir Contraseña:
        </label>
        <div class="amplify-flex amplify-field-group">
          <input class="amplify-input amplify-field-group__control"
            id="amplify-field-wWK568qXSEqX" type="password" name="confirm_password" placeholder="Confirmar contraseña"
            required="" autocomplete="new-password" aria-invalid="true">
          <div class="amplify-field-group__outer-end">
            <button amplify-button=""
              class="amplify-field-group__control amplify-field__show-password amplify-button amplify-button--default amplify-button--medium"
              aria-label="Hide password" type="button" data-fullwidth="false" data-size="medium"
              data-variation="default" style="font-weight: normal;"><svg xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 24 24" class="amplify-icon">
                <path
                  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z">
                </path>
              </svg><!--bindings={
          "ng-reflect-ng-if": "true"
        }--><!--bindings={
          "ng-reflect-ng-if": "false"
        }--></button>
          </div>
        </div>
        <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Celular:
        </label>
        <div amplify-field-group="" class="amplify-flex amplify-phonenumberfield" style="gap: 0px;">
          <div class="amplify-field-group__outer-start">
            <div class="amplify-flex amplify-field amplify-selectfield amplify-countrycodeselect amplify-dialcodeselect"
              style="flex-direction: column;"><label class="amplify-label amplify-visually-hidden"
                for="amplify-field-OuMeO-2IXX0D">Country Code</label>
              <div class="amplify-select__wrapper"><select autocomplete="tel-country-code"
                  class="amplify-select amplify-field-group__control" id="amplify-field-OuMeO-2IXX0D"
                  name="country_code">
                  <option value="+1"> +1 </option>
                  <option value="+7"> +7 </option>
                  <option value="+20"> +20 </option>
                  <option value="+27"> +27 </option>
                  <option value="+30"> +30 </option>
                  <option value="+31"> +31 </option>
                  <option value="+32"> +32 </option>
                  <option value="+33"> +33 </option>
                  <option value="+34"> +34 </option>
                  <option value="+36"> +36 </option>
                  <option value="+39"> +39 </option>
                  <option value="+40"> +40 </option>
                  <option value="+41"> +41 </option>
                  <option value="+43"> +43 </option>
                  <option value="+44"> +44 </option>
                  <option value="+45"> +45 </option>
                  <option value="+46"> +46 </option>
                  <option value="+47"> +47 </option>
                  <option value="+48"> +48 </option>
                  <option value="+49"> +49 </option>
                  <option value="+51"> +51 </option>
                  <option value="+52"> +52 </option>
                  <option value="+53"> +53 </option>
                  <option value="+54"> +54 </option>
                  <option value="+55"> +55 </option>
                  <option value="+56"> +56 </option>
                  <option selected="true" value="+57"> +57 </option>
                  <option value="+58"> +58 </option>
                  <option value="+60"> +60 </option>
                  <option value="+61"> +61 </option>
                  <option value="+62"> +62 </option>
                  <option value="+63"> +63 </option>
                  <option value="+64"> +64 </option>
                  <option value="+65"> +65 </option>
                  <option value="+66"> +66 </option>
                  <option value="+81"> +81 </option>
                  <option value="+82"> +82 </option>
                  <option value="+84"> +84 </option>
                  <option value="+86"> +86 </option>
                  <option value="+90"> +90 </option>
                  <option value="+91"> +91 </option>
                  <option value="+92"> +92 </option>
                  <option value="+93"> +93 </option>
                  <option value="+94"> +94 </option>
                  <option value="+95"> +95 </option>
                  <option value="+98"> +98 </option>
                  <option value="+212"> +212 </option>
                  <option value="+213"> +213 </option>
                  <option value="+216"> +216 </option>
                  <option value="+218"> +218 </option>
                  <option value="+220"> +220 </option>
                  <option value="+221"> +221 </option>
                  <option value="+222"> +222 </option>
                  <option value="+223"> +223 </option>
                  <option value="+224"> +224 </option>
                  <option value="+225"> +225 </option>
                  <option value="+226"> +226 </option>
                  <option value="+227"> +227 </option>
                  <option value="+228"> +228 </option>
                  <option value="+229"> +229 </option>
                  <option value="+230"> +230 </option>
                  <option value="+231"> +231 </option>
                  <option value="+232"> +232 </option>
                  <option value="+233"> +233 </option>
                  <option value="+234"> +234 </option>
                  <option value="+235"> +235 </option>
                  <option value="+236"> +236 </option>
                  <option value="+237"> +237 </option>
                  <option value="+238"> +238 </option>
                  <option value="+239"> +239 </option>
                  <option value="+240"> +240 </option>
                  <option value="+241"> +241 </option>
                  <option value="+242"> +242 </option>
                  <option value="+243"> +243 </option>
                  <option value="+244"> +244 </option>
                  <option value="+245"> +245 </option>
                  <option value="+246"> +246 </option>
                  <option value="+248"> +248 </option>
                  <option value="+249"> +249 </option>
                  <option value="+250"> +250 </option>
                  <option value="+251"> +251 </option>
                  <option value="+252"> +252 </option>
                  <option value="+253"> +253 </option>
                  <option value="+254"> +254 </option>
                  <option value="+255"> +255 </option>
                  <option value="+256"> +256 </option>
                  <option value="+257"> +257 </option>
                  <option value="+258"> +258 </option>
                  <option value="+260"> +260 </option>
                  <option value="+261"> +261 </option>
                  <option value="+262"> +262 </option>
                  <option value="+263"> +263 </option>
                  <option value="+264"> +264 </option>
                  <option value="+265"> +265 </option>
                  <option value="+266"> +266 </option>
                  <option value="+267"> +267 </option>
                  <option value="+268"> +268 </option>
                  <option value="+269"> +269 </option>
                  <option value="+290"> +290 </option>
                  <option value="+291"> +291 </option>
                  <option value="+297"> +297 </option>
                  <option value="+298"> +298 </option>
                  <option value="+299"> +299 </option>
                  <option value="+345"> +345 </option>
                  <option value="+350"> +350 </option>
                  <option value="+351"> +351 </option>
                  <option value="+352"> +352 </option>
                  <option value="+353"> +353 </option>
                  <option value="+354"> +354 </option>
                  <option value="+355"> +355 </option>
                  <option value="+356"> +356 </option>
                  <option value="+357"> +357 </option>
                  <option value="+358"> +358 </option>
                  <option value="+359"> +359 </option>
                  <option value="+370"> +370 </option>
                  <option value="+371"> +371 </option>
                  <option value="+372"> +372 </option>
                  <option value="+373"> +373 </option>
                  <option value="+374"> +374 </option>
                  <option value="+375"> +375 </option>
                  <option value="+376"> +376 </option>
                  <option value="+377"> +377 </option>
                  <option value="+378"> +378 </option>
                  <option value="+379"> +379 </option>
                  <option value="+380"> +380 </option>
                  <option value="+381"> +381 </option>
                  <option value="+382"> +382 </option>
                  <option value="+385"> +385 </option>
                  <option value="+386"> +386 </option>
                  <option value="+387"> +387 </option>
                  <option value="+389"> +389 </option>
                  <option value="+420"> +420 </option>
                  <option value="+421"> +421 </option>
                  <option value="+423"> +423 </option>
                  <option value="+500"> +500 </option>
                  <option value="+501"> +501 </option>
                  <option value="+502"> +502 </option>
                  <option value="+503"> +503 </option>
                  <option value="+504"> +504 </option>
                  <option value="+505"> +505 </option>
                  <option value="+506"> +506 </option>
                  <option value="+507"> +507 </option>
                  <option value="+508"> +508 </option>
                  <option value="+509"> +509 </option>
                  <option value="+537"> +537 </option>
                  <option value="+590"> +590 </option>
                  <option value="+591"> +591 </option>
                  <option value="+593"> +593 </option>
                  <option value="+594"> +594 </option>
                  <option value="+595"> +595 </option>
                  <option value="+596"> +596 </option>
                  <option value="+597"> +597 </option>
                  <option value="+598"> +598 </option>
                  <option value="+599"> +599 </option>
                  <option value="+670"> +670 </option>
                  <option value="+672"> +672 </option>
                  <option value="+673"> +673 </option>
                  <option value="+674"> +674 </option>
                  <option value="+675"> +675 </option>
                  <option value="+676"> +676 </option>
                  <option value="+677"> +677 </option>
                  <option value="+678"> +678 </option>
                  <option value="+679"> +679 </option>
                  <option value="+680"> +680 </option>
                  <option value="+681"> +681 </option>
                  <option value="+682"> +682 </option>
                  <option value="+683"> +683 </option>
                  <option value="+685"> +685 </option>
                  <option value="+686"> +686 </option>
                  <option value="+687"> +687 </option>
                  <option value="+688"> +688 </option>
                  <option value="+689"> +689 </option>
                  <option value="+690"> +690 </option>
                  <option value="+691"> +691 </option>
                  <option value="+692"> +692 </option>
                  <option value="+850"> +850 </option>
                  <option value="+852"> +852 </option>
                  <option value="+853"> +853 </option>
                  <option value="+855"> +855 </option>
                  <option value="+856"> +856 </option>
                  <option value="+872"> +872 </option>
                  <option value="+880"> +880 </option>
                  <option value="+886"> +886 </option>
                  <option value="+960"> +960 </option>
                  <option value="+961"> +961 </option>
                  <option value="+962"> +962 </option>
                  <option value="+963"> +963 </option>
                  <option value="+964"> +964 </option>
                  <option value="+965"> +965 </option>
                  <option value="+966"> +966 </option>
                  <option value="+967"> +967 </option>
                  <option value="+968"> +968 </option>
                  <option value="+970"> +970 </option>
                  <option value="+971"> +971 </option>
                  <option value="+972"> +972 </option>
                  <option value="+973"> +973 </option>
                  <option value="+974"> +974 </option>
                  <option value="+975"> +975 </option>
                  <option value="+976"> +976 </option>
                  <option value="+977"> +977 </option>
                  <option value="+992"> +992 </option>
                  <option value="+993"> +993 </option>
                  <option value="+994"> +994 </option>
                  <option value="+995"> +995 </option>
                  <option value="+996"> +996 </option>
                  <option value="+998"> +998 </option>
                </select>
                <div class="amplify-flex amplify-select__icon-wrapper"
                  style="align-items: center; justify-content: center;"><svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24" data-size="large" fill="currentColor" class="amplify-icon amplify-icon--large">
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                  </svg></div>
              </div>
            </div>
          </div><input class="amplify-input" id="amplify-field-dSEbj3-de-Qu" type="tel" name="phone_number"
            placeholder="(555) 555-1212" required="" autocomplete="tel" aria-invalid="true">
        </div>

        <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Ciudad:
        </label>
        <input class="amplify-input" id="amplify-field-ziudpJLJc1kG" type="undefined" name="address"
          placeholder="Ingresar Ciudad" required="" autocomplete="undefined" aria-invalid="true">

        <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Perfil:
        </label>
        <div class="amplify-select__wrapper"><select autocomplete="tel-country-code"
            class="amplify-select amplify-field-group__control" id="amplify-field" name="nickname">
            <option value="Agencia Inmobiliaria">Agencia Inmobiliaria</option>
            <option value="Agente inmobiliario" selected>Agente inmobiliario</option>
            <option value="Avaluador">Avaluador</option>
            <option value="Comercial">Comercial</option>
            <option value="Constructor">Constructor</option>
            <option value="Flipping">Flipping</option>
          </select>
          <div class="amplify-flex amplify-select__icon-wrapper" style="align-items: center; justify-content: center;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-size="large" fill="currentColor"
              class="amplify-icon">
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
            </svg>
          </div>
        </div>
        <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Email Referido (Opcional):
        </label>
        <input class="amplify-input" id="amplify-field" name="profile" type="text"
          placeholder="Ingresar email de quien te recomendó Homty">


        <!-- Append & require Terms & Conditions field to sign up -->
        <amplify-checkbox [errorMessage]="validationErrors.acknowledgement"
          [hasError]="!!validationErrors.acknowledgement" name="acknowledgement" value="yes">
          <a href="https://imagenes-homty.s3.amazonaws.com/Politica+de+tratmiento+de+Datos+Homty+-+septiembre++2021.pdf"
            target="_blank">Términos y condiciones</a>
        </amplify-checkbox>

      </ng-template>

      <!-- signed-in content override -->
      <!-- Para diferentes a Agencias -->
      <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
        <div *ngIf="user['attributes']['nickname'] && user['attributes']['nickname'] != 'Agencia Inmobiliaria'"
          class="container">
          <div class="btm-info-team">
            <div class="row">
              <div class="col-lg-12 sm-pt-30">
                <div class="con-info ">
                  <span class="designation-info d-flex justify-content-center">¡Bienvenido
                    {{user["attributes"]["family_name"]}}!
                  </span>
                  <div class="short-desc d-flex justify-content-center mt-10">
                    <p><b>Plan actual: </b>
                      {{user["attributes"]["custom:free_trial"] == 1 ? "Prueba gratuita" :
                      user["attributes"]["custom:active_plan"] == 1 ? " Bronce" :
                      user["attributes"]["custom:active_plan"] == 2 ? " Plata":
                      user["attributes"]["custom:active_plan"] == 3 ? " Oro":
                      user["attributes"]["custom:active_plan"] == 4 ? " Plan Por Paquete":
                      ' No tienes un plan activo'}}</p>
                  </div>
                  <div class="d-flex justify-content-center mt-10">
                    <p
                      *ngIf="user['attributes']['custom:free_trial'] == 1 || user['attributes']['custom:active_plan'] != 0">
                      {{user["attributes"]["custom:free_trial"] == 1 ? "A partir de este momento tienes: "
                      + user["attributes"]["custom:plan_days"] + " días para usar Homty":
                      user["attributes"]["custom:active_plan"] == 1 ? "A partir de este momento tienes: " +
                      user["attributes"]["custom:plan_days"] + " días para usar Homty":
                      user["attributes"]["custom:active_plan"] == 2 ? 'A partir de este momento tienes: ' +
                      user["attributes"]["custom:plan_days"] + " días para usar Homty":
                      user["attributes"]["custom:active_plan"] == 3 ? 'A partir de este momento tienes: ' +
                      user["attributes"]["custom:plan_days"] + " días para usar Homty":
                      ''}}</p>
                  </div>
                  <br>
                  <br>
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <input class="submit-btn" type="button" value="Realizar nueva consulta"
                          (click)="goToSearch()" />
                      </div>
                      <div class="col">
                        <input class="submit-btn" type="button" value="Crear inmuebles" (click)="goToInventory()" />
                      </div>
                    </div>

                  </div>
                  <br>
                  <br>
                  <div class="short-desc d-flex justify-content-center mt-10">
                    <p><b>Agenda una cita con un profesional</b></p>
                  </div>
                  <div class="short-desc d-flex justify-content-center mt-10">
                    <a href="https://meetings.hubspot.com/sac12" target="_blank">
                      <button type="button" class="submit-btn">
                        Agendar
                      </button>
                    </a>
                  </div>
                  <!-- <button type="button" id="btnModalAgencyData" #btnModalAgencyData class="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#modalAgencyData" hidden></button> -->

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Solo Para Agencias Inmobiliarias -->
        <div *ngIf="user['attributes']['nickname'] && user['attributes']['nickname'] == 'Agencia Inmobiliaria'"
          class="container">
          <div class="row">
            <div class="col-lg-12 sm-pt-30">
              <div class="con-info ">
                <span class="designation-info d-flex justify-content-center">¡Bienvenido
                  {{user["attributes"]["family_name"]}}!
                </span>
                <div class="short-desc d-flex justify-content-center mt-10">
                  <p><b>Plan actual: </b>
                    {{user["attributes"]["custom:free_trial"] == 1 ? " Prueba gratuita" :
                    user["attributes"]["custom:active_plan"] == 1 ? " Bronce" :
                    user["attributes"]["custom:active_plan"] == 2 ? " Plata":
                    user["attributes"]["custom:active_plan"] == 3 ? 'Oro' :
                    user["attributes"]["custom:active_plan"] == 4 ? 'Plan por paquete activo' :
                    user["attributes"]["custom:active_plan"] == 7 ? 'Plan paquete Agencia Inmobiliaria' :
                    'No tienes un plan activo'}}</p>
                </div>
                <div class="d-flex justify-content-center mt-10">
                  <p
                    *ngIf="user['attributes']['custom:free_trial'] == 1 || user['attributes']['custom:active_plan'] != 0">
                    {{user["attributes"]["custom:free_trial"] == 1 ? "A partir de este momento tienes: "
                    + user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    user["attributes"]["custom:active_plan"] == 1 ? "A partir de este momento tienes: " +
                    user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    user["attributes"]["custom:active_plan"] == 2 ? 'A partir de este momento tienes: ' +
                    user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    user["attributes"]["custom:active_plan"] == 3 ? 'A partir de este momento tienes: ' +
                    user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    user["attributes"]["custom:active_plan"] == 7 ? 'A partir de este momento tienes: ' +
                    user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    ''}}</p>
                </div>
                <br>
                <br>
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <input class="submit-btn" type="button" value="Realizar nueva consulta" (click)="goToSearch()" />
                    </div>
                    <div class="col">
                      <input class="submit-btn" type="button" value="Administrar Usuarios" routerLink="/users-admin" />
                    </div>
                  </div>
                </div>
                <br>
                <br>


                <div class="short-desc d-flex justify-content-center mt-10">
                  <p><b>Agenda una cita con un profesional</b></p>
                </div>
                <div class="short-desc d-flex justify-content-center mt-10">
                  <a href="https://meetings.hubspot.com/sac12" target="_blank">
                    <button type="button" class="submit-btn">
                      Agendar
                    </button>
                  </a>
                </div>
                <!-- <button id="btnModalAgencyData" type="button" #btnModalAgencyData class="btn btn-primary" data-bs-toggle="modal"
                    data-bs-target="#modalAgencyData" hidden></button> -->
              </div>
            </div>
          </div>
        </div>

      </ng-template>

    </amplify-authenticator>

    <amplify-authenticator *ngIf="initialState == 'signUp'" [services]="services"
    [signUpAttributes]="['phone_number','family_name','address']" [initialState]="'signUp'" stat
    [formFields]="formFields">

    <ng-template amplifySlot="sign-up-form-fields" let-validationErrors="validationErrors">
      <!-- Re-use default `Authenticator.SignUp.FormFields` -->
      <div>
        <h6>Crea una cuenta ahora para empezar a mejorar tu productividad con Homty</h6>
      </div>
      <!--  <amplify-sign-up-form-fields></amplify-sign-up-form-fields> -->
      <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Nombre:
      </label>

      <input class="amplify-input" id="amplify-field-0Xupuu2DtnA3" type="text" name="family_name"
        placeholder="Ingresar Nombre" required="" autocomplete="family-name" aria-invalid="true">

      <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Email:
      </label>
      <input class="amplify-input" id="amplify-field-FPCk1oNijtzi" type="text" name="username"
        placeholder="Ingresar correo electrónico" required="" autocomplete="username" aria-invalid="true">

      <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Contraseña:
      </label>
      <div class="amplify-flex amplify-field-group">
        <input class="amplify-input amplify-field-group__control"
          id="amplify-field-0eiKuWhBR-3x" type="password" name="password" placeholder="Ingresar contraseña"
          required="" autocomplete="new-password" aria-invalid="true">
        <div class="amplify-field-group__outer-end">
          <button amplify-button=""
            class="amplify-field-group__control amplify-field__show-password amplify-button amplify-button--default amplify-button--medium"
            aria-label="Show password" type="button" data-fullwidth="false" data-size="medium"
            data-variation="default" style="font-weight: normal;" ><svg xmlns="http://www.w3.org/2000/svg"
              fill="currentColor" viewBox="0 0 24 24" class="amplify-icon" (click)="classMostrar()">
              <path
                d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z">
              </path>
            </svg><!--bindings={
        "ng-reflect-ng-if": "true"
      }--><!--bindings={
        "ng-reflect-ng-if": "false"
      }--></button>
        </div>
      </div>
      <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Repetir Contraseña:
      </label>
      <div class="amplify-flex amplify-field-group">
        <input class="amplify-input amplify-field-group__control"
          id="amplify-field-wWK568qXSEqX" type="password" name="confirm_password" placeholder="Confirmar contraseña"
          required="" autocomplete="new-password" aria-invalid="true">
        <div class="amplify-field-group__outer-end">
          <button amplify-button=""
            class="amplify-field-group__control amplify-field__show-password amplify-button amplify-button--default amplify-button--medium"
            aria-label="Hide password" type="button" data-fullwidth="false" data-size="medium"
            data-variation="default" style="font-weight: normal;" (click)="classMostrar2()"><svg xmlns="http://www.w3.org/2000/svg"
              fill="currentColor" viewBox="0 0 24 24" class="amplify-icon">
              <path
                d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z">
              </path>
            </svg><!--bindings={
        "ng-reflect-ng-if": "true"
      }--><!--bindings={
        "ng-reflect-ng-if": "false"
      }--></button>
        </div>
      </div>
      <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Celular:
      </label>
      <div amplify-field-group="" class="amplify-flex amplify-phonenumberfield" style="gap: 0px;">
        <div class="amplify-field-group__outer-start">
          <div class="amplify-flex amplify-field amplify-selectfield amplify-countrycodeselect amplify-dialcodeselect"
            style="flex-direction: column;"><label class="amplify-label amplify-visually-hidden"
              for="amplify-field-OuMeO-2IXX0D">Country Code</label>
            <div class="amplify-select__wrapper"><select autocomplete="tel-country-code"
                class="amplify-select amplify-field-group__control" id="amplify-field-OuMeO-2IXX0D"
                name="country_code">
                <option value="+1"> +1 </option>
                <option value="+7"> +7 </option>
                <option value="+20"> +20 </option>
                <option value="+27"> +27 </option>
                <option value="+30"> +30 </option>
                <option value="+31"> +31 </option>
                <option value="+32"> +32 </option>
                <option value="+33"> +33 </option>
                <option value="+34"> +34 </option>
                <option value="+36"> +36 </option>
                <option value="+39"> +39 </option>
                <option value="+40"> +40 </option>
                <option value="+41"> +41 </option>
                <option value="+43"> +43 </option>
                <option value="+44"> +44 </option>
                <option value="+45"> +45 </option>
                <option value="+46"> +46 </option>
                <option value="+47"> +47 </option>
                <option value="+48"> +48 </option>
                <option value="+49"> +49 </option>
                <option value="+51"> +51 </option>
                <option value="+52"> +52 </option>
                <option value="+53"> +53 </option>
                <option value="+54"> +54 </option>
                <option value="+55"> +55 </option>
                <option value="+56"> +56 </option>
                <option selected="true" value="+57"> +57 </option>
                <option value="+58"> +58 </option>
                <option value="+60"> +60 </option>
                <option value="+61"> +61 </option>
                <option value="+62"> +62 </option>
                <option value="+63"> +63 </option>
                <option value="+64"> +64 </option>
                <option value="+65"> +65 </option>
                <option value="+66"> +66 </option>
                <option value="+81"> +81 </option>
                <option value="+82"> +82 </option>
                <option value="+84"> +84 </option>
                <option value="+86"> +86 </option>
                <option value="+90"> +90 </option>
                <option value="+91"> +91 </option>
                <option value="+92"> +92 </option>
                <option value="+93"> +93 </option>
                <option value="+94"> +94 </option>
                <option value="+95"> +95 </option>
                <option value="+98"> +98 </option>
                <option value="+212"> +212 </option>
                <option value="+213"> +213 </option>
                <option value="+216"> +216 </option>
                <option value="+218"> +218 </option>
                <option value="+220"> +220 </option>
                <option value="+221"> +221 </option>
                <option value="+222"> +222 </option>
                <option value="+223"> +223 </option>
                <option value="+224"> +224 </option>
                <option value="+225"> +225 </option>
                <option value="+226"> +226 </option>
                <option value="+227"> +227 </option>
                <option value="+228"> +228 </option>
                <option value="+229"> +229 </option>
                <option value="+230"> +230 </option>
                <option value="+231"> +231 </option>
                <option value="+232"> +232 </option>
                <option value="+233"> +233 </option>
                <option value="+234"> +234 </option>
                <option value="+235"> +235 </option>
                <option value="+236"> +236 </option>
                <option value="+237"> +237 </option>
                <option value="+238"> +238 </option>
                <option value="+239"> +239 </option>
                <option value="+240"> +240 </option>
                <option value="+241"> +241 </option>
                <option value="+242"> +242 </option>
                <option value="+243"> +243 </option>
                <option value="+244"> +244 </option>
                <option value="+245"> +245 </option>
                <option value="+246"> +246 </option>
                <option value="+248"> +248 </option>
                <option value="+249"> +249 </option>
                <option value="+250"> +250 </option>
                <option value="+251"> +251 </option>
                <option value="+252"> +252 </option>
                <option value="+253"> +253 </option>
                <option value="+254"> +254 </option>
                <option value="+255"> +255 </option>
                <option value="+256"> +256 </option>
                <option value="+257"> +257 </option>
                <option value="+258"> +258 </option>
                <option value="+260"> +260 </option>
                <option value="+261"> +261 </option>
                <option value="+262"> +262 </option>
                <option value="+263"> +263 </option>
                <option value="+264"> +264 </option>
                <option value="+265"> +265 </option>
                <option value="+266"> +266 </option>
                <option value="+267"> +267 </option>
                <option value="+268"> +268 </option>
                <option value="+269"> +269 </option>
                <option value="+290"> +290 </option>
                <option value="+291"> +291 </option>
                <option value="+297"> +297 </option>
                <option value="+298"> +298 </option>
                <option value="+299"> +299 </option>
                <option value="+345"> +345 </option>
                <option value="+350"> +350 </option>
                <option value="+351"> +351 </option>
                <option value="+352"> +352 </option>
                <option value="+353"> +353 </option>
                <option value="+354"> +354 </option>
                <option value="+355"> +355 </option>
                <option value="+356"> +356 </option>
                <option value="+357"> +357 </option>
                <option value="+358"> +358 </option>
                <option value="+359"> +359 </option>
                <option value="+370"> +370 </option>
                <option value="+371"> +371 </option>
                <option value="+372"> +372 </option>
                <option value="+373"> +373 </option>
                <option value="+374"> +374 </option>
                <option value="+375"> +375 </option>
                <option value="+376"> +376 </option>
                <option value="+377"> +377 </option>
                <option value="+378"> +378 </option>
                <option value="+379"> +379 </option>
                <option value="+380"> +380 </option>
                <option value="+381"> +381 </option>
                <option value="+382"> +382 </option>
                <option value="+385"> +385 </option>
                <option value="+386"> +386 </option>
                <option value="+387"> +387 </option>
                <option value="+389"> +389 </option>
                <option value="+420"> +420 </option>
                <option value="+421"> +421 </option>
                <option value="+423"> +423 </option>
                <option value="+500"> +500 </option>
                <option value="+501"> +501 </option>
                <option value="+502"> +502 </option>
                <option value="+503"> +503 </option>
                <option value="+504"> +504 </option>
                <option value="+505"> +505 </option>
                <option value="+506"> +506 </option>
                <option value="+507"> +507 </option>
                <option value="+508"> +508 </option>
                <option value="+509"> +509 </option>
                <option value="+537"> +537 </option>
                <option value="+590"> +590 </option>
                <option value="+591"> +591 </option>
                <option value="+593"> +593 </option>
                <option value="+594"> +594 </option>
                <option value="+595"> +595 </option>
                <option value="+596"> +596 </option>
                <option value="+597"> +597 </option>
                <option value="+598"> +598 </option>
                <option value="+599"> +599 </option>
                <option value="+670"> +670 </option>
                <option value="+672"> +672 </option>
                <option value="+673"> +673 </option>
                <option value="+674"> +674 </option>
                <option value="+675"> +675 </option>
                <option value="+676"> +676 </option>
                <option value="+677"> +677 </option>
                <option value="+678"> +678 </option>
                <option value="+679"> +679 </option>
                <option value="+680"> +680 </option>
                <option value="+681"> +681 </option>
                <option value="+682"> +682 </option>
                <option value="+683"> +683 </option>
                <option value="+685"> +685 </option>
                <option value="+686"> +686 </option>
                <option value="+687"> +687 </option>
                <option value="+688"> +688 </option>
                <option value="+689"> +689 </option>
                <option value="+690"> +690 </option>
                <option value="+691"> +691 </option>
                <option value="+692"> +692 </option>
                <option value="+850"> +850 </option>
                <option value="+852"> +852 </option>
                <option value="+853"> +853 </option>
                <option value="+855"> +855 </option>
                <option value="+856"> +856 </option>
                <option value="+872"> +872 </option>
                <option value="+880"> +880 </option>
                <option value="+886"> +886 </option>
                <option value="+960"> +960 </option>
                <option value="+961"> +961 </option>
                <option value="+962"> +962 </option>
                <option value="+963"> +963 </option>
                <option value="+964"> +964 </option>
                <option value="+965"> +965 </option>
                <option value="+966"> +966 </option>
                <option value="+967"> +967 </option>
                <option value="+968"> +968 </option>
                <option value="+970"> +970 </option>
                <option value="+971"> +971 </option>
                <option value="+972"> +972 </option>
                <option value="+973"> +973 </option>
                <option value="+974"> +974 </option>
                <option value="+975"> +975 </option>
                <option value="+976"> +976 </option>
                <option value="+977"> +977 </option>
                <option value="+992"> +992 </option>
                <option value="+993"> +993 </option>
                <option value="+994"> +994 </option>
                <option value="+995"> +995 </option>
                <option value="+996"> +996 </option>
                <option value="+998"> +998 </option>
              </select>
              <div class="amplify-flex amplify-select__icon-wrapper"
                style="align-items: center; justify-content: center;"><svg xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24" data-size="large" fill="currentColor" class="amplify-icon amplify-icon--large">
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                </svg></div>
            </div>
          </div>
        </div><input class="amplify-input" id="amplify-field-dSEbj3-de-Qu" type="tel" name="phone_number"
          placeholder="(555) 555-1212" required="" autocomplete="tel" aria-invalid="true">
      </div>

      <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Ciudad:
      </label>
      <input class="amplify-input" id="amplify-field-ziudpJLJc1kG" type="undefined" name="address"
        placeholder="Ingresar Ciudad" required="" autocomplete="undefined" aria-invalid="true">

      <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Perfil:
      </label>
      <div class="amplify-select__wrapper"><select autocomplete="tel-country-code"
          class="amplify-select amplify-field-group__control" id="amplify-field" name="nickname">
          <option value="Agencia Inmobiliaria">Agencia Inmobiliaria</option>
          <option value="Agente inmobiliario" selected>Agente inmobiliario</option>
          <option value="Avaluador">Avaluador</option>
          <option value="Comercial">Comercial</option>
          <option value="Constructor">Constructor</option>
          <option value="Flipping">Flipping</option>
        </select>
        <div class="amplify-flex amplify-select__icon-wrapper" style="align-items: center; justify-content: center;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-size="large" fill="currentColor"
            class="amplify-icon">
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
          </svg>
        </div>
      </div>
      <label class="amplify-label" for="amplify-field-4aqMiwAg7IA3"> Email Referido (Opcional):
      </label>
      <input class="amplify-input" id="amplify-field" name="profile" type="text"
        placeholder="Ingresar email de quien te recomendó Homty">


      <!-- Append & require Terms & Conditions field to sign up -->
      <amplify-checkbox [errorMessage]="validationErrors.acknowledgement"
        [hasError]="!!validationErrors.acknowledgement" name="acknowledgement" value="yes">
        <a href="https://imagenes-homty.s3.amazonaws.com/Politica+de+tratmiento+de+Datos+Homty+-+septiembre++2021.pdf"
          target="_blank">Términos y condiciones</a>
      </amplify-checkbox>

    </ng-template>

    <!-- signed-in content override -->
    <!-- Para diferentes a Agencias -->
    <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
      <div *ngIf="user['attributes']['nickname'] && user['attributes']['nickname'] != 'Agencia Inmobiliaria'"
        class="container">
        <div class="btm-info-team">
          <div class="row">
            <div class="col-lg-12 sm-pt-30">
              <div class="con-info ">
                <span class="designation-info d-flex justify-content-center">¡Bienvenido
                  {{user["attributes"]["family_name"]}}!
                </span>
                <div class="short-desc d-flex justify-content-center mt-10">
                  <p><b>Plan actual: </b>
                    {{user["attributes"]["custom:free_trial"] == 1 ? "Prueba gratuita" :
                    user["attributes"]["custom:active_plan"] == 1 ? " Bronce" :
                    user["attributes"]["custom:active_plan"] == 2 ? " Plata":
                    user["attributes"]["custom:active_plan"] == 3 ? " Oro":
                    user["attributes"]["custom:active_plan"] == 4 ? " Plan Por Paquete":
                    ' No tienes un plan activo'}}</p>
                </div>
                <div class="d-flex justify-content-center mt-10">
                  <p
                    *ngIf="user['attributes']['custom:free_trial'] == 1 || user['attributes']['custom:active_plan'] != 0">
                    {{user["attributes"]["custom:free_trial"] == 1 ? "A partir de este momento tienes: "
                    + user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    user["attributes"]["custom:active_plan"] == 1 ? "A partir de este momento tienes: " +
                    user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    user["attributes"]["custom:active_plan"] == 2 ? 'A partir de este momento tienes: ' +
                    user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    user["attributes"]["custom:active_plan"] == 3 ? 'A partir de este momento tienes: ' +
                    user["attributes"]["custom:plan_days"] + " días para usar Homty":
                    ''}}</p>
                </div>
                <br>
                <br>
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <input class="submit-btn" type="button" value="Realizar nueva consulta"
                        (click)="goToSearch()" />
                    </div>
                    <div class="col">
                      <input class="submit-btn" type="button" value="Crear inmuebles" (click)="goToInventory()" />
                    </div>
                  </div>

                </div>
                <br>
                <br>
                <div class="short-desc d-flex justify-content-center mt-10">
                  <p><b>Agenda una cita con un profesional</b></p>
                </div>
                <div class="short-desc d-flex justify-content-center mt-10">
                  <a href="https://meetings.hubspot.com/sac12" target="_blank">
                    <button type="button" class="submit-btn">
                      Agendar
                    </button>
                  </a>
                </div>
                <!-- <button type="button" id="btnModalAgencyData" #btnModalAgencyData class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#modalAgencyData" hidden></button> -->

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Solo Para Agencias Inmobiliarias -->
      <div *ngIf="user['attributes']['nickname'] && user['attributes']['nickname'] == 'Agencia Inmobiliaria'"
        class="container">
        <div class="row">
          <div class="col-lg-12 sm-pt-30">
            <div class="con-info ">
              <span class="designation-info d-flex justify-content-center">¡Bienvenido
                {{user["attributes"]["family_name"]}}!
              </span>
              <div class="short-desc d-flex justify-content-center mt-10">
                <p><b>Plan actual: </b>
                  {{user["attributes"]["custom:free_trial"] == 1 ? " Prueba gratuita" :
                  user["attributes"]["custom:active_plan"] == 1 ? " Bronce" :
                  user["attributes"]["custom:active_plan"] == 2 ? " Plata":
                  user["attributes"]["custom:active_plan"] == 3 ? 'Oro' :
                  user["attributes"]["custom:active_plan"] == 4 ? 'Plan por paquete activo' :
                  user["attributes"]["custom:active_plan"] == 7 ? 'Plan paquete Agencia Inmobiliaria' :
                  'No tienes un plan activo'}}</p>
              </div>
              <div class="d-flex justify-content-center mt-10">
                <p
                  *ngIf="user['attributes']['custom:free_trial'] == 1 || user['attributes']['custom:active_plan'] != 0">
                  {{user["attributes"]["custom:free_trial"] == 1 ? "A partir de este momento tienes: "
                  + user["attributes"]["custom:plan_days"] + " días para usar Homty":
                  user["attributes"]["custom:active_plan"] == 1 ? "A partir de este momento tienes: " +
                  user["attributes"]["custom:plan_days"] + " días para usar Homty":
                  user["attributes"]["custom:active_plan"] == 2 ? 'A partir de este momento tienes: ' +
                  user["attributes"]["custom:plan_days"] + " días para usar Homty":
                  user["attributes"]["custom:active_plan"] == 3 ? 'A partir de este momento tienes: ' +
                  user["attributes"]["custom:plan_days"] + " días para usar Homty":
                  user["attributes"]["custom:active_plan"] == 7 ? 'A partir de este momento tienes: ' +
                  user["attributes"]["custom:plan_days"] + " días para usar Homty":
                  ''}}</p>
              </div>
              <br>
              <br>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <input class="submit-btn" type="button" value="Realizar nueva consulta" (click)="goToSearch()" />
                  </div>
                  <div class="col">
                    <input class="submit-btn" type="button" value="Administrar Usuarios" routerLink="/users-admin" />
                  </div>
                </div>
              </div>
              <br>
              <br>


              <div class="short-desc d-flex justify-content-center mt-10">
                <p><b>Agenda una cita con un profesional</b></p>
              </div>
              <div class="short-desc d-flex justify-content-center mt-10">
                <a href="https://meetings.hubspot.com/sac12" target="_blank">
                  <button type="button" class="submit-btn">
                    Agendar
                  </button>
                </a>
              </div>
              <!-- <button id="btnModalAgencyData" type="button" #btnModalAgencyData class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#modalAgencyData" hidden></button> -->
            </div>
          </div>
        </div>
      </div>

    </ng-template>

  </amplify-authenticator>


    <!-- <amplify-authenticator *ngIf="initialState == 'resetPassword'" initialState="forgotPassword">
    </amplify-authenticator> -->

  </ng-container>
</div>

<!-- Start of HubSpot Embed Code -->
<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8998298.js"></script>
<!-- End of HubSpot Embed Code -->
<!--</amplify-auth-container>-->