import { Component, OnInit, ViewChild } from '@angular/core';
import { Auth } from "aws-amplify";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { ShareAcmService } from 'src/app/services/share-acm.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AppraisalService } from 'src/app/services/appraisal.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AgenciesService } from 'src/app/services/agencies.service';
@Component({
  selector: 'app-acms-history',
  templateUrl: './acms-history.component.html',
  styleUrls: ['./acms-history.component.css']
})
export class AcmsHistoryComponent implements OnInit {

  loading: boolean = false;
  loadingTable: boolean = true;
  user: CognitoUserInterface | undefined;
  usersAgency: any[] = []
  allAcmsHistory: any[] = [];

  formGroupSelect = new FormGroup({
    viewAcmsOf: new FormControl('Todos'),
  })

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['index', 'municipio', 'barrio', 'inmueble', 'tamano', 'precio', 'date']

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  constructor(private appraisalService: AppraisalService, private agenciesService: AgenciesService, private shareAcmService: ShareAcmService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then(user => {
      this.user = user;
    });
    
    if (this.user?.attributes?.nickname == 'Agencia Inmobiliaria') {
      this.loading = true;
      this.getAcmsHistory('Todos');
      this.getUsersAgency();
    } else {
      this.loading = true;
      this.getAcmsHistory(this.user?.attributes.email);
    }

    this.formGroupSelect.get('viewAcmsOf')?.valueChanges.subscribe((emailUser: any) => {
      console.log(this.formGroupSelect.get('viewAcmsOf')?.value);
      this.dataSource = new MatTableDataSource();
      this.getAcmsHistory(emailUser);
    });
  }

  getUsersAgency() {
    let agencyName = this.user?.attributes?.family_name;
    this.agenciesService.getUsers(agencyName).subscribe(resp => {
      this.usersAgency = resp.users;
    });
  }

  getAcmsHistory(userEmail: string) {
    this.loadingTable = true;
    if (userEmail != 'Todos') {
      this.appraisalService.getAcmsHistory(userEmail).subscribe(resp => {
        console.log(resp);
        if (resp.answer) {
          this.dataSource = new MatTableDataSource(resp.data)
          this.dataSource.paginator = this.paginator
          this.dataSource.sort = this.sort
        } else {
          this.dataSource = new MatTableDataSource();
        }
        this.loadingTable = false;
        this.loading = false;
      }, error => {
        this.dataSource = new MatTableDataSource();
        this.loadingTable = false;
        this.loading = false;
        console.log(error);
        Swal.fire({
          title: "Error",
          text: 'Ha ocurrido un error al cargar los ACMs',
          confirmButtonColor: '#ff653f',
          confirmButtonText: 'Ok',
        })
      })
    } else {
      if (this.allAcmsHistory.length > 0) {
        this.dataSource = new MatTableDataSource(this.allAcmsHistory);
        this.loadingTable = false;
        this.loading = false;
      } else {
        let agencyName = this.user?.attributes?.family_name;
        this.appraisalService.getAcmsHistoryAgency(agencyName).subscribe(resp => {
          if (resp.answer) {
            this.allAcmsHistory = resp.data;
            this.dataSource = new MatTableDataSource(resp.data)
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort
          } else {
            this.dataSource = new MatTableDataSource();
          }
          this.loadingTable = false;
          this.loading = false;
        }, error => {
          this.dataSource = new MatTableDataSource();
          this.loadingTable = false;
          this.loading = false;
          console.log(error);
          Swal.fire({
            title: "Error",
            text: 'Ha ocurrido un error al cargar los ACMs',
            confirmButtonColor: '#ff653f',
            confirmButtonText: 'Ok',
          })
        });
      }
    }

  }

  selectAcm(acm: any) {
    this.shareAcmService.shareAcm = acm;
    this.router.navigate(['/acp'], { queryParams: { view: 1 } });
  }

}
