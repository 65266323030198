<app-loading
  *ngIf="loading || (authenticator.route !== 'authenticated' && payProcess)"
  class="animate__animated animate__fadeIn vertical-center"
>
</app-loading>

<div
  class="container animate__animated animate__fadeIn vertical-center"
  *ngIf="!loading"
>
  <ng-container *ngIf="authenticator.route !== 'authenticated' && !payProcess">
    <div
      class="rs-team-Single pt-20 pb-10 md-pt-10 md-pb-5 animate__animated animate__fadeIn"
      *ngIf="authState !== 'signedin' && !user"
    >
      <div class="container">
        <div class="btm-info-team">
          <div class="row">
            <div class="col-lg-12 sm-pt-30">
              <div class="con-info">
                <h4 class="title d-flex justify-content-center">
                  Inicia sesión para continuar con la compra
                </h4>
                <div class="short-desc d-flex justify-content-center">
                  Es necesario que inicies sesión o te registres para poder
                  continuar con la compra de tu membresía
                </div>
                <div class="col-md-12 mt--3 col-sm-12 offset-lg-4 col-lg-4">
                  <input
                    class="submit-btn"
                    type="button"
                    value="¡Inicia sesión aquí!"
                    [routerLink]="['/login', 'signIn']"
                    [queryParams]="{
                      plan: selectedPlan,
                      acms: plans[3].acms,
                      properties: plans[3].properties
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="authenticator.route === 'authenticated'">
    <div class="full-grid animate__animated animate__fadeIn">
      <form>
        <div class="billing-fields">
          <div class="checkout-title">
            <h3>Datos personales</h3>
          </div>
          <div class="form-content-box">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label>Nombres</label>
                  <input
                    id="fname"
                    name="fname"
                    class="form-control-mod"
                    type="text"
                    required
                    value="{{ authenticator.user.attributes?.family_name }}"
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label>Ciudad</label>
                  <input
                    id="hnumber"
                    name="hnumber"
                    class="form-control-mod margin-bottom"
                    type="text"
                    placeholder="Ingresa tu dirección de domicilio"
                    required=""
                    value="{{ authenticator.user.attributes?.address }}"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>Celular</label>
                  <input
                    id="number"
                    name="number"
                    class="form-control-mod"
                    type="text"
                    required=""
                    value="{{ authenticator.user.attributes?.phone_number }}"
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    id="email"
                    name="email"
                    class="form-control-mod"
                    type="email"
                    required
                    [(ngModel)]="authenticator.user.attributes!.email"
                    disabled="true"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>Código vendedor (Opcional)</label>
                  <input
                    id="number"
                    name="number"
                    class="form-control-mod"
                    type="text"
                    [(ngModel)]="vendor"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .billing-fields -->

        <div class="ordered-product">
          <div class="checkout-title">
            <h3>
              <fa-icon class="detalle-icon" [icon]="faCartArrowDown"></fa-icon>
              Tu compra
            </h3>
          </div>
          <table>
            <thead>
              <tr>
                <th class="h-table">Plan</th>
                <th class="h-table">Periodicidad</th>
                <th class="h-table">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ plans[+selectedPlan - 1].plan }}
                  <span *ngIf="selectedPlan == '4'"
                    >({{ plans[3].acms }} ACMs y
                    {{ plans[3].properties }} Propiedades)</span
                  >
                  <span *ngIf="selectedPlan == '7'"
                    >({{ plans[6].numberUsers }} Usuarios)</span
                  >
                </td>
                <td>{{ plans[+selectedPlan - 1].periodicity }}</td>
                <td>
                  {{
                    plans[+selectedPlan - 1].price
                      | currency : "COP" : "symbol-narrow" : "1.0-0"
                  }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>
                  {{
                    plans[+selectedPlan - 1].price
                      | currency : "COP" : "symbol-narrow" : "1.0-0"
                  }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="payment-method mt-40 md-mt-20">
          <div class="container">
            <p-carousel
              [value]="products"
              styleClass="custom-carousel"
              [numVisible]="9"
              [numScroll]="1"
              [circular]="true"
              [autoplayInterval]="2000"
              [responsiveOptions]="responsiveOptions"
              [showNavigators]="false"
              [showIndicators]="false"
            >
              <ng-template let-product pTemplate="item">
                <div class="product-item">
                  <div class="product-item-content">
                    <div class="mb-3">
                      <img
                        src="./assets/images/shop/{{ product }}"
                        class="product-image"
                      />
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>

          <div class="bottom-area mb-50 d-flex flex-row-reverse">
            <button
              class="submit-btn w-30"
              (click)="createPayment(authenticator.user.attributes!.email)"
            >
              Proceder con el pago
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</div>
