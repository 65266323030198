import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';
import { AgenciesService } from 'src/app/services/agencies.service';
import { Auth } from 'aws-amplify';
import { CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AdditionalsService } from 'src/app/services/additionals.service';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
declare var jQuery: any;

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.css']
})
export class UsersAdminComponent implements OnInit {

  @ViewChild('modalCreateUser') modalCreateUser: ElementRef | undefined;

  telOptions = { initialCountry: 'co' };

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['index', 'nombre', 'email', 'ciudad', 'celular', 'actions'];
  loading: boolean = true;

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  user: CognitoUserInterface | undefined;
  agencyDetails: any

  formCreateUser = new FormGroup({
    nombre: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    rol: new FormControl('', Validators.required),
    ciudad: new FormControl('', Validators.required),
    celular: new FormControl('', Validators.required)
  });

  constructor(private router: Router, private agenciesService: AgenciesService, private additionalsService: AdditionalsService, private toastr: ToastrService, private clipboard: Clipboard, private title: Title, private meta: Meta) { }

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then(user => {
      this.user = user;
    });
    this.getAgencyDetails();
    this.getUsers();
    this.title.setTitle(`Homty - Usuarios Agencia`);
    this.meta.addTag({ name: 'description', content: 'Usuarios Agencia, administra tus usuarios'});
    this.meta.addTag({ name: 'keywords', content: 'homty, inmobiliario, suscripciones, administracion, pagos, nuevo, inscripcion, servicios'});
  }

  getAgencyDetails() {
    this.agenciesService.getAgencyDetails(this.user?.attributes?.family_name).subscribe((resp: any) => {
      if (resp.answer) {
        this.agencyDetails = resp.dataAgency;
      }
    });
  }

  getUsers() {
    let agencyName = this.user?.attributes?.family_name;
    this.loading = true;
    this.agenciesService.getUsers(agencyName).subscribe(resp => {
      this.dataSource = new MatTableDataSource(resp.users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  canCreateUser() {
    if(this.agencyDetails.disponibles >= 1) {
      jQuery(this.modalCreateUser?.nativeElement).modal('show');
    } else {
      Swal.fire({
        icon: 'error',
        title: 'No tienes usuarios disponibles',
        text: 'Actualmente no cuentas con más usuarios disponibles, actualiza tu plan o elimina usuarios de tu lista.',
        showCancelButton: true,
        confirmButtonColor: '#ff653f',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Ir a Comprar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/pay-subscription', 'agency']);
        }
      });
    }
  }

  createUser() {
    this.formCreateUser.markAllAsTouched();

    if (this.formCreateUser.valid) {
      this.loading = true;
      const newUser = this.formCreateUser.value;
      const agencyName = this.user?.attributes.family_name;
      const renovationDate = this.user?.attributes['custom:renovation_date'];
      this.agenciesService.createUser(agencyName, renovationDate, newUser).subscribe((resp: any) => {

        Swal.fire({
          title: "¡Bien Hecho!",
          html: 'El usuario se ha creado correctamente. Su contraseña temporal es <br><br> <str>' + resp.password + '</str>  <br><br> Guárdala o envíasela al usuario.',
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Copiar Contraseña',
          denyButtonText: `Enviar al Correo`,
          denyButtonColor: '#FF653F',
          cancelButtonText: 'Cerrar',
          confirmButtonColor: '#353F71'
        }).then((result) => {
          if (result.isConfirmed) {
            this.clipboard.copy(resp.password);
          } else if (result.isDenied) {
            this.additionalsService.sendEmailNewUserAgency(agencyName, newUser.email, newUser.rol, resp.password).subscribe((resp: any) => {
              console.log(resp);
              if (resp.answer) {
                this.toastr.success('Correo enviado correctamente', '¡Bien hecho!');
              }
            });
          }
        });
        this.getAgencyDetails();
        this.getUsers();
        this.formCreateUser.reset();
      }, error => {
        this.loading = false;
      });
      jQuery(this.modalCreateUser?.nativeElement).modal('hide');
    }
  }

  hasError(event: any): void {
    if (!event && this.formCreateUser.value.celular !== '') {
      this.formCreateUser.get('celular')!.setErrors(['invalid_cell_phone', true]);
    }
  }

  getNumber(number: any) {
    this.formCreateUser.get('celular')!.setValue(number, { emitEvent: false });
  }

  editUser(user: any) {
    // Lógica para editar un user en la API o en la fuente de datos
  }

  deleteUser(userEmail: string) {
    Swal.fire({
      title: "¿Está seguro?",
      text: "Haga click en confirmar si en realidad desea eliminar el usuario '" + userEmail + "'",
      showCancelButton: true,
      confirmButtonColor: '#ff653f',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      console.log(this.user);
      const agencyName = this.user?.attributes.family_name;
      if (result.isConfirmed) {
        this.loading = true;
        this.agenciesService.deleteUser(agencyName, userEmail).subscribe((resp: any) => {
          console.log(resp);
          if (resp.answer) {
            this.toastr.success(resp.message, 'Bien Hecho');
          } else {
            this.toastr.error(resp.message, 'Error');
          }
          this.getAgencyDetails();
          this.getUsers();
        });
      }
    });
  }

}
