import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Auth } from "aws-amplify";
import { EstatePage } from 'src/app/models/estatepage.model';
import { EstateService } from 'src/app/services/estate.service';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Estate } from 'src/app/models/estate.model';
import { faLink, faStream, faTags, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from "@angular/material/dialog";
import { DownloadPrivateComponent } from "../../components/download-private/download-private.component"

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})


export class FavoritesComponent implements OnInit {
  displayedColumns: string[] = ['Barrio', 'Inmueble', 'Operacion', 'Precio', 'Habitaciones', 'Banos', 'Parking', 'Estrato', 'Valor_Admin', 'Tamano', 'Antiguedad', 'Piso', 'Miguel'];

  dataSource!: MatTableDataSource<EstatePage>;
  length: number | undefined;
  pageSize: number = 25;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];


  public loading: boolean = true;

  user: CognitoUserInterface | undefined;

  selectedPropertyToModal: Estate = new Estate("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "","","", []);

  faStream = faStream;
  faLink = faLink;
  faTags = faTags;
  faDownload = faDownload;
  faWhatsapp = faWhatsapp;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(private estateService: EstateService, private _liveAnnouncer: LiveAnnouncer, private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getFavorites();
  }

  getFavorites() {
    Auth.currentUserInfo().then(user => {
      this.user = user;
      this.estateService.getFavorites(user?.attributes?.email).subscribe(resp => {
        if (resp.items.length == 0) {
          this.dataSource = new MatTableDataSource();
        } else {
          this.length = resp.items.length
          this.dataSource = new MatTableDataSource(resp.items);
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
        this.loading = false;
      })
    });
  }

  updateFavorite(action: string, propertyId: string) {
    this.estateService.updateFavorite(this.user?.attributes?.email, propertyId, action).subscribe(resp => {
      this.getFavorites()
      this.dataSource._updateChangeSubscription();
    })
  }

  download() {
    this.loading = true;
    this.estateService.downloadFavorites(this.user!["attributes"]["email"])
      .subscribe(resp => {
        window.open(resp.url);
        this.loading = false;
      }, error => {
        if (error.status == 403) {
          this.loading = false;
          this.matDialog.open(DownloadPrivateComponent, {
            width: '510px',
            data: { message:error.error.error },
          });
        }
      });
  }
}


