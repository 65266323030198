<section class="pricing py-5">
  <div class="container">
    <div class="row">
      <h1 class="text-center">
        <!-- Agente, empieza hoy a optimizar tu tiempo -->
        Planes y precios
      </h1>
      <p class="text-center">
        <!-- Mejora hoy los tiempos de captación de propiedades con Homty -->
        Realiza avalúos o estudios de mercado con muestras más grandes y a la
        velocidad de la luz
      </p>
    </div>
    <div class="cards-container">
      <!-- Gratis -->
      <div class="col">
        <div class="card mb-5 mb-lg-0">
          <div class="card-header bg-color-gray">
            <div class="mt-20">
              <h3 class="text-uppercase text-center mt-20">Gratis</h3>
              <h2 class="h2-custom text-center">$0</h2>
              <p class="card-desc text-center">¡Regístrate ya!</p>
            </div>
          </div>
          <div class="card-body">
            <ul class="fa-ul">
              <p class="card-desc">
                <!-- <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Por registrarte obtén, 4 días totalmente Gratis
                  1 Usuario
                </li> -->
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  <!-- Busqueda libre de propiedades -->
                  4 descargas en Excel <del>sin estadísticas de la zona</del>
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  <!-- Generar descripciones automáticas -->
                  Información sin verificar
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  <!-- Generar 2 análisis comparativos de mercado -->
                  Hasta 30 muestras por descarga
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Acceso a 100 mil inmuebles
                </li>
                <li>
                  <i class="fa-solid fa-times mr-5"></i>
                  <del style="color: gray">
                    Recibe todos los meses un informe estadístico del sector
                    inmobiliario
                  </del>
                </li>
              </p>
            </ul>
          </div>
          <div class="card-footer mt--20">
            <div class="d-grid">
              <a
                href="#"
                class="btn btn-primary text-uppercase"
                style="background-color: black"
                [routerLink]="['/login', 'signUp']"
                >Regístrate ahora</a
              >
            </div>
            <p class="card-desc text-center mt-10">
              <span class="text-muted"
                ><!-- Prueba totalmente gratuita <br />
                de cero riesgo -->
                No tendrás que pagar nada
              </span>
            </p>
          </div>
        </div>
      </div>

      <!-- Bronce -->
      <div class="col">
        <div class="card mb-5 mb-lg-0">
          <div class="card-header bg-color-gray">
            <div class="mt-20">
              <h3 class="text-uppercase text-center mt-20">Bronce</h3>
              <h2 class="h2-custom text-center">$50mil</h2>
              <p class="card-desc text-center">Mensual</p>
            </div>
          </div>
          <div class="card-body">
            <ul class="fa-ul">
              <p class="card-desc">
                <!-- <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  1 Usuario
                </li> -->
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  15 descargas en Excel <del>sin estadísticas de la zona</del>
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Información sin verificar
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Hasta 50 muestras por descarga
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Acceso a 300 mil inmuebles
                </li>
                <li>
                  <i class="fa-solid fa-times mr-5"></i>
                  <del style="color: gray">
                    Recibe todos los meses un informe estadístico del sector
                    inmobiliario
                  </del>
                </li>
              </p>
            </ul>
          </div>
          <div class="card-footer mt--20">
            <div class="d-grid">
              <a
                href="#"
                routerLink="/checkout"
                [queryParams]="{ plan: '1' }"
                class="btn btn-primary text-uppercase"
                style="background-color: black"
              >
                Comprar ya
              </a>
            </div>
            <p class="card-desc text-center mt-10">
              <span class="text-muted"
                >Puedes cancelar tu membresía en cualquier momento
              </span>
            </p>
          </div>
        </div>
      </div>

      <!-- Plata -->
      <div class="col">
        <div class="card mb-5 mb-lg-0">
          <div class="card-header bg-color-pro">
            <div class="mt-20">
              <h3 class="pro text-uppercase text-center">Plata</h3>
              <h2 class="h2-custom pro">
                $150mil
                <!-- <small class="underlined-price"><s>$99mil</s></small> -->
              </h2>
              <p class="card-desc text-center" style="color: #fff">Mensual</p>
            </div>
          </div>
          <div class="card-body">
            <ul class="fa-ul">
              <p class="card-desc">
                <!-- <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Acceso a más de 500kinmuebles
                  3 Usuarios
                </li> -->
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  <!-- Cargar y actualizar inmuebles ilimitados -->
                  60 descargas en Excel con estadísticas de la zona
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  <!-- Realizar y descargar hasta 6 análisis comparativo de mercado -->
                  Información verificada
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  <!-- Acceso a Homty academy -->
                  Hasta 100 muestras por descarga
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Acceso a 600 mil inmuebles
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Recibe todos los meses un informe estadístico del sector
                  inmobiliario
                </li>
              </p>
            </ul>
          </div>
          <div class="card-footer mt--20">
            <div class="d-grid">
              <a
                href="#"
                routerLink="/checkout"
                [queryParams]="{ plan: '2' }"
                class="btn btn-primary text-uppercase"
                style="background-color: #ff653f; border-color: #ff653f"
              >
                Comprar ya
              </a>
              <!-- <a href="#" routerLink="/checkout" class="btn btn-primary text-uppercase">¡Comprar ya!</a> -->
            </div>
            <p class="card-desc text-center mt-10">
              <span class="text-muted">
                <!-- Puede cancelar su membresía en <br />
                cualquier momento -->
                Puedes cancelar tu membresía en cualquier momento
              </span>
            </p>
          </div>
        </div>
      </div>

      <!-- Oro -->
      <div class="col">
        <div class="card mb-5 mb-lg-0">
          <div class="card-header bg-color-gray">
            <div class="mt-20">
              <h3 class="text-uppercase text-center mt-20">Oro</h3>
              <h2 class="h2-custom text-center">$500mil</h2>
              <p class="card-desc text-center">Mensual</p>
            </div>
          </div>
          <div class="card-body">
            <ul class="fa-ul">
              <p class="card-desc">
                <!--  <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  10 Usuarios
                </li> -->
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Descargas <b>ilimitadas</b> en Excel con estadisiticas de la
                  zona
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Información verificada
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Hasta 100 muestras por descarga
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Acceso a 600 mil inmuebles
                </li>
                <li>
                  <i class="fa-solid fa-check mr-5"></i>
                  Recibe todos los meses un informe estadístico del sector
                  inmobiliario
                </li>
              </p>
            </ul>
          </div>
          <div class="card-footer mt--20">
            <div class="d-grid">
              <a
                href="#"
                routerLink="/checkout"
                [queryParams]="{ plan: '3' }"
                class="btn btn-primary text-uppercase"
                style="background-color: black"
              >
                Comprar ya
              </a>
            </div>
            <p class="card-desc text-center mt-10">
              <span class="text-muted">
                Puedes cancelar tu membresía en cualquier momento
              </span>
            </p>
          </div>
        </div>
      </div>

      <!-- Empresarial -->
      <!-- <div class="col">
        <div class="card mb-5 mb-lg-0">
          <div class="card-header">
            <div class="mt-20">
              <h3 class="text-uppercase text-center mt-20">Empresarial</h3>
              <h3 class="text-center">Contáctanos</h3>
              <p class="card-desc text-center">Comunícate con nosotros</p>
            </div>

          </div>
          <div class="card-body">
            <ul class="fa-ul">
              <p class="card-desc">
                <i class="fa-solid fa-check"></i>
                Tus asesores comerciales amarán nuestro servicio porque tendrán más tiempo para aumentar sus ventas.
              </p>
              <p class="card-desc">
                <i class="fa-solid fa-check"></i>
                Aplica para +10 usuarios
              </p>
              <p class="card-desc">
                <i class="fa-solid fa-check"></i>
                Beneficios especiales
              </p>
            </ul>


          </div>
          <div class="card-footer mt--20">
            <div class="d-grid">
              <a href="#" class="btn btn-primary text-uppercase" [routerLink]="['/login', 'signUp']">Regístrate
                ahora</a>
            </div>
            <p class="card-desc text-center mt-10">
              <span class="text-muted">Ideal para hacer crecer tu empresa inmobiliaria </span>
            </p>
          </div>

        </div>
      </div>
    </div> -->

      <!-- Agencias -->
      <!-- <div class="col">
      <div class="card mb-5 mb-lg-0">

        <div class="card-header">
          <span class="ribbon-new">¡ NUEVO !</span>
          <div class="mt-20">
            <h3 class="text-uppercase text-center mt-20">Agencias</h3>
            <h2 class="h2-custom text-center">$250mil <small class="underlined-price" style="color: #7a7a7a;">X
                Usuario</small></h2>
            <p class="card-desc text-center">Anual</p>
          </div>

        </div>
        <div class="card-body">
          <ul class="fa-ul">
            <p class="card-desc">
              <i class="fa-solid fa-check"></i>
              Tus asesores comerciales amarán nuestro servicio porque tendrán más tiempo para aumentar sus ventas.
            </p>
            <p class="card-desc">
              <i class="fa-solid fa-check"></i>
              Administra tus empleados con diferentes usuarios para que usen nuestros servicios
            </p>
            <p class="card-desc">
              <i class="fa-solid fa-check"></i>
              Beneficios especiales
            </p>
          </ul>


        </div>
        <div class="card-footer mt--20">
          <div class="d-grid">
            <a href="#" [routerLink]="['/pay-subscription', 'agency']" class="btn btn-primary text-uppercase">¡Comprar
              ya!</a>
          </div>
          <p class="card-desc text-center mt-10">
            <span class="text-muted">Ideal para hacer crecer tu empresa inmobiliaria </span>
          </p>
        </div>

      </div>
    </div> -->
    </div>
    <br />

    <!-- Planes -->
    <div class="row mt-20">
      <!-- Plan Semestral -->
      <!-- <div class="col-lg-6 mt-4">
        <div class="plans semester-plan">
          <div class="card-body">
            <div class="m-4 mt-20">
              <h3 class="mt-20 ml-auto">Plan semestral</h3>
              <h2 class="h2-price color-pro">$240.000</h2>
              <p class="mt-3">Ahorra un 20% pagando 6 meses anticipadamente</p>
              <p class="mt-3">
                Puedes generar 35 análisis comparativos de mercado
              </p>
              <div class="d-flex">
                <a
                  href="#"
                  routerLink="/checkout"
                  [queryParams]="{ plan: '2' }"
                  class="btn btn-primary text-uppercase"
                  >Obtener oferta</a
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Plan Anual -->
      <!-- <div class="col-lg-6 mt-4">
        <div class="plans anual-plan">
          <div class="card-body">
            <div class="m-4 mt-20">
              <h3 class="mt-20 ml-auto color-white">Plan anual</h3>
              <h2 class="h2-price color-white">$360.000</h2>
              <p class="mt-3 color-white">
                Ahorra un 40% pagando 1 año por anticipado
              </p>
              <p class="mt-3 color-white">
                Puedes generar 70 análisis comparativos de mercado
              </p>
              <div class="d-flex">
                <a
                  href="#"
                  routerLink="/checkout"
                  [queryParams]="{ plan: '3' }"
                  class="btn btn-primary text-uppercase"
                  >Obtener oferta</a
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <div class="row mt-30">
      <div class="card mb-5 mb-lg-0">
        <div class="card-body">
          <h3>Beneficios extra de tu membresía PRO</h3>

          <p class="card-desc mt-20">
            <i class="fa-solid fa-check mr-5"></i>
            Hacer parte de la revolución del mercado inmobiliario
          </p>
          <p class="card-desc">
            <i class="fa-solid fa-check mr-5"></i>
            Acceso a los reportes de mercado inmobiliario mensualmente.
          </p>
          <p class="card-desc">
            <i class="fa-solid fa-check mr-5"></i>
            Descuento del 10% del Curso de formación inmobiliaria en alianza con
            CSI (1).
          </p>
          <p class="card-desc">
            <i class="fa-solid fa-check mr-5"></i>
            Descuento del 10% en pautas publicitarias en tu voz el Magazín
            Inmobiliario. (2)
          </p>
          <p class="card-desc">
            <i class="fa-solid fa-check mr-5"></i>
            El 15% de Descuento en consultoría empresarial inmobiliaria.
          </p>
          <p class="card-desc">
            <i class="fa-solid fa-check mr-5"></i>
            El 10% de descuento en consultoría juridica para la elaboración de
            documentos legales como compra-venta de inmuebles, estudios de
            titulos, o cualquier otro proceso jurídico inmobiliario
          </p>

          <div class="row">
            <span>
              (1) 123 horas de formación teórico práctico. El curso más completo
              del mercado inmobiliario, con los mejores instructores y
              certificado de asistencia
            </span>
          </div>

          <div class="row">
            <span>
              (2) Tu voz el Magazín Inmobiliario, 1.080 am todos los sábados y
              en vivo en el canal de Youtube. El único Magazín especializado en
              el Mercado inmobiliario del país
            </span>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row mt-30">
      <div class="card mb-5 mb-lg-0">
        <div class="card-body">
          <h3 style="text-align: center">Preguntas Frecuentes</h3>
          <ul class="fa-ul">
            <p class="card-desc">
              <li>
                <i class="fa-solid mr-5">1</i>
                <b>¿En qué ciudades funciona Homty?</b> Homty extrae información
                de internet así que funciona muy bien en las ciudades
                principales de Colombia como Bogotá, Medellín, Cali, Bucaramanga
                o Barranquilla. También funciona muy bien en ciudades
                intermedias como Armenia, Popayán o Santa Marta. En las ciudades
                más pequeñas donde no hay suficiente información pedimos tu
                ayuda para subir información de propiedades para toda la
                comunidad.
              </li>
              <li>
                <i class="fa-solid mr-5">2</i>
                <b>¿Qué tipo de propiedades tiene Homty?</b> Apartamentos,
                Aparta estudios, Bodegas, Casas, Casas campestres, Consultorios,
                Fincas, Locales, Lotes y Oficinas. Todas en arriendo y en venta.
              </li>
              <li>
                <i class="fa-solid mr-5">3</i>
                <b>¿Para quién es Homty?</b> Para todos los interesados en el
                sector inmobiliario, especialmente para avaluadores que usan
                datos para sus avalúos o estudios de mercado. Para agentes
                inmobiliarios independientes o inmobiliarias que quieran acceder
                a listados de información más rápidos y conocer el precio del
                mercado que trabajan. También para desarrolladores inmobiliarios
                que quieran conocer más información acerca del mercado en donde
                planean construir.
              </li>
            </p>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mt-30">
      <div class="card mb-5 mb-lg-0">
        <div class="card-body">
          <h4>
            ¿Aún tienes dudas de como funciona Homty? Mira este video
            <a
              class="btn btn-primary text-uppercase"
              style="background-color: #ff653f; border-color: #ff653f"
              href="https://youtu.be/i34Ed6mlm9o?si=beItcrPMIHwhpTj4"
              target="_blank"
            >
              Ver video
            </a>
          </h4>
          <h4>
            <a
              class="btn btn-primary text-uppercase"
              style="background-color: #ff653f; border-color: #ff653f"
              href="https://wa.link/324ikq"
              target="_blank"
            >
              NECESITO LA INFORMACIÓN
            </a>
            ¿Te gustaría acceder a información con mayor profundidad?
            <div class="text-center" style="padding-left: 300px">
              <p style="text-align: center; font-size: 15px">
                Tenemos reportes personalizados con tendencias históricas del
                <br />
                mercado nuevo y de usados. Además, información demográfica, de
                <br />
                transporte, interesés socioeconómicos y más.
              </p>
            </div>
          </h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Start of HubSpot Embed Code -->
<script
  type="text/javascript"
  id="hs-script-loader"
  async
  defer
  src="//js.hs-scripts.com/8998298.js"
></script>
<!-- End of HubSpot Embed Code -->
