<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>

<div class="container mt-30 mb-20" *ngIf="!loading">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <h2>Empieza a recibir ofertas de las propiedades que realmente te interesan</h2>
      <div class="row mt-3">
        <h4 *ngIf="params.results > 0">Propiedades Encontradas</h4>
        <h4 *ngIf="params.results == 0">No encontramos propiedades, lo sentimos. Cada dia estamos actualizando nuestra
          base de
          datos.</h4>
        <hr>
        <p class="sub-header pt-20 pb-20">Hemos encontrado <b>{{params.results}}</b> resultados que coinciden con los
          filtros
          que seleccionaste</p>
          <p class="sub-header pt-20 pb-20">¡Nosotros hacemos el trabajo por ti! <br>
            
            Como funciona:<br><br>
            
            1. Nosotros vamos a contactar directamente a por lo menos 100 agencias o los propietarios para que sepan que estás buscando una propiedad. <br>
            2. Ellos se van a contactar contigo directamente a tu WhatsApp o por una llamada. Tu solo tienes que esperar a que te contacten.<br>
            3. Elije la oferta que más se ajuste a tus intereses y cierra el trato directamente con la agencia o el propietario.<br>
            4. ¡Recomienda a Homty o un amigo o familiar que esté buscando su nueva casa para arrendar o para comprar y cuéntale todo el tiempo y el esfuerzo que te ahorraste!
            <br><br>
            
            Garantía Homty: <br>
            Si no recibes una oferta que se ajuste a tus intereses, te devolvemos el dinero.</p>
        <hr>

        <div class="filters-container">
          <div class="filter-item" *ngIf="params.inmueble">
            Tipo inmueble:
            <div class="filter-value">{{params.inmueble | titlecase}}</div>
          </div>
          <div class="filter-item" *ngIf="params.operacion">
            Operación:
            <div class="filter-value">{{params.operacion | titlecase}}</div>
          </div>
          <div class="filter-item" *ngIf="params.municipio">
            Municipio:
            <div class="filter-value">{{params.municipio | titlecase}}</div>
          </div>
          <div class="filter-item" *ngIf="params.barrio">
            Barrio:
            <div class="filter-value">{{params.barrio | titlecase}}</div>
          </div>
          <div class="filter-item" *ngIf="params.habitaciones">
            Habitaciones:
            <div class="filter-value">{{params.habitaciones}}</div>
          </div>
          <div class="filter-item" *ngIf="params.banos">
            Baños:
            <div class="filter-value">{{params.banos}}</div>
          </div>
          <div class="filter-item" *ngIf="params.parqueadero">
            Parqueaderos:
            <div class="filter-value">{{params.parqueadero}}</div>
          </div>
          <div class="filter-item" *ngIf="params.preciomin">
            Precio mínimo:
            <div class="filter-value">{{params.preciomin | currency:'COP':'symbol-narrow':'1.0-0'}}</div>
          </div>
          <div class="filter-item" *ngIf="params.preciomax">
            Precio máximo:
            <div class="filter-value">{{params.preciomax | currency:'COP':'symbol-narrow':'1.0-0'}}</div>
          </div>
          <div class="filter-item" *ngIf="params.antiguedad && params.antiguedad != '--'">
            Antigüedad:
            <div class="filter-value">{{params.antiguedad}}</div>
          </div>
          <div class=" filter-item" *ngIf="params.estrato">
            Estrato mínimo:
            <div class="filter-value">{{params.estrato}}</div>
          </div>
          <div class="filter-item" *ngIf="params.estratomax">
            Estrato máximo:
            <div class="filter-value">{{params.estratomax}}</div>
          </div>
          <div class="filter-item" *ngIf="params.mtmin">
            Metros² mínimos:
            <div class="filter-value">{{params.mtmin}}</div>
          </div>
          <div class="filter-item" *ngIf="params.mtmax">
            Metros² máximos:
            <div class="filter-value">{{params.mtmax}}</div>
          </div>
          <div class="filter-item" *ngIf="params.valorAdmin">
            Metros² mínimos:
            <div class="filter-value">{{params.valorAdmin}}</div>
          </div>
          <div class="filter-item" *ngIf="params.valorAdminmax">
            Metros² máximos:
            <div class="filter-value">{{params.valorAdminmax}}</div>
          </div>
          <div class="filter-item" *ngIf="params.piso">
            Piso:
            <div class="filter-value">{{params.piso}}</div>
          </div>

        </div>

      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12" *ngIf="params.results > 0">
      <div class="card" style="width: 100%; margin: 10px 20px">
        <img class="card-img-top" src="assets/images/animation/invertir-casas.gif" alt="Image Animation">
        <div class="card-body">
          <h5 class="card-title">Precio</h5>
          <h3 class="orange">$15.000 <span class="text-muted">COP</span> </h3>
          <p class="card-text">Al cancelar inmediatamente empezaremos a contactar a los propietarios</p>
          <div class="text-center">
            <a (click)="goToConfirmationPage()" class="btn btn-primary text-uppercase text-center">¡Comprar
              ya!</a>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>