import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class AppraisalService {

  constructor(private http: HttpClient) { }

  getNeighbourhood(latitude: any, longitude: any): Observable<any> {
    let url = 'https://nominatim.openstreetmap.org/reverse'

    let params = new HttpParams();
    params = params.set("lat", latitude)
    params = params.set("lon", longitude)

    return this.http.get<any>(url, { params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    )
  }

  createAppraisal(property: any) {
    let url = `${base_url}/acp`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(url, property, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    )
  }

  getAcmsHistory(userEmail: string) {
    let url = `${base_url}/getacm`;

    return this.http.get<any>(url, { params: { user: userEmail } }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getAcmsHistoryAgency(agencyName: string) {
    let url = `${base_url}/get_acms_agency`;

    return this.http.get<any>(url, { params: { agencia: agencyName } }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  saveHistoryACMPaid(data: any) {
    let url = `${base_url}/save_acm_history`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    )
  }

}