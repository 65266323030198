import { Component, Inject, OnInit } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { ContactService } from 'src/app/services/contact.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title, Meta } from '@angular/platform-browser';


export interface DialogData {
  nombre: string;
  apellido: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private contactService: ContactService, public dialog: MatDialog, private title: Title, private meta: Meta) { }

  faEnvelope = faEnvelope;
  faWhatsapp = faWhatsapp;

  nombre = ''
  apellido = ''
  email = ''
  asunto = ''

  ngOnInit(): void {
    this.title.setTitle('Homty - Contacto');
    this.meta.addTag({ name: 'description', content: 'Homty contacto inmobiliario, formulario de contacto' });
    this.meta.addTag({ name: 'keywords', content: 'homty, inmobiliario, propiedades, contacto, numero, informacion, telefono, correo electronico, formulario de contacto' });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '500px',
      data: { nombre: this.nombre, apellido: this.apellido }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.nombre = result;
    });
  }

  sendMessage() {
    this.contactService.sendMessage(this.nombre, this.apellido, this.email, this.asunto)
      .subscribe(res => {
        this.openDialog()
        this.nombre = " ";
        this.apellido = " ";
        this.email = " ";
        this.asunto = " ";
      });
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.component.html',
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
