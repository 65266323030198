<section class="contact py-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title text-center">Información de contacto</h4>                        
                    </div>
                    <div class="card-body">
                        <ul class="fa-ul">
                            <li><fa-icon [icon]="faEnvelope"></fa-icon><a href="mailto:sac@homty.com" target="_blank">  sac@homty.com</a></li>
                            <li><fa-icon [icon]="faWhatsapp"></fa-icon><a href="https://wa.me/573054064684" target="_blank">  3054064684</a></li>
                        </ul>                        
                    </div>
                </div>
            </div> 
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title text-center">Formulario de contacto</h4>
                    </div>
                    <div class="card-body">                        
                        <div class="row g-3">
                            <div class="form-floating col-md-6">                                    
                                <input type="text" class="form-control" id="idNombre" placeholder="Nombre" [(ngModel)]="nombre">
                                <label for="idNombre">Nombre</label>
                            </div>
                            <div class="form-floating col-md-6">                                    
                                <input type="text" class="form-control" id="idApellido" placeholder="Apellido" [(ngModel)]="apellido">
                                <label for="idApellido">Apellido</label>
                            </div>
                            <div class="form-floating col-12">                                    
                                <input type="email" class="form-control" id="idEmail" placeholder="name@example.com" [(ngModel)]="email">
                                <label for="idEmail">Email</label>
                            </div>
                            <div class="col-12 mb-3">                                    
                                <label for="idAsunto">Asunto</label>                                  
                                <textarea class="form-control" id="idAsunto" placeholder="Escriba aquí su asunto" rows="5" [(ngModel)]="asunto"></textarea> 
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn" (click)="sendMessage()">
                                    Enviar mensaje
                                </button>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start of HubSpot Embed Code -->
<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8998298.js"></script>
<!-- End of HubSpot Embed Code -->
