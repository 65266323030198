import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Estate } from '../models/estate.model';
import { catchError, map, retry } from 'rxjs/operators';
import { City } from '../models/city';
import { Observable, throwError } from 'rxjs';
import Auth from '@aws-amplify/auth';
import { DownloadData, EstateData } from '../models/estateData.model';
import { Sort } from '@angular/material/sort';
import { AuthService } from './auth.service';


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class EstateService {

  constructor(private http: HttpClient, private authService: AuthService) { }


  getEstate() {
    const url = `${base_url}/list`;
    return this.http.get<Estate[]>(url);
  }

  getCities() {
    const url = `${base_url}/listcities`;
    return this.http.get<City[]>(url, { headers: { skip: "true" } });
  }

  getPropertyTypes() {
    const url = `${base_url}/listpropertytypes`;
    return this.http.get<any[]>(url, { headers: { skip: "true" } });
  }

  getOrigins() {
    const url = `${base_url}/getorigins`;
    return this.http.get<any[]>(url, { headers: { skip: "true" } });
  }

  getEstateFiltered(inmueble: string, municipio: string, precioMin: string, precioMax: string) {
    const url = `${base_url}/getbyfilters`;

    let params = new HttpParams()
      .set("inmueble", inmueble)
      .set("municipio", municipio)
      .set("preciomin", precioMin)
      .set("preciomax", precioMax)

    return this.http.get<Estate[]>(url, { params })

  }

  getPropertiesFiltered(inmueble: string, barrio: string, municipio: string, precioMin: string,  precioMax: string, habitaciones: string, banos: string) {
    const url = `${base_url}/getbyallfilters`;

    let params = new HttpParams()

    if (inmueble != '') {
      params = params.set("inmueble", inmueble);
    }

    if (barrio != '') {
      params = params.set("barrio", barrio);
    }


    if (municipio != '') {
      params = params.set("municipio", municipio);
    }

    if (habitaciones != '0' && habitaciones != '') {
      params = params.set("habitaciones", habitaciones);
    }

    if (banos != '0' && banos != '') {
      params = params.set("banos", banos);
    }
    params = params.set("preciomin", precioMin)
    params = params.set("preciomax", precioMax)


    return this.http.get<Estate[]>(url, { params })
  }

  validateEmptyParams(inmueble: string, operacion: string, municipio: string, barrio: string, habitaciones: string, banos: string,
    mtmin: string, mtmax: string, parqueadero: string, estrato: string, estratoMax: string, valorAdmin: string, valorAdminMax: string, antiguedad: string, piso: string, origin: string, sortState?: Sort) {
    let params = new HttpParams()

    if (origin != '') {
      params = params.set("origen", origin);
    }

    if (inmueble != '') {
      params = params.set("inmueble", inmueble);
    }

    if (operacion != '') {
      params = params.set("operacion", operacion);
    }

    if (municipio != '') {
      params = params.set("municipio", municipio);
    }

    if (barrio != '') {
      params = params.set("barrio", barrio);
    }

    if (habitaciones != null) {
      if (habitaciones != '0' && habitaciones != '') {
        params = params.set("order", habitaciones);
      }
    }

    if (banos != null) {
      if (banos != '0' && banos != '') {
        params = params.set("banos", banos);
      }
    }

    if (mtmin != null) {
      if (mtmin != '0' && mtmin != '') {
        params = params.set("mtmin", mtmin);
      }
    }

    if (mtmax != null) {
      if (mtmax != '0' && mtmax != '') {
        params = params.set("mtmax", mtmax);
      }
    }

    if (parqueadero != null && parqueadero != '' && parqueadero != '0') {
      params = params.set("parqueadero", parqueadero);
    }

    if (estrato != null && estrato != '' && estrato != '0') {
      params = params.set("estrato", estrato);
    }

    if (estratoMax != null && estratoMax != '' && estratoMax != '0') {
      params = params.set("estratomax", estratoMax);
    }

    if (valorAdmin != null && valorAdmin != '' && valorAdmin != '0') {
      params = params.set("valorAdmin", valorAdmin);
    }

    if (valorAdminMax != null && valorAdminMax != '' && valorAdminMax != '0') {
      params = params.set("valorAdminmax", valorAdminMax);
    }

    if (antiguedad != null && antiguedad != '' && antiguedad != '--') {
      params = params.set("antiguedad", antiguedad);
    }

    if (piso != null && piso != '' && piso != '0') {
      params = params.set("piso", piso);
    }

    if (sortState?.active != null && sortState.direction != '') {
      params = params.set("order", sortState?.active);
      params = params.set("asc", sortState.direction)
    }

    return params;
  }


  getPropertiesFilteredAndPaginated(inmueble: string, operacion: string, municipio: string, precioMin: string, precioMax: string, barrio: string,
    habitaciones: string, banos: string, pageSize: string, pageNumber: string, mtmin: string, mtmax: string, parqueadero: string,
    estrato: string, estratoMax: string, valorAdmin: string, valorAdminMax: string, antiguedad: string, piso: string, origin: string, sortState?: Sort)
    : Observable<EstateData> {

    //const url = `${base_url}/getbypage`;
    const url = `${base_url}/getprop`;

    let params = this.validateEmptyParams(inmueble, operacion, municipio, barrio, habitaciones, banos, mtmin, mtmax, parqueadero, estrato, estratoMax, valorAdmin, valorAdminMax, antiguedad, piso, origin, sortState);

    params = params.set("preciomin", precioMin)
    params = params.set("preciomax", precioMax)
    params = params.set("pagesize", pageSize)
    params = params.set("page", pageNumber)

    return this.http.get<EstateData>(url, { params, headers: { skip: "true" } })
      .pipe(
        catchError(this.handleError)
      )
  }

  getPropertiesFilteredAndPaginatedLogin(inmueble: string, operacion: string, municipio: string, precioMin: string, precioMax: string, barrio: string,
    habitaciones: string, banos: string, pageSize: string, pageNumber: string, mtmin: string, mtmax: string, parqueadero: string,
    estrato: string, estratoMax: string, valorAdmin: string, valorAdminMax: string, antiguedad: string, piso: string, userEmail: string, origin: string, sortState?: Sort,)
    : Observable<EstateData> {
    const url = `${base_url}/getpropertieshomty`;
    // const url = `${base_url}/getproplogin`;
    let params = this.validateEmptyParams(inmueble, operacion, municipio, barrio, habitaciones, banos, mtmin, mtmax, parqueadero, estrato, estratoMax, valorAdmin, valorAdminMax, antiguedad, piso, origin, sortState);

    params = params.set("preciomin", precioMin)
    params = params.set("preciomax", precioMax)
    params = params.set("pagesize", pageSize)
    params = params.set("page", pageNumber)
    params = params.set("user", userEmail)
    return this.http.get<EstateData>(url, { params, headers: { skip: "true" } })
      .pipe(
        catchError(this.handleError)
      )
  }

  
  getPropertiesFilteredAndPaginatedLogin2(inmueble: string, operacion: string, order: string, municipio: string, precioMin: string, precioMax: string, barrio: string,
    habitaciones: string, banos: string, pageSize: string, pageNumber: string, mtmin: string, mtmax: string, parqueadero: string,
    estrato: string, estratoMax: string, valorAdmin: string, valorAdminMax: string, antiguedad: string, piso: string, userEmail: string, origin: string, sortState?: Sort,)
    : Observable<EstateData> {
    const url = `${base_url}/getpropertieshomty`;
    // const url = `${base_url}/getproplogin`;

    let params = this.validateEmptyParams(inmueble, operacion, municipio, barrio, habitaciones, banos, mtmin, mtmax, parqueadero, estrato, estratoMax, valorAdmin, valorAdminMax, antiguedad, piso, origin, sortState);
    console.log(order)   
    if(order == undefined){
       order = "Precio"
     }
    params =  params.set("order", order)
    params = params.set("preciomin", precioMin)
    params = params.set("preciomax", precioMax)
    params = params.set("pagesize", pageSize)
    params = params.set("page", pageNumber)
    params = params.set("user", userEmail)
    return this.http.get<EstateData>(url, { params, headers: { skip: "true" } })
      .pipe(
        catchError(this.handleError)
      )
  }

  downloadPropertiesLogin(inmueble: string, operacion: string, municipio: string, precioMin: string, precioMax: string, barrio: string,
    habitaciones: string, banos: string, mtmin: string, mtmax: string, parqueadero: string, estrato: string, estratoMax: string, valorAdmin: string, valorAdminMax: string, antiguedad: string, piso: string, userEmail: string, origin: string)
    : Observable<DownloadData> {
    //const url = `${base_url}/getbypage`;
    const url = `${base_url}/downloadproperties`;

    let params = this.validateEmptyParams(inmueble, operacion, municipio, barrio, habitaciones, banos, mtmin, mtmax, parqueadero, estrato, estratoMax, valorAdmin, valorAdminMax, antiguedad, piso, origin);

    params = params.set("preciomin", precioMin)
    params = params.set("preciomax", precioMax)
    params = params.set("user", userEmail)


    return this.http.get<DownloadData>(url, { params })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      )
  }


  updateFavorite(userEmail: string, propertyId: string, action: string) {

    let url;

    if (action == '0') {
      url = `${base_url}/uncheckproperty`
    } else {
      url = `${base_url}/checkproperty`
    }

    const params = new HttpParams()
      .set("user", userEmail)
      .set("property", propertyId)
    return this.http.get<string>(url, { params }).pipe(
      catchError(this.handleError)
    )

  }

  getFavorites(userEmail: string): Observable<EstateData> {
    let url = `${base_url}/getfav`;

    const params = new HttpParams()
      .set("user", userEmail)

    return this.http.get<EstateData>(url, { params }).pipe(
      map(response => response),
      retry()
    )
  }

  downloadFavorites(email: string)
    : Observable<DownloadData> {
    const url = `${base_url}/downloadfavorites`;

    let params = new HttpParams()
    params = params.set("email", email)



    return this.http.get<DownloadData>(url, { params })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      )
  }


  private handleError(error: HttpErrorResponse) {
    return throwError('MIue' +
      error);
  }
}
