<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>
<div class="container" *ngIf="!loading">
    <div *ngIf="property && property.Imagenes.length > 0 && property.Imagenes[0] != ''" id="carouselExampleIndicators"
        class="carousel carousel-dark slide mt-30" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item" [ngClass]="{'active': i==0}"
                *ngFor="let imagen of property.Imagenes; let i = index">
                <img [src]="imagen" class="d-block w-100 img-fluid" alt="...">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <div class="row mt-4">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <h1>{{property?.Inmueble}} {{property?.Barrio}}</h1>
            <h3>Barrio: {{property?.Barrio}}</h3>
            <p>{{property?.Descripcion}}</p>
            <p class="codigo">{{property?.id}}</p>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="row fondo-naranja pt-15 pl-10 pr-10 pb-15">
                <div class="col">
                    <p class="titulo-precio">{{property?.Precio}}</p>
                    <p class="subtitulo-precio">Precio de venta / Alquiler</p>
                </div>
                <div class="col">
                    <p class="titulo-precio-admin">{{property?.Valor_Admin}}</p>
                    <p class="subtitulo-precio text-right">Valor administración</p>
                </div>
            </div>

            <div class="row fondo-gris pt-15 pl-10 pr-10 pb-15">
                <div class="row">
                    <div class="col-md-4">
                        <h6>Administración</h6>
                        <p class="valores">{{property?.Valor_Admin}}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Área</h6>
                        <p class="valores">{{property?.Tamano}}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Piso</h6>
                        <p class="valores">{{property?.Piso}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <h6>Parqueadero</h6>
                        <p class="valores">{{property?.Parking}}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Habitaciones</h6>
                        <p class="valores">{{property?.Habitaciones}}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Baños</h6>
                        <p class="valores">{{property?.Banos}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <h6>Antigüedad</h6>
                        <p class="valores">{{property?.Antiguedad}}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Estrato</h6>
                        <p class="valores">{{property?.Estrato}}</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
   <br>
   <div style="display: flex;">
    <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="row fondo-naranja pt-15 pl-10 pr-10 pb-15">
            <div class="col">
                <p class="titulo-precio" >Análisis financiero de la propiedad</p>
                <p class="subtitulo-precio" >Basado en información recolectada por Homty de diferentes fuentes de información</p>
            </div>
        </div>

        <div class="row fondo-gris pt-15 pl-10 pr-10 pb-15">
            <div class="row">
                <div class="col-md-6">
                    <h6>Precio de compra</h6>
                    <p class="valores">{{property?.Precio}}</p>
                </div>
                <div class="col-md-6">
                    <h6>Valorización anual aproximada</h6>
                    <p class="valores">{{valorizacion}}%</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Gastos notariales</h6>
                    <p class="valores">{{gastos_notarial | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
                <div class="col-md-6">
                    <h6>Valorización anual aproximada ($)</h6>
                    <p class="valores">{{valorizacionpre | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Ingreso bruto aproximado anual</h6>
                    <p class="valores">{{ingresobruto | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
                <div class="col-md-6">
                    <h6>Rentabilidad anual aproximada </h6>
                    <p class="valores">{{rentabilidad.toFixed(2)}}%</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Gastos aproximados anuales</h6>
                    <p class="valores">{{gastos | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Ingreso aproximado neto anual </h6>
                    <p class="valores">{{ingresaanual | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
            </div>
        </div>
    </div>
 
    <div class="col-lg-6 col-md-6 col-sm-6"  style="margin-left: 20px; ">
        <div class="row fondo-naranja pt-15 pl-10 pr-10 pb-15">
            <div class="col">
                <p class="titulo-precio" > Calculo de gastos aproximados anuales</p>
                <p class="subtitulo-preciop"> .</p>
            </div>
        </div>

        <div class="row fondo-gris pt-15 pl-10 pr-10 pb-15">
            <div class="row">
                <div class="col-md-12">
                    <h6>Gastos impuestos aproximados anual ($)</h6>
                    <p class="valores">{{impuestos | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h6>Gastos imprevistos aproximados anual ($)</h6>
                    <p class="valores">{{gastosImprevistos | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h6>Gastos administración aproximados anuales ($) </h6>
                    <p class="valores">{{administracion | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h6>Gastos seguro aproximados anual ($) </h6>
                    <p class="valores">{{seguro | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h6>Gastos inmobiliaria aproximados anual ($)</h6>
                    <p class="valores">{{inmboliaria  | currency : "USD" : "symbol" : "1.0-0"}}</p>
                </div>
            </div>
        </div>
    </div>
</div>


    <div class="container mt-50">
        <div class="col-md-12 shadow">
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [iconUrl]="iconHomtyUrl"
                    [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                    <div *ngFor="let itemi of dataCercanias">
                        <ng-container *ngIf="itemi.checked">
                            <agm-marker *ngFor="let itemj of itemi.items" 
                                [latitude]="itemj.latitud" 
                                [longitude]="itemj.longitud"
                                [iconUrl]="itemi.iconUrl"
                            ></agm-marker>
                        </ng-container>
                    </div>
            </agm-map>
        </div>
    </div>

    <app-cercanias [dataCercanias]="dataCercanias" [lat]="latitude" [lng]="longitude" (updateCercanias)="updateCercanias($event)"></app-cercanias>
    
    <div class="container mt-50 mb-100">
        <div class="row fondo-naranja pt-10 pl-10">
            <p class="titulo-precio">Datos de contacto</p>
        </div>
        <div class="row fondo-gris shadow pt-15 pl-10 pr-10 pb-15">
            <h4>Agencia / Vendedor</h4>
            <p class="valores-contacto">{{property?.Vendedor_Agencia}}</p>

            <h4>Whatsapp</h4>
            <a class="valores-contacto" [href]="'https://wa.link/xtvmyw' + '?text=Hola, vi tu inmueble en homty.co con el código ' + property?.id +
                    ' en la fuente Homty y estoy interesado. https://www.homty.com/propiedad?' + property?.id"
                target="_blank">
                <img src="./assets/images/icons/whatsapp.png" alt="">
                3054064684
            </a>
        </div>

</div>