<div class="modal-header">
    <h2 class="modal-title">¡Haz alcanzado el límite de descargas!</h2>
</div>

<div class="modal-body">
    <p class="modal-text">
        <b>{{data.message}}</b>
    </p>
    <button type="button" class="submit-btn" (click)="closeModal()">
       Entendido
    </button>
</div>
