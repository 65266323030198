import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { AgenciesService } from 'src/app/services/agencies.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-agency-data',
  templateUrl: './modal-agency-data.component.html',
  styleUrls: ['./modal-agency-data.component.css']
})
export class ModalAgencyDataComponent implements OnInit {

  user: CognitoUserInterface | undefined;

  formAgencyData = new FormGroup({
    representante: new FormControl('', Validators.required),
    direccion: new FormControl('', Validators.required)
  });

  constructor(private agenciesService: AgenciesService) { }

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then(user => {
      this.user = user;
    });
  }

  async saveAgencyData() {
    await Auth.currentUserInfo().then(user => {
      this.user = user;
    });

    let formData = this.formAgencyData.value
    const agencia = {
      nombre: this.user!.attributes.family_name,
      direccion: formData.direccion,
      representante: formData.representante,
      celular: this.user!.attributes.phone_number,
      ciudad: this.user!.attributes.address,
      email: this.user!.attributes.email
    }
    this.agenciesService.createAgency(agencia).subscribe((resp: any) => {
      console.log(resp);
      if (resp.answer) {
        Swal.fire({
          icon: 'success',
          title: '¡Bien Hecho!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.closeModalAgencyData();
          }
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: '¡Error!',
          text: 'Ocurrió un error al guardar los datos. Intenta de nuevo por favor.',
          showConfirmButton: false,
          timer: 3000
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.openModalAgencyData();
          }
        });
      }
    });
  }

  openModalAgencyData() {
    setTimeout(() => {
      const boton = document.getElementById('btnModalAgencyData');
      boton!.click();
    }, 10);
  }

  closeModalAgencyData() {
    const boton = document.getElementById('closeModalAgencyData');
    boton?.click();
  }


}
