<h2 class="title mt-4 mb-3">CERCANÍAS</h2>

<p class="text-muted">Marca las casillas para consultar las cercanias correspondientes y verlas en el mapa.</p>

<section>

  <div id="accordion" class="myaccordion mb-4 w-100">

    <div class="card" *ngFor="let item of dataCercanias; let i = index">

      <div class="card-header p-0" [id]="'heading' + i">
        <h2 class="mb-0">
          <button class="d-flex py-3 px-4 align-items-center justify-content-between btn-accordion collapsed"
            [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false"
            [attr.aria-controls]="'collapse' + i">
            <div class="heading d-flex align-items-center">
              <div class="mb-0 ml-3 check-item">
                <input class="form-check-input" type="checkbox" [id]="'chkAccordion' + i" [(ngModel)]="item.checked"
                  (change)="onCheckboxChange(item)">
              </div>
              <div class="rounded-icon d-flex align-items-center justify-content-center"
                [style.background]="item.color">
                <i [class]="item.icon" class="fas fa-dollar-sign"></i>
              </div>
              <h3 class="mb-0 ml-3">{{ item.title }}</h3>
            </div>
            <p class="mb-0 num">{{ item.consulted ? item.items.length : '--' }}</p>
          </button>
        </h2>
      </div>

      <div [id]="'collapse' + i" class="collapse" [attr.aria-labelledby]="'heading' + i" data-parent="#accordion">
        <div class="card-body p-0">
          <ul class="mt-2 mb-2" *ngIf="item.loading">
            <li class="text-center ">
              <i class="fa-solid fa-spinner fa-spin" style="color: #ff653f; font-size:2rem"></i>
            </li>
          </ul>
          <ul *ngIf="!item.loading">
            <li *ngFor="let subitem of item.items; let j = index">
              <a class="d-flex justify-content-between align-items-center">
                <p><span>{{ j+1 }}</span> {{ subitem.nombre }}</p>
                <span class="num">{{ subitem.direccion }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>

</section>