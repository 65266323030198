import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Homty360Service } from 'src/app/services/homty360.service';
import { Cercania } from '../../../models/cercania.model';

@Component({
  selector: 'app-cercanias',
  templateUrl: './cercanias.component.html',
  styleUrls: ['./cercanias.component.css']
})
export class CercaniasComponent implements OnInit {

  @Input() dataCercanias?: Cercania[];
  @Input() lat?: number;
  @Input() lng?: number;
  @Output() updateCercanias = new EventEmitter<Cercania[]>();

  constructor(private homty360: Homty360Service) { }

  ngOnInit(): void {
  }

  getCercanias(item: any): void {
    if (this.lat && this.lng) {
      this.homty360.getCercanias(item.typeValue, this.lat, this.lng).subscribe((resp: any) => {
        // console.log("Response: ", JSON.parse(resp.items));
        item.items = JSON.parse(resp.items);
        item.consulted = true;
        item.loading = false;
      }, error => {
        item.loading = false; 
      }
      )
    }
  }

  onCheckboxChange(item: any) {
    if (!item.consulted) {
      item.loading = true;
      this.getCercanias(item);
    }
  }

}
