<app-loading
  *ngIf="loading"
  class="animate__animated animate__fadeIn vertical-center"
>
</app-loading>
<div class="container-fluid">
  <div class="container mt-20" *ngIf="!loading">
    <div class="sec-title2 text-center mb-45">
      <h2 class="title">Mis favoritos</h2>
      <div class="heading-line" [ngClass]="{ loading: loading }"></div>
    </div>
  </div>
  <div *ngIf="!loading" class="mat-elevation-z8">
    <mat-table matSort [dataSource]="dataSource!">
      <ng-container
        *ngFor="let displayedColumn of displayedColumns"
        matColumnDef="{{ displayedColumn }}"
      >
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{
            displayedColumn == "Tamano"
              ? "Area (m²)"
              : displayedColumn && displayedColumn == "Banos"
              ? "Baños"
              : displayedColumn && displayedColumn == "Valor_Admin"
              ? "Valor Admin."
              : displayedColumn && displayedColumn == "Inmueble"
              ? "Tipo de inmueble"
              : displayedColumn && displayedColumn == "Operacion"
              ? "Tipo de operación"
              : displayedColumn && displayedColumn == "Habitaciones"
              ? "Hab."
              : displayedColumn && displayedColumn == "Parking"
              ? "Parquead."
              : displayedColumn && displayedColumn == "Miguel"
              ? "_______________________"
              : displayedColumn && displayedColumn == "Antiguedad"
              ? "Antig."
              : displayedColumn
          }}
        </mat-header-cell>
        <mat-cell
          [matTooltip]="
            displayedColumn == 'Descripcion' ? element[displayedColumn] : ''
          "
          matTooltipPosition="above"
          matTooltipClass="custom-tooltip"
          *matCellDef="let element"
        >
          {{
            displayedColumn == "Precio"
              ? [
                  element[displayedColumn]
                    | currency : "COP" : "symbol-narrow" : "1.0-0"
                ]
              : element[displayedColumn] && displayedColumn == "Favorito"
              ? ""
              : element[displayedColumn] && displayedColumn == "Whatsapp"
              ? ""
              : element[displayedColumn] && displayedColumn == "Origenes"
              ? ""
              : element[displayedColumn]
          }}

          <img
            src="./assets/images/heart-regular.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat"
            *ngIf="displayedColumn == 'Miguel' && element['Favorito'] == '0'"
            (click)="element['Favorito'] = '1'; updateFavorite('1', element.id)"
          />

          <img
            src="./assets/images/heart-solid.png"
            class="favorite-icon pointer animate__animated animate__heartBeat"
            *ngIf="displayedColumn == 'Miguel' && element['Favorito'] == '1'"
            (click)="element['Favorito'] = '0'; updateFavorite('0', element.id)"
          />

          <a
            class="ml-10"
            *ngIf="
              displayedColumn == 'Miguel' && element['Origenes'].length < 2
            "
            [href]="element['Origenes'][0].URL"
            target="_blank"
          >
            <img
              *ngIf="element.Origen == 'MetroCuadrado'"
              src="./assets/images/icons/metrocuadrado.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Finca Raiz'"
              src="./assets/images/icons/fincaraiz.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'CienCuadras'"
              src="./assets/images/icons/ciencuadras.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'WebInmobiliaria'"
              src="./assets/images/icons/webinmobiliaria.png"
              class="favorite-icon-20 pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Wasi'"
              src="./assets/images/icons/wasi.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Alnago'"
              src="./assets/images/icons/alnago.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'AraujoySegovia'"
              src="./assets/images/icons/araujoysegovia.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Inurbanas'"
              src="./assets/images/icons/inurbanas.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Financar'"
              src="./assets/images/icons/logo-financar.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'VozInmobiliaria'"
              src="./assets/images/icons/vozinmobiliaria.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Dinamica Inmobiliaria'"
              src="./assets/images/icons/dinamicainmobiliaria.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Escobar Bonnett'"
              src="./assets/images/icons/escobarbonnett.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Oscar Sandoval Varona'"
              src="./assets/images/icons/oscarsandoval.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'TodoPropiedades'"
              src="./assets/images/icons/todopropiedades.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Franquicia Inmobiliaria'"
              src="./assets/images/icons/franquiciainmobiliaria.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Get Home'"
              src="./assets/images/icons/gethome.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Ana Hernandez'"
              src="./assets/images/icons/anahernandez.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Aliados Inmobiliarios'"
              src="./assets/images/icons/aliadosinmobiliarios.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Ideal Homes'"
              src="./assets/images/icons/idealhomes.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Go Habitat'"
              src="./assets/images/icons/gohabitat.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Su Propiedad'"
              src="./assets/images/icons/supropiedad.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Kota Group'"
              src="./assets/images/icons/kotagroup.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Marlongi'"
              src="./assets/images/icons/marlongi.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Gruges Inmobiliario'"
              src="./assets/images/icons/gruges.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Casa Invicta'"
              src="./assets/images/icons/casainvicta.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Home Inmobiliaria'"
              src="./assets/images/icons/homeinmobiliaria.jpg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Home Capital'"
              src="./assets/images/icons/homecapital.svg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
          </a>

          <button
            *ngIf="
              displayedColumn == 'Miguel' && element['Origenes'].length > 1
            "
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Lista de origenes"
          >
            <mat-icon [ngStyle]="{ color: '#ff5324' }">link</mat-icon>
            <mat-menu #menu="matMenu" class="origins">
              <button mat-menu-item *ngFor="let element of element['Origenes']">
                <a [href]="element.URL" target="_blank">
                  <img
                    *ngIf="element.Origen == 'MetroCuadrado'"
                    src="./assets/images/icons/metrocuadrado.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'Finca Raiz'"
                    src="./assets/images/icons/fincaraiz.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'CienCuadras'"
                    src="./assets/images/icons/ciencuadras.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'WebInmobiliaria'"
                    src="./assets/images/icons/webinmobiliaria.png"
                    class="favorite-icon-20 pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'Wasi'"
                    src="./assets/images/icons/wasi.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'Alnago'"
                    src="./assets/images/icons/alnago.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'AraujoySegovia'"
                    src="./assets/images/icons/araujoysegovia.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Inurbanas'"
                    src="./assets/images/icons/inurbanas.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Financar'"
                    src="./assets/images/icons/logo-financar.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'VozInmobiliaria'"
                    src="./assets/images/icons/vozinmobiliaria.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Dinamica Inmobiliaria'"
                    src="./assets/images/icons/dinamicainmobiliaria.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Escobar Bonnett'"
                    src="./assets/images/icons/escobarbonnett.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Oscar Sandoval Varona'"
                    src="./assets/images/icons/oscarsandoval.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'TodoPropiedades'"
                    src="./assets/images/icons/todopropiedades.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Franquicia Inmobiliaria'"
                    src="./assets/images/icons/franquiciainmobiliaria.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Get Home'"
                    src="./assets/images/icons/gethome.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Ana Hernandez'"
                    src="./assets/images/icons/anahernandez.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Aliados Inmobiliarios'"
                    src="./assets/images/icons/aliadosinmobiliarios.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Ideal Homes'"
                    src="./assets/images/icons/idealhomes.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Go Habitat'"
                    src="./assets/images/icons/gohabitat.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Su Propiedad'"
                    src="./assets/images/icons/supropiedad.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Kota Group'"
                    src="./assets/images/icons/kotagroup.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Marlongi'"
                    src="./assets/images/icons/marlongi.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Gruges Inmobiliario'"
                    src="./assets/images/icons/gruges.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Casa Invicta'"
                    src="./assets/images/icons/casainvicta.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Home Inmobiliaria'"
                    src="./assets/images/icons/homeinmobiliaria.jpg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Home Capital'"
                    src="./assets/images/icons/homecapital.svg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <span>{{ element.URL }}</span>
                </a>
              </button>
            </mat-menu>
          </button>

          <a
            class="ml-10"
            [href]="
              'https://wa.me/' +
              element['Whatsapp'] +
              '?text=Hola, vi tu inmueble en homty.co con el código ' +
              element['id'] +
              ' en la fuente ' +
              element['Origen'] +
              ' y estoy interesado. ' +
              element['Origenes'][0].URL
            "
            *ngIf="displayedColumn == 'Miguel'"
            target="_blank"
          >
            <img src="./assets/images/icons/whatsapp.png" alt="" />
          </a>

          <button
            class="ver-detalles-btn ml-10"
            type="button"
            *ngIf="displayedColumn == 'Miguel'"
            data-bs-toggle="modal"
            data-bs-target="#detallesModal"
            (click)="selectedPropertyToModal = element"
          >
            +Detalle
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="loading">
        <mat-footer-cell *matFooterCellDef colspan="19">
          Cargando información...
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef colspan="19">
          No hay propiedades con los parámetros de busqueda
        </mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: dataSource != null }"
      ></mat-footer-row>
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{
          hide: !(dataSource != null && dataSource.data.length == 0)
        }"
      ></mat-footer-row>
    </mat-table>
    <div class="row">
      <div class="col-lg-2 pt-10 pl-20 pr-20">
        <button class="ver-detalles-btn" type="button" (click)="download()">
          <fa-icon [icon]="faDownload"></fa-icon> Descargar
        </button>
      </div>
      <div class="col-lg-10">
        <mat-paginator
          *ngIf="!(dataSource != null && dataSource.data.length == 0)"
          #paginator
          [length]="length"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<!-- Detail Modal -->
<div
  class="modal fade"
  id="detallesModal"
  tabindex="-1"
  aria-labelledby="detallesModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="detallesModal">
          Detalles: {{ selectedPropertyToModal.Municipio }}-{{
            selectedPropertyToModal.Barrio
          }},
          {{
            selectedPropertyToModal.Precio
              | currency : "COP" : "symbol-narrow" : "1.0-0"
          }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="contact-box">
          <div class="row mb-25">
            <div class="col-2 col-lg-1">
              <div class="address-icon">
                <fa-icon class="detalle-icon" [icon]="faStream"></fa-icon>
              </div>
            </div>
            <div class="col-10 col-lg-11">
              <div class="address-text">
                <span class="label">Descripción:</span>
                <div class="desc">
                  {{ selectedPropertyToModal.Descripcion }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-25">
            <div class="col-2 col-lg-1">
              <div class="address-icon">
                <fa-icon class="detalle-icon" [icon]="faLink"></fa-icon>
              </div>
            </div>
            <!-- <div class="col-10 col-lg-11">
                            <div class="address-text">
                                <span class="label">Url fuente de información:</span>
                                <ul>
                                    <li *ngFor="let origen of selectedPropertyToModal.Origenes">
                                        <a [href]="origen.URL" target="_blank">{{origen.Origen}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
          </div>
          <div class="row mb-25">
            <div class="col-2 col-lg-1">
              <div class="address-icon">
                <fa-icon class="detalle-icon" [icon]="faTags"></fa-icon>
              </div>
            </div>
            <div class="col-10 col-lg-11">
              <div class="address-text">
                <span class="label">Agencia/Vendedor:</span>
                <div class="desc">
                  <!-- {{ selectedPropertyToModal.Vendedor_Agencia }} -->
                  Miguel Abadía
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-25">
            <div class="col-2 col-lg-1">
              <div class="address-icon">
                <fa-icon class="detalle-icon" [icon]="faWhatsapp"></fa-icon>
              </div>
            </div>
            <div class="col-10 col-lg-11">
              <div class="address-text">
                <span class="label">Whatsapp</span>
                <div class="desc" *ngIf="selectedPropertyToModal.id != ''">
                  <a href="https://wa.link/xtvmyw" target="_blank">
                    +57 305 4064684
                  </a>
                  <!-- <a
                    [href]="
                      'https://wa.me/' +
                      selectedPropertyToModal.Whatsapp +
                      '?text=Hola, vi tu inmueble en homty.co con el código ' +
                      selectedPropertyToModal.id +
                      ' en la fuente ' +
                      selectedPropertyToModal.Origenes[0].Origen +
                      ' y estoy interesado. ' +
                      selectedPropertyToModal.Origenes[0].URL
                    "
                    target="_blank"
                  >
                    {{ selectedPropertyToModal.Whatsapp }}</a
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Start of HubSpot Embed Code -->
<script
  type="text/javascript"
  id="hs-script-loader"
  async
  defer
  src="//js.hs-scripts.com/8998298.js"
></script>
<!-- End of HubSpot Embed Code -->
