<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>

<div class="filter" *ngIf="!loading">

  <div class="container pt-100">
    <div class="d-flex flex-column align-items-center justify-content-center">

      <h2 class="">Encontrar propiedades más rápido</h2>
      <div class="row align-items-center">
        <div class="col-lg-10">
          <h4 class="white pt-40">Buscamos por ti las propiedades</h4>
          <p class="white">Buscamos los propietarios de los inmuebles con los filtros seleccionados para que te
            contacten
            a ti directamente. </p>
        </div>
        <div class="col-lg-2 text-center">
          <picture>
            <source srcset="assets/images/img/homty-success.webp" type="image/webp" />
            <source srcset="assets/images/img/homty-success.png" type="image/png" />
            <img src="assets/images/img/homty-success.webp" alt="" style="width: 100%">
          </picture>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="filter pt-20 pb-20 " *ngIf="!loading">
  <!-- d-none d-xl-block d-lg-block -->
  <div class="container">
    <h3>Filtros</h3>
    <form>
      <div class="row animate__animated animate__fadeIn">

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" *ngIf="filteredCities">
          <label for="municipio" class="form-label required">Municipio</label>
          <input class="form-select shadow-none" list="cities" name="municipio" id="municipio" formControlName="municipio" [formControl]="municipioAutocompleteControl" [(ngModel)]="selectedMunicipio">
          <datalist id="cities">
            <option *ngFor="let city of filteredCities | async" [value]="city">{{ city }}</option>
          </datalist>
          <div *ngFor="let validation of validation_msgs.municipioAutocompleteControl">
            <div *ngIf="municipioAutocompleteControl.hasError(validation.type)" class="mt-2 alert alert-danger">
              {{ validation.message }}
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="barrio" class="form-label">Barrio</label>
          <input type="text" class="form-control" id="barrio" name="barrio" [(ngModel)]="selectedBarrio">
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="propertyType" class="form-label required">Tipo de inmueble</label>
          <select class="form-select" [(ngModel)]="selectedPropertyType" name="propertyType">
            <option *ngFor="let propertyType of propertyTypes" [value]="propertyType.Inmueble">
              {{propertyType.Inmueble}}
            </option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="operacion" class="form-label required">Operación</label>
          <select class="form-select" [(ngModel)]="selectedOperation" name="operacion" (change)="updatePrices($event)">
            <option value="venta">Venta</option>
            <option value="arriendo">Arriendo</option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider">
          <label for="operacion" class="form-label required">Precio</label>          
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Min.</span>
                  <input type="text" class="form-control" id="precioMin" name="precioMin" [formControl]="precioMin" [inputMask]="currencyInputMask">
                  <div *ngIf="precioMin.errors && (precioMin.dirty || precioMin.touched)" class="mt-2 alert alert-danger">
                    <div *ngFor="let validation of validation_msgs.precioMin">
                      <div *ngIf="precioMin.hasError(validation.type)">
                        {{validation.message}}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Max.</span>
                  <input type="text" class="form-control" id="precioMax" name="precioMax" [formControl]="precioMax" [inputMask]="currencyInputMask">
                </div>
              </td>
            </tr>
          </table>
        </div>

      </div>

      <div class="row animate__animated animate__fadeIn" [hidden]="!showAllFilters" >

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" *ngIf="selectedPropertyType !== 'Lote' && selectedPropertyType !== 'Bodega' && selectedPropertyType !== 'Consultorio' && selectedPropertyType !== 'Local' && selectedPropertyType !== 'Oficina'">
          <label for="habitaciones" class="form-label">Habitaciones</label>
          <div class="form-group">
            <input type="number" class="form-control" id="habitaciones" name="habitaciones" [(ngModel)]="selectedHabitaciones"
              placeholder="Número de habitaciones">
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" *ngIf="selectedPropertyType !== 'Lote'">
          <label for="banos" class="form-label">Baños</label>
          <div class="form-group">
            <input type="number" class="form-control" id="banos" name="banos" [(ngModel)]="selectedBanos" placeholder="Número de baños">
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" *ngIf="selectedPropertyType !== 'Lote'">
          <label for="parqueadero" class="form-label">Parqueadero</label>
          <div class="form-group">
            <input type="number" class="form-control" id="parqueadero" name="parqueadero" [(ngModel)]="selectedParqueadero" placeholder="Número de parqueaderos">
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" *ngIf="selectedPropertyType !== 'Lote'">
          <label for="piso" class="form-label">Piso</label>
          <div class="form-group">
            <input type="number" class="form-control" id="piso" name="piso" [(ngModel)]="selectedPiso" placeholder="Número de piso">
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider">
          <label for="operacion" class="form-label">
            Gastos de administración
            <mat-icon class="help-icon" matTooltip="Los gastos de administración de un inmueble son los costos que se incurren en la gestión , mantenimiento y conservación de dicho inmueble. Estos gastos pueden incluir el pago de empleados del equipo de administración, como gerentes y conserjes, así como también los costos asociados con la limpieza, mantenimiento, reparación y mejora de las instalaciones del inmueble." matTooltipClass="my-tooltip">help_outline</mat-icon>
          </label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Min.</span>
                  <input type="text" class="form-control" id="valorAdmin" name="valorAdmin" [formControl]="valorAdmin" [inputMask]="currencyInputMask">
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Max.</span>
                  <input type="text" class="form-control" id="valorAdminMax" name="valorAdminMax" [formControl]="valorAdminMax" [inputMask]="currencyInputMask">
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      
      <div class="row animate__animated animate__fadeIn">

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" [hidden]="!showAllFilters">
          <label for="origin" class="form-label required">
            Origen
            <mat-icon class="help-icon" matTooltip="Selecciona el origen o la fuente de donde se obtuvo la información sobre el inmueble" matTooltipClass="my-tooltip">help_outline</mat-icon>
          </label>
          <select class="form-select" id="origin" name="origin" [(ngModel)]="selectedOrigin">
            <option *ngFor="let origin of origins" [value]="origin.Origen">
              {{origin.Origen}}
            </option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider" [hidden]="!showAllFilters">
          <label for="operacion" class="form-label">Área (m²)</label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Min.</span>
                  <input type="number" class="form-control" id="mtmin" name="mtmin" aria-label="Area min (m²)" [(ngModel)]="mtmin">
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Max.</span>
                  <input type="number" class="form-control" id="mtmax" name="mtmax" aria-label="Area max (m²)" [(ngModel)]="mtmax">
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" [hidden]="!showAllFilters" *ngIf="selectedPropertyType !== 'Lote'">
          <label for="antiguedad" class="form-label">Antigüedad</label>
          <div class="form-group">
            <select class="form-select" id="antiguedad" name="antiguedad" [(ngModel)]="selectedAntiguedad">
              <option value="--">--</option>
              <option value="sin especificar">Sin especificar</option>
              <option value="menor a 1 año">Menor a 1 año</option>
              <option value="1 a 8 años">1 a 8 años</option>
              <option value="9 a 15 años">9 a 15 años</option>
              <option value="16 a 30 años">16 a 30 años</option>
              <option value="más de 30 años">Más de 30 años</option>
            </select>
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-3 custom-slider" [hidden]="!showAllFilters">
          <label for="operacion" class="form-label">Estrato</label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Min.</span>
                  <input type="number" class="form-control" id="estrato" name="estrato" [(ngModel)]="selectedEstrato">
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Max.</span>
                  <input type="number" class="form-control" id="estratoMax" name="estratoMax" [(ngModel)]="selectedEstratoMax">
                </div>
              </td>
            </tr>
          </table>
        </div>

      </div>
      <div class="row animate__animated animate__fadeIn">
        <div class="col-md-12 col-sm-12 col-lg-7"></div>
        <div class="col-md-12 col-sm-12 col-lg-2 mt-25  d-flex flex-column justify-content-center align-items-end">
          <h6 class="pointer" (click)="showAllFilters = !showAllFilters"> {{!showAllFilters ? 'Ver más filtros' : 'Ver menos filtros' }}</h6>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-3 d-flex flex-column justify-content-center align-items-center">
          <input class="submit-btn align-bottom" type="submit" value="Comenzar Búsqueda" (click)="applyFilter()" />
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Mobile Menu Modal -->
<div class="modal animate__animated animate__slideInLeft" id="mobileMenuModal" tabindex="-1"
  aria-labelledby="mobileMenuModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen-lg-down">
    <div class="modal-content orange-modal">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div class="col-md-12 col-sm-12 col-lg-2">
          <mat-form-field appearance="fill">
            <mat-label>Tipo de inmueble</mat-label>
            <mat-select [(value)]="selectedPropertyType">
              <mat-option *ngFor="let propertyType of propertyTypes" [value]="propertyType.Inmueble">
                {{propertyType.Inmueble}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Operación</mat-label>
            <mat-select [(value)]="selectedOperation">
              <mat-option value="venta">
                Venta
              </mat-option>
              <mat-option value="arriendo">
                Arriendo
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-2" *ngIf="filteredCities">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Municipio</mat-label>
            <input type="text" placeholder="Selecciona uno" aria-label="Municipio" matInput
              [formControl]="municipioAutocompleteControl" [matAutocomplete]="auto" [(ngModel)]="selectedMunicipio"
              (click)="selectedMunicipio=''">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let city of filteredCities | async" [value]="city">
                {{city}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngFor="let validation of validation_msgs.municipioAutocompleteControl">
              <div *ngIf="municipioAutocompleteControl.hasError(validation.type)">
                {{validation.message}}
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Barrio {{selectedBarrio}}</mat-label>
            <input type="text" aria-label="Barrio" matInput [(ngModel)]="selectedBarrio" name="barrio">
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-2 mb-20">
          <mat-accordion class="example-headers-align">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Precio
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <div class="col pt-15">
                  <mat-form-field appearance="fill">
                    <mat-label>Precio desde (COP)</mat-label>
                    <input matInput [inputMask]="currencyInputMask" [formControl]="precioMin" class="text-left" />
                    <mat-error *ngFor="let validation of validation_msgs.precioMin">
                      <div *ngIf="precioMin.hasError(validation.type)">
                        {{validation.message}}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="fill">
                    <mat-label>Precio hasta (COP)</mat-label>
                    <input matInput [inputMask]="currencyInputMask" [formControl]="precioMax" class="text-left" />
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Habitaciones</mat-label>
            <input type="number" aria-label="Habitaciones" matInput [(ngModel)]="selectedHabitaciones"
              name="habitaciones">
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Baños</mat-label>
            <input type="number" aria-label="Baños" matInput [(ngModel)]="selectedBanos" name="banos">
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-2 mb-20">
          <mat-accordion class="example-headers-align">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Área
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <div class="col pt-15">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Area min (m²)</mat-label>
                    <input type="number" aria-label="Area min (m²)" matInput [(ngModel)]="mtmin" name="mtmin">
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Area max (m²)</mat-label>
                    <input type="number" aria-label="Area max (m²)" matInput [(ngModel)]="mtmax" name="mtmax">
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Parqueadero</mat-label>
            <input type="number" aria-label="Parqueadero" matInput [(ngModel)]="selectedParqueadero" name="parqueadero">
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Estrato</mat-label>
            <input type="number" aria-label="Estrato" matInput [(ngModel)]="selectedEstrato" name="estrato">
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Valor Admin</mat-label>
            <input matInput [inputMask]="currencyInputMask" [formControl]="valorAdmin" class="text-left" />
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-2">
          <label for="operacion" class="form-label">Antiguedad</label>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-select [(value)]="selectedAntiguedad">
              <mat-option value="">

              </mat-option>
              <mat-option value="sin especificar">
                Sin especificar
              </mat-option>
              <mat-option value="menor a 1 año">
                Menor a 1 año
              </mat-option>
              <mat-option value="1 a 8 años">
                1 a 8 años
              </mat-option>
              <mat-option value="9 a 15 años">
                9 a 15 años
              </mat-option>
              <mat-option value="16 a 30 años">
                16 a 30 años
              </mat-option>
              <mat-option value="más de 30 años">
                Más de 30 años
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1 pd-5">
          <input class="submit-btn" type="submit" value="Aplicar filtros" (click)="applyFilter()"
            data-bs-dismiss="modal" aria-label="Close" />
        </div>

      </div>

    </div>
  </div>
</div>