import { Component } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { Router, NavigationEnd } from '@angular/router'; 

declare let ga: Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title:string = '';

  minValue: number = 0;
  maxValue: number = 5000000;
  options: Options = {
    floor: 0,
    ceil: 5000000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Precio min:</b> $' + value;
        case LabelType.High:
          return '<b>Precio máx:</b> $' + value;
        default:
          return '$' + value;
      }
    }
  };

  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
        fbq('track', 'PageView');
      }
    });
  }
}
