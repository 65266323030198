<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>
<div class="container" *ngIf="!loading">
    <div *ngIf="property && property.Imagenes.length > 0 && property.Imagenes[0] != ''" id="carouselExampleIndicators"
        class="carousel carousel-dark slide mt-30" data-bs-ride="carousel">
        <div class="carousel-inner">

            <div class="carousel-item" [ngClass]="{'active': i==0}"
                *ngFor="let imagen of property.Imagenes; let i = index">
                <img [src]="imagen" class="d-block w-100 img-fluid" alt="...">
            </div>


        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <div class="row mt-20">
        <div class="col-md-8">
            <h1>{{property?.Inmueble}} {{property?.Barrio}}</h1>
        </div>
    </div>
    <div class="row">
        <p class="codigo">{{property?.id}}</p>
    </div>

    <div class="row">
        <h3>Barrio: {{property?.Barrio}}</h3>
        <p>{{property?.Descripcion}}</p>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-7 shadow">
                <div class="row fondo-naranja pt-15 pl-10 pr-10 pb-15">
                    <div class="col">
                        <p class="titulo-precio">{{property?.Precio}}</p>
                        <p class="subtitulo-precio">Precio de venta / Alquiler</p>
                    </div>
                    <div class="col">
                        <p class="titulo-precio-admin">{{property?.Valor_Admin}}</p>
                        <p class="subtitulo-precio text-right">Gastos de administración</p>
                    </div>
                </div>

                <div class="row fondo-gris pt-15 pl-10 pr-10 pb-15">
                    <div class="row">
                        <div class="col-md-4">
                            <h6>Administración</h6>
                            <p class="valores">{{property?.Valor_Admin}}</p>
                        </div>
                        <div class="col-md-4">
                            <h6>Área</h6>
                            <p class="valores">{{property?.Tamano}}</p>
                        </div>
                        <div class="col-md-4">
                            <h6>Piso</h6>
                            <p class="valores">{{property?.Piso}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <h6>Parqueadero</h6>
                            <p class="valores">{{property?.Parking}}</p>
                        </div>
                        <div class="col-md-4">
                            <h6>Habitaciones</h6>
                            <p class="valores">{{property?.Habitaciones}}</p>
                        </div>
                        <div class="col-md-4">
                            <h6>Baños</h6>
                            <p class="valores">{{property?.Banos}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <h6>Antigüedad</h6>
                            <p class="valores">{{property?.Antiguedad}}</p>
                        </div>
                        <div class="col-md-4">
                            <h6>Estrato</h6>
                            <p class="valores">{{property?.Estrato}}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-5">
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                        (dragEnd)="markerDragEnd($event)"></agm-marker>
                </agm-map>
            </div>
        </div>
    </div>

    <div class="container mt-50 mb-100">
        <div class="row fondo-naranja pt-10 pl-10">
            <p class="titulo-precio">Datos de contacto</p>
        </div>
        <div class="row fondo-gris shadow pt-15 pl-10 pr-10 pb-15">
            <h4>Agencia / Vendedor</h4>
            <p class="valores-contacto">{{property?.Vendedor_Agencia}}</p>

            <h4>Whatsapp</h4>
            <a class="valores-contacto" [href]="'https://wa.link/xtvmyw' + '?text=Hola, vi tu inmueble en homty.co con el código ' + property?.id +
                    ' en la fuente Homty y estoy interesado. https://www.homty.com/propiedad?' + property?.id"
                target="_blank">
                <img src="./assets/images/icons/whatsapp.png" alt="">
                {{property?.Whatsapp}}
            </a>
        </div>
    </div>
</div>