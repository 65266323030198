import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cercania } from 'src/app/models/cercania.model';

@Component({
  selector: 'app-cercanias-acm',
  templateUrl: './cercanias-acm.component.html',
  styleUrls: ['./cercanias-acm.component.css']
})
export class CercaniasAcmComponent {

  @Input() dataCercanias?: Cercania[];
  @Output() updateCercanias = new EventEmitter<Cercania[]>();

  constructor() { }

  onCheckboxChange(index: number) {
    if (this.dataCercanias) {
      this.updateCercanias.emit(this.dataCercanias)
    }
  }

}
