import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Auth } from "aws-amplify";
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { PaymentService } from 'src/app/services/payment.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-owners',
  templateUrl: './contact-owners.component.html',
  styleUrls: ['./contact-owners.component.css']
})
export class ContactOwnersComponent implements OnInit {

  user: CognitoUserInterface | undefined;
  params: any;
  results: number = 0;
  loading: boolean = false;
  continueProcess: boolean = false

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private paymentService: PaymentService, public authenticator: AuthenticatorService) { }

  ngOnInit(): void {

    Auth.currentUserInfo().then(user => {
      this.user = user;
    });

    this.activatedRoute.queryParams.subscribe(params => {

      this.params = params;

      if (params.continueProcess) {
        this.loading = true;
        this.continueProcess = params.continueProcess;
        this.verifySessionState();
      }
    })
  }

  verifySessionState() {
    setTimeout(() => {
      if (this.authenticator.route === 'authenticated') {
        this.goToConfirmationPage();
      } else {
        this.verifySessionState();
      }
    }, 1000);
  }

  goToConfirmationPage() {
    if (this.authenticator.route === 'authenticated' && this.user) {
      this.loading = true;
      let email = this.user["attributes"]["email"];
      let phone = this.user["attributes"]["phone_number"];
      this.createPayment(email, phone)
    } else {
      this.router.navigate(['/confirm-owners-contact'], { queryParams: this.params })
    }
  }

  createPayment(email: string, phone: string) {
    this.paymentService.createPaymentContactOwners(email, phone, '5').subscribe(resp => {
      let payvalidaUrl = 'https://' + resp.answer.DATA.checkout;
      this.params = { ...this.params, order: resp.answer.DATA.OrdenID, email: email, whatsapp: phone };
      this.router.navigate(['/payment-result-owners'], { queryParams: this.params }).then(result => {
        const screenX = window.screenX + (window.outerWidth - 800) / 2;
        const screenY = window.screenY + (window.outerHeight - 600) / 2;
        let newWindow = window.open('', '_blank', `height=600,width=800,top=${screenY},left=${screenX},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`);
        if (newWindow) {
          newWindow.location.href = payvalidaUrl;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error...',
            text: 'Ha ocurrido un error al abrir la ventana de pago. Activa las ventanas emergentes. Abrela manualmente dandole al botón inferior.',
            confirmButtonText: 'Abrir ventana de pago',
            confirmButtonColor: '#FF653F;'
          }).then((result) => {
            if(result.isConfirmed) {
              newWindow = window.open('', '_blank', `height=600,width=800,top=${screenY},left=${screenX},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`);
              if (newWindow) {
                newWindow.location.href = payvalidaUrl;
              } else {
                Swal.fire('No se pudo abrir la ventana de pago', '', 'error');
              }
            }
          })
        }
      })
      this.loading = false;
    }, (error: any) => {
      console.log(error)
      this.loading = false;
    });
  }
}
