import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { Order } from 'src/app/models/Order';
import { PaymentService } from 'src/app/services/payment.service';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import awsExports from '../../../../../src/aws-exports';
import { Amplify, Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.css']
})
export class PaymentResultComponent implements OnInit {
  faExclamationCircle = faExclamationCircle;
  order: Order | undefined;
  ordenId: string = ''
  ordenStatus: string = ''
  selectedPlan: string = '';
  checksum: string = ''
  authState: AuthState | undefined;
  user: CognitoUserInterface | undefined;
  email: string = '';
  dataACM: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private paymentService: PaymentService, private sharingService: SharingService, public authenticator: AuthenticatorService) {
    Amplify.configure(awsExports);
    //this.authenticator = authenticator;
    //this.email = authenticator.user.attributes!.email
   }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
        this.ordenId = params.order
        if(params.plan) {
          this.selectedPlan = params.plan
        }
    })   
    if (this.selectedPlan == '6') this.dataACM = this.sharingService.sharingValue;
    this.ordenStatus = 'pending';

    /*onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.email = this.user['attributes']['email'];
    })*/

    this.getPaymentState();

  }
  
  getEmail(email:string){
    this.email = email;
  }

  async getPaymentState() {
    if (this.ordenId != '') {
      const promise = this.paymentService.getPaymentState(this.ordenId, this.email).toPromise();
      promise.then( (resp) => {
        this.ordenStatus = resp.state;
        this.checksum = resp.checksum;

        this.getOrderInformation();
        if (this.ordenStatus == 'pending') {
          setTimeout(() => { this.getPaymentState(); }, 1000);
        }
   
        if(this.selectedPlan == '6' && this.ordenStatus == 'approved') {
          setTimeout(()=>{
            this.sharingService.sharingValue = this.dataACM;
            this.router.navigate(['/acp'], {queryParams: {plan: this.selectedPlan, payacm: '1'}});
          }, 2500);
        }
      })
    } else {
      this.ordenStatus = 'No se proporcionó el número de orden'
    }
  }

  getOrderInformation() {
    this.paymentService.getOrderInformation(this.ordenId, this.checksum).subscribe(resp => {
      this.order = resp
    })
  }

  goToHome() {
    this.router.navigate(['/']);
  }

}
