import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { CognitoUserInterface, AuthState } from "@aws-amplify/ui-components";
import { PaymentService } from "src/app/services/payment.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import Swal from "sweetalert2";
import { SharingService } from "src/app/services/sharing.service";
import { prices } from "../configPrices";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"],
})
export class CheckoutComponent implements OnInit {
  selectedPlan: string = "1";
  plans: any = [
    {
      plan: "Plan Bronce",
      periodicity: "Mensual",
      price: prices.suscriptions.mensual_Bronce,
    },
    {
      plan: "Plan Plata",
      periodicity: "Mensual",
      price: prices.suscriptions.mensual_Plata,
    },
    {
      plan: "Plan Oro",
      periodicity: "Mensual",
      price: prices.suscriptions.mensual_Oro,
    },
    {
      plan: "Plan Semestral",
      periodicity: "Semestral",
      price: prices.suscriptions.semestral,
    },
    {
      plan: "Plan Anual",
      periodicity: "Anual",
      price: prices.suscriptions.anual,
    },
    {
      plan: "Pago por Paquetes",
      periodicity: "60 Días",
      acms: 0,
      properties: 0,
      price: 0,
    },
    { plan: "Contactar propietarios", periodicity: "No Aplica", price: 15000 },
    {
      plan: "Pago de ACM individual",
      periodicity: "1 Vez",
      price: prices.packages.acms,
    },
    {
      plan: "Pago de Suscripción Agencia",
      periodicity: "Anual",
      numberUsers: 0,
      price: 0,
    },
  ];

  dataACM: any;
  payProcess: boolean = false;
  loading: boolean = false;
  vendor: string = "";
  authState: AuthState | undefined;
  user: CognitoUserInterface | undefined;
  email: string = "";
  faCartArrowDown = faCartArrowDown;
  responsiveOptions: any;
  products: String[] = [
    "1.jpeg",
    "2.jpeg",
    "3.jpeg",
    "4.jpeg",
    "5.jpeg",
    "6.jpeg",
    "7.jpeg",
    "8.jpeg",
    "9.jpeg",
    "10.jpeg",
    "11.jpeg",
    "12.jpeg",
    "13.jpeg",
    "14.jpeg",
    "15.jpeg",
    "16.jpeg",
    "17.jpeg",
    "18.jpeg",
    "19.jpeg",
    "20.jpeg",
  ];
  payvalidaUrl: string = "";
  constructor(
    private ref: ChangeDetectorRef,
    private rutaActiva: ActivatedRoute,
    private paymentService: PaymentService,
    private sharingService: SharingService,
    private router: Router,
    public authenticator: AuthenticatorService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: "1200px",
        numVisible: 9,
        numScroll: 3,
      },
      {
        breakpoint: "1024px",
        numVisible: 6,
        numScroll: 3,
      },
      {
        breakpoint: "768px",
        numVisible: 3,
        numScroll: 2,
      },
    ];
  }

  ngOnInit(): void {
    /*onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.email = this.user['attributes']['email'];
      this.ref.detectChanges();
      this.loading = false;
    })*/

    this.rutaActiva.queryParams.subscribe((params) => {
      if (params.plan) {
        this.selectedPlan = params.plan;
      }
      if (params.payProcess) {
        this.payProcess = params.payProcess;
      }
      if (params.acms) {
        this.plans[3].acms = params.acms;
      }
      if (params.properties) {
        this.plans[3].properties = params.properties;
      }
      if (params.numberUsers) {
        this.plans[6].numberUsers = params.numberUsers;
      }
      this.plans[3].price =
        this.plans[3].acms * prices.packages.acms +
        this.plans[3].properties * prices.packages.properties;
      this.plans[6].price =
        this.plans[6].numberUsers * prices.packages.agencyUsers;
    });
    switch (this.selectedPlan) {
      case "1":
        break;
      case "2":
        break;
      case "3":
        break;
      case "4":
        break;
      case "5":
        break;
      case "6":
        this.dataACM = this.sharingService.sharingValue;
        break;
      case "7":
        break;
      default:
        this.selectedPlan = "1";
    }
  }

  createPayment(email: string) {
    this.loading = true;
    this.paymentService
      .createPayment(
        email,
        this.selectedPlan,
        this.vendor,
        this.plans[3].acms,
        this.plans[3].properties,
        this.plans[6].numberUsers
      )
      .subscribe((resp) => {
        this.payvalidaUrl = "https://" + resp.answer.DATA.checkout;
        this.sharingService.sharingValue = this.dataACM;
        this.router
          .navigate(["/payment-result"], {
            queryParams: {
              order: resp.answer.DATA.OrdenID,
              plan: this.selectedPlan,
            },
          })
          .then((result) => {
            const screenX = window.screenX + (window.outerWidth - 800) / 2;
            const screenY = window.screenY + (window.outerHeight - 600) / 2;
            let newWindow = window.open(
              "",
              "_blank",
              `height=600,width=800,top=${screenY},left=${screenX},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`
            );
            if (newWindow) {
              newWindow.location.href = this.payvalidaUrl;
            } else {
              Swal.fire({
                icon: "error",
                title: "Error...",
                text: "Ha ocurrido un error al abrir la ventana de pago. Activa las ventanas emergentes. Abrela manualmente dandole al botón inferior.",
                confirmButtonText: "Abrir ventana de pago",
                confirmButtonColor: "#fc6641",
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  newWindow = window.open(
                    "",
                    "_blank",
                    `height=600,width=800,top=${screenY},left=${screenX},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`
                  );
                  if (newWindow) {
                    newWindow.location.href = this.payvalidaUrl;
                  } else {
                    Swal.fire(
                      "No se pudo abrir la ventana de pago",
                      "",
                      "error"
                    );
                  }
                }
              });
            }
          });
        this.loading = false;
      });
  }
}
