import { Component, OnInit } from '@angular/core';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth } from "aws-amplify";
import { Router } from '@angular/router';

@Component({
  selector: 'app-homty360',
  templateUrl: './homty360.component.html',
  styleUrls: ['./homty360.component.css']
})
export class Homty360Component implements OnInit {
  user: CognitoUserInterface | undefined;
  url: string | undefined;
  constructor(private router: Router) { }

  ngOnInit(): void {

    Auth.currentUserInfo().then(user => {
      this.user = user
      this.getUrl()

    });

  }

  getUrl() {
    if (this.user) {
      if (this.user["attributes"]["nickname"] == 'Agente inmobiliario') {
        this.url = 'https://app.powerbi.com/view?r=eyJrIjoiNDUwNGY4MTUtNjUzMi00NzE4LWFjMTEtMjk3ODQzN2UyNGJmIiwidCI6IjI2OTdkNDViLTZjYjAtNDZmNC1iNGIzLWUyNDIzMmMxYTYyMSJ9&pageName=ReportSection8ce2c861a91be01aa337&utm_source=Homty+Agente&utm_medium=Homty+Agente&utm_campaign=Homty+Agente';
      } else {
        this.url = 'https://app.powerbi.com/view?r=eyJrIjoiN2RlMTY3YTEtN2QxNy00ZmQ4LWJiNjEtYTNlNTgzNGM2NzRlIiwidCI6IjI2OTdkNDViLTZjYjAtNDZmNC1iNGIzLWUyNDIzMmMxYTYyMSJ9&utm_source=Agente+Avaluador&utm_medium=Agente+Avaluador&utm_campaign=Agente+Avaluador'
      }

    }
  }

  goToSearch() {

    Auth.currentUserInfo().then(user => {
      // this.router.navigate(['propiedades', 'Apartamento', 'venta', user["attributes"]["address"], '', '20000000', '70000000000']
      this.router.navigate(['/propiedades'],
        {
          queryParams: {
            inmueble: 'Apartamento',
            operacion: 'venta',
            municipio: user["attributes"]["address"],
            barrio: '',
            preciomin: '20000000',
            preciomax: '70000000000'
          }
        }
      );
    })
  }

}
