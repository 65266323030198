import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class Homty360Service {

  constructor(private http: HttpClient) { }

  getMarkerIcon(iconAwesome: string, color: string) {
  
    let baseUrl = `https://api.geoapify.com/v1/icon/`;
    
    let params = new HttpParams()
      .set('type', 'awesome')
      .set('size', 'x-large')
      .set('color', `%${color.substring(1)}`)
      .set('icon', iconAwesome)
      .set('apiKey', environment.geoapifyKey);

    const completeUrl = `${baseUrl}?${params.toString()}`
    console.log(completeUrl);
    return completeUrl;
  }

  getCercanias(tipo: string, latitud: number, longitud: number) {
    let url = `${base_url}/find_place_details`
    
    let params = new HttpParams()
      .set('tipo', tipo)
      .set('latitud', latitud.toString())
      .set('longitud', longitud.toString())

    return this.http.get<any>(url, {params: params} ).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
