
export class Estate {

    constructor(
        public Antiguedad: string,
        public Banos: string,
        public Barrio: string,
        public Colegaje: string,
        public Descripcion: string,
        public Duplicado: string,
        public Estrato: string,
        public Favorito: string,
        public Fecha_adicion: string,
        public Fecha_creacion: string,
        public Fecha_modificacion: string,
        public Habitaciones: string,
        public Inmueble: string,
        public Latitud: string,
        public Longitud: string,
        public Municipio: string,
        public Operacion: string,
        public Origen: string,
        public Origenes: any,
        public Parking: string,
        public Piso: string,
        public Precio: string,
        public Sector: string,
        public Tamano: string,
        public Telefono: string,
        public Valor_Admin: string,
        public Vendedor_Agencia: string,
        public Whatsapp: string,
        public id: string,
        public Verificado: string,
        public Fecha_verificacion: string,
        public Imagenes: string[]

    ) {}
}

