import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { PaymentService } from 'src/app/services/payment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Auth from '@aws-amplify/auth';
import { AdditionalsService } from 'src/app/services/additionals.service';
import Swal from 'sweetalert2';

function validatePhone(control: FormControl) {
  const phoneRegex = /^\d{10}$/;
  return !phoneRegex.test(control.value) ? { 'phoneValidator': { value: control.value } } : null;
}

@Component({
  selector: 'app-confirm-owners-contact',
  templateUrl: './confirm-owners-contact.component.html',
  styleUrls: ['./confirm-owners-contact.component.css']
})
export class ConfirmOwnersContactComponent implements OnInit {

  authState: AuthState | undefined;
  user: CognitoUserInterface | undefined;

  options = ['Con tu usuario', 'Como invitado'];
  selectedOption: string = 'Con tu usuario';

  form = new FormGroup({
    email: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required, validatePhone])
  });

  email: FormControl = new FormControl('', Validators.required);
  phone: FormControl = new FormControl('', [Validators.required, validatePhone]);

  loading: boolean = false;

  constructor(private additionalsService: AdditionalsService, private paymentService: PaymentService, private router: Router, private activatedRoute: ActivatedRoute, public authenticator: AuthenticatorService) { }

  params: any

  ngOnInit(): void {
    Auth.currentUserInfo().then(user => {
      this.user = user;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });

  }

  onSubmit() {
    let userOrGuest = this.selectedOption === 'Con tu usuario' ? 'User' : 'Guest';
    if (userOrGuest === 'User') {
      this.params = { ...this.params, plan: '5' };
      this.router.navigate(['login', 'signIn'], { queryParams: this.params });
    } else {
      this.loading = true
      let email = this.form.controls.email.value;
      let phone = this.form.controls.phone.value;
      this.createPayment(email, phone);
    }
  }

  createPayment(email: string, phone: string) {
    this.paymentService.createPaymentContactOwners(email, phone, '5', true).subscribe(resp => {
      let payvalidaUrl = 'https://' + resp.answer.DATA.checkout;
      this.params = { ...this.params, order: resp.answer.DATA.OrdenID, guest: true, email: email, whatsapp: phone };
      this.router.navigate(['/payment-result-owners'], { queryParams: this.params }).then(result => {
        const screenX = window.screenX + (window.outerWidth - 800) / 2;
        const screenY = window.screenY + (window.outerHeight - 600) / 2;
        let newWindow = window.open('', '_blank', `height=600,width=800,top=${screenY},left=${screenX},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`);
        if (newWindow) {
          newWindow.location.href = payvalidaUrl;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error...',
            text: 'Ha ocurrido un error al abrir la ventana de pago. Activa las ventanas emergentes. Abrela manualmente dandole al botón inferior.',
            confirmButtonText: 'Abrir ventana de pago',
            confirmButtonColor: '#3085d6'
          }).then((result) => {
            if(result.isConfirmed) {
              newWindow = window.open('', '_blank', `height=600,width=800,top=${screenY},left=${screenX},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`);
              if (newWindow) {
                newWindow.location.href = payvalidaUrl;
              } else {
                Swal.fire('No se pudo abrir la ventana de pago', '', 'error');
              }
            }
          })
        }
      })
      this.loading = false;
    }, (error: any) => {
      console.log(error)
      this.loading = false;
    });
  }

}
