<section class="pricing py-5">
    <div class="container">
        <div class="row">
            <h1>
                Hola! empieza hoy a optimizar tu tiempo
            </h1>
            <p>
                Mejora hoy los tiempos de captación de propiedades con Homty
            </p>
        </div>
        <div class="row">
            <!-- Pago por paquetes -->
            <div class="col-12 col-md-6 col-lg-6">
                <div class="card mb-5 mb-lg-0">
                    <div class="card-header">
                        <div class="mt-20 mb-20">
                            <h3 class="text-uppercase text-center mt-20">Pagos por <br> Paquetes</h3>
                            <!-- <h2 class="h2-custom text-center">$0</h2> -->
                            <!-- <p class="card-desc text-center">¡Regístrate ya!</p> -->
                        </div>

                    </div>
                    <div class="card-body">
                        <ul class="fa-ul">
                            <p class="card-desc mt-2">
                                <li>
                                    <fa-icon [icon]="faCheck" class="mr-5"></fa-icon>Paga por el número de propiedades a publicar por dos meses
                                </li>
                                <li>
                                    <fa-icon [icon]="faCheck" class="mr-5"></fa-icon>Paga los ACMs que vas a calcular de forma individual
                                </li>

                            </p>
                        </ul>

                    </div>
                    <div class="card-footer">
                        <div class="d-grid">
                            <a href="#" class="btn btn-primary text-uppercase"
                            routerLink="/pay-package">¡Comprar ya!</a>
                        </div>
                        <!-- <p class="card-desc text-center mt-10">
                            Prueba gratuita de cero riesgo
                        </p> -->
                    </div>

                </div>
            </div>
            <!-- Pago por suscripción -->
            <div class="col-12 col-md-6 col-lg-6">
                <div class="card mb-5 mb-lg-0">
                    <div class="card-header bg-color-pro">
                        <div class="mt-20 mb-20">
                            <h3 class="pro text-uppercase text-center">Pagos por <br> Suscripción</h3>
                            <!-- <h2 class="h2-custom text-center pro">$35mil <small class="underlined-price"><s>$99mil</s></small></h2> -->
                            <!-- <p class="card-desc text-center" style="color: #fff">Mensual</p> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="fa-ul">
                            <p class="card-desc mt-2">
                                <li>
                                    <fa-icon [icon]="faCheck" class="mr-5"></fa-icon>Cálculo de ACMs disponibles según la suscripción
                                </li>
                                <li>
                                    <fa-icon [icon]="faCheck" class="mr-5"></fa-icon>Publicación de propiedades ilimitadas
                                </li>
                            </p>

                        </ul>

                    </div>
                    <div class="card-footer">
                        <div class="d-grid">
                            <a href="#" routerLink="/pay-subscription" class="btn btn-primary text-uppercase">¡Comprar ya!</a>
                            <!-- <a href="#" routerLink="/checkout" class="btn btn-primary text-uppercase">¡Comprar ya!</a> -->
                        </div>
                        <!-- <p class="card-desc text-center mt-10">
                            Puede cancelar  en cualquier momento
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start of HubSpot Embed Code -->
<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8998298.js"></script>
<!-- End of HubSpot Embed Code -->