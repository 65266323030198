<div *ngIf="!loading" class="container mt-30 mb-50">

  <div class="d-flex flex-row align-items-center justify-content-start">
    <h1>ACM - Análisis Comparativo de Mercado</h1>
    <!-- (click)="goToAvaluo()" -->
    <button class="acm-btn" type="button" routerLink="/blog/ACM-Inmobiliario">¿Qué es un ACM?</button>
  </div>
  <div>
    <h5 *ngIf='user && user["attributes"]["custom:active_plan"] == 4 && packagePlanInfo' style="color: black;">Te
      quedan {{packagePlanInfo.acms}} ACMs disponibles</h5>
      
  </div>

  <div class="mb-4" *ngIf="showAvailableAcmsLoading">
    <i class="fas fa-spinner fa-spin" style="font-size: 1.3rem;"></i> &nbsp; Cargando...
  </div>

  <div class="mb-4" *ngIf="showAvailableAcmsLoading != undefined && !showAvailableAcmsLoading && !oldUser">
    <span class="subtitle"> <strong>ACMs disponibles: {{availableAcms}} </strong> </span>
  </div>

  <p>Ingresa la información del inmueble que quieres comparar.</p>

  <form [formGroup]="propertyForm" (ngSubmit)="onSubmit()">

    <h5>Ubicación del Inmueble</h5>
    <div class="mb-3 col-md-12">
      <label for="ciudad" class="form-label required">Municipio</label>
      <input class="form-control shadow-none homty-color" list="cities" name="municipio" id="municipio"
        formControlName="municipio">
      <datalist id="cities">
        <option *ngFor="let city of cities" [value]="city">
      </datalist>
      <div
        *ngIf="propertyForm.controls['municipio']?.errors?.required  && (propertyForm.controls['municipio'].dirty || propertyForm.controls['municipio'].touched)"
        class="mt-2 alert alert-danger">
        El municipio es obligatorio
      </div>
    </div>

    <div class="mb-3 col-md-12 mt-20">
      <div class="form-group">
        <label>
          Dirección
          <mat-icon class="help-icon" matTooltip="Formato esperado: Calle/Carretera/Vía [nombre de la vía] [número] [opcional: - piso/depto], [ciudad], [provincia/estado], [país]. Ejemplo: Carrera 50 #43-19, Medellín, Antioquia, Colombia" matTooltipClass="my-tooltip">help_outline</mat-icon>
        </label>
        <input type="text" class="form-control shadow-none" (keydown.enter)="$event.preventDefault()"
          placeholder="Encuentra la ubicación" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
          #search placeholder="Ejemplo: Carrera 50 #43-19, Medellín, Antioquia, Colombia">
      </div>

      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (mapReady)="mapClicked($event)">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [iconUrl]="iconHomtyUrl" [markerDraggable]="true"
          (dragEnd)="markerDragEnd($event)"></agm-marker>
      </agm-map>

    </div>

    <h5 class="mt-30">Características del inmueble</h5>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label for="inmueble" class="form-label required">Tipo de inmueble</label>
        <select class="form-select shadow-none homty-color" name="inmueble" formControlName="inmueble">
          <option value="Apartamento">Apartamento</option>
          <option value="Apartaestudio">Apartaestudio</option>
          <option value="Bodega">Bodega</option>
          <option value="Casa">Casa</option>
          <option value="Local">Local</option>
          <option value="Oficina">Oficina</option>
        </select>
        <div
          *ngIf="propertyForm.controls['inmueble']?.errors?.required  && (propertyForm.controls['inmueble'].dirty || propertyForm.controls['inmueble'].touched)"
          class="mt-2 alert alert-danger">
          El tipo de inmueble es obligatorio
        </div>
      </div>
      <div class="mb-3 col-md-6">
        <label for="estrato" class="form-label required">
          Estrato
          <mat-icon class="help-icon" matTooltip="Es importante conocer el estrato de la zona para obtener resultados exitosos en la comparación" matTooltipClass="my-tooltip">help_outline</mat-icon>
        </label>
        <input type="number" class="form-control shadow-none homty-color" id="estrato" name="estrato"
          formControlName="estrato">
        <div
          *ngIf="propertyForm.controls['estrato']?.errors?.required  && (propertyForm.controls['estrato'].dirty || propertyForm.controls['estrato'].touched)"
          class="mt-2 alert alert-danger">
          El estrato es obligatorio
        </div>
      </div>
    </div>

    <div class="row mt-20">
      <div class="mb-3 col-md-6">
        <label for="area_construida" class="form-label required">Área en m2</label>
        <input type="number" class="form-control shadow-none homty-color" id="area_construida" name="tamano"
          formControlName="tamano" placeholder="Ingrese el número">
        <div
          *ngIf="propertyForm.controls['tamano']?.errors?.required  && (propertyForm.controls['tamano'].dirty || propertyForm.controls['tamano'].touched)"
          class="mt-2 alert alert-danger">
          El tamaño es obligatorio
        </div>
      </div>
      <div class="mb-3 col-md-6">
        <label for="antiguedad" class="form-label required">Antigüedad</label>
        <select class="form-select shadow-none homty-color" name="antiguedad" formControlName="antiguedad">
          <option value="menor a 1 año">Menor a 1 año</option>
          <option value="1 a 8 años">1 a 8 años</option>
          <option value="9 a 15 años">9 a 15 años</option>
          <option value="16 a 30 años">16 a 30 años</option>
          <option value="más de 30 años">más de 30 años</option>
        </select>
        <div
          *ngIf="propertyForm.controls['antiguedad']?.errors?.required  && (propertyForm.controls['antiguedad'].dirty || propertyForm.controls['antiguedad'].touched)"
          class="mt-2 alert alert-danger">
          La antigüedad es obligatoria
        </div>
      </div>
    </div>

    <div class="row mt-20">
      <div class="mb-3 col-md-4">
        <label for="habitaciones" class="form-label required">Habitaciones</label>
        <input type="number" class="form-control shadow-none homty-color" id="habitaciones" name="habitaciones"
          formControlName="habitaciones" placeholder="Número de habitaciones">
        <div
          *ngIf="propertyForm.controls['habitaciones']?.errors?.required  && (propertyForm.controls['habitaciones'].dirty || propertyForm.controls['habitaciones'].touched)"
          class="mt-2 alert alert-danger">
          El número de habitaciones es obligatorio
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="banos" class="form-label required">Baños</label>
        <input type="number" class="form-control shadow-none homty-color" id="banos" name="banos"
          formControlName="banos" placeholder="Número de baños">
        <div
          *ngIf="propertyForm.controls['banos']?.errors?.required  && (propertyForm.controls['banos'].dirty || propertyForm.controls['banos'].touched)"
          class="mt-2 alert alert-danger">
          El número de baños es obligatorio
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="parqueadero" class="form-label required">Parqueadero</label>
        <input type="number" class="form-control shadow-none homty-color" id="parqueadero" name="parqueadero"
          formControlName="parqueadero" placeholder="Número de parqueaderos">
        <div
          *ngIf="propertyForm.controls['parqueadero']?.errors?.required  && (propertyForm.controls['parqueadero'].dirty || propertyForm.controls['parqueadero'].touched)"
          class="mt-2 alert alert-danger">
          El número de parqueaderos es obligatorio
        </div>
      </div>
    </div>

    <button type="submit" class="btn btn-submit shadow-none mt-30">Generar ACM</button>

  </form>
</div>