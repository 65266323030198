<div class="col">
  <div class="card card-container">
    <div class="content-card">
      <div class="container-img">
        <img
          src="{{
            property
              ? property.Imagenes[0]
              : './assets/images/placeholder-image2.jpg'
          }}"
          class="card-img-top"
          alt="Imagen no disponible"
          (error)="onImageError($event)"
          (click)="changeSelectedProperty()"
        />

        <span class="favorite-badge">
          <img
            src="./assets/images/heart-solid.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat"
            *ngIf="property?.Favorito == '1'"
            (click)="updateFavorite('0', property ? property.id : '')"
          />
          <img
            src="./assets/images/heart-regular.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat"
            *ngIf="property?.Favorito == '0'"
            (click)="updateFavorite('1', property ? property.id : '')"
          />
        </span>
      </div>

      <div class="card-body">
        <div class="d-flex flex-row">
          <div class="col-md-10" (click)="changeSelectedProperty()">
            <!-- data-bs-toggle="modal" data-bs-target="#detallesModal" -->
            <h5 class="card-title">
              {{ property?.Precio | currency : "USD" : "symbol" : "1.0-0" }}
              &nbsp;<small>COP</small>
            </h5>
            <h6 class="card-subtitle text-muted mb-2">
              {{ property?.Barrio | titlecase }} - {{ property?.Municipio }}
            </h6>

            <a type="button" (click)="openHomty360($event)" class="btn360">
              <span style="font-size: 1rem"
                >Ver análisis <i class="icon360 fas fa-chevron-right"></i
              ></span>
            </a>

            <div class="flex-row mt-3">
              <button
                class="btn btn-sm verify-button"
                (click)="openModalVerifyProperty($event)"
              >
                <span class="button-text">Verificar</span>
                <i class="fas fa-check"></i>
              </button>
              <mat-icon
                class="help-icon ml-4"
                matTooltip="Ayudanos a verificar si la propiedad se encuentra disponible."
                matTooltipClass="my-tooltip"
                >help_outline</mat-icon
              >
            </div>
          </div>

          <div class="container-icons">
            <div matTooltip="Metros Cuadrados" class="icon-item">
              <span class="tag-number">
                &nbsp;<strong>{{ property?.Tamano | number }}m²</strong></span
              >
            </div>
            <div matTooltip="Habitaciones" class="icon-item">
              <i class="icon m-size fa-solid fa-bed"></i>
              <span class="tag-number">
                &nbsp;<strong>{{ property?.Habitaciones }}</strong></span
              >
            </div>
            <div matTooltip="Baños" class="icon-item">
              <i class="icon fa-solid fa-shower"></i>
              <span class="tag-number">
                &nbsp;<strong>{{ property?.Banos }}</strong></span
              >
            </div>
            <div matTooltip="Parqueaderos" class="icon-item">
              <i class="icon fa-solid fa-square-parking"></i>
              <span class="tag-number">
                &nbsp;<strong>{{ property?.Parking }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <small>Análisis de la propiedad</small>
      <br />
      <div class="col-12">    

      <div class="row">
            <div class="col-md-3">
                <p style="font-size: 11px; font-weight: bold;">{{ingreso | currency : "USD" : "symbol" : "1.0-0"}}  <br> Valor arriendo</p>
            </div>
          <div class="col-md-3">
            <p style="font-size: 11px; font-weight: bold;">{{valormetro | currency : "USD" : "symbol" : "1.0-0"}}  <br> Valor m²</p>
           </div>
          <div class="col-md-5">
            <p style="font-size: 11px; font-weight: bold; ">{{valor.toFixed(2)}}% <br> Rentabilidad</p>
          </div>
        </div>
          <!-- [href]="element.URL" *ngFor="let element of property?.Origenes" -->
          <!-- <img *ngIf="element.Origen == 'Homty'" src="./assets/images/fav.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat mr-10">
          <img *ngIf="element.Origen == 'MetroCuadrado'" src="./assets/images/icons/metrocuadrado.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat mr-10">
          <img *ngIf="element.Origen == 'Finca Raiz'" src="./assets/images/icons/fincaraiz.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat mr-10">
          <img *ngIf="element.Origen == 'CienCuadras'" src="./assets/images/icons/ciencuadras.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat mr-10">
          <img *ngIf="element.Origen == 'WebInmobiliaria'" src="./assets/images/icons/webinmobiliaria.png"
            class="favorite-icon-20 pointer show animate__animated animate__heartBeat mr-10">
          <img *ngIf="element.Origen == 'Wasi'" src="./assets/images/icons/wasi.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat mr-10">
          <img *ngIf="element.Origen == 'Informe Inmobiliario'" src="./assets/images/icons/informeinmobiliario.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'AraujoySegovia'" src="./assets/images/icons/araujoysegovia.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Alnago'" src="./assets/images/icons/alnago.png"
            class="favorite-icon pointer show animxate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Inurbanas'" src="./assets/images/icons/inurbanas.jpeg"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Financar'" src="./assets/images/icons/logo-financar.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'VozInmobiliaria'" src="./assets/images/icons/vozinmobiliaria.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Dinamica Inmobiliaria'" src="./assets/images/icons/dinamicainmobiliaria.jpeg"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Escobar Bonnett'" src="./assets/images/icons/escobarbonnett.jpeg"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Oscar Sandoval Varona'" src="./assets/images/icons/oscarsandoval.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'TodoPropiedades'" src="./assets/images/icons/todopropiedades.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Franquicia Inmobiliaria'"
            src="./assets/images/icons/franquiciainmobiliaria.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Get Home'" src="./assets/images/icons/gethome.jpeg"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Ana Hernandez'" src="./assets/images/icons/anahernandez.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Aliados Inmobiliarios'" src="./assets/images/icons/aliadosinmobiliarios.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Ideal Homes'" src="./assets/images/icons/idealhomes.jpeg"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Go Habitat'" src="./assets/images/icons/gohabitat.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Su Propiedad'" src="./assets/images/icons/supropiedad.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Kota Group'" src="./assets/images/icons/kotagroup.jpeg"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Marlongi'" src="./assets/images/icons/marlongi.jpeg"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Gruges Inmobiliario'" src="./assets/images/icons/gruges.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Casa Invicta'" src="./assets/images/icons/casainvicta.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Home Inmobiliaria'" src="./assets/images/icons/homeinmobiliaria.jpg"
            class="favorite-icon pointer show animate__animated animate__heartBeat ">
          <img *ngIf="element.Origen == 'Home Capital'" src="./assets/images/icons/homecapital.svg"
            class="favorite-icon pointer show animate__animated animate__heartBeat "> -->
      
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  #modalVerifyProperty
  id="modalVerifyProperty"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Verificación de Propiedad</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          Ayudanos a verificar si esta propiedad aún se encuentra disponible.
          <br /><br />
          Si te contactaste para obtener información de la propiedad y diste con
          que la propiedad ya no se encuentra disponible, ayudanos a saberlo
          para mantener la información más actualizada en Homty.
        </p>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          type="button"
          class="btn btn-success"
          (click)="verifyProperty('si')"
        >
          Si está disponible
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="verifyProperty('no')"
        >
          No está disponible
        </button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>
