import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Renderer2,
} from "@angular/core";
import {
  onAuthUIStateChange,
  CognitoUserInterface,
  AuthState,
} from "@aws-amplify/ui-components";
import { CognitoIdToken } from "amazon-cognito-identity-js";
import { PhoneFormFieldType } from "@aws-amplify/ui-components";
import { I18n } from "@aws-amplify/core";
import { Translations } from "@aws-amplify/ui-components";
import { ActivatedRoute, Router } from "@angular/router";
import { HubCallback } from "@aws-amplify/core";
import Swal from "sweetalert2";
import { Amplify, Auth, Hub } from "aws-amplify";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import { Notification } from "src/app/models/notification.model";
import awsExports from "../../../../src/aws-exports";
import { WebsocketService } from "src/app/services/websocket.service";
import { ToastrService } from "ngx-toastr";
import { notificationConfig } from "../notifications/notificationConfig";
import { AgenciesService } from "src/app/services/agencies.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  formAgencyData = new FormGroup({
    representante: new FormControl("", Validators.required),
    direccion: new FormControl("", Validators.required),
  });

  //formFields: FormFieldTypes | undefined;
  formPhoneField: PhoneFormFieldType | undefined;
  title = "amplify-angular-auth";
  user: CognitoUserInterface | undefined;
  authState: AuthState | undefined;
  token: string | undefined;
  name: string | undefined;
  cognitoUserSession: CognitoIdToken | undefined;
  noAuthMessage = "user is undefined";
  message: string | undefined;
  handleToastErrors!: HubCallback;
  initialState: any = "signIn";
  mostrar: string = "1";

  currentPlan: string | undefined;
  renovationDate: any | undefined;
  todayDate: any | undefined;
  remainingDays: number | undefined;
  remainingDaysFree: number | undefined;
  remainingDaysAnual: number | undefined;

  selectedPlan: string = "";
  infoPlan: any | undefined;

  params: any;

  acceptTerms: boolean = false;

  services = {
    async validateCustomSignUp(formData: Record<string, string>) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: "Debes aceptar los términos y condiciones",
        };
      } else {
        return null;
      }
    },
    async handleSignIn(formData: Record<string, any>) {
      let { username, password, attributes } = formData;
      username = username.toLowerCase();

      const variable = await Auth.signIn({ username, password });
      return Auth.signIn({ username, password });
    },
  };

  constructor(
    private ref: ChangeDetectorRef,
    private router: Router,
    public authenticator: AuthenticatorService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private agenciesService: AgenciesService,
    private websocketService: WebsocketService,
    private toastr: ToastrService
  ) {
    Amplify.configure(awsExports);
    I18n.putVocabulariesForLanguage("es-CO", {
      [Translations.SIGN_IN_HEADER_TEXT]: "Ingresar",
      [Translations.SIGN_IN_ACTION]: "Aceptar",
      [Translations.BACK_TO_SIGN_IN]: "Regresar a ingreso",
      [Translations.CONFIRM]: "Confirmar",
      [Translations.USERNAME_LABEL]: "Correo electrónico *",
      [Translations.PASSWORD_LABEL]: "Contraseña *",
      [Translations.PASSWORD_PLACEHOLDER]: "Ingresar contraseña",
      [Translations.USERNAME_PLACEHOLDER]: "Ingresar Correo electrónico",
      Username: "Ingresar correo electrónico",
      "Password must have at least 8 characters":
        "La contraseña debe tener mínimo 8 caracteres",
      Password: "Ingresar contraseña",
      "Family Name": "Ingresar nombre",
      Code: "Código de verificación",
      Phone: "(555) 555-1212",
      "Confirm Password": "Confirmar contraseña",
      "validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [p{L}p{M}p{S}p{N}p{P}]+":
        "Hay un error en el correo electrónico. Por favor verifica que no tenga caracteres especiales o espacios al final",
      "An account with the given email already exists.":
        "Ya existe una cuenta con este correo",
      "New Password": "Nueva contraseña",
      "Invalid verification code provided, please try again.":
        "Código de verificación inválido. Por favor intenta nuevamente",
      "Your passwords must match": "Las contraseñas deben coincidir",
      "User does not exist.": "Usuario no existe.",
      "Username should be an email.":
        "Debes ingresar un correo electrónico válido",
      "Your code is on the way. To log in, enter the code we emailed to":
        "Tu código de confirmación ha sido enviado a",
      "It may take a minute to arrive.":
        "Puede tomar hasta un minuto en recibirlo",
      "Password did not conform with policy: Password must have uppercase characters":
        "Contraseña no cumple con las políticas. Debe tener almenos una mayúscula",
      "Password did not conform with policy: Password must have lowercase characters":
        "Contraseña no cumple con las políticas. Debe tener almenos una minúscula",
      "Password did not conform with policy: Password must have numeric characters":
        "Contraseña no cumple con las políticas. Debe tener almenos un número",
      "Password did not conform with policy: Password must have symbol characters":
        "Contraseña no cumple con las políticas. Debe tener almenos un caracter especial (!, #, &, etc)",
      "Password does not conform to policy: Password must have uppercase characters":
        "Contraseña no cumple con las políticas. Debe tener almenos una mayúscula",
      "Password does not conform to policy: Password must have numeric characters":
        "Contraseña no cumple con las políticas. Debe tener almenos un número",
      "Password does not conform to policy: Password must have symbol characters":
        "Contraseña no cumple con las políticas. Debe tener almenos un caracter especial (!, #, &, etc)",
      "Incorrect username or password.":
        "Nombre de usuario o contraseña incorrecto",
      "Username/client id combination not found.": "Nombre de usuario invalido",
      "We Emailed You": "Te hemos enviado correo",
      [Translations.FORGOT_PASSWORD_TEXT]: "Olvidaste tu contraseña?",
      [Translations.RESET_PASSWORD_TEXT]: "Recuperar contraseña",
      [Translations.NO_ACCOUNT_TEXT]: "No tienes cuenta?",
      [Translations.CREATE_ACCOUNT_TEXT]: "Crear cuenta",
      [Translations.SIGN_UP_HEADER_TEXT]: "Crear nueva cuenta",
      [Translations.EMAIL_LABEL]: "Correo electrónico *",
      [Translations.EMAIL_PLACEHOLDER]: "Ingresar correo electrónico",
      [Translations.PHONE_LABEL]: "Celular *",
      [Translations.PHONE_PLACEHOLDER]: "(555) 555-1212",
      [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Ya tienes cuenta?",
      [Translations.SIGN_IN_ACTION]: "Ingresar",
      [Translations.SIGN_IN_TEXT]: "Ingresar",
      [Translations.CREATE_ACCOUNT_TEXT]: "Crear cuenta",
      [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Crear cuenta",
      [Translations.PHONE_PLACEHOLDER]: "(555) 555-1212",
      [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Código de confirmación",
      [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]:
        "Ingresa el código que recibiste en tu correo electrónico",
      [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Confirmar registro",
      [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Perdiste tu código?",
      [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Reenviar código",
      [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Confirmar",
      [Translations.CODE_PLACEHOLDER]: "Ingresar código",
      [Translations.CODE_LABEL]: "Código de verificación",
      [Translations.CHANGE_PASSWORD]: "Cambiar contraseña",
      [Translations.SEND_CODE]: "Continuar",
      [Translations.SUBMIT]: "Enviar",
      [Translations.RESET_YOUR_PASSWORD]: "Reestablecer contraseña",
      [Translations.RESET_PASSWORD_TEXT]: "Reestablecer contraseña",
      [Translations.NEW_PASSWORD_LABEL]: "Nueva contraseña",
      [Translations.NEW_PASSWORD_PLACEHOLDER]: "Ingresar tu nueva contraseña",
    });

    I18n.setLanguage("es-CO");
  }
  ngAfterViewInit(): void {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.params = params;

      if (params.plan) {
        this.selectedPlan = params.plan;
      }
      if (params.acms) {
        this.infoPlan = {
          acms: params.acms,
        };
      }
      if (params.properties) {
        this.infoPlan = {
          ...this.infoPlan,
          properties: params.properties,
        };
      }
    });

    switch (this.selectedPlan) {
      case "1":
        break;
      case "2":
        break;
      case "3":
        break;
      case "4":
        break;
      case "5":
        break;
      default:
        this.selectedPlan = "";
    }

    this.activatedRoute.params.subscribe((params) => {
      this.initialState = params["initialState"];
    });
    this.handleToastErrors = ({ payload }) => {
      if (payload.event == "signIn_failure") {
        if (payload.data.message == "Incorrect username or password.")
          this.message = "Nombre de usuario o contraseña incorrecto";
        if (payload.message == "User does not exist.")
          this.message = "El usuario no existe";
        if (
          payload.message == "An account with the given email already exists."
        )
          this.message = "Ya existe una cuenta con este correo electrónico";
        if (
          payload.message ==
          "Password did not conform with policy: Password not long enough"
        )
          this.message = "La contraseña debe tener mínimo 8 caracteres";
        if (
          payload.message ==
          "Password did not conform with policy: Password must have uppercase characters"
        )
          this.message =
            "La contraseña debe contener por lo menos una letra mayúscula";
        if (
          payload.message ==
          "Password did not conform with policy: Password must have lowercase characters"
        )
          this.message =
            "La contraseña debe contener por lo menos una letra minúscula";
        if (
          payload.message ==
          "Password did not conform with policy: Password must have symbol characters"
        )
          this.message =
            "La contraseña debe contener por lo menos un símbolo o caracter especial. Ejemplo: (¡, !, $)";
        if (
          payload.message ==
          "Password did not conform with policy: Password must have numeric characters"
        )
          this.message =
            "La contraseña debe contener por lo menos un caracter numérico";
        if (payload.message == "Invalid phone number format.")
          this.message =
            "Número de celular inválido, asegurate de escribirlo sin carácteres o espacios";
        if (
          payload.message ==
          "Invalid verification code provided, please try again."
        )
          this.message =
            "El código de verificación es inválido, por favor intenta de nuevo";
        if (
          payload.message ==
          "Attempt limit exceeded, please try after some time."
        )
          this.message =
            "Límite de intentos superados, por favor intenta en unos minutos";
        if (
          payload.message ==
          "Password does not conform to policy: Password not long enough"
        )
          this.message = "La contraseña debe contener mínimo 8 carácteres";
        if (
          payload.data.message ==
          "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [p{L}p{M}p{S}p{N}p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [p{L}p{M}p{S}p{N}p{P}]+"
        )
          this.message =
            "Hay un error en el correo electrónico. Por favor verifica que no tenga caracteres especiales o espacios al final";

        if (this.message != undefined) {
          Swal.fire({
            title: "Error",
            showClass: {
              popup: "animate__animated animate__fadeIn",
            },
            text: this.message,
            icon: "error",
            confirmButtonColor: "#ff542a",
            confirmButtonText: "Acepto",
          });
        }
      }
      if (
        payload.event == "signUp_failure" ||
        payload.event == "forgotPassword_failure" ||
        payload.event == "signIn_failure"
      ) {
        if (payload.data.message.includes("username")) {
          payload.data.message = "";
          payload.data.message =
            "Hay un error en el correo electrónico. Por favor verifica que no tenga caracteres especiales o espacios al final";
        }
        if (payload.data.message.includes("userAlias")) {
          payload.data.message = "";
          payload.data.message =
            "Hay un error en el correo electrónico. Por favor verifica que no tenga caracteres especiales o espacios al final";
        }
      }
      if (payload.event === "signIn" || payload.event === "configured") {
        Auth.currentUserInfo().then((user) => {
          this.user = user;
          let email = user?.attributes?.email;
          // console.log("SOCKET DESDE LOGIN");
          if (email) {
            this.websocketService.connect(email);
            this.websocketService.notifications?.subscribe(
              (notification: Notification) => {
                // console.log(notification);
                let message =
                  notification.message.length > 40
                    ? notification.message.substring(0, 40).concat(" ...")
                    : notification.message;
                switch (notification.type) {
                  case "success":
                    this.toastr.success(
                      message,
                      notification.title,
                      notificationConfig
                    );
                    break;
                  case "info":
                    this.toastr.info(
                      message,
                      notification.title,
                      notificationConfig
                    );
                    break;
                  case "warning":
                    this.toastr.warning(
                      message,
                      notification.title,
                      notificationConfig
                    );
                    break;
                  case "error":
                    this.toastr.error(
                      message,
                      notification.title,
                      notificationConfig
                    );
                    break;
                }
              }
            );
          }
        });
        if (this.selectedPlan != "") {
          if (this.selectedPlan === "5") {
            this.params = { ...this.params, continueProcess: true };
            this.router.navigate(["/contact-owners"], {
              queryParams: this.params,
            });
          } else {
            this.router.navigate(["/checkout"], {
              queryParams: {
                plan: this.selectedPlan,
                acms: this.infoPlan.acms,
                properties: this.infoPlan.properties,
                payProcess: true,
              },
            });
          }
        }
        // console.log(payload);
        if (payload.data.attributes.nickname == "Agencia Inmobiliaria") {
          this.agenciesService
            .getAgencyDetails(payload.data.attributes.family_name)
            .subscribe(
              (resp: any) => {
                if (resp.answer == false) {
                  this.openModalAgencyData();
                }
              },
              (error) => {
                this.openModalAgencyData();
              }
            );
        }
      }

      this.ref.detectChanges();
    };

    // Auth.currentUserInfo().then(user => {
    //   this.user = user;
    //   console.log(user);
    // });

    Hub.listen("auth", this.handleToastErrors);
    return () => Hub.remove("auth", this.handleToastErrors);
  }

  openModalAgencyData() {
    setTimeout(() => {
      const boton = document.getElementById("btnModalAgencyData");
      boton!.click();
    }, 10);
  }

  classMostrar() {
    const boton = document.getElementById("amplify-field-0eiKuWhBR-3x");
   
    if(boton?.getAttribute("type") == "password"){
      boton?.setAttribute("type", "text");
      boton?.click();
    }else{
      boton?.setAttribute("type", "password");
      boton?.click();
    }
   
  }

  classMostrar2() {
    const boton = document.getElementById("amplify-field-wWK568qXSEqX");
   
    if(boton?.getAttribute("type") == "password"){
      boton?.setAttribute("type", "text");
      boton?.click();
    }else{
      boton?.setAttribute("type", "password");
      boton?.click();
    }
    
  }

  closeModalAgencyData() {
    const boton = document.getElementById("closeModalAgencyData");
    boton?.click();
  }

  saveAgencyData() {
    let formData = this.formAgencyData.value;
    const agencia = {
      nombre: this.user?.attributes.family_name,
      direccion: formData.direccion,
      representante: formData.representante,
      celular: this.user?.attributes.phone_number,
      ciudad: this.user?.attributes.address,
      email: this.user?.attributes.email,
    };
    this.agenciesService.createAgency(agencia).subscribe((resp: any) => {
      if (resp.answer) {
        Swal.fire({
          icon: "success",
          title: "¡Bien Hecho!",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.closeModalAgencyData();
          }
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "¡Error!",
          text: "Ocurrió un error al guardar los datos. Intenta de nuevo por favor.",
          showConfirmButton: false,
          timer: 3000,
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.openModalAgencyData();
          }
        });
      }
    });
  }

  // public async handleSignUp(formData: any) {
  //   let data;
  //   let terms = await Swal.fire({
  //     title: '¿Aceptas nuestra politica de tratamiento de datos?',
  //     html: '<a href="https://imagenes-homty.s3.amazonaws.com/Politica+de+tratmiento+de+Datos+Homty+-+septiembre++2021.pdf" target="_blank">Términos y condiciones</a> ',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Si acepto',
  //     cancelButtonText: 'No acepto',

  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.acceptTerms = true;
  //     }
  //   })

  //   if (this.acceptTerms) {
  //     data = Auth.signUp(formData);
  //   }
  //   return data;

  // }

  goToSearch() {
    Auth.currentUserInfo().then((user) => {
      this.router.navigate(["/propiedades"], {
        queryParams: {
          inmueble: "Apartamento",
          operacion: "venta",
          municipio: user["attributes"]["address"],
          barrio: "",
          preciomin: "20000000",
          preciomax: "70000000000",
        },
      });
    });
  }

  goToInventory() {
    Auth.currentUserInfo().then((user) => {
      if (
        user &&
        (user!["attributes"]["custom:free_trial"] == 1 ||
          user["attributes"]["custom:active_plan"] != 0)
      ) {
        this.router.navigate(["/inventory"]);
      } else {
        let terms = Swal.fire({
          title: "No tienes un plan activo, ¿Deseas adquirir uno?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Comprar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(["pay-subscription"]);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  public formFields = {
    signIn: {
      username: {
        labelHidden: false,
        //placeholder: 'Enter Your Email Here',
        isRequired: true,
        label: "Email:",
      },
      password: {
        labelHidden: false,
        //placeholder: 'Enter Your Email Here',
        isRequired: true,
        label: "Contraseña:",
      },
    },
    signUp: {
      phone_number: {
        labelHidden: false,
        label: "Celular *:",
        dialCode: "+57",
        isRequired: true,
      },
      password: {
        hint: "Mínimo 8 caracteres, con mayúscula, minúscula y caracter especial. Ejemplo: ( '!', '¡', '$')",
        labelHidden: false,
        label: "Contraseña *:",
        placeholder: "Ingrese Contraseña",
      },
      username: {
        labelHidden: false,
        //placeholder: 'Enter Your Email Here',
        isRequired: true,
        label: "Email:",
      },
      confirm_password: {
        labelHidden: false,
        label: "Repetir Contraseña *:",
        placeholder: "Confirmar Contraseña",
      },
      family_name: {
        labelHidden: false,
        label: "Nombre:",
        placeholder: "Ingresar Nombre",
        order: 1,
        isRequired: true,
      },
      address: {
        labelHidden: false,
        label: "Ciudad:",
        placeholder: "Ingresar Ciudad",
        isRequired: true,
      },
    },
  };
}
