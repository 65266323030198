<section class="pricing py-5">
  <div class="container">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <h1>Suscripción para Agencias</h1>
      <p>Paga individualmente por el número de usuarios con el que deseas contar</p>

      <!-- Paquetes -->
      <div class="d-flex col-12 align-items-center justify-content-center mt-4">
        <!-- ACMs -->
        <div class="card plans background1">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-8">
                <div class="m-4 mt-20">
                  <h3 class="mt-20 ml-auto">Usuario</h3>
                  <h2 class="h2-price color-pro">$250.000</h2>
                  <div class="form-outline mt-2">
                    <label class="form-label" for="numberUsers"><strong>Cantidad</strong></label>
                    <input class="form-control p-3" type="number" min="0" id="numberUsers" [formControl]="numberUsers" />
                  </div>
  
                  <p class="mt-3">Selecciona cuantos usuarios necesitas. <br><br> <span class="text-muted">* Periodo de
                      un año</span></p>
                </div>
  
              </div>
  
              <div class="col-lg-4 d-flex justify-content-center align-items-center text-center">
                <div class=""> 
                  <h3 class="">Total:</h3>
                  <h3 class="h4-price color-pro">{{total | currency:'COP':'symbol-narrow':'1.0-0'}}</h3>
                  <div class="row justify-content-center mt-4">
                    <a (click)="openCheckout()" class="col-8 btn btn-primary text-uppercase">Comprar</a>
                    <!-- routerLink="/checkout" [queryParams]="{plan: '4', acms: quantityAcms.value, properties: quantityProperties.value}" -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>