<app-loading
  *ngIf="loading"
  class="animate__animated animate__fadeIn vertical-center"
>
</app-loading>

<div class="fixed-bottom d-lg-none d-flex justify-content-end pr-3 pb-3">
  <button
    type="button"
    class="btn btn-lg btn-homty-blue rounded-circle ms-auto me-3"
    data-bs-toggle="modal"
    data-bs-target="#mobileMenuModal"
    matTooltip="Filtros"
    matTooltipClass="my-tooltip"
  >
    <fa-icon [icon]="faFilter"></fa-icon>
  </button>
</div>

<div class="filter pt-20 pb-20 d-none d-xl-block d-lg-block" *ngIf="!loading">
  <div class="container desk">
    <div class="d-flex justify-content-between">
      <h3 class="">Filtros</h3>
      <!-- <div class="col-md-12 col-sm-12 col-lg-3 text-center ml-auto">
        <a class="submit-btn align-bottom" routerLink="/owners"
          >¡Quiero encontrar más rápido!</a
        >
      </div> -->
    </div>

    <form>
      <div class="row animate__animated animate__fadeIn">
        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" *ngIf="filteredCities">
          <label for="municipio" class="form-label required">Municipio</label>
          <input
            class="form-select shadow-none"
            list="cities"
            name="municipio"
            id="municipio"
            formControlName="municipio"
            [formControl]="municipioAutocompleteControl"
            [(ngModel)]="selectedMunicipio"
          />
          <datalist id="cities">
            <option *ngFor="let city of filteredCities | async" [value]="city">
              {{ city }}
            </option>
          </datalist>
          <div
            *ngFor="
              let validation of validation_msgs.municipioAutocompleteControl
            "
          >
            <div
              *ngIf="municipioAutocompleteControl.hasError(validation.type)"
              class="mt-2 alert alert-danger"
            >
              {{ validation.message }}
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="barrio" class="form-label">Barrio</label>
          <input
            type="text"
            class="form-control"
            id="barrio"
            name="barrio"
            [(ngModel)]="selectedBarrio"
          />
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="propertyType" class="form-label required"
            >Tipo de inmueble</label
          >
          <select
            class="form-select"
            [(ngModel)]="selectedPropertyType"
            name="propertyType"
          >
            <option
              *ngFor="let propertyType of propertyTypes"
              [value]="propertyType.Inmueble"
            >
              {{ propertyType.Inmueble }}
            </option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="operacion" class="form-label required">Operación</label>
          <select
            class="form-select"
            [(ngModel)]="selectedOperation"
            name="operacion"
            (change)="updatePrices($event)"
          >
            <option value="venta">Venta</option>
            <option value="arriendo">Arriendo</option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider">
          <label for="operacion" class="form-label required">Precio</label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="precioMin"
                    name="precioMin"
                    [formControl]="precioMin"
                    [inputMask]="currencyInputMask"
                  />
                  <div
                    *ngIf="
                      precioMin.errors && (precioMin.dirty || precioMin.touched)
                    "
                    class="mt-2 alert alert-danger"
                  >
                    <div *ngFor="let validation of validation_msgs.precioMin">
                      <div *ngIf="precioMin.hasError(validation.type)">
                        {{ validation.message }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="precioMax"
                    name="precioMax"
                    [formControl]="precioMax"
                    [inputMask]="currencyInputMask"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label  class="form-label required">Ordenar</label>
          <select
            class="form-select"
            [(ngModel)]="selectedMinMax"
            id="filterMaxMin"
            name="filterMaxMin2"
            (change)="updateMaxMin()"
          >
            <option value="">Seleccionar</option>
            <option value="Precio">Precio Min</option>
            <option value="Valor_Admin">Administración Min</option>
          </select>
        </div>
     <!--    <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="barrio" class="form-label">Ordenar</label>
          <select
            type="text"
            class="form-select shadow-none"
            id="ordenar"
            name="ordenar"
          >
            <option value="" selected>Seleccionar</option>
            <option value="Precio">Precio Min</option>
            <option value="Valor_Admin">Valor administración Min</option>
          </select>
        </div> -->
      </div>

      <div
        class="row animate__animated animate__fadeIn"
        [hidden]="!showAllFilters"
      >
        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="
            selectedPropertyType !== 'Lote' &&
            selectedPropertyType !== 'Bodega' &&
            selectedPropertyType !== 'Consultorio' &&
            selectedPropertyType !== 'Local' &&
            selectedPropertyType !== 'Oficina'
          "
        >
          <label for="habitaciones" class="form-label">Habitaciones</label>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="habitaciones"
              name="habitaciones"
              [(ngModel)]="selectedHabitaciones"
              placeholder="Número de habitaciones"
            />
          </div>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="selectedPropertyType !== 'Lote'"
        >
          <label for="banos" class="form-label">Baños</label>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="banos"
              name="banos"
              [(ngModel)]="selectedBanos"
              placeholder="Número de baños"
            />
          </div>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="selectedPropertyType !== 'Lote'"
        >
          <label for="parqueadero" class="form-label">Parqueadero</label>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="parqueadero"
              name="parqueadero"
              [(ngModel)]="selectedParqueadero"
              placeholder="Número de parqueaderos"
            />
          </div>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="selectedPropertyType !== 'Lote'"
        >
          <label for="piso" class="form-label">Piso</label>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="piso"
              name="piso"
              [(ngModel)]="selectedPiso"
              placeholder="Número de piso"
            />
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider">
          <label for="operacion" class="form-label">
            Gastos de administración
            <mat-icon
              class="help-icon"
              matTooltip="Los gastos de administración de un inmueble son los costos que se incurren en la gestión , mantenimiento y conservación de dicho inmueble. Estos gastos pueden incluir el pago de empleados del equipo de administración, como gerentes y conserjes, así como también los costos asociados con la limpieza, mantenimiento, reparación y mejora de las instalaciones del inmueble."
              matTooltipClass="my-tooltip"
              >help_outline</mat-icon
            >
          </label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="valorAdmin"
                    name="valorAdmin"
                    [formControl]="valorAdmin"
                    [inputMask]="currencyInputMask"
                  />
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="valorAdminMax"
                    name="valorAdminMax"
                    [formControl]="valorAdminMax"
                    [inputMask]="currencyInputMask"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row animate__animated animate__fadeIn">
        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          [hidden]="!showAllFilters"
        >
          <label for="origin" class="form-label required">
            Origen
            <mat-icon
              class="help-icon"
              matTooltip="Selecciona el origen o la fuente de donde se obtuvo la información sobre el inmueble"
              matTooltipClass="my-tooltip"
              >help_outline</mat-icon
            >
          </label>
          <select
            class="form-select"
            id="origin"
            name="origin"
            [(ngModel)]="selectedOrigin"
          >
            <option *ngFor="let origin of origins" [value]="origin.Origen">
              {{ origin.Origen }}
            </option>
          </select>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider"
          [hidden]="!showAllFilters"
        >
          <label for="operacion" class="form-label">Área (m²)</label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="mtmin"
                    name="mtmin"
                    aria-label="Area min (m²)"
                    [(ngModel)]="mtmin"
                  />
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="mtmax"
                    name="mtmax"
                    aria-label="Area max (m²)"
                    [(ngModel)]="mtmax"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          [hidden]="!showAllFilters"
          *ngIf="selectedPropertyType !== 'Lote'"
        >
          <label for="antiguedad" class="form-label">Antigüedad</label>
          <div class="form-group">
            <select
              class="form-select"
              id="antiguedad"
              name="antiguedad"
              [(ngModel)]="selectedAntiguedad"
            >
              <option value="--">--</option>
              <option value="sin especificar">Sin especificar</option>
              <option value="menor a 1 año">Menor a 1 año</option>
              <option value="1 a 8 años">1 a 8 años</option>
              <option value="9 a 15 años">9 a 15 años</option>
              <option value="16 a 30 años">16 a 30 años</option>
              <option value="más de 30 años">Más de 30 años</option>
            </select>
          </div>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-3 custom-slider"
          [hidden]="!showAllFilters"
        >
          <label for="operacion" class="form-label">Estrato</label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="estrato"
                    name="estrato"
                    [(ngModel)]="selectedEstrato"
                  />
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="estratoMax"
                    name="estratoMax"
                    [(ngModel)]="selectedEstratoMax"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row animate__animated animate__fadeIn">
        <div class="col-md-12 col-sm-12 col-lg-8"></div>
        <div
          class="col-md-12 col-sm-12 col-lg-2 mt-25 d-flex flex-column justify-content-center align-items-end"
        >
          <h6 class="pointer" (click)="showAllFilters = !showAllFilters">
            {{ !showAllFilters ? "Ver más filtros" : "Ver menos filtros" }}
          </h6>
        </div>
        <div
          class="col-md-12 col-sm-12 col-lg-2 d-flex flex-column justify-content-center align-items-center"
        >
          <input
            class="submit-btn align-bottom"
            type="submit"
            value="Aplicar filtros"
            (click)="applyFilter()"
          />
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-fluid" *ngIf="!loading">
  <div class="row">
    <div class="col-md-8">
      <p class="sub-header pt-20 pb-20">
        <b>{{ length }}</b> Resultados de la búsqueda de
        {{ selectedPropertyType }} en {{ selectedOperation }} en
        {{ selectedMunicipio }}
      </p>
    </div>
    <div class="col-md-2 d-flex flex-row-reverse pt-20 pb-20">
      <!-- <mat-slide-toggle *ngIf="user" [checked]="viewOnMap" (change)="toggleViewMap($event)">
        <p class="subheader pt-2 results-map-toggle">Ver resultados en mapa</p>
      </mat-slide-toggle> -->
      <button
        mat-flat-button
        class="view-type-btn"
        [matMenuTriggerFor]="viewTypeMenu"
      >
        Tipo de Vista: {{ viewTypeLabels[viewType].label }}
      </button>
      <mat-menu #viewTypeMenu="matMenu" class="pt-15 pl-15 pr-20">
        <div *ngFor="let item of viewTypeLabels">
          <button
            mat-menu-item
            *ngIf="
              item.restricted
                ? user
                  ? user['attributes']['custom:free_trial'] == 1 ||
                    user['attributes']['custom:active_plan'] != 0
                  : false
                : true
            "
            (click)="changeViewType(item.value)"
          >
            {{ item?.label }}
          </button>
        </div>
      </mat-menu>
    </div>    
  </div>

  <div class="col-lg-2 pt-10 pl-20 pr-20" *ngIf="user">
    <button class="ver-detalles-btn-green" type="button" (click)="download()">
      <fa-icon [icon]="faDownload"></fa-icon> Descargar Excel
    </button>
  </div>
  <div class="row">
    <div class="col-md-4 d-flex">
      <!-- <span
        *ngIf="!user"
        class="bg-warning badge span-registrate d-flex justify-content-center align-items-center mt-12 pointer"
        [routerLink]="['/login', 'signUp']"
      >
        Desbloquea nuevas funcionalidades, prueba durante 4 días
      </span> -->
      <button
        *ngIf="!user"
        class="ver-detalles-btn-green"
        type="button"
        (click)="openModal()"
      >
        <fa-icon [icon]="faDownload"></fa-icon> Descargar Excel
      </button>
    </div>
  </div>

  <div *ngIf="viewType == 0" class="properties-container mt-4 mb-5">
    <app-property-card
      *ngFor="let property of data"
      (applyFilter)="applyFilter()"
      [property]="property"
      (selectedProperty)="selectedPropertyToModal = $event; openProperty()"
    ></app-property-card>
  </div>

  <div class="row" *ngIf="viewType == 0">
    <div class="col-lg-2 pt-10 pl-20 pr-20" *ngIf="user">
      <button class="ver-detalles-btn-green" type="button" (click)="download()">
        <fa-icon [icon]="faDownload"></fa-icon> Descargar Excel
      </button>
    </div>
    <div class="row">
      <div class="col-md-4 d-flex">
        <!-- <span
          *ngIf="!user"
          class="bg-warning badge span-registrate d-flex justify-content-center align-items-center mt-12 pointer"
          [routerLink]="['/login', 'signUp']"
        >
          Desbloquea nuevas funcionalidades, prueba durante 4 días
        </span> -->
        <button
          *ngIf="!user"
          class="ver-detalles-btn-green"
          type="button"
          (click)="openModal()"
        >
          <fa-icon [icon]="faDownload"></fa-icon> Descargar Excel
        </button>
      </div>
    </div>
    <div class="col-lg-3 pt-10 pl-20 pr-20" *ngIf="user">
      <p>Se exportarán máximo 100 propiedades</p>
    </div>
    <div class="col-lg-3 pt-10 pl-20 pr-20" *ngIf="!user">
      <p>Se exportarán máximo 100 propiedades</p>
    </div>
    <div class="col-lg-7">
      <mat-paginator
        *ngIf="
          !(dataSource != null && dataSource.data.length == 0) && viewType == 0
        "
        (page)="onPageChange($event)"
        #paginator
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>

  <div class="mat-elevation-z8 animate__animated animate__fadeIn mb-20 mt-4">
    <mat-table
      *ngIf="viewType == 2"
      [dataSource]="dataSource!"
      matSort
      (matSortChange)="sortPropertiesByColumn($event)"
    >
      <ng-container
        *ngFor="let displayedColumn of displayedColumns"
        matColumnDef="{{ displayedColumn }}"
      >
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{
            displayedColumn == "Tamano"
              ? "Area (m²)"
              : displayedColumn && displayedColumn == "Banos"
              ? "Baños"
              : displayedColumn && displayedColumn == "Valor_Admin"
              ? "Valor Admin."
              : displayedColumn && displayedColumn == "Inmueble"
              ? "Tipo de inmueble"
              : displayedColumn && displayedColumn == "Operacion"
              ? "Operación"
              : displayedColumn && displayedColumn == "Habitaciones"
              ? "Hab."
              : displayedColumn && displayedColumn == "Parking"
              ? "Parquead."
              : displayedColumn && displayedColumn == "Miguel"
              ? "_______________________"
              : displayedColumn && displayedColumn == "Antiguedad"
              ? "Antig."
              : displayedColumn
          }}
        </mat-header-cell>
        <mat-cell
          [matTooltip]="
            displayedColumn == 'Descripcion' ? element[displayedColumn] : ''
          "
          matTooltipPosition="above"
          matTooltipClass="custom-tooltip"
          *matCellDef="let element"
        >
          {{
            displayedColumn == "Precio"
              ? [
                  element[displayedColumn]
                    | currency : "COP" : "symbol-narrow" : "1.0-0"
                ]
              : element[displayedColumn] && displayedColumn == "Favorito"
              ? ""
              : element[displayedColumn] && displayedColumn == "Whatsapp"
              ? ""
              : element[displayedColumn] && displayedColumn == "Origenes"
              ? ""
              : element[displayedColumn] && displayedColumn == "Barrio"
              ? [element[displayedColumn] | titlecase]
              : element[displayedColumn]
          }}

          <img
            src="./assets/images/heart-regular.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat"
            *ngIf="displayedColumn == 'Miguel' && element['Favorito'] == '0'"
            (click)="element['Favorito'] = '1'; updateFavorite('1', element.id)"
          />

          <img
            src="./assets/images/heart-solid.png"
            class="favorite-icon pointer animate__animated animate__heartBeat"
            *ngIf="displayedColumn == 'Miguel' && element['Favorito'] == '1'"
            (click)="element['Favorito'] = '0'; updateFavorite('0', element.id)"
          />

          <img
            src="./assets/images/heart-regular.png"
            class="favorite-icon pointer show animate__animated animate__heartBeat"
            *ngIf="displayedColumn == 'Miguel' && element['Favorito'] == '2'"
            (click)="showRegisterModal()"
          />

          <!-- <a
            class="ml-10"
            *ngIf="
              displayedColumn == 'Miguel' && element['Origenes'].length < 2
            "
            [href]="element['Origenes'][0].URL"
            target="_blank"
          >
            <img
              *ngIf="element.Origen == 'MetroCuadrado'"
              src="./assets/images/icons/metrocuadrado.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Finca Raiz'"
              src="./assets/images/icons/fincaraiz.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'CienCuadras'"
              src="./assets/images/icons/ciencuadras.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'WebInmobiliaria'"
              src="./assets/images/icons/webinmobiliaria.png"
              class="favorite-icon-20 pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Wasi'"
              src="./assets/images/icons/wasi.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Informe Inmobiliario'"
              src="./assets/images/icons/informeinmobiliario.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'AraujoySegovia'"
              src="./assets/images/icons/araujoysegovia.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Alnago'"
              src="./assets/images/icons/alnago.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Inurbanas'"
              src="./assets/images/icons/inurbanas.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Financar'"
              src="./assets/images/icons/logo-financar.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'VozInmobiliaria'"
              src="./assets/images/icons/vozinmobiliaria.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Dinamica Inmobiliaria'"
              src="./assets/images/icons/dinamicainmobiliaria.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Escobar Bonnett'"
              src="./assets/images/icons/escobarbonnett.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Oscar Sandoval Varona'"
              src="./assets/images/icons/oscarsandoval.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'TodoPropiedades'"
              src="./assets/images/icons/todopropiedades.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Franquicia Inmobiliaria'"
              src="./assets/images/icons/franquiciainmobiliaria.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Get Home'"
              src="./assets/images/icons/gethome.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Ana Hernandez'"
              src="./assets/images/icons/anahernandez.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Aliados Inmobiliarios'"
              src="./assets/images/icons/aliadosinmobiliarios.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Ideal Homes'"
              src="./assets/images/icons/idealhomes.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Go Habitat'"
              src="./assets/images/icons/gohabitat.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Su Propiedad'"
              src="./assets/images/icons/supropiedad.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Kota Group'"
              src="./assets/images/icons/kotagroup.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Marlongi'"
              src="./assets/images/icons/marlongi.jpeg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Gruges Inmobiliario'"
              src="./assets/images/icons/gruges.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Casa Invicta'"
              src="./assets/images/icons/casainvicta.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Home Inmobiliaria'"
              src="./assets/images/icons/homeinmobiliaria.jpg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
            <img
              *ngIf="element.Origen == 'Home Capital'"
              src="./assets/images/icons/homecapital.svg"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
          </a> -->
          <a
            *ngIf="
              displayedColumn == 'Miguel' && element['Origenes'].length < 2
            "
            (click)="getProperty(element)"
          >
            <img
              *ngIf="element.Origen == 'Homty'"
              src="./assets/images/fav.png"
              class="favorite-icon pointer show animate__animated animate__heartBeat"
            />
          </a>

          <!-- <button
            *ngIf="
              displayedColumn == 'Miguel' && element['Origenes'].length > 1
            "
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Lista de origenes"
          >
            <mat-icon [ngStyle]="{ color: '#ff5324' }">link</mat-icon>
            <mat-menu #menu="matMenu" class="origins">
              <button mat-menu-item *ngFor="let element of element['Origenes']">
                <a [href]="element.URL" target="_blank">
                  <img
                    *ngIf="element.Origen == 'Homty'"
                    src="./assets/images/fav.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'MetroCuadrado'"
                    src="./assets/images/icons/metrocuadrado.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'Finca Raiz'"
                    src="./assets/images/icons/fincaraiz.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'CienCuadras'"
                    src="./assets/images/icons/ciencuadras.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'WebInmobiliaria'"
                    src="./assets/images/icons/webinmobiliaria.png"
                    class="favorite-icon-20 pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'Wasi'"
                    src="./assets/images/icons/wasi.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat mr-10"
                  />
                  <img
                    *ngIf="element.Origen == 'Informe Inmobiliario'"
                    src="./assets/images/icons/informeinmobiliario.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'AraujoySegovia'"
                    src="./assets/images/icons/araujoysegovia.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Alnago'"
                    src="./assets/images/icons/alnago.png"
                    class="favorite-icon pointer show animxate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Inurbanas'"
                    src="./assets/images/icons/inurbanas.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Financar'"
                    src="./assets/images/icons/logo-financar.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'VozInmobiliaria'"
                    src="./assets/images/icons/vozinmobiliaria.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Dinamica Inmobiliaria'"
                    src="./assets/images/icons/dinamicainmobiliaria.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Escobar Bonnett'"
                    src="./assets/images/icons/escobarbonnett.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Oscar Sandoval Varona'"
                    src="./assets/images/icons/oscarsandoval.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'TodoPropiedades'"
                    src="./assets/images/icons/todopropiedades.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Franquicia Inmobiliaria'"
                    src="./assets/images/icons/franquiciainmobiliaria.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Get Home'"
                    src="./assets/images/icons/gethome.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Ana Hernandez'"
                    src="./assets/images/icons/anahernandez.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Aliados Inmobiliarios'"
                    src="./assets/images/icons/aliadosinmobiliarios.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Ideal Homes'"
                    src="./assets/images/icons/idealhomes.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Go Habitat'"
                    src="./assets/images/icons/gohabitat.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Su Propiedad'"
                    src="./assets/images/icons/supropiedad.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Kota Group'"
                    src="./assets/images/icons/kotagroup.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Marlongi'"
                    src="./assets/images/icons/marlongi.jpeg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Gruges Inmobiliario'"
                    src="./assets/images/icons/gruges.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Casa Invicta'"
                    src="./assets/images/icons/casainvicta.png"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Home Inmobiliaria'"
                    src="./assets/images/icons/homeinmobiliaria.jpg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <img
                    *ngIf="element.Origen == 'Home Capital'"
                    src="./assets/images/icons/homecapital.svg"
                    class="favorite-icon pointer show animate__animated animate__heartBeat"
                  />
                  <span>{{ element.URL }}</span>
                </a>
              </button>
            </mat-menu>
          </button> -->

          <a
            class="ml-10"
            [href]="
              'https://wa.link/xtvmyw' +
              '?text=Hola, vi tu inmueble en homty.co con el código ' +
              element['id'] +
              ' en la fuente ' +
              element['Origen'] +
              ' y estoy interesado. ' +
              element['Origenes'][0].URL
            "
            *ngIf="displayedColumn == 'Miguel'"
            target="_blank"
          >
            <img src="./assets/images/icons/whatsapp.png" alt="" />
          </a>

          <button
            class="ver-detalles-btn ml-10"
            type="button"
            *ngIf="displayedColumn == 'Miguel'"
            data-bs-toggle="modal"
            data-bs-target="#detallesModal"
            (click)="selectedPropertyToModal = element"
          >
            +Detalle
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="loading">
        <mat-footer-cell *matFooterCellDef colspan="19">
          Cargando información...
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef colspan="19">
          No hay propiedades con los parámetros de busqueda
        </mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: dataSource != null }"
      ></mat-footer-row>
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{
          hide: !(dataSource != null && dataSource.data.length == 0)
        }"
      ></mat-footer-row>
    </mat-table>
    <h4
      *ngIf="dataSource != null && dataSource.data.length == 0"
      class="text-center noData pt-10"
    >
      No hay propiedades con los parámetros de búsqueda
    </h4>

    <agm-map
      *ngIf="viewType == 1 && data != null && data.length > 0"
      class="mb-4"
      [latitude]="+data[0]['Latitud']"
      [longitude]="+data[0]['Longitud']"
      [zoom]="zoom"
    >
      <agm-marker
        *ngFor="let property of data"
        [latitude]="property.Latitud"
        [longitude]="property.Longitud"
        (markerClick)="selectedPropertyToModal = property; openProperty()"
      >
      </agm-marker>
    </agm-map>
    <div class="row" *ngIf="viewType != 0">
      <div class="col-lg-2 pt-10 pl-20 pr-20" *ngIf="user">
        <button
          class="ver-detalles-btn-green"
          type="button"
          (click)="download()"
        >
          <fa-icon [icon]="faDownload"></fa-icon> Descargar Excel
        </button>
      </div>
      <div class="col-lg-3 pt-10 pl-20 pr-20" *ngIf="user">
        <p>Se exportarán máximo 100 propiedades</p>
      </div>
      <div class="col-lg-7">
        <mat-paginator
          *ngIf="!(dataSource != null && dataSource.data.length == 0)"
          (page)="onPageChange($event)"
          #paginator
          [length]="length"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>


<!-- <app-error403 *ngIf="!loading && !activePlan" [errorMessage]="errorMessage" [buttonText]="buttonText">
</app-error403> -->

<!-- Detail Modal -->
<div
  #detallesModal
  class="modal fade"
  id="detallesModal"
  tabindex="-1"
  aria-labelledby="detallesModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div
        *ngIf="
          selectedPropertyToModal.Imagenes.length > 0 &&
          selectedPropertyToModal.Imagenes[0] != ''
        "
        id="carouselExampleIndicators"
        class="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item"
            [ngClass]="{ active: i == 0 }"
            *ngFor="
              let imagen of selectedPropertyToModal.Imagenes;
              let i = index
            "
          >
            <img
              [src]="imagen"
              (error)="onImageError($event)"
              class="d-block w-100 img-fluid"
              alt="..."
            />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <div class="modal-header">
        <h5 class="modal-title" id="detallesModal">
          Detalles: {{ selectedPropertyToModal.Municipio }}-{{
            selectedPropertyToModal.Barrio
          }},
          {{
            selectedPropertyToModal.Precio
              | currency : "COP" : "symbol-narrow" : "1.0-0"
          }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="close"
        ></button>

        <script>
          $("#close").click(function () {
            document.write(5 + 7);
            $(".carousel").carousel(0);
          });
        </script>
      </div>
      <div class="modal-body">
        <div class="contact-box">
          <div class="row mb-25">
            <div class="col-2 col-lg-1">
              <div class="address-icon">
                <fa-icon class="detalle-icon" [icon]="faStream"></fa-icon>
              </div>
            </div>
            <div class="col-10 col-lg-11">
              <div class="address-text">
                <span class="label">Descripción:</span>
                <div class="desc">
                  {{ selectedPropertyToModal.Descripcion }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row mb-25">
            <div class="col-2 col-lg-1">
              <div class="address-icon">
                <fa-icon class="detalle-icon" [icon]="faLink"></fa-icon>
              </div>
            </div>
            <div class="col-10 col-lg-11">
              <div class="address-text">
                <span class="label">Url fuente de información:</span>
                <ul>
                  <li *ngFor="let origen of selectedPropertyToModal.Origenes">
                    <a [href]="origen.URL" target="_blank">{{
                      origen.Origen
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          <div class="row mb-25">
            <div class="col-2 col-lg-1">
              <div class="address-icon">
                <fa-icon class="detalle-icon" [icon]="faTags"></fa-icon>
              </div>
            </div>
            <div class="col-10 col-lg-11">
              <div class="address-text">
                <span class="label">Agencia/Vendedor:</span>
                <div class="desc">
                  <!-- {{ selectedPropertyToModal.Vendedor_Agencia }} -->
                  Miguel Abadía
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-25">
            <div class="col-2 col-lg-1">
              <div class="address-icon">
                <fa-icon class="detalle-icon" [icon]="faWhatsapp"></fa-icon>
              </div>
            </div>
            <div class="col-10 col-lg-11">
              <div class="address-text">
                <span class="label">Whatsapp</span>
                <div class="desc" *ngIf="selectedPropertyToModal.id != ''">
                  <a href="https://wa.link/xtvmyw" target="_blank">
                    +57 305 4064684
                  </a>
                  <!-- <a
                    [href]="
                      'https://wa.me/' +
                      selectedPropertyToModal.Whatsapp +
                      '?text=Hola, vi tu inmueble en homty.co con el código ' +
                      selectedPropertyToModal.id +
                      ' en la fuente ' +
                      selectedPropertyToModal.Origenes[0].Origen +
                      ' y estoy interesado. ' +
                      selectedPropertyToModal.Origenes[0].URL
                    "
                    target="_blank"
                  >
                    {{ 'selectedPropertyToModal.Whatsapp' }}</a
                  > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile Menu Modal -->
<div
  class="modal animate__animated animate__slideInLeft filter-modal"
  id="mobileMenuModal"
  tabindex="-1"
  aria-labelledby="mobileMenuModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-fullscreen-lg-down">
    <div class="modal-content blue-modal">
      <div class="modal-header">
        Filtros
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mb-3 col-md-12 col-sm-12 col-lg-2" *ngIf="filteredCities">
          <label for="municipio" class="form-label required">Municipio</label>
          <input
            class="form-select shadow-none"
            list="cities"
            name="municipio"
            id="municipio"
            formControlName="municipio"
            [formControl]="municipioAutocompleteControl"
            [(ngModel)]="selectedMunicipio"
          />
          <datalist id="cities">
            <option *ngFor="let city of filteredCities | async" [value]="city">
              {{ city }}
            </option>
          </datalist>
          <div
            *ngFor="
              let validation of validation_msgs.municipioAutocompleteControl
            "
          >
            <div
              *ngIf="municipioAutocompleteControl.hasError(validation.type)"
              class="mt-2 alert alert-danger"
            >
              {{ validation.message }}
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="barrio" class="form-label">Barrio</label>
          <input
            type="text"
            class="form-control"
            id="barrio"
            name="barrio"
            [(ngModel)]="selectedBarrio"
          />
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="propertyType" class="form-label required"
            >Tipo de inmueble</label
          >
          <select
            class="form-select"
            [(ngModel)]="selectedPropertyType"
            name="propertyType"
          >
            <option
              *ngFor="let propertyType of propertyTypes"
              [value]="propertyType.Inmueble"
            >
              {{ propertyType.Inmueble }}
            </option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="operacion" class="form-label required">Operación</label>
          <select
            class="form-select"
            [(ngModel)]="selectedOperation"
            name="operacion"
            (change)="updatePrices($event)"
          >
            <option value="venta">Venta</option>
            <option value="arriendo">Arriendo</option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider">
          <label for="operacion" class="form-label required">Precio</label>
          <table class="table" cellspacing="0">
            <tr class="tr-block">
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="precioMin"
                    name="precioMin"
                    [formControl]="precioMin"
                    [inputMask]="currencyInputMask"
                  />
                  <div
                    *ngIf="
                      precioMin.errors && (precioMin.dirty || precioMin.touched)
                    "
                    class="mt-2 alert alert-danger"
                  >
                    <div *ngFor="let validation of validation_msgs.precioMin">
                      <div *ngIf="precioMin.hasError(validation.type)">
                        {{ validation.message }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="precioMax"
                    name="precioMax"
                    [formControl]="precioMax"
                    [inputMask]="currencyInputMask"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="barrio" class="form-label">Ordenar</label>
          <select
            type="text"
            class="form-select shadow-none"
            id="ordenar"
            name="ordenar"
          >
            <option value="" selected>Seleccionar</option>
            <option value="Precio">Precio Min</option>
            <option value="Valor_Admin">Valor administración Min</option>
          </select>
        </div>
        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="
            selectedPropertyType !== 'Lote' &&
            selectedPropertyType !== 'Bodega' &&
            selectedPropertyType !== 'Consultorio' &&
            selectedPropertyType !== 'Local' &&
            selectedPropertyType !== 'Oficina'
          "
        >
          <label for="habitaciones" class="form-label">Habitaciones</label>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="habitaciones"
              name="habitaciones"
              [(ngModel)]="selectedHabitaciones"
              placeholder="Número de habitaciones"
            />
          </div>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="selectedPropertyType !== 'Lote'"
        >
          <label for="banos" class="form-label">Baños</label>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="banos"
              name="banos"
              [(ngModel)]="selectedBanos"
              placeholder="Número de baños"
            />
          </div>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="selectedPropertyType !== 'Lote'"
        >
          <label for="parqueadero" class="form-label">Parqueadero</label>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="parqueadero"
              name="parqueadero"
              [(ngModel)]="selectedParqueadero"
              placeholder="Número de parqueaderos"
            />
          </div>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="selectedPropertyType !== 'Lote'"
        >
          <label for="piso" class="form-label">Piso</label>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="piso"
              name="piso"
              [(ngModel)]="selectedPiso"
              placeholder="Número de piso"
            />
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider">
          <label for="operacion" class="form-label">
            Gastos de administración
            <mat-icon
              class="help-icon"
              matTooltip="Los gastos de administración de un inmueble son los costos que se incurren en la gestión , mantenimiento y conservación de dicho inmueble. Estos gastos pueden incluir el pago de empleados del equipo de administración, como gerentes y conserjes, así como también los costos asociados con la limpieza, mantenimiento, reparación y mejora de las instalaciones del inmueble."
              matTooltipClass="my-tooltip"
              >help_outline</mat-icon
            >
          </label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="valorAdmin"
                    name="valorAdmin"
                    [formControl]="valorAdmin"
                    [inputMask]="currencyInputMask"
                  />
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="valorAdminMax"
                    name="valorAdminMax"
                    [formControl]="valorAdminMax"
                    [inputMask]="currencyInputMask"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="origin" class="form-label required">
            Origen
            <mat-icon
              class="help-icon"
              matTooltip="Selecciona el origen o la fuente de donde se obtuvo la información sobre el inmueble"
              matTooltipClass="my-tooltip"
              >help_outline</mat-icon
            >
          </label>
          <select
            class="form-select"
            id="origin"
            name="origin"
            [(ngModel)]="selectedOrigin"
          >
            <option *ngFor="let origin of origins" [value]="origin.Origen">
              {{ origin.Origen }}
            </option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider">
          <label for="operacion" class="form-label">Área (m²)</label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="mtmin"
                    name="mtmin"
                    aria-label="Area min (m²)"
                    [(ngModel)]="mtmin"
                  />
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="mtmax"
                    name="mtmax"
                    aria-label="Area max (m²)"
                    [(ngModel)]="mtmax"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          class="mb-3 col-md-12 col-sm-12 col-lg-2"
          *ngIf="selectedPropertyType !== 'Lote'"
        >
          <label for="antiguedad" class="form-label">Antigüedad</label>
          <div class="form-group">
            <select
              class="form-select"
              id="antiguedad"
              name="antiguedad"
              [(ngModel)]="selectedAntiguedad"
            >
              <option value="--">--</option>
              <option value="sin especificar">Sin especificar</option>
              <option value="menor a 1 año">Menor a 1 año</option>
              <option value="1 a 8 años">1 a 8 años</option>
              <option value="9 a 15 años">9 a 15 años</option>
              <option value="16 a 30 años">16 a 30 años</option>
              <option value="más de 30 años">Más de 30 años</option>
            </select>
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2 custom-slider">
          <label for="operacion" class="form-label">Estrato</label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="estrato"
                    name="estrato"
                    [(ngModel)]="selectedEstrato"
                  />
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="estratoMax"
                    name="estratoMax"
                    [(ngModel)]="selectedEstratoMax"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-1 pd-5">
          <input
            class="submit-btn"
            type="submit"
            value="Aplicar filtros"
            (click)="applyFilter()"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<amplify-authenticator [hidden]="!false"> </amplify-authenticator>

<!-- Start of HubSpot Embed Code -->
<script
  type="text/javascript"
  id="hs-script-loader"
  async
  defer
  src="//js.hs-scripts.com/8998298.js"
></script>
<!-- End of HubSpot Embed Code -->
