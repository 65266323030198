import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pay-package',
  templateUrl: './pay-package.component.html',
  styleUrls: ['./pay-package.component.css']
})
export class PayPackageComponent implements OnInit {

  quantityAcms = new FormControl(1);
  quantityProperties = new FormControl(1);
  total: number | undefined;
  showedAlert: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.total = (this.quantityAcms.value * 10000) + (this.quantityProperties.value * 5000)
    this.quantityAcms.valueChanges.subscribe(value => {
      this.validate();
    })
    this.quantityProperties.valueChanges.subscribe(value => {
      this.validate();
    })
  }

  openCheckout() {
    if (this.quantityAcms.value >= 0 && this.quantityProperties.value >= 0 && (this.quantityAcms.value > 0 || this.quantityProperties.value > 0)) {
      this.router.navigate(['/checkout'], { queryParams: { plan: '4', acms: this.quantityAcms.value, properties: this.quantityProperties.value } })
    } else {
      Swal.fire({
        icon: 'error',
        text: "El minimo debe ser 1 unidad en alguno de los dos servicios",
        confirmButtonColor: '#ff653f'
      });
    }
  }

  validate() {
    let newTotal = (this.quantityAcms.value * 10000) + (this.quantityProperties.value * 5000)

    if (this.quantityAcms.value <= 0 && this.quantityProperties.value <= 0) {
      Swal.fire({
        icon: 'error',
        text: "El minimo debe ser 1 unidad en alguno de los dos servicios",
        confirmButtonColor: '#ff653f'
      });
    }
    if (newTotal > 50000 && !this.showedAlert) {
      this.showedAlert = true;
      Swal.fire({
        icon: 'warning',
        text: "Tu compra actual supera el valor de la suscripción mensual, te recomendamos comprar la suscripción.",
        showCancelButton: true,
        confirmButtonColor: '#ff653f',
        confirmButtonText: 'Ir a suscripciones',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['pay-subscription']);
        }
      })
    }
    this.total = newTotal
  }
}
