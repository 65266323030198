import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareAcmService {
  
  private acm: any

  get shareAcm() {
    return this.acm
  }

  set shareAcm(obj) {
    this.acm = obj;
  }

}
