import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { MdePopoverTrigger } from "@material-extended/mde";
import { Estate } from "src/app/models/estate.model";
import { EstateService } from "src/app/services/estate.service";
import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { AdditionalsService } from "src/app/services/additionals.service";
import { ToastrService } from "ngx-toastr";
declare var jQuery: any;

@Component({
  selector: "app-property-card",
  templateUrl: "./property-card.component.html",
  styleUrls: ["./property-card.component.css"],
})
export class PropertyCardComponent implements OnInit {
  @Input() property: Estate | undefined;
  @Output() selectedProperty = new EventEmitter<Estate>();
  @Output() applyFilter = new EventEmitter();
  @ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger | undefined;
  @ViewChild("modalVerifyProperty") modalVerifyProperty: ElementRef | undefined;

  user: CognitoUserInterface | undefined;
  ingreso: number = 0;
  valormetro: number = 0;
  valor: number = 0;
  constructor(
    private estateService: EstateService,
    private router: Router,
    private elementRef: ElementRef,
    private additionalServices: AdditionalsService,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then((user) => {
      this.user = user;
      const tamanoEntero: number =(this.property?.Tamano? parseInt(this.property.Tamano.toString(), 10): 0)
      var porcentaje = 0;
      console.log(tamanoEntero)
      if(tamanoEntero  >= 150){
        porcentaje = 0.007;
     }else{
       if(tamanoEntero ==  140  || tamanoEntero == 130  || tamanoEntero == 120  || tamanoEntero == 110  || tamanoEntero == 100){
         porcentaje = 0.0064
       }else{
        if(tamanoEntero == 90  || tamanoEntero == 80  || tamanoEntero == 70  ){
          porcentaje = 0.006
        }else{
          if( tamanoEntero == 60  || tamanoEntero == 50 || tamanoEntero == 40  || tamanoEntero == 30){
            porcentaje = 0.0056
          }else{
            porcentaje = 0.005
          }
        }
       }
     }
      const valorConSigno = this.property?.Precio || "0";
      const valorNumerico = parseInt(valorConSigno.replace(/\D/g, ""), 10) || 0;
      const valorinmueble = Math.floor(valorNumerico / 100);
      const valorConSignoAdmin = this.property?.Valor_Admin || '0'; 
      const valorinmuebleAdmin = parseInt(valorConSignoAdmin.replace(/\D/g, ''), 10) || 0;
      const arriendomes = valorinmueble * porcentaje;
      const arriendoano = arriendomes * 12;
      const valorizacionano  = valorinmueble * 0.1;
      const inmobiliaria = 0.1;
      const seguro = 0.02;
      const admin = (valorinmuebleAdmin/arriendomes)
      const admin2 = admin === Infinity ? 0 : admin;
      const porcentajeGastos = inmobiliaria + seguro + admin2  + 0.02 + porcentaje;
      const gastos = arriendoano * parseFloat(porcentajeGastos.toFixed(2));
     
      const longitude = Number(this.property?.Tamano);
      this.ingreso = valorinmueble * porcentaje;
      this.valormetro = valorinmueble / longitude;
      this.valor = ((arriendoano + valorizacionano - gastos) / valorinmueble) * 100;
    });
  }

  enableDisablePopover() {
    this.trigger?.togglePopover();
    /* const valorConSigno = this.property?.Precio || '0'; 
    const valorNumerico = parseInt(valorConSigno.replace(/\D/g, ''), 10) || 0;
    this.ingreso = (valorNumerico * 0.0064) */
  }

  changeSelectedProperty() {
    this.selectedProperty.emit(this.property);
    /* const valorConSigno = this.property?.Precio || '0'; 
    const valorNumerico = parseInt(valorConSigno.replace(/\D/g, ''), 10) || 0;
    this.ingreso = (valorNumerico * 0.0064) */
  }

  openModalVerifyProperty(event: MouseEvent) {
    event.stopPropagation();

    jQuery(this.modalVerifyProperty?.nativeElement).modal("show");
  }

  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = "./assets/images/placeholder-image2.jpg";
  }

  verifyProperty(state: string) {
    Swal.fire({
      title: "Confirmación de Información",
      text: "¿Estás seguro de que la información que vas a suministrar es correcta?",
      showCancelButton: true,
      confirmButtonColor: "#fc6641",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        jQuery(this.modalVerifyProperty?.nativeElement).modal("hide");

        let email = "NOTLOGGED";
        if (this.user) {
          email = this.user["attributes"]["email"];
        }

        this.additionalServices
          .verifyProperty(
            this.property?.Origenes,
            state,
            this.property!.Operacion,
            email
          )
          .subscribe((resp: any) => {
            console.log(resp);
            if (resp?.answer === "OK") {
              this.toastr.success(
                "La propiedad se ha verificado correctamente.",
                "¡Gracias!"
              );
              this.applyFilter.emit();
            } else {
              this.toastr.error(
                "Ha ocurrido un error al verificar la propiedad. Intentalo nuevamente.",
                "Oh Oohh!"
              );
            }
          });
      }
    });
  }

  openHomty360(event: MouseEvent) {
    event.stopPropagation();

    Auth.currentUserInfo().then((user) => {
     
        const id = this.property?.id;
        this.router.navigate(["/property360"], { queryParams: { id } });
     
    });
  }

  updateFavorite(value: string, propertyId: string) {
    Auth.currentSession().then(
      (resp) => {
        Auth.currentUserInfo().then(
          (user) => {
            this.user = user;
            if (this.user && this.property != undefined) {
              this.property.Favorito = value;
              this.estateService
                .updateFavorite(
                  this.user!["attributes"]["email"],
                  propertyId,
                  value
                )
                .subscribe((resp) => {});
            } else {
              let terms = Swal.fire({
                title:
                  "Registrate para que agregues propiedades a tus favoritos",
                showCancelButton: true,
                confirmButtonColor: "#fc6641",
                confirmButtonText: "Registarme",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(["login", "signUp"]);
                }
              });
            }
          },
          (error) => {
            console.log(error);
            let terms = Swal.fire({
              title: "Registrate para que agregues propiedades a tus favoritos",
              showCancelButton: true,
              confirmButtonColor: "#fc6641",
              confirmButtonText: "Registarme",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(["login", "signUp"]);
              }
            });
          }
        );
      },
      (error) => {
        console.log(error);
        let terms = Swal.fire({
          title: "Registrate para que agregues propiedades a tus favoritos",
          showCancelButton: true,
          confirmButtonColor: "#fc6641",
          confirmButtonText: "Registarme",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(["login", "signUp"]);
          }
        });
      }
    );
  }
}
