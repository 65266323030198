
<div class="container">
  <!-- Pendiente de aprobación-->
  <div>
    <!--{{getPaymentState(authenticator.user.attributes!.email)}}-->
    <div *ngIf="ordenStatus == 'pending'" class="payment-info animate__animated animate__fadeIn">
      <div class="billing-fields">
        <div class="checkout-title text-center">
          <h3>Pago pendiente por aprobación</h3>
          <h3>{{email}}</h3>
          <p>{{order?.DATA?.CREATION_DATE | date:'medium'}}</p>
          <img class="loading" src="assets/images/icons/loading.png" alt="">
        </div>
        <div class="alert alert-warning d-flex align-items-center" role="alert">
          <fa-icon class="fa-2x" [icon]="faExclamationCircle"></fa-icon>

          <div class="ml-10 mt-2">
            Tu pago esta siendo procesado, puedes salir de esta pantalla y una vez el pago sea confirmado te
            notificaremos,
            de lo contrario espera a que confirmemos tu pago!
          </div>
        </div>

        <div class="form-content-box">
          <div class="d-flex justify-content-between">
            <div>
              <h3>Monto total</h3>
            </div>
            <div>
              <h3>{{order?.DATA?.AMOUNT | currency}}</h3>
            </div>
          </div>
          <hr>
          <h4>Información adicional</h4>
          <div class="d-flex justify-content-between">
            <div>
              <p>Tipo de transacción</p>
            </div>
            <div>
              <h6>{{order?.DATA?.DESCRIPTION}}</h6>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <p>Costo de la transacción</p>
            </div>
            <div>
              <h6>{{'0' | currency}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Aprobado / Rechazado-->
    <div *ngIf="ordenStatus != '' && ordenStatus != 'pending'" class="payment-info animate__animated animate__fadeIn mt-2">
      <div class="billing-fields">
        <div *ngIf="ordenStatus == 'approved'" class="checkout-title text-center">
          <h3>¡Tu pago ha sido aprobado!</h3>
          <h4>De inmediato empezaremos a contactar los propietarios de las propiedades filtradas para que te contacten.</h4>
          <img src="./assets/images/icons/call.gif" alt="Call Animation" style="width: 100px">
          <hr>
          <p>{{order?.DATA?.CREATION_DATE | date:'medium'}}</p>
          <img src="./assets/images/icons/aproved.png" alt="">
        </div>
        <div *ngIf="ordenStatus == 'cancelled'" class="checkout-title text-center">
          <h3>Tu pago ha sido rechazado</h3>
          <p>{{order?.DATA?.CREATION_DATE | date:'medium'}}</p>
          <img src="./assets/images/icons/rejected.png" alt="">
        </div>

        <div class="form-content-box">

          <div class="d-flex justify-content-between">
            <div>
              <h3>Monto total</h3>
            </div>
            <div>
              <h3>{{order?.DATA?.AMOUNT | currency}}</h3>
            </div>
          </div>
          <hr>
          <h4>Detalle del pago</h4>
          <div class="d-flex justify-content-between">
            <div>
              <p>{{order?.DATA?.PAYMNENT_METHOD}}</p>
            </div>
            <div>
              <h6>{{order?.DATA?.AMOUNT | currency}}</h6>
            </div>
          </div>
          <hr>
          <h4>Información adicional</h4>
          <div class="d-flex justify-content-between">
            <div>
              <p>Tipo de transacción</p>
            </div>
            <div>
              <h6>{{order?.DATA?.DESCRIPTION}}</h6>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <p>Método de pago</p>
            </div>
            <div>
              <h6>{{order?.DATA?.PAYMNENT_METHOD}}</h6>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <p>Costo de la transacción</p>
            </div>
            <div>
              <h6>{{'0' | currency}}</h6>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="row">

              <div class="col">
                <a class="submit-btn" [routerLink]="['/']">Ir a buscar propiedades</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


</div>