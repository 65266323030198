import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { faCheck, faGift, faBuilding } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor( private title: Title, private meta: Meta) { }

  faCheck = faCheck;
  faGift = faGift;
  faBuilding = faBuilding;

  ngOnInit(): void {
    this.title.setTitle(`Homty - Precios`);
    this.meta.addTag({ name: 'description', content: 'Homty - Precios, selecciona un plan adecuado para ti'});
    this.meta.addTag({ name: 'keywords', content: 'homty, inmobiliario, suscripciones, paquetes, pagos, planes, inscripcion, servicios'});
  }

}

