import { Routes, RouterModule } from "@angular/router";
import { NgModule, OnInit } from "@angular/core";

import { PagesComponent } from "./pages.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "",
    component: PagesComponent,
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "contactenos",
        loadChildren: () =>
          import("./contact/contact.module").then((m) => m.ContactModule),
      },
      {
        path: "precios",
        loadChildren: () =>
          import("./pricing/pricing.module").then((m) => m.PricingModule),
      },
      {
        path: "blog",
        loadChildren: () =>
          import("./blog/blog.module").then((m) => m.BlogModule),
      },
      {
        path: "blog/:blogTitle",
        loadChildren: () =>
          import("./blog/blog.module").then((m) => m.BlogModule),
      },
      {
        path: "login/:initialState",
        loadChildren: () =>
          import("./login/login.module").then((m) => m.LoginModule),
      },
      {
        path: "perfil",
        loadChildren: () =>
          import("./profile/profile.module").then((m) => m.ProfileModule),
      },
      {
        path: "favoritos",
        loadChildren: () =>
          import("./profile/favorites/favorites.module").then(
            (m) => m.FavoritesModule
          ),
      },
      {
        path: "checkout",
        loadChildren: () =>
          import("./pricing/checkout/checkout.module").then(
            (m) => m.CheckoutModule
          ),
      },
      {
        path: "payment-result",
        loadChildren: () =>
          import("./pricing/payment-result/payment-result.module").then(
            (m) => m.PaymentResultModule
          ),
      },
      {
        path: "pay-package",
        loadChildren: () =>
          import("./pricing/pay-package/pay-package.module").then(
            (m) => m.PayPackageModule
          ),
      },
      {
        path: "pay-subscription",
        loadChildren: () =>
          import("./pricing/pay-subscription/pay-subscription.module").then(
            (m) => m.PaySubscriptionModule
          ),
      },
      {
        path: "propiedades",
        loadChildren: () =>
          import("./estate/estate.module").then((m) => m.EstateModule),
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./inventory/inventory.module").then((m) => m.InventoryModule),
      },
      {
        path: "inventory/crear",
        loadChildren: () =>
          import("./inventory/inventory-form/inventory-form.module").then(
            (m) => m.InventoryFormModule
          ),
      },
      {
        path: "propiedad",
        loadChildren: () =>
          import("./inventory/get-property/get-property.module").then(
            (m) => m.GetPropertyModule
          ),
      },
      {
        path: "homty360",
        loadChildren: () =>
          import("./homty360/homty360.module").then((m) => m.Homty360Module),
      },
      {
        path: "property360",
        loadChildren: () =>
          import("./property360/property360.module").then((m) => m.Property360Module),
      },
      {
        path: "avaluo",
        loadChildren: () =>
          import("./appraisal/appraisal.module").then((m) => m.AppraisalModule),
      },
      {
        path: "acp",
        loadChildren: () =>
          import("./appraisal/acp/acp.module").then((m) => m.AcpModule),
      },
      {
        path: "statistics/:tab",
        loadChildren: () =>
          import("./statistics/statistics.module").then(
            (m) => m.StatisticsModule
          ),
      },
      {
        path: "acms-history",
        loadChildren: () =>
          import("./statistics/acms-history/acms-history.module").then(
            (m) => m.AcmsHistoryModule
          ),
      },
      {
        path: "property-statistics",
        loadChildren: () =>
          import("./statistics/property-statistics/property-statistics.module").then(
            (m) => m.PropertyStatisticsModule
          ),
      },
      {
        path: "descriptions",
        loadChildren: () =>
          import("./descriptions/descriptions.module").then(
            (m) => m.DescriptionsModule
          ),
      },
      {
        path: "owners",
        loadChildren: () =>
          import("./owners/owners.module").then((m) => m.OwnersModule),
      },
      {
        path: "contact-owners",
        loadChildren: () =>
          import("./owners/contact-owners/contact-owners.module").then(
            (m) => m.ContactOwnersModule
          ),
      },
      {
        path: "confirm-owners-contact",
        loadChildren: () =>
          import("./owners/confirm-owners-contact/confirm-owners-contact.module").then(
            (m) => m.ConfirmOwnersContactModule
          ),
      },
      {
        path: "payment-result-owners",
        loadChildren: () =>
          import("./owners/payment-result-owners/payment-result-owners.module").then(
            (m) => m.PaymentResultOwnersModule
          ),
      },
      {
        path: "users-admin",
        loadChildren: () =>
          import("./agencies/users-admin/users-admin.module").then(
            (m) => m.UsersAdminModule
          ),
      },
      {
        path: "change-password",
        loadChildren: () =>
          import("./change-password/change-password.module").then(
            (m) => m.ChangePasswordModule
          ),
      },
      // {
      //   path: "notifications",
      //   loadChildren: () =>
      //     import("./notifications/notifications.module").then(
      //       (m) => m.NotificationsModule
      //     ),
      // }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {

}

