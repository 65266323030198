<h2 class="title mt-4 mb-3">CERCANÍAS</h2>

<section>
  
  <div id="accordion" class="myaccordion mb-4 w-100">

    <div class="card" *ngFor="let item of dataCercanias; let i = index">

      <div class="card-header p-0" [id]="'heading' + i">
        <h2 class="mb-0">
          <button class="d-flex py-3 px-4 align-items-center justify-content-between btn-accordion collapsed"
            [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false"
            [attr.aria-controls]="'collapse' + i">
            <div class="heading d-flex align-items-center">
              <div class="mb-0 ml-3 check-item">
                <input class="form-check-input" type="checkbox" [id]="'chkAccordion' + i" [(ngModel)]="item.checked"
                  (change)="onCheckboxChange(i)">
              </div>
              <div class="rounded-icon d-flex align-items-center justify-content-center"
                [style.background]="item.color">
                <i [class]="item.icon" class="fas fa-dollar-sign"></i>
              </div>
              <h3 class="mb-0 ml-3">{{ item.title }}</h3>
            </div>
            <p class="mb-0 num">{{ item.items ? item.items.length : '0' }}</p>
          </button>
        </h2>
      </div>

      <div [id]="'collapse' + i" class="collapse" [attr.aria-labelledby]="'heading' + i" data-parent="#accordion">
        <div class="card-body p-0">
          <ul>
            <li *ngFor="let subitem of item.items; let j = index">
              <a class="d-flex justify-content-between align-items-center">
                <p><span>{{ j+1 }}</span> {{ subitem['nombre'] }}</p>
                <span class="num">{{ subitem['direccion'] }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>

</section>