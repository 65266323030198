import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import Swal from 'sweetalert2';
import { Title, Meta } from '@angular/platform-browser';

const DATOS_CIUDADES_PARA_INVERTIR_TABLA1: any = [
  {municipio: 'Bogotá', estrato_3: 14.662, estrato_4: 21.001, estrato_5: 12.872, estrato_6: 24.248, total: 72.783},
  {municipio: 'Cali', estrato_3: 946, estrato_4: 2.882, estrato_5: 1.953, estrato_6: 2.657, total: 8.438},
  {municipio: 'Medellín', estrato_3: 3.541, estrato_4: 5.079, estrato_5: 5.839, estrato_6: 8.021, total: 22.480}
];

const DATOS_CIUDADES_PARA_INVERTIR_TABLA2: any = [
  {municipio: 'Bogotá', prr_promedio: 17.3, prr_mediana: 16.8},
  {municipio: 'Cali', prr_promedio: 16.4, prr_mediana: 16.1}
]

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  blogs: string[] = ['', 'WeWork', 'Proptech2022', 'ACM-Inmobiliario', 'Ciudades-para-invertir-en-Colombia', 'anuncios-inmobiliarios-en-elecciones'];
  currentRoute: string = '';

  /* --------------- TABLAS - CIUDADES PARA INVERTIR EN COLOMBIA --------------- */
  displayedColumnsTableCities1: string[] = ['municipio', 'estrato_3', 'estrato_4', 'estrato_5', 'estrato_6', 'total'];
  dataTableCities1 = DATOS_CIUDADES_PARA_INVERTIR_TABLA1;

  displayedColumnsTableCities2: string[] = ['municipio', 'prr_promedio', 'prr_mediana'];
  dataTableCities2 = DATOS_CIUDADES_PARA_INVERTIR_TABLA2;
  
  authState: AuthState | undefined;
  @Input() userCog: CognitoUserInterface | undefined;

  constructor(private zone: NgZone, private router: Router, private route: ActivatedRoute, public authenticator: AuthenticatorService, private title: Title, private meta: Meta) { 
    this.route.paramMap.subscribe((paramMap:any) => {
      const {params} = paramMap;
      this.currentRoute = params.blogTitle;
    })
  }

  ngOnInit(): void {
    if (this.currentRoute && this.currentRoute != '') {
      this.title.setTitle(`Homty Blog - ${this.currentRoute}`);
    } else {
      this.title.setTitle('Homty - Blog inmobiliario');
    }
    this.meta.addTag({ name: 'description', content: 'Homty blog inmobiliario, proptech 2022, acm inmobiliario, ciudades para invertir, anuncios inmobiliarios'});
    this.meta.addTag({ name: 'keywords', content: 'homty, inmobiliario, propiedades, blog, blog inmobiliario, informacion, info comercial, informacion comercial, anuncios inmobiliarios'});
  }

  goToAvaluo() {
    Auth.currentUserInfo().then(user => {
      this.userCog = user
      
      if (this.userCog) {
        this.router.navigate(['/avaluo']);
      } else {
        let terms = Swal.fire({
          title: 'Para utilizar este servicio debe iniciar sesión',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Iniciar Sesión',
          cancelButtonText: 'Cancelar',

        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['login', 'signIn']);
          }
        })
      }
    });
  }

}
