import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryModel } from '../../models/inventory.model';
import { InventoryService } from '../../services/inventory.service'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth } from "aws-amplify";
import { SharingService } from 'src/app/services/sharing.service';
import Swal from 'sweetalert2';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { AdditionalsService } from 'src/app/services/additionals.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  user: CognitoUserInterface | undefined;

  displayedColumns: string[] = ['id', 'city', 'status', 'verified', 'estateType', 'operationType', 'price', 'admin', 'url', 'edit']
  dataSource!: MatTableDataSource<InventoryModel>

  length: number | undefined
  pageSize: number = 25
  pageIndex: number = 0
  pageSizeOptions: number[] = [5, 10, 25, 100]
  loading: boolean = true;
  faCircle = faCircle
  faShare = faShare
  packagePlanInfo: any | undefined;

  constructor(private inventoryService: InventoryService, private additionalsService: AdditionalsService, private router: Router, private sharingService: SharingService, private title: Title, private meta: Meta) { }

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  ngOnInit(): void {
    this.getInvetory()
    //SEO
    this.title.setTitle(`Homty - Mi Inventario`);
    this.meta.addTag({ name: 'description', content: 'Homty - Mi Inventario, administrar mis propiedades'});
    this.meta.addTag({ name: 'keywords', content: 'homty, inmobiliario, propiedades, inventario, casas, apartamentos, bodegas, arriendo, venta'});

  }

  getInvetory() {
    Auth.currentUserInfo().then(user => {
      this.user = user;
      this.additionalsService.getNumberAcmsProperties(user["attributes"]["email"]).subscribe(resp => {
        let respJson= JSON.parse(resp.item);
        this.packagePlanInfo = respJson;
      })
      this.inventoryService.getInventory(this.pageSize.toString(), (this.pageIndex + 1).toString(), this.user!["attributes"]["email"]).subscribe(resp => {
        if (resp.items.length == 0) {
          this.dataSource = new MatTableDataSource()
          this.length = 0
        } else {
          this.length = resp.items.length
          this.dataSource = new MatTableDataSource(resp.items)
        }
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.loading = false
      }, error => {
        this.dataSource = new MatTableDataSource()
        this.loading = false
      })
    })
  }

  edit(element: InventoryModel) {
    this.sharingService.sharingValue = element;
    this.router.navigate(['/inventory/crear'],
      {
        queryParams: {
          id: element.id,
        },
        state: {
          currentElement: element
        }
      },
    );
  }

  delete(element: InventoryModel) {
    let terms = Swal.fire({
      title: '¿Estas seguro que deseas eliminar este inmueble?',
      showCancelButton: true,
      confirmButtonColor: '#ff653f',
      cancelButtonColor: '#999999',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true
        this.inventoryService.deleteProperty(element.id, element.Operacion, element.nombre_usuario).subscribe(resp => {
          this.loading = false
          Swal.fire({
            confirmButtonColor: '#ff653f',
            title: 'Se ha eliminado el inmueble correctamente',
            icon: 'success',
          },

          )
          this.getInvetory()
        })
      }
    })
  }

  crearInmueble() {
    if (this.dataSource.data.length > 50) {
      let terms = Swal.fire({
        title: 'Solo puedes crear 50 inmuebles',
        showCancelButton: false,
        confirmButtonColor: '#ff653f',
        confirmButtonText: 'Ok',

      })
    } else {
      this.router.navigate(['/inventory/crear']);
    }
  }

  openUrl(element: any) {
    window.open("https://www.homty.co/propiedad?id=" + element.id);
  }


}
