<div class="container d-flex justify-content-center align-items-center mt-5">
  <div class="card col-sm-12 col-md-8 col-lg-6 p-3">
    <h3 class="card-title">Restablecer contraseña</h3>
    <div class="card-body" [formGroup]="resetPasswordForm">
      <div class="alert alert-danger" role="alert" *ngIf="alertVisible">
        {{textAlert}}
      </div>
      <div class="form-group mb-2">
        <label for="currentPassword">Contraseña actual:</label>
        <input class="form-control" type="password" id="currentPassword" name="currentPassword"
          placeholder="Ingresa la contraseña actual" formControlName="currentPassword" required>
          <div *ngIf="resetPasswordForm.controls['currentPassword'].invalid && resetPasswordForm.controls['currentPassword'].touched"
          class="text-danger">
          La contraseña actual es requerida.
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="newPassword">Nueva contraseña:</label>
        <div class="input-group">
          <input class="form-control" type="{{ newPasswordVisible ? 'text' : 'password' }}" id="newPassword"
            name="newPassword" placeholder="Ingresa la nueva contraseña" formControlName="newPassword" required>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="togglePasswordVisibility('newPassword')">
              <span *ngIf="!newPasswordVisible"><i class="fa-solid fa-eye"></i></span>
              <span *ngIf="newPasswordVisible"><i class="fa-solid fa-eye-slash"></i></span>
            </button>
          </div>
        </div>
        <div *ngIf="resetPasswordForm.controls['newPassword'].invalid && resetPasswordForm.controls['newPassword'].touched"
          class="text-danger">
          La nueva contraseña es requerida
        </div>
        <div *ngIf="resetPasswordForm.get('newPassword')?.hasError('minlength')" class="text-danger">
          La nueva contraseña debe tener al menos 6 caracteres.
        </div>
        <div *ngIf="resetPasswordForm.get('newPassword')?.hasError('pattern')" class="text-danger">
          La nueva contraseña debe contener al menos un número.
        </div>
      </div>
      <div class="form-group mb-3">
        <label for="confirmPassword">Confirmar nueva contraseña:</label>
        <div class="input-group">
          <input class="form-control" type="{{ confirmPasswordVisible ? 'text' : 'password' }}" id="confirmPassword"
            name="confirmPassword" placeholder="Confirma la nueva contraseña" formControlName="confirmPassword"
            required>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button"
              (click)="togglePasswordVisibility('confirmPassword')">
              <span *ngIf="!confirmPasswordVisible"><i class="fa-solid fa-eye"></i></span>
              <span *ngIf="confirmPasswordVisible"><i class="fa-solid fa-eye-slash"></i></span>
            </button>
          </div>
        </div>
        <div *ngIf="resetPasswordForm.controls['confirmPassword'].invalid && resetPasswordForm.controls['confirmPassword'].touched"
          class="text-danger">
          Confirma la nueva contraseña ingresada.
        </div>
        <div *ngIf="resetPasswordForm.value.newPassword !== resetPasswordForm.value.confirmPassword && resetPasswordForm.controls['confirmPassword'].touched"
          class="text-danger">
          Las contraseñas nuevas ingresadas no coinciden.
        </div>
      </div>
      <button class="btn btn-confirm btn-primary" [disabled]="resetPasswordForm.invalid || resetPasswordForm.pristine"
        (click)="confirmResetPassword()">Confirmar</button>
    </div>
  </div>
</div>