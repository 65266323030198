<div class="row news-row">
  <span class="news text-center">
    <!-- ¡Ahora puedes ayudar a la comunidad Homty a tener la información más actualizada verificando propiedades que ya no estén disponibles! &#10071;&#10071; -->
    <!-- ¡Próximo lanzamiento! Módulo para cargar tus propiedades -->
    <!-- Estamos presentando problemas técnicos y trabajando en ello, agradecemos su
    paciencia. -->
    Ingresa a el análisis de cada propiedad y encuentra las mejores
    oportunidades de inversión
  </span>
</div>

<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <!-- <div class="navb-logo">
      <img src="assets/images/homty-logo.png" alt="Logo">
    </div> -->
    <a routerLink="/">
      <picture>
        <source srcset="assets/images/homty-logo.webp" type="image/webp" />
        <source srcset="assets/images/homty-logo.png" type="image/png" />
        <img class="logo" src="assets/images/homty-logo.webp" alt="" />
      </picture>
    </a>

    <div class="col-1"></div>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
        <li class="nav-item mr-10">
          <a
            class="nav-link underline"
            routerLink="/home"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="active"
            >Inicio</a
          >
        </li>

        <li
          class="nav-item dropdown mr-10"
          *ngIf="authenticator.route === 'authenticated'"
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Servicios
          </a>
          <ul class="dropdown-menu">
            <!-- <li *ngIf="authenticator.route === 'authenticated'">
              <a
                class="dropdown-item"
                (click)="goToAvaluo()"
                routerLinkActive="active"
                >ACMs</a
              >
            </li> -->
            <li *ngIf="authenticator.route === 'authenticated'">
              <a
                class="dropdown-item"
                (click)="goToSearch()"
                routerLink="propiedades"
                routerLinkActive="active"
                >Búsqueda</a
              >
            </li>
            <!-- <li>
              <a
                class="dropdown-item"
                routerLink="owners"
                routerLinkActive="active"
                >Contactar Propietarios</a
              >
            </li> -->
            <!-- <li>
              <a
                class="dropdown-item"
                routerLink="descriptions"
                routerLinkActive="active"
                >Descripciones</a
              >
            </li> -->
            <!-- <li>
              <a
                class="dropdown-item"
                target="_blank"
                href="https://app.holadora.co/la-investigadora/2-astnobx60-homty-sasnone/nuevo-estudio/"
                >Estudio de arrendamiento</a
              >
            </li> -->
          </ul>
        </li>

        <li class="nav-item mr-10">
          <a
            class="nav-link underline"
            routerLink="pay-subscription"
            routerLinkActive="active"
            >Precios</a
          >
        </li>

        <li class="nav-item mr-10">
          <a
            class="nav-link underline"
            routerLink="blog"
            routerLinkActive="active"
            >Blog</a
          >
        </li>

        <li class="nav-item mr-10">
          <a
            class="nav-link underline"
            href="https://t.me/+oZScRZLvOdZhNjdh"
            target="_blank"
            >Academy</a
          >
        </li>

        <li class="nav-item mr-10">
          <a
            class="nav-link underline"
            href="https://wa.link/324ikq"
            target="_blank"
            >Contacto</a
          >
          <!-- routerLink="contactenos"
            routerLinkActive="active" -->
        </li>

        <li
          class="nav-item mr-10"
          *ngIf="authenticator.route !== 'authenticated'"
        >
          <a class="nav-link btn-signin" [routerLink]="['login', 'signIn']"
            >Ingresar</a
          >
        </li>

        <li
          class="nav-item mr-10"
          [routerLink]="['login', 'signUp']"
          *ngIf="authenticator.route !== 'authenticated'"
        >
          <button type="button" class="btn btn-light">Registro</button>
        </li>

        <li
          class="nav-item btn-group pull-right top-head-dropdown mr-10"
          *ngIf="authenticator.route === 'authenticated'"
        >
          <!-- <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            Notification <span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right"> -->
          <a
            *ngIf="websocketService.loading; &quot;dropdown-toggle&quot;"
            class="nav-link"
            role="button"
          >
            <i class="fas fa-spinner fa-spin" style="font-size: 1.3rem"></i>
          </a>
          <a
            *ngIf="!websocketService.loading"
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            (click)="hideBadgeNotification()"
          >
            <span
              *ngIf="
                websocketService.received.length -
                  websocketService.viewedNotifications.length !=
                  0 && websocketService.showBadgeNotifications
              "
              class="badge rounded-pill badge-notification bg-danger"
              >{{
                websocketService.received.length -
                  websocketService.viewedNotifications.length <
                10
                  ? websocketService.received.length -
                    websocketService.viewedNotifications.length
                  : "9+"
              }}</span
            >
            <i class="fas fa-bell" style="font-size: 1.3rem"></i>
          </a>

          <ul class="dropdown-menu dropdown-menu-right">
            <li class="toolbar">
              <div class="col-8 item-toolbar dropdown-toolbar-title">
                Notificaciones
                <span
                  >({{
                    websocketService.received.length -
                      websocketService.viewedNotifications.length
                  }})</span
                >
              </div>
              <div
                class="col-4 item-toolbar show-all"
                data-bs-toggle="modal"
                data-bs-target="#modalNotifications"
                *ngIf="websocketService.received.length != 0"
              >
                Ver todas
              </div>
            </li>

            <div
              *ngIf="websocketService.received.length == 0"
              class="text-center"
            >
              Sin Notificaciones
              <i
                style="font-size: 1.3rem; color: #aaa"
                class="fa-solid fa-circle-xmark"
              ></i>
            </div>

            <li
              class="top-text-block"
              *ngFor="
                let notification of websocketService.received
                  .slice()
                  .reverse()
                  .slice(0, 5)
              "
              (click)="openNotification(notification)"
            >
              <div class="top-text-heading">
                <span
                  *ngIf="
                    !websocketService.viewedNotifications.includes(
                      notification.id
                    )
                  "
                >
                  <i
                    class="fa-solid fa-chevron-right"
                    style="
                      color: #ff653f;
                      font-size: 1rem;
                      padding: 6px 12px 0 0;
                    "
                  ></i>
                </span>
                <strong>{{ notification.title }}</strong>
              </div>
              <div
                class="top-text-heading"
                *ngIf="notification.message.length <= 30; else truncado"
              >
                {{ notification.message }}
              </div>
              <ng-template class="top-text-heading" #truncado
                >{{ notification.message.substr(0, 30) }} ...</ng-template
              >
              <div class="top-text-light">
                {{ notification.date }} &nbsp;
                <span style="font-size: 1.2rem">
                  <i
                    *ngIf="notification.type === 'success'"
                    style="color: #009417"
                    class="fa-solid fa-check"
                  ></i>
                  <i
                    *ngIf="notification.type === 'info'"
                    style="color: #aaa"
                    class="fa-solid fa-circle-info"
                  ></i>
                  <i
                    *ngIf="notification.type === 'warning'"
                    style="color: #f9c801"
                    class="fa-solid fa-triangle-exclamation"
                  ></i>
                  <i
                    *ngIf="notification.type === 'error'"
                    style="color: #ee0000"
                    class="fa-solid fa-xmark"
                  ></i>
                </span>
              </div>
            </li>
          </ul>
        </li>

        <li
          *ngIf="authenticator.route === 'authenticated'"
          class="nav-item mr-10"
          (click)="toggleProfileMenu()"
          id="profileMenuToggle"
        >
          <a class="dropdown-toggle" type="button">
            <picture>
              <source
                srcset="assets/images/profile/profile-user.webp"
                type="image/webp"
              />
              <source
                srcset="assets/images/profile/profile-user.png"
                type="image/png"
              />
              <img
                src="assets/images/profile/profile-user.webp"
                alt="Avatar"
                class="avatar"
              />
            </picture>
          </a>
        </li>

        <div
          class="nav-item mr-10"
          *ngIf="authenticator.route === 'authenticated'"
          class="submenu-wrap"
          id="submenu-wrap"
        >
          <div class="submenu">
            <div class="user-info">
              <picture>
                <source
                  srcset="assets/images/profile/profile-user.webp"
                  type="image/webp"
                />
                <source
                  srcset="assets/images/profile/profile-user.png"
                  type="image/png"
                />
                <img
                  src="assets/images/profile/profile-user.webp"
                  alt="Avatar"
                  class="avatar"
                />
              </picture>
              <h2>{{ authenticator.user.attributes?.family_name }}</h2>
            </div>
            <hr />

            <a
              (click)="toggleProfileMenu(); goToProfile()"
              class="sub-menu-link"
            >
              <i class="fa-solid fa-user"></i>
              <div class="label">Mi Perfil</div>
              <span
                ><i
                  class="fa-solid fa-chevron-right"
                  style="font-size: 1rem"
                ></i
              ></span>
            </a>
            <a
              *ngIf="
                authenticator.user.attributes?.nickname ==
                'Agencia Inmobiliaria'
              "
              (click)="toggleProfileMenu(); goToUsersAdmin()"
              class="sub-menu-link"
            >
              <i class="fa-solid fa-users"></i>
              <div class="label">Administrar Usuarios</div>
              <span
                ><i
                  class="fa-solid fa-chevron-right"
                  style="font-size: 1rem"
                ></i
              ></span>
            </a>
            <a
              (click)="toggleProfileMenu(); goToInventory()"
              class="sub-menu-link"
            >
              <i class="fa-solid fa-boxes-stacked"></i>
              <div class="label">Mi Inventario</div>
              <span
                ><i
                  class="fa-solid fa-chevron-right"
                  style="font-size: 1rem"
                ></i
              ></span>
            </a>
            <a
              (click)="toggleProfileMenu(); goToFavorites()"
              class="sub-menu-link"
            >
              <i class="fa-solid fa-heart"></i>
              <div class="label">Mis Favoritos</div>
              <span
                ><i
                  class="fa-solid fa-chevron-right"
                  style="font-size: 1rem"
                ></i
              ></span>
            </a>
            <a
              (click)="toggleProfileMenu(); goToStatistics()"
              class="sub-menu-link"
            >
              <i class="fa-solid fa-chart-simple"></i>
              <div class="label">Estadisticas</div>
              <span
                ><i
                  class="fa-solid fa-chevron-right"
                  style="font-size: 1rem"
                ></i
              ></span>
            </a>
            <a (click)="toggleProfileMenu(); signOut()" class="sub-menu-link">
              <i class="fa-solid fa-right-from-bracket"></i>
              <div class="label">Cerrar Sesión</div>
              <span
                ><i
                  class="fa-solid fa-chevron-right"
                  style="font-size: 1rem"
                ></i
              ></span>
            </a>
          </div>
        </div>
      </ul>
    </div>

    <!-- Button trigger modal -->
    <div class="mobile-toggler d-lg-none">
      <button
        class="navbar-toggler"
        id="btn-modal-mobile"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#navbModal"
      >
        <i class="fa-solid fa-bars" style="color: #fff; font-size: 1.6rem"></i>
      </button>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="navbModal"
      tabindex="-1"
      aria-labelledby="navbModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog navb">
        <div class="modal-content">
          <div class="modal-header">
            <picture>
              <source
                srcset="assets/images/homty-logo.webp"
                type="image/webp"
              />
              <source srcset="assets/images/homty-logo.png" type="image/png" />
              <img src="assets/images/homty-logo.webp" alt="Logo" />
            </picture>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i
                class="fa-solid fa-xmark"
                style="color: white; font-size: 1.4rem"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <div
              class="modal-line"
              (click)="closeSidebar()"
              routerLink="/"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active"
            >
              <i class="fa-solid fa-house"></i><a>&nbsp; Inicio</a>
            </div>

            <div class="modal-line dropdown">
              <i class="fa-solid fa-list-check"></i>
              <a
                class="dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                &nbsp; Servicios
              </a>
              <ul class="dropdown-menu">
                <li
                  *ngIf="authenticator.route === 'authenticated'"
                  (click)="closeSidebar()"
                >
                  <a
                    class="dropdown-item"
                    (click)="goToAvaluo()"
                    routerLinkActive="active"
                    >ACMs</a
                  >
                </li>
                <li
                  *ngIf="authenticator.route === 'authenticated'"
                  (click)="closeSidebar()"
                >
                  <a
                    class="dropdown-item"
                    (click)="goToSearch()"
                    routerLinkActive="active"
                    >Búsqueda</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="closeSidebar()"
                    routerLink="owners"
                    routerLinkActive="active"
                    >Contactar Propietarios</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="closeSidebar()"
                    routerLink="descriptions"
                    routerLinkActive="active"
                    >Descripciones</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="closeSidebar()"
                    target="_blank"
                    href="https://app.holadora.co/la-investigadora/2-astnobx60-homty-sasnone/nuevo-estudio/"
                    >Estudio de arrendamiento</a
                  >
                </li>
              </ul>
            </div>

            <div
              class="modal-line"
              (click)="closeSidebar()"
              routerLink="pay-subscription"
            >
              <i class="fa-solid fa-dollar-sign"></i>
              <a routerLinkActive="active">&nbsp; Precios</a>
            </div>

            <div class="modal-line" (click)="closeSidebar()" routerLink="blog">
              <i class="fa-solid fa-circle-info"></i
              ><a routerLinkActive="active">&nbsp; Blog</a>
            </div>

            <div
              class="modal-line"
              (click)="closeSidebar()"
              routerLink="contactenos"
            >
              <i class="fa-solid fa-headset"></i
              ><a routerLinkActive="active">&nbsp; Contacto</a>
            </div>

            <hr
              class="border border-2 border-secundary opacity-50"
              style="color: white"
            />

            <div
              *ngIf="authenticator.route === 'authenticated'"
              class="modal-line dropdown"
            >
              <i class="fa-solid fa-user"></i>
              <a
                class="dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                &nbsp; {{ authenticator.user.attributes?.family_name }}
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="closeSidebar(); goToProfile()"
                    >Mi Perfil</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="closeSidebar(); goToInventory()"
                    >Mi Inventario</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="closeSidebar(); goToFavorites()"
                    >Mis Favoritos</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    (click)="closeSidebar(); goToStatistics()"
                    >Estadisticas</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" (click)="closeSidebar(); signOut()"
                    ><i class="fa-solid fa-right-from-bracket"></i>&nbsp; Cerrar
                    Sesión</a
                  >
                </li>
              </ul>
            </div>

            <div
              class="modal-line"
              *ngIf="authenticator.route === 'authenticated'"
              (click)="closeSidebar()"
              data-bs-toggle="modal"
              data-bs-target="#modalNotifications"
            >
              <i class="fa-solid fa-bell"></i>
              <a>&nbsp; Notificaciones</a>
            </div>

            <a
              (click)="closeSidebar()"
              [routerLink]="['login', 'signIn']"
              *ngIf="authenticator.route !== 'authenticated'"
              class="navb-button-signin"
              type="button"
              >Ingresar</a
            >
            <a
              (click)="closeSidebar()"
              [routerLink]="['login', 'signUp']"
              *ngIf="authenticator.route !== 'authenticated'"
              class="navb-button-signup"
              type="button"
              >Registrar</a
            >
          </div>

          <div class="mobile-modal-footer">
            <a target="_blank" href="https://www.instagram.com/homty__"
              ><i class="fa-brands fa-instagram"></i
            ></a>
            <a target="_blank" href="https://t.me/+oZScRZLvOdZhNjdh"
              ><i class="fa-brands fa-telegram"></i
            ></a>
            <a target="_blank" href="https://www.youtube.com/@homtyacademy6732"
              ><i class="fa-brands fa-youtube"></i
            ></a>
            <a target="_blank" href="https://wa.me/573054064684"
              ><i class="fa-brands fa-whatsapp"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- ------------------------- Modal notifications ------------------------- -->
<div
  class="modal fade"
  #modalNotifications
  id="modalNotifications"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header notifications">
        <h5 class="modal-title dropdown-toolbar-title" id="exampleModalLabel">
          Notificaciones
        </h5>
        <div class="subtitle">
          <span>Total: {{ websocketService.received.length }}</span>
          <span
            >Sin leer:
            {{
              websocketService.received.length -
                websocketService.viewedNotifications.length
            }}</span
          >
        </div>
        <button class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i style="font-size: 1.3rem" class="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="websocketService.received.length == 0" class="text-center">
          Sin Notificaciones
          <i
            style="font-size: 1.3rem; color: #aaa"
            class="fa-solid fa-circle-xmark"
          ></i>
        </div>
        <ul>
          <li
            class="top-text-block"
            *ngFor="
              let notification of websocketService.received.slice().reverse()
            "
            (click)="openNotification(notification)"
          >
            <div class="top-text-heading">
              <span
                *ngIf="
                  !websocketService.viewedNotifications.includes(
                    notification.id
                  )
                "
              >
                <i
                  class="fa-solid fa-chevron-right"
                  style="color: #ff653f; font-size: 1rem; padding: 6px 12px 0 0"
                ></i>
              </span>
              <strong>{{ notification.title }}</strong>
            </div>
            <div
              class="top-text-heading"
              *ngIf="notification.message.length <= 50; else truncado"
            >
              {{ notification.message }}
            </div>
            <ng-template class="top-text-heading" #truncado
              >{{ notification.message.substr(0, 50) }} ...</ng-template
            >
            <div class="top-text-light">
              {{ notification.date }} &nbsp;
              <span style="font-size: 1.2rem">
                <i
                  *ngIf="notification.type === 'success'"
                  style="color: #009417"
                  class="fa-solid fa-check"
                ></i>
                <i
                  *ngIf="notification.type === 'info'"
                  style="color: #aaa"
                  class="fa-solid fa-circle-info"
                ></i>
                <i
                  *ngIf="notification.type === 'warning'"
                  style="color: #f9c801"
                  class="fa-solid fa-triangle-exclamation"
                ></i>
                <i
                  *ngIf="notification.type === 'error'"
                  style="color: #ee0000"
                  class="fa-solid fa-xmark"
                ></i>
              </span>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">Cerrar</button>
      </div> -->
    </div>
  </div>
</div>

<!-- ------------------------- Modal Open Notification ------------------------- -->
<div
  class="modal fade"
  #modalOpenNotification
  id="modalOpenNotification"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title dropdown-toolbar-title" id="exampleModalLabel">
          {{ selectedNotificationToModal?.title }}
        </h5>
        <div class="subtitle">
          <span>Recibida: {{ selectedNotificationToModal?.date }}</span>
          <span
            >Tipo:
            {{
              selectedNotificationToModal?.type === "success"
                ? "Éxito"
                : selectedNotificationToModal?.type === "info"
                ? "Información"
                : selectedNotificationToModal?.type === "warning"
                ? "Importante"
                : selectedNotificationToModal?.type === "error"
                ? "Cuidado"
                : null
            }}</span
          >

          <!-- <span style="font-size: 1.2rem;" data-toggle="tooltip" data-placement="top" [title]="selectedNotificationToModal?.type | titlecase"> -->
          <!-- <i *ngIf="selectedNotificationToModal?.type === 'success'" style="color: #009417" class="fa-solid fa-check"></i>
            <i *ngIf="selectedNotificationToModal?.type === 'info'" style="color: #aaa;" class="fa-solid fa-circle-info"></i>
            <i *ngIf="selectedNotificationToModal?.type === 'warning'" style="color: #F9C801" class="fa-solid fa-triangle-exclamation"></i>
            <i *ngIf="selectedNotificationToModal?.type === 'error'" style="color: #EE0000" class="fa-solid fa-xmark"></i> -->
          <!-- </span> -->
        </div>
        <button class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i style="font-size: 1.3rem" class="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div class="modal-body">
        {{ selectedNotificationToModal?.message }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>

<amplify-authenticator [hidden]="!false"> </amplify-authenticator>
