import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { Order } from 'src/app/models/Order';
import { PaymentService } from 'src/app/services/payment.service';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import awsExports from '../../../../../src/aws-exports';
import { AdditionalsService } from 'src/app/services/additionals.service';

@Component({
  selector: 'app-payment-result-owners',
  templateUrl: './payment-result-owners.component.html',
  styleUrls: ['./payment-result-owners.component.css']
})
export class PaymentResultOwnersComponent implements OnInit {

  loading: boolean = false;
  faExclamationCircle = faExclamationCircle;
  order: Order | undefined;
  ordenId: string = ''
  ordenStatus: string = ''
  checksum: string = ''
  authState: AuthState | undefined;
  user: CognitoUserInterface | undefined;
  email: string = ''
  params: any = {}
  guest: boolean = false;

  constructor(private additionalsService: AdditionalsService, private paymentService: PaymentService, private router: Router, private activatedRoute: ActivatedRoute, public authenticator: AuthenticatorService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params
      this.ordenId = params.order
      this.email = params.email
      console.log(this.params)

      if (params.guest) {
        this.guest = params.guest
      }
    })

    this.ordenStatus = 'pending'

    /*onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.email = this.user['attributes']['email'];
    })*/

    this.getPaymentState();
  }

  getEmail(email: string) {
    this.email = email;
  }

  getPaymentState() {
    if (this.ordenId != '') {

      this.paymentService.getPaymentState(this.ordenId, this.email, this.guest).subscribe(resp => {

        this.ordenStatus = resp.state;
        this.checksum = resp.checksum;

        this.getOrderInformation()
        if (this.ordenStatus == 'pending') {
          setTimeout(() => { this.getPaymentState(); }, 1000);
        }
        if (this.ordenStatus == 'approved') {
          this.additionalsService.contactOwners(this.params, this.guest).subscribe(resp => {
            console.log(resp)
          }, error => {
            console.log(error)
          });
        }
      }, error => {
        console.log(error)
      })
    } else {
      this.ordenStatus = 'No se proporcionó el número de orden'
    }
  }

  getOrderInformation() {
    this.paymentService.getOrderInformation(this.ordenId, this.checksum).subscribe(resp => {
      this.order = resp
    })
  }
}
