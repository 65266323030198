import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
/*import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';*/
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { HomeComponent } from './home/home.component';
import { PagesComponent } from './pages.component';
import { ContactComponent } from './contact/contact.component';
import { Ng5SliderModule } from 'ng5-slider';
import { PricingComponent } from './pricing/pricing.component';
import { BlogComponent } from './blog/blog.component';
import { LoginComponent } from './login/login.component';
import { EstateComponent } from './estate/estate.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { getDutchPaginatorIntl } from './dutch-paginator-intl';
import { MatButtonModule } from '@angular/material/button';
import { MatTableFilterModule } from 'mat-table-filter';
import { SidebarModule } from 'ng-sidebar';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { InfoComponent } from './home/info/info.component';
import { MatCurrencyFormatModule } from 'mat-currency-format';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { Error403Component } from './components/error403/error403.component';
import { DownloadPrivateComponent } from './components/download-private/download-private.component';
import { FavoritesComponent } from './profile/favorites/favorites.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { LoadingComponent } from './components/loading/loading.component';
import { CheckoutComponent } from './pricing/checkout/checkout.component';
import { CarouselModule } from 'primeng/carousel';
import { PaymentResultComponent } from './pricing/payment-result/payment-result.component';
import { JwtInterceptor } from '../interceptors/jwt.interceptor';
import { RegisterModalComponent } from './estate/register-modal/register-modal.component';
import { MdePopoverModule } from '@material-extended/mde';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryFormComponent } from './inventory/inventory-form/inventory-form.component';
import { FooterComponent } from './footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import { Homty360Component } from './homty360/homty360.component';
import { GetPropertyComponent } from './inventory/get-property/get-property.component';
import { AppraisalComponent } from './appraisal/appraisal.component';
import { Loading2Component } from './components/loading2/loading2.component';
import { AcpComponent } from './appraisal/acp/acp.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PropertyCardComponent } from './estate/property-card/property-card.component';
import { PayPackageComponent } from './pricing/pay-package/pay-package.component';
import { PaySubscriptionComponent } from './pricing/pay-subscription/pay-subscription.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { DescriptionsComponent } from './descriptions/descriptions.component';
import { OwnersComponent } from './owners/owners.component';
import { ContactOwnersComponent } from './owners/contact-owners/contact-owners.component';
import { ConfirmOwnersContactComponent } from './owners/confirm-owners-contact/confirm-owners-contact.component';
import { PaymentResultOwnersComponent } from './owners/payment-result-owners/payment-result-owners.component';
import { AcmsHistoryComponent } from './statistics/acms-history/acms-history.component';
import { PropertyStatisticsComponent } from './statistics/property-statistics/property-statistics.component';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { ToastrModule } from 'ngx-toastr';
import { PagesRoutingModule } from './pages.routing';
import { CercaniasComponent } from './components/cercanias/cercanias.component';
import { CercaniasAcmComponent } from './components/cercanias-acm/cercanias-acm.component';
import { Property360Component } from './property360/property360.component';
import { UsersAdminComponent } from './agencies/users-admin/users-admin.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { ModalAgencyDataComponent } from './agencies/modal-agency-data/modal-agency-data.component';
import { PaySubscriptionAgencyComponent } from './pricing/pay-subscription-agency/pay-subscription-agency.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({ 
  declarations: [ 
    HomeComponent,
    PagesComponent,
    ContactComponent,
    DescriptionsComponent,
    PricingComponent,
    BlogComponent,
    LoginComponent,
    EstateComponent,
    StatisticsComponent,
    HeaderComponent,
    ProfileComponent,
    InfoComponent,
    Error403Component,
    DownloadPrivateComponent,
    FavoritesComponent,
    LoadingComponent,
    CheckoutComponent,
    PaymentResultComponent,
    RegisterModalComponent,
    InventoryComponent,
    InventoryFormComponent,
    FooterComponent,
    Homty360Component,
    GetPropertyComponent,
    AppraisalComponent,
    Loading2Component,
    AcpComponent,
    CercaniasComponent,
    CercaniasAcmComponent,
    PropertyCardComponent,
    PaySubscriptionComponent,
    PayPackageComponent,
    OwnersComponent,
    ContactOwnersComponent,
    ConfirmOwnersContactComponent,
    PaymentResultComponent,
    PaymentResultOwnersComponent,
    AcmsHistoryComponent,
    PropertyStatisticsComponent,
    Property360Component,
    UsersAdminComponent,
    ModalAgencyDataComponent,
    PaySubscriptionAgencyComponent,
    ChangePasswordComponent
  ],
  imports: [
    PagesRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    Ng5SliderModule,
    FontAwesomeModule,
    AmplifyAuthenticatorModule,
    //AmplifyUIAngularModule,
    HttpClientModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatTableFilterModule,
    MatCurrencyFormatModule,
    MatGridListModule,
    MatCardModule,
    MatExpansionModule,
    MatDialogModule,
    MatMenuModule,
    MatSlideToggleModule,
    SidebarModule.forRoot(),
    MatIconModule,
    MatToolbarModule,
    CarouselModule,
    MdePopoverModule,
    NgxChartsModule,
    MatTabsModule,  
    // BrowserAnimationsModule,
    ToastrModule.forRoot(),
    Ng2TelInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC7-qqO2oOopzQAR-BuKXTkFOR40Z-DMOo',
      libraries: ['places']
    }),
    InputMaskModule.forRoot({ inputSelector: 'input', isAsync: true })
  ],
  exports: [
    HomeComponent
  ],
  providers: [
    CurrencyPipe,
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ]
})
export class PagesModule  {
}