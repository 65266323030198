import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VisitorsService {

    constructor(
        private http: HttpClient
    ) {

    }

    getIpAddress(): any {
        return this.http
            .get('https://api.ipify.org/?format=json')
            .pipe(
                catchError(this.handleError)
            );
    }

    getGEOLocation(ip: any): any {
        // Update your api key to get from https://ipgeolocation.io
        let url = "https://api.ipgeolocation.io/ipgeo?apiKey=f1b03d0038cf4b9282461d7c1115b20e&ip=" + ip;
        return this.http
            .get(url)
            .pipe(
                catchError(this.handleError)
            );
    }

    callApi(Longitude: number, Latitude: number): any {
        const url = `https://api.bigdatacloud.net/data/reverse-geocode?latitude=${Latitude}&longitude=${Longitude}&key=5a58fe6fd5224477b5da7d55f141135e`
        return this.http
            .get(url, { headers: { skip: "true" } })
            .pipe(
                catchError(this.handleError)
            );
        //Call API
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

}