<div class="container pt-20 pb-20"
    *ngIf="user && (user['attributes']['nickname']=='Avaluador' || user['attributes']['nickname']=='Agente inmobiliario')">
    <div class="d-flex">
        <p class="sub-header">Volver a la búsqueda básica</p>
        <input class="homty360-button mt--10 ml-10" (click)="goToSearch()" value="Ir a Homty" type="button">
    </div>

    <div class="d-lg-none d-flex flex-row-reverse">

        <div class="container mt-20" *ngIf="user">
            <iframe *ngIf="user['attributes']['nickname'] == 'Agente inmobiliario'"
                src="https://app.powerbi.com/view?r=eyJrIjoiYTk4M2U1Y2MtN2NhMS00YTQ3LTg4OGItMzE4MTE1ZjY1YTJlIiwidCI6IjI2OTdkNDViLTZjYjAtNDZmNC1iNGIzLWUyNDIzMmMxYTYyMSJ9&pageName=ReportSectionb5f7ec84841614b68622"
                allowfullscreen></iframe>

            <iframe *ngIf="user['attributes']['nickname'] == 'Avaluador'"
                src="https://app.powerbi.com/view?r=eyJrIjoiMDQ3NGZjNTQtYzI4MC00MGQ5LWEzYTctZDdiMWIwOTI2NjE4IiwidCI6IjI2OTdkNDViLTZjYjAtNDZmNC1iNGIzLWUyNDIzMmMxYTYyMSJ9"
                allowfullscreen></iframe>

        </div>
    </div>

    <div class="container  mt-20  d-none d-xl-block d-lg-block" *ngIf="user">
        <iframe *ngIf="user['attributes']['nickname'] == 'Agente inmobiliario'"
            src="https://app.powerbi.com/view?r=eyJrIjoiNDUwNGY4MTUtNjUzMi00NzE4LWFjMTEtMjk3ODQzN2UyNGJmIiwidCI6IjI2OTdkNDViLTZjYjAtNDZmNC1iNGIzLWUyNDIzMmMxYTYyMSJ9&pageName=ReportSection8ce2c861a91be01aa337&utm_source=Homty+Agente&utm_medium=Homty+Agente&utm_campaign=Homty+Agente"
            allowfullscreen></iframe>

        <iframe *ngIf="user['attributes']['nickname'] == 'Avaluador'"
            src="https://app.powerbi.com/view?r=eyJrIjoiN2RlMTY3YTEtN2QxNy00ZmQ4LWJiNjEtYTNlNTgzNGM2NzRlIiwidCI6IjI2OTdkNDViLTZjYjAtNDZmNC1iNGIzLWUyNDIzMmMxYTYyMSJ9&utm_source=Agente+Avaluador&utm_medium=Agente+Avaluador&utm_campaign=Agente+Avaluador"
            allowfullscreen></iframe>

    </div>
</div>

<div class="container">




</div>
<!-- Start of HubSpot Embed Code -->
<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8998298.js"></script>
<!-- End of HubSpot Embed Code -->