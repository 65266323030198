<div class="container">
  <div class="row">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
      <h1 class="header">
        ¡Haz alcanzado el límite de búsquedas gratuitas!
      </h1>
    </div>
    <div class="col-lg-2"></div>   
  </div>

  <div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-6">
      <p>
        Crea tu cuenta gratis y accede a 
      </p>
  
      <ul>
        <li>4 Días Gratis</li>
        <li>Búsquedas Ilimitadas</li>
        <li>Acceso A Más De 500.000 Propiedades</li>
        <li>Más posibilidades de colegaje</li>
      </ul>
  
      <button type="button" class="submit-btn" [routerLink]="['/login', 'signUp']">
        Ir a registro
      </button>
    </div>
    <div class="col-lg-2"></div>
  </div>
</div>