import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})

export class AdditionalsService {

  constructor(private http: HttpClient) { }

  contactOwners(params: any, guest: boolean = false) {
    let url = `${base_url}/send_message`;

    let dict = guest ? { params, headers: { skip: "true" } } : { params }

    return this.http.get<any>(url, dict).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  markNotificationRead(notificationId: string, email: string) {
    let url = `${base_url}/update-notification`;

    const params = new HttpParams()
      .set("notificationId", notificationId)
      .set("email", email)

    return this.http.post<string>(url, {}, { params }).pipe(
      catchError(error => throwError(error))
    )
  }

  getAvailableAcms(userEmail: string) {
    let url = `${base_url}/available_acms`;

    const params = new HttpParams()
      .set("email", userEmail)

    return this.http.get<any>(url, { params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getNumberAcmsProperties(userEmail: string) {
    let url = `${base_url}/getpropacm`;

    return this.http.get<any>(url, { params: { user: userEmail } }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getPropertyViews(propertyId: string = "") {
    let url = `${base_url}/getpropviews`;

    return this.http.get<any>(url).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getDescription(tipo_inmueble: string, ciudad: string, habitaciones: string, banos: string, barrio: string, precio: string, administracion: string,
    area: string, parqueadero: string, piso: string, nombre: string, whatsapp: string, operacion: string, estilo: string) {
    let url = `${base_url}/getdescription`;

    return this.http.get<any>(url, {
      params: {
        tipo_inmueble: tipo_inmueble, ciudad: ciudad, habitaciones: habitaciones, banos: banos, barrio: barrio, precio: precio,
        administracion: administracion, area: area, parqueadero: parqueadero, piso: piso, nombre: nombre, whatsapp: whatsapp, operacion: operacion, estilo: estilo
      }, headers: { skip: "true" }
    }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  sendEmailNewUserAgency(agencyName: string, newUserEmail: string, rol: string, password: string) {
    let url = `${base_url}/send_email`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body = {
      agency: agencyName,
      email: newUserEmail,
      rol: rol,
      password: password
    }

    return this.http.post<string>(url, body, httpOptions).pipe(
      catchError(error => throwError(error))
    )
  }

  verifyProperty(properties: object[], state: string, typeOperation: string, email: string) {
    const url = `${base_url}/verify_property`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'skip': 'true'
      })
    };

    const requestBody = {
      propiedades: properties,
      estado: state,
      operacion: typeOperation,
      email: email
    };

    return this.http.post<string>(url, requestBody, httpOptions).pipe(
      catchError(error => throwError(error))
    );
  }
}
