import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ContactResponse } from '../models/contactResponse.model';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendMessage(nombre: string, apellido: string, email: string, asunto: string)
  :Observable<any>{
    const url = `${base_url}/email`;



    const queryparams = new HttpParams()
                  .set("TopicArn", `arn:aws:sns:us-east-1:312299482491:homty-users-contact`)
                  .set("Subject", nombre +" "+ apellido + ","+ email )
                  .set("Message", asunto);
    

    const headersJson = new HttpHeaders()
          .set('Content-Type', 'application/json; charset=utf-8');

    let options = {
      headers: headersJson,
      params: queryparams
    };
    
      
    return this.http.post(url,"{}" , options);
  }
}
