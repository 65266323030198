<button id="btnModalAgencyData" type="button" #btnModalAgencyData class="btn btn-primary" data-bs-toggle="modal"
data-bs-target="#modalAgencyData" hidden></button>

<!-- Completar informacion Agencia Inmobiliaria -->
<div #modalAgencyData class="modal fade" id="modalAgencyData" data-bs-backdrop="static" data-bs-keyboard="false"
  tabindex="-1" role="dialog" aria-labelledby="modalAgencyDataLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title hello-info d-flex justify-content-center" id="modalAgencyDataLabel">¡Bienvenido/a {{user?.attributes?.family_name}}!</h5>
      </div>
      <div class="modal-body">

        <div class="col-lg-12 sm-pt-30">

          <div class="alert alert-warning d-flex align-items-center mt-2" role="alert">
            <i class="fa-regular fa-circle-exclamation fa-fade" style="font-size: 2rem;"></i>

            <div class="ml-10">
              Te has registrado como una Agencia Inmobiliaria por lo que necesitamos unos pocos datos
              adicionales, llénalos para que completes el registro y puedas empezar a utilizar Homty.
            </div>
          </div>

          <div class="card mt-2">
            <div class="card-body">
              <h5 class="card-title mb-3">Datos Adicionales</h5>

              <form [formGroup]="formAgencyData">
                <div class="form-group mb-2">
                  <label for="representante">Representante Legal</label>
                  <input type="text" class="form-control" formControlName="representante" id="representante"
                    placeholder="Ingrese el nombre del representante legal">
                </div>
                <div class="form-group mb-2">
                  <label for="direccion">Dirección de la Agencia</label>
                  <input type="text" class="form-control" formControlName="direccion" id="direccion"
                    placeholder="Ingrese la dirección">
                </div>
              </form>

            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-modal-save"
          [disabled]="formAgencyData.invalid || formAgencyData.pristine"
          data-bs-dismiss="modal" aria-label="Close"
          (click)="saveAgencyData()">Guardar</button>
      </div>
    </div>
  </div>
</div>