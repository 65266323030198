import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.css']
})
export class Error403Component implements OnInit {
  @Input() errorMessage: string = "";
  @Input() buttonText: string = "";


  constructor() { }

  ngOnInit(): void {
  }

}
