import { MapsAPILoader } from "@agm/core";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import { AppraisalService } from "src/app/services/appraisal.service";
import { SharingService } from "src/app/services/sharing.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ShareAcmService } from "src/app/services/share-acm.service";
import Swal from "sweetalert2";
import { Homty360Service } from "src/app/services/homty360.service";
import { Cercania } from "src/app/models/cercania.model";

@Component({
  selector: "app-acp",
  templateUrl: "./acp.component.html",
  styleUrls: ["./acp.component.css"],
})
export class AcpComponent implements OnInit {
  faLink = faLink;
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = [
    "barrio",
    "inmueble",
    "precio",
    "estrato",
    "tamano",
    "antiguedad",
    "url",
  ];

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  actionPage: number = 0;
  latitude: number = 6.230833;
  longitude: number = -75.590553;
  zoom: number = 17;
  address: string = "";
  private geoCoder!: {
    geocode: (
      arg0: { location: { lat: any; lng: any } },
      arg1: (results: any, status: any) => void
    ) => void;
  };
  @ViewChild("search")
  public searchElementRef!: ElementRef;
  private historyAcm: any;
  private sendLocation: any;
  selectedPlan: string = "";
  payacm: string = "";

  loading: boolean = false;
  property: any;
  appraisal: any;

  iconHomtyUrl = "assets/images/pins-map/property.png";

  showDataCercanias: boolean = true;
  dataCercanias: Array<Cercania> = [
    {
      title: "Bancos",
      checked: false,
      icon: "fas fa-dollar-sign",
      iconAwesome: "dollar-sign",
      iconUrl: "assets/images/pins-map/bank.png",
      color: "#017216",
      typeValue: "bank",
      items: [],
    },
    {
      title: "Farmacias",
      checked: false,
      icon: "fas fa-briefcase-medical",
      iconAwesome: "briefcase-medical",
      iconUrl: "assets/images/pins-map/pharmacy.png",
      color: "#238AFF",
      typeValue: "pharmacy",
      items: [],
    },
    {
      title: "Gimnasios",
      checked: false,
      icon: "fas fa-dumbbell",
      iconAwesome: "dumbbell",
      iconUrl: "assets/images/pins-map/gym.png",
      color: "#FEBD23",
      typeValue: "gym",
      items: [],
    },
    {
      title: "Hospitales",
      checked: false,
      icon: "fas fa-hospital",
      iconAwesome: "hospital",
      iconUrl: "assets/images/pins-map/hospital.png",
      color: "#FB302F",
      typeValue: "hospital",
      items: [],
    },
    {
      title: "Iglesias",
      checked: false,
      icon: "fas fa-church",
      iconAwesome: "church",
      iconUrl: "assets/images/pins-map/church.png",
      color: "#21C8D1",
      typeValue: "church",
      items: [],
    },
    {
      title: "Parques",
      checked: false,
      icon: "fas fa-tree",
      iconAwesome: "tree",
      iconUrl: "assets/images/pins-map/park.png",
      color: "#029F14",
      typeValue: "park",
      items: [],
    },
    {
      title: "Restaurantes",
      checked: false,
      icon: "fas fa-utensils",
      iconAwesome: "utensils",
      iconUrl: "assets/images/pins-map/restaurant.png",
      color: "#790079",
      typeValue: "restaurant",
      items: [],
    },
  ];

  constructor(
    private appraisalService: AppraisalService,
    private sharingService: SharingService,
    private shareAcmService: ShareAcmService,
    private mapsAPILoader: MapsAPILoader,
    public homty360: Homty360Service,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.view) {
        this.actionPage = params.view;
      }

      if (params.payacm) {
        this.payacm = params.payacm;
      }

      if (params.plan) {
        this.selectedPlan = params.plan;
      }
    });

    if (this.actionPage == 1) {
      // Visualizacion de ACM ya generado
      this.loadACP();
    } else if (
      this.payacm != undefined &&
      this.payacm == "1" &&
      this.selectedPlan == "6"
    ) {
      // Visualizacion de ACM pagado
      this.loadACPPaid();
    } else {
      // Resultado de un nuevo ACM
      this.createACP();
    }
  }

  updateCercanias(newData: Cercania[]): void {
    this.dataCercanias = newData;
  }

  isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "object") {
      return Object.keys(value).length === 0;
    } else {
      return !value;
    }
  }

  async loadACPPaid() {
    this.loading = true;
    this.historyAcm = this.sharingService.sharingValue;

    const property: any = {
      antiguedad: this.historyAcm.antiguedad,
      banos: this.historyAcm.banos,
      estrato: this.historyAcm.estrato,
      habitaciones: this.historyAcm.habitaciones,
      latitud: +this.historyAcm.location.latitud,
      longitud: +this.historyAcm.location.longitud,
      municipio: this.historyAcm.municipio,
      parqueadero: this.historyAcm.parqueadero,
      tamano: this.historyAcm.tamano,
      tipo_inmueble: this.historyAcm.tipo_inmueble,
    };

    const appraisal = {
      ACP: this.historyAcm.ACP,
      items: this.historyAcm.items,
      location: {
        barrio: this.historyAcm.location.barrio,
        latitud: +this.historyAcm.location.latitud,
        longitud: +this.historyAcm.location.longitud,
      },
    };

    this.historyAcm.email = "";
    await Auth.currentUserInfo().then((user) => {
      this.historyAcm.email = user["attributes"]["email"];
    });

    this.appraisalService
      .saveHistoryACMPaid(this.historyAcm)
      .subscribe((resp) => {
        console.log(resp);
        if (resp.answer) {
          Swal.fire({
            title: "Bien Hecho!",
            text: "El ACM quedó registrado en tu historial de ACMs en estadísticas, allí podras verlo de nuevo.",
            timer: 3000,
            showConfirmButton: false,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      });

    this.property = property;
    this.appraisal = appraisal;

    this.dataSource = new MatTableDataSource(this.historyAcm.items);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
    });

    this.dataCercanias = this.dataCercanias.map((item: any) => {
      if (item.typeValue) {
        try {
          const parsedItems = JSON.parse(this.historyAcm[item.typeValue]);
          return { ...item, items: parsedItems };
        } catch (error) {
          return { ...item, items: [] };
        }
      }
    });
    console.log(this.dataCercanias);
    this.loading = false;
  }

  loadACP() {
    this.loading = true;
    this.historyAcm = this.shareAcmService.shareAcm;

    const property: any = {
      antiguedad: this.historyAcm.antiguedad,
      banos: this.historyAcm.banos,
      estrato: this.historyAcm.estrato,
      habitaciones: this.historyAcm.habitaciones,
      municipio: this.historyAcm.municipio,
      parqueadero: this.historyAcm.parqueadero,
      tamano: this.historyAcm.tamano,
      tipo_inmueble: this.historyAcm.inmueble,
    };

    const appraisal = {
      ACP: this.historyAcm.ACP,
      items: this.historyAcm.items,
      location: {
        barrio: this.historyAcm.location.barrio,
        latitud: +this.historyAcm.location.latitud,
        longitud: +this.historyAcm.location.longitud,
      },
    };

    this.property = property;
    this.appraisal = appraisal;

    this.dataSource = new MatTableDataSource(this.historyAcm.items);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
    });

    this.dataCercanias = this.dataCercanias.map((item: any) => {
      if (item.typeValue) {
        try {
          const parsedItems = JSON.parse(this.historyAcm[item.typeValue]);
          return { ...item, items: parsedItems };
        } catch (error) {
          return { ...item, items: [] };
        }
      }
    });

    this.showDataCercanias = !this.dataCercanias.every((item) => {
      return this.isEmpty(item.items);
    });

    this.loading = false;
  }

  createACP() {
    this.loading = true;
    this.property = this.sharingService.sharingValue;
    this.appraisalService.createAppraisal(this.property).subscribe(
      (resp) => {
        this.appraisal = resp;
        this.dataSource = new MatTableDataSource(resp.items);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.mapsAPILoader.load().then(() => {
          this.setCurrentLocation();
          this.geoCoder = new google.maps.Geocoder();
        });
        this.dataCercanias = this.dataCercanias.map((item: any) => {
          if (item.typeValue) {
            return {
              ...item,
              items: JSON.parse(this.appraisal[item.typeValue]),
            };
          }
        });

        if (this.selectedPlan == "6") {
          this.appraisal = { ...this.appraisal, ...this.property };
          this.sharingService.sharingValue = this.appraisal;
          this.router.navigate(["/checkout"], { queryParams: { plan: "6" } });
        } else {
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        console.log(error);
        // Error 1 - No tiene ACMs disponibles
        // Error 2 - No hay suficiente información
        // Error 3 - Otros errores
        if (error.error.type_error == 1 || error.error.type_error == 3) {
          let terms = Swal.fire({
            title: "¿Quiere comprar otro paquete?",
            text: error.error.answer,
            showCancelButton: true,
            confirmButtonColor: "#ff653f",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(["/pay-subscription"]);
            }
            if (result.isDismissed) {
              this.router.navigate(["/avaluo"]);
            }
          });
        } else if (error.error.type_error == 2) {
          let terms = Swal.fire({
            title: "Error al generar el ACM",
            text: error.error.answer,
            confirmButtonColor: "#ff653f",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(["/avaluo"]);
            }
          });
        } else {
          Swal.fire({
            title: "Error al generar el ACM",
            icon: error,
            text: error.error.answer,
            confirmButtonColor: "#ff653f",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(["/avaluo"]);
            }
          });
        }
      }
    );
  }

  private setCurrentLocation() {
    this.latitude = this.appraisal.location.latitud;
    this.longitude = this.appraisal.location.longitud;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: any, status: any) => {
        if (status === "OK") {
          if (results[0]) {
            this.address = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    console.log($event);

    // this.latitude = $event.latLng.lat
    // this.longitude = $event.latLng.lng
    // this.getAddress(this.latitude, this.longitude);
  }

  printPage() {
    window.print();
  }
}
