import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InventoryModel } from 'src/app/models/inventory.model';
import { InventoryService } from 'src/app/services/inventory.service';
import { SharingService } from 'src/app/services/sharing.service';
import { Meta, Title } from '@angular/platform-browser';
import { Auth } from 'aws-amplify';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-get-property',
  templateUrl: './get-property.component.html',
  styleUrls: ['./get-property.component.css']
})
export class GetPropertyComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private sharingService: SharingService, private inventoryService: InventoryService, private websocketService: WebsocketService, private title: Title, private meta: Meta) { }

  property: InventoryModel | undefined;
  loading: boolean = false;
  latitude: number = 0
  longitude: number = 0
  zoom: number = 15;
  private geoCoder!: { geocode: (arg0: { location: { lat: any; lng: any; }; }, arg1: (results: any, status: any) => void) => void; };
  address: string = '';

  ngOnInit(): void {
    this.getPropertyById()
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getPropertyById() {

    this.activatedRoute.queryParams
      .subscribe(params => {
        if (params.id) {
          this.property = this.sharingService.sharingValue;
          if (this.property != undefined) {
            this.latitude = Number(this.property.Latitud)
            this.longitude = Number(this.property.Longitud)
          } else {
            this.loading = true;
            this.inventoryService.getProperyById(params.id).subscribe(resp => {
              this.property = resp.property
              this.loading = false;
              this.latitude = Number(this.property?.Latitud)
              this.longitude = Number(this.property?.Longitud)
              
              //Notificar visualizacion
              this.sendNotification();

              //SEO
              this.title.setTitle(`Homty - ${this.property?.Inmueble} ${this.property?.Barrio}`);
              this.meta.addTag({ name: 'description', content: `Homty ${this.property?.Inmueble} ${this.property?.Barrio}, ${this.property?.Descripcion}`});
              this.meta.addTag({ name: 'keywords', content: `homty, inmobiliario, propiedades, ${this.property?.Inmueble}, ${this.property?.Barrio}, ${this.property?.Operacion}, Piso ${this.property?.Piso}, ${this.property?.Habitaciones} Habitaciones`});

            })
          }

        }
      }
      );
  }

  sendNotification() {
    // Si el usuario esta logueado y la propiedad es de homty 
    // se le envia notificacion al usuario que publicó la propiedad
    // para notificar que vió la propiedad

    Auth.currentUserInfo().then(user => {
      if (this.property?.nombre_usuario !== user["attributes"]["email"]) {
        let notification = {
          action: 'sendMessage', 
          type: 'success', 
          typeInformation: 'propertyVisualization',
          title: '¡Han visto tu propiedad!',
          message: `${user["attributes"]["email"]} ha visto tu propiedad ${this.property?.id} ubicada en ${this.property?.Barrio} - ${this.property?.Municipio}. ¡Ponte en contacto!`,
          recipients: [this.property?.nombre_usuario]
        }
        console.log(notification);
        this.websocketService.sendMessage(notification);
      }
    });
  }

  markerDragEnd($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
}
