import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-pay-subscription',
  templateUrl: './pay-subscription.component.html',
  styleUrls: ['./pay-subscription.component.css']
})
export class PaySubscriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
