import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  newPasswordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;
  alertVisible: boolean = false;
  textAlert: string = '';

  resetPasswordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.*[0-9]).*$')]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.*[0-9]).*$')])
  });

  constructor(private router: Router, private toastr: ToastrService) { }

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then((user) => {
      if (user == undefined) {
        this.router.navigate(['home']);
      }
    });
  }

  changePassword() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const formValues = this.resetPasswordForm.value;
        console.log(formValues);
        return Auth.changePassword(user, formValues.currentPassword, formValues.newPassword);
      })
      .then((data) => { 
        if(data == 'SUCCESS') {
          this.toastr.success( 'La contraseña se ha actualizado correctamente', '¡Bien Hecho!');
          this.resetPasswordForm.reset(); 
          this.router.navigate(['perfil']);
        }
        console.log(data);
      })
      .catch((err) => console.log(err));
  }

  confirmResetPassword() {
    this.alertVisible = false;
    if (this.resetPasswordForm.invalid) {
      // Realiza las acciones necesarias cuando el formulario es inválido
      this.showAlert('Hay errores en los campos');
      return;
    }

    this.changePassword();
  }

  showAlert(text: string) {
    this.alertVisible = true;
    this.textAlert = text;
    setTimeout(() => {
      this.alertVisible = false;
      this.textAlert = '';
    }, 5000);
  }

  togglePasswordVisibility(field: string) {
    if (field === 'newPassword') {
      this.newPasswordVisible = !this.newPasswordVisible;
    } else if (field === 'confirmPassword') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
  }


}
