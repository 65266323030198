<app-loading2 *ngIf="loading" [textLoading]="actionPage == 0 ? 'Generando su ACM ...' : 'Cargando su ACM ...'"
    class="animate__animated animate__fadeIn vertical-center">
</app-loading2>

<div id="print">
    <div *ngIf="!loading" class="container mt-30">
        <div class="row">
            <div class="col-lg-9">
                <h1>{{property.tipo_inmueble}} {{appraisal.location.barrio}}</h1>
            </div>
            <div *ngIf="actionPage == 0" class="col-lg-3 text-right">
                <a href="#" routerLink="/avaluo" class="btn">
                    Crear otro ACM
                </a>
            </div>
            <div *ngIf="actionPage == 1" class="col-lg-3 text-right">
                <a href="#" routerLink="/statistics/1" class="btn">
                    Regresar
                </a>
            </div>
        </div>
        <!-- m.isClicked ? './assets/images/pins-map/property.png' : './assets/images/bike.svg' -->
        <div class="col-md-12 mt-20">
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"
                    [iconUrl]="iconHomtyUrl"></agm-marker>
                <div *ngFor="let itemi of dataCercanias">
                    <ng-container *ngIf="itemi.checked">
                        <agm-marker *ngFor="let itemj of itemi.items" [latitude]="itemj.latitud"
                            [longitude]="itemj.longitud" [iconUrl]="itemi.iconUrl"></agm-marker>
                    </ng-container>
                </div>
            </agm-map>

            <div class="container mt-30 shadow">
                <div class="row">
                    <div class="col fondo-naranja pt-10 pb-10">
                        <p class="titulo-precio">{{appraisal.ACP.precio_mt2 | currency:'COP':'symbol-narrow':'1.0-0'}}
                        </p>
                        <p class="subtitulo-precio">Precio metro cuadrado</p>
                    </div>
                    <div class="col primary-border pt-10 pb-10">
                        <p class="titulo-precio2">{{appraisal.ACP.precio_min | currency:'COP':'symbol-narrow':'1.0-0'}}
                        </p>
                        <p class="subtitulo-precio2">Precio mínimo</p>
                    </div>
                    <div class="col primary-border pt-10 pb-10">
                        <p class="titulo-precio2">{{appraisal.ACP.precio_max | currency:'COP':'symbol-narrow':'1.0-0'}}
                        </p>
                        <p class="subtitulo-precio2">Precio máximo</p>
                    </div>
                    <!-- <div *ngIf="actionPage == 0" class="col fondo-naranja pt-10 pb-10">
                        <p class="subtitulo-precio">Comparamos <b>{{appraisal.items.length}}</b> inmuebles con
                            carácteristicas similares al suyo</p>
                    </div> -->
                    <div *ngIf="appraisal.items && appraisal.items.length > 0" class="col fondo-naranja pt-10 pb-10">
                        <p class="subtitulo-precio">Comparamos <b>{{appraisal.items.length}}</b> inmuebles con
                            carácteristicas similares al suyo</p>
                    </div>
                </div>

                <div class="row pt-15">
                    <div class="col-md-3">
                        <h6>Área</h6>
                        <p class="valores">{{property.tamano}}</p>
                    </div>
                    <div class="col-md-3">
                        <h6>Estrato</h6>
                        <p class="valores">{{property.estrato}}</p>
                    </div>
                    <div class="col-md-3">
                        <h6>Habitaciones</h6>
                        <p class="valores">{{property.habitaciones}}</p>
                    </div>
                    <div class="col-md-3">
                        <h6>Baños</h6>
                        <p class="valores">{{property.banos}}</p>
                    </div>
                </div>

                <div class="row pt-15">
                    <div class="col-md-4">
                        <h6>Parqueadero</h6>
                        <p class="valores">{{property.parqueadero}}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Antigüedad</h6>
                        <p class="valores">{{property.antiguedad}}</p>
                    </div>
                </div>

            </div>

            <app-cercanias-acm *ngIf="actionPage == 0 || (actionPage == 1 && showDataCercanias)"
                [dataCercanias]="dataCercanias" (updateCercanias)="updateCercanias($event)"></app-cercanias-acm>

            <div class="container mt-30">
                <p>
                    Nota: Este resultado es un valor de referencia que no reemplaza un avalúo certificado por un
                    avaluador
                    certificado en el Registro Abierto de Avaluadores (RAA), ley 1673 de 2013, Resolución IGAC 620 de
                    2008,
                    Decreto IGAC 1420 del 1998.
                </p>

                <p>
                    Encuentre aquí el listado de avaluadores certificados con RAA que tienen membresía en Homty.co*
                </p>
                <p class="container mt-30 mb-50">
                    <a href="https://imagenes-homty.s3.amazonaws.com/Listado+Avaluadores+con+Membresia+Homty+.pdf"
                        target="_blank">Ver Avaluadores certificados</a>*
                </p>
            </div>

        </div>
    </div>

    <div *ngIf="!loading && appraisal.items && appraisal.items.length > 0" class="container mt-20 mb-50">
        <h2>Listado de inmuebles comparados</h2>
        <div class="row">
            <div class="col-lg-12">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 border-radius-top">

                    <!-- Barrio Column -->
                    <ng-container matColumnDef="barrio">
                        <th mat-header-cell *matHeaderCellDef> Barrio </th>
                        <td mat-cell *matCellDef="let element"> {{element.barrio | titlecase}} </td>
                    </ng-container>

                    <!-- Inmueble Column -->
                    <ng-container matColumnDef="inmueble">
                        <th mat-header-cell *matHeaderCellDef> Tipo de inmueble </th>
                        <td mat-cell *matCellDef="let element">{{element.inmueble | titlecase}}
                        </td>
                    </ng-container>

                    <!-- Precio Column -->
                    <ng-container matColumnDef="precio">
                        <th mat-header-cell *matHeaderCellDef> Precio </th>
                        <td mat-cell *matCellDef="let element">{{element.precio}}
                        </td>
                    </ng-container>

                    <!-- Estrato Column -->
                    <ng-container matColumnDef="estrato">
                        <th mat-header-cell *matHeaderCellDef> Estrato </th>
                        <td mat-cell *matCellDef="let element"> {{element.estrato}} </td>
                    </ng-container>

                    <!-- Tamano Column -->
                    <ng-container matColumnDef="tamano">
                        <th mat-header-cell *matHeaderCellDef> Area (m2) </th>
                        <td mat-cell *matCellDef="let element"> {{element.tamano}} </td>
                    </ng-container>

                    <!-- Antiguedad Column -->
                    <ng-container matColumnDef="antiguedad">
                        <th mat-header-cell *matHeaderCellDef> Antigüedad </th>
                        <td mat-cell *matCellDef="let element"> {{element.antiguedad}} </td>
                    </ng-container>

                    <!-- Url Column -->
                    <ng-container matColumnDef="url">
                        <th mat-header-cell *matHeaderCellDef> Ver propiedad </th>
                        <td mat-cell *matCellDef="let element">
                            <a [href]="element.url" target="_blank">
                                <fa-icon [icon]="faLink" class="mr-10"></fa-icon>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="loading">
                        <td mat-footer-cell *matFooterCellDef colspan="19">
                            Cargando información...
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide': !loading}"></tr>
                </table>
            </div>


        </div>

        <div *ngIf="!loading" class="container mt-10">
            <button class="btn" (click)="printPage()">
                Generar PDF
            </button>
        </div>