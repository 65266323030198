import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Estate } from 'src/app/models/estate.model';
import { EstateService } from 'src/app/services/estate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { faFilter, faLink, faList, faSpinner, faStream, faTags, faHeart, faDownload, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth } from "aws-amplify";
import { createMask } from '@ngneat/input-mask';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { MatDialog } from "@angular/material/dialog";
import { MdePopoverTrigger } from '@material-extended/mde';
import { SharingService } from 'src/app/services/sharing.service';
import { Meta, Title } from '@angular/platform-browser';
import { VisitorsService } from 'src/app/services/visitors.service';

function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return null
    }
    return null  /* valid option selected */
  }
}

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.css']
})
export class OwnersComponent implements OnInit {
  @ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger | undefined;

  user: CognitoUserInterface | undefined;
  authState: AuthState | undefined;

  activePlan: boolean = true;
  errorMessage: string = '';

  faFilter = faFilter;
  faList = faList;
  faStream = faStream;
  faLink = faLink;
  faTags = faTags;
  faWhatsapp = faWhatsapp;
  faSpinner = faSpinner;
  faHeart = faHeart;
  faDownload = faDownload;
  faChevronDown = faChevronDown;
  barrio = new FormControl(''); //,Validators.required);

  length: number | undefined;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  filterEntity: Estate = new Estate("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", []);

  currencyInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 0,
    prefix: '$ ',
  });
  precioMin = new FormControl('20000000', Validators.required);
  precioMax = new FormControl('70000000000');
  valorAdmin = new FormControl('');
  valorAdminMax = new FormControl('');

  mtmin: string = '';
  mtmax: string = '';

  public loading: boolean = false;
  sortState: Sort | undefined;

  // Select de municipios
  municipioAutocompleteControl = new FormControl('',
    { validators: [autocompleteObjectValidator(), Validators.required] })
  selectedMunicipio: string = '';
  selectedBarrio: string = '';
  selectedPropertyType: string = 'Apartamento';
  selectedOperation: string = 'venta';
  selectedHabitaciones: string = '';
  selectedBanos: string = '';
  selectedParqueadero: string = '';
  selectedEstrato: string = '';
  selectedEstratoMax: string = '';
  selectedPiso: string = '';
  selectedAntiguedad: string = '--';
  filteredCities: Observable<string[]> | undefined;
  propertyTypes: any[] = [];
  cities: string[] = [];
  buttonText: string = '';
  origins: any[] = [];
  selectedOrigin: string = ''
  showAllFilters: boolean = false;
  selectedPropertyTypeFixed: string = '';
  selectedOperationFixed: string = '';
  selectedMunicipioFixed: string = '';
  popUpDisplayed: boolean = false;

  public validation_msgs = {
    'municipioAutocompleteControl': [
      { type: 'required', message: 'Municipio no reconocido, por favor selecciona uno de la lista' },
    ],
    'precioMin': [
      { type: 'required', message: 'Se debe ingresar un valor ' }
    ]
  }

  selectedPropertyToModal: Estate = new Estate("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", []);

  constructor(private visitorsService: VisitorsService, private estateService: EstateService, private activatedRoute: ActivatedRoute, private ref: ChangeDetectorRef, private router: Router, public authenticator: AuthenticatorService, public matDialog: MatDialog, private sharingService: SharingService, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.getLocation()
    this.getCities()
    this.getPropertyTypes()
    this.getOrigins()

    this.selectedPropertyType = 'Apartamento';
    this.selectedOperation = 'venta';
    this.selectedBarrio = '';
    this.precioMin.setValue('20000000');
    this.precioMax.setValue('70000000000')

    Auth.currentUserInfo().then(user => {
      this.selectedMunicipio = user["attributes"]["address"]
    }
    );

    this.activatedRoute.queryParams.subscribe(params => {

      if (typeof (params.estratomax) !== "undefined") {
        this.selectedMunicipio = params.municipio;
      }

      if (typeof (params.selectedOperation) !== "undefined") {
        this.selectedOperation = params.operacion;
      }

      if (typeof (params.selectedPropertyType) !== "undefined") {
        this.selectedPropertyType = params.inmueble;
      }

      this.municipioAutocompleteControl.setValue(this.selectedMunicipio);

      if (typeof (params.estratomax) !== "undefined")
        this.selectedEstratoMax = params.estratomax
      else
        this.selectedEstratoMax = ''

      if (typeof (params.estrato) !== "undefined")
        this.selectedEstrato = params.estrato
      else
        this.selectedEstrato = ''

      if (typeof (params.barrio) !== "undefined")
        this.selectedBarrio = params.barrio
      else
        this.selectedBarrio = ''

      if (typeof (params.habitaciones) !== "undefined")
        this.selectedHabitaciones = params.habitaciones
      else
        this.selectedHabitaciones = ''

      if (typeof (params.banos) !== "undefined")
        this.selectedBanos = params.banos
      else
        this.selectedBanos = ''


      if (typeof (params.mtmin) !== "undefined")
        this.mtmin = params.mtmin
      else
        this.mtmin = ''

      if (typeof (params.mtmax) !== "undefined")
        this.mtmax = params.mtmax
      else
        this.mtmax = ''

      if (typeof (params.parqueadero) !== "undefined")
        this.selectedParqueadero = params.parqueadero
      else
        this.selectedParqueadero = ''


      if (typeof (params.antiguedad) !== "undefined")
        this.selectedAntiguedad = params.antiguedad
      else
        this.selectedAntiguedad = '--'

      if (typeof (params.piso) !== "undefined")
        this.selectedPiso = params.piso
      else
        this.selectedPiso = ''

      if (typeof (params.valorAdminmax) == "undefined")
        this.valorAdminMax = new FormControl('');
      else
        this.valorAdminMax.setValue(params.valorAdminmax);

      if (typeof (params.valorAdmin) == "undefined")
        this.valorAdmin = new FormControl('');
      else
        this.valorAdmin.setValue(params.valorAdmin);

      //SEO
      // this.title.setTitle(`Homty - ${this.selectedPropertyType} en ${this.selectedOperation} en ${this.selectedMunicipio}`);
      // this.meta.addTag({ name: 'description', content: `Homty contacto inmobiliario, ${this.selectedPropertyType} en ${this.selectedOperation} en ${this.selectedMunicipio}` });
      // this.meta.addTag({ name: 'keywords', content: `homty, inmobiliario, propiedades, ${this.selectedPropertyType}, ${this.selectedOperation}, ${this.selectedMunicipio}` });

      // this.getPropertiesFilteredAndPaginated(params.inmueble, params.operacion, params.municipio, params.preciomin, params.preciomax, this.selectedBarrio, this.selectedHabitaciones, params.banos, this.pageSize.toString(), "1", params.mtmin, params.mtmax, params.parqueadero, params.estrato, params.estratomax, params.valorAdmin, params.valorAdminmax, params.antiguedad, params.piso, 'Todos');
    })
  }

  getPropertiesFilteredAndPaginated(inmueble: string, operacion: string, municipio: string, preciomin: string, preciomax: string, barrio: string,
    habitaciones: string, banos: string, pageSize: string, pageNumber: string, mtmin: string, mtmax: string, parqueadero: string,
    estrato: string, estratoMax: string, valorAdmin: string, valorAdminMax: string, antiguedad: string, piso: string, origin: string, sortState?: Sort) {

    this.selectedPropertyTypeFixed = inmueble;
    this.selectedMunicipioFixed = municipio
    this.selectedOperationFixed = operacion;

    let emailHistory: string = "NA";

    Auth.currentUserInfo().then(user => {
      this.user = user;
      emailHistory = user ? user["attributes"]["email"] : "NA";
    }, error => {
      console.log(error);
    })

    this.estateService.getPropertiesFilteredAndPaginatedLogin(inmueble, operacion, municipio, preciomin, preciomax, barrio, habitaciones, banos, pageSize, pageNumber, mtmin, mtmax, parqueadero, estrato, estratoMax, valorAdmin, valorAdminMax, antiguedad, piso, emailHistory, origin, sortState)
      .subscribe(resp => {
        if (resp.meta.totalItems > 0) {
          this.length = resp.meta.totalItems;
        } else {
          this.length = 0;
        }

        let params = this.getParams()
        this.pageIndex = parseInt(pageNumber) - 1;
        this.router.navigate(['/contact-owners'], { queryParams: params });
        this.loading = false;
      },
        (error) => {
          console.log("Error");
          console.log(error);
          console.log(error.status);
          this.loading = false;
          this.errorMessage = "Opps! Ha ocurrido un error al realizar la búsqueda!";
          this.buttonText = "Registrate"
        });
  }

  applyFilter() {
    if (this.municipioAutocompleteControl.valid && this.precioMin.valid) {
      this.loading = true;

      this.selectedPropertyTypeFixed = this.selectedPropertyType;
      this.selectedMunicipioFixed = this.selectedMunicipio
      this.selectedOperationFixed = this.selectedOperation;

      console.log("este es el servicio que no vale monda")

     this.getPropertiesFilteredAndPaginated(this.selectedPropertyType, this.selectedOperation, this.selectedMunicipio, this.precioMin.value.replace(/[^0-9]/g, ''), this.precioMax.value.replace(/[^0-9]/g, ''), this.selectedBarrio,
        this.selectedHabitaciones, this.selectedBanos, this.pageSize.toString(), "1", this.mtmin, this.mtmax, this.selectedParqueadero,
        this.selectedEstrato, this.selectedEstratoMax, this.valorAdmin.value.replace(/[^0-9]/g, ''), this.valorAdminMax.value.replace(/[^0-9]/g, ''), this.selectedAntiguedad, this.selectedPiso, this.selectedOrigin)
    }
  }

  getParams(): any {

    let params: any = {
      'inmueble': this.selectedPropertyType,
      'operacion': this.selectedOperation,
      'municipio': this.selectedMunicipio,
      'preciomin': this.precioMin.value.replace(/[^0-9]/g, ''),
      'preciomax': this.precioMax.value.replace(/[^0-9]/g, ''),
      'barrio': this.selectedBarrio,
      'habitaciones': this.selectedHabitaciones,
      'banos': this.selectedBanos,
      'page': this.pageIndex.toString(),
      'mtmin': this.mtmin,
      'mtmax': this.mtmax,
      'parqueadero': this.selectedParqueadero,
      'estrato': this.selectedEstrato,
      'estratomax': this.selectedEstratoMax,
      'valorAdmin': this.valorAdmin.value.replace(/[^0-9]/g, ''),
      'valorAdminmax': this.valorAdminMax.value.replace(/[^0-9]/g, ''),
      'antiguedad': this.selectedAntiguedad,
      'piso': this.selectedPiso,
      'results': this.length
    };

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] === null || params[key] === undefined || params[key] === '' || params[key] === '0') {
          delete params[key]
        }
      }
    }

    return params;

  }

  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        this.visitorsService.callApi(longitude, latitude).subscribe((res: { city: any; }) => {
          if (res.city != '') {
            this.selectedMunicipio = res.city
          } else {
            this.selectedMunicipio = 'Medellín'
          }
        });
      },
        (error) => {
          this.selectedMunicipio = 'Medellín'
        }
      );
    } else {
      this.selectedMunicipio = 'Medellín'
    }
  }

  // Metodos para el select de municipio
  updateMunicipioSelection(value: string) {
    this.selectedMunicipio = value;
    this.applyFilter();
  }

  getCities() {
    this.estateService.getCities()
      .subscribe(cities => {
        for (let i = 0; i < cities.length; i++) {
          this.cities.push(cities[i].Municipio);
        }
        this.filteredCities = this.municipioAutocompleteControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      })
  }

  getPropertyTypes() {
    this.estateService.getPropertyTypes()
      .subscribe(resp => {
        this.propertyTypes = resp;
      })
  }

  getOrigins() {
    this.estateService.getOrigins()
      .subscribe(resp => {
        this.origins = resp;
        this.selectedOrigin = 'Todos'
      })
  }

  updatePrices(event: any) {
    if (event.value == 'arriendo') {
      this.precioMin.setValue('400000');
      this.precioMax.setValue('200000000');
    } else {
      this.precioMin.setValue('20000000');
      this.precioMax.setValue('70000000000');
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter(option => option.toLowerCase().includes(filterValue));
  }
}


