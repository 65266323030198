<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>

<div class="container mt-4" *ngIf="!loading">
  <div class="table-responsive">
    <div class="table-wrapper">
      <div class="table-title">
        <div class="row">
          <div class="col-8">
            <h2>Administrar Usuarios</h2>
            <div class="row mt-2">
              <div class="col-lg-5 col-md-6 col-sm-12 counters">Usuarios activos: {{dataSource.data.length}}</div>
              <div class="col-lg-5 col-md-6 col-sm-12 counters">Usuarios restantes: {{agencyDetails.disponibles}}</div>
            </div>
          </div>
          <div class="col-4">
            <button class="btn btn-secondary add-new" (click)="canCreateUser()"><i
                class="fa-solid fa-user-plus"></i> Añadir Usuario</button>
            <!-- <button class="btn btn-secondary add-new" data-bs-toggle="modal" data-bs-target="#newUserModal"><i
                class="fa-solid fa-user-plus"></i> Añadir Usuario</button> -->
          </div>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 border-radius-top">

        <!-- index Column -->
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef> <strong>#</strong> </th>
          <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let element">{{element.nombre | titlecase}}
          </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element">{{element.email}}
          </td>
        </ng-container>

        <!-- Ciudad Column -->
        <ng-container matColumnDef="ciudad">
          <th mat-header-cell *matHeaderCellDef> Ciudad </th>
          <td mat-cell *matCellDef="let element"> {{element.ciudad}} </td>
        </ng-container>

        <!-- Celular Column -->
        <ng-container matColumnDef="celular">
          <th mat-header-cell *matHeaderCellDef> Celular </th>
          <td mat-cell *matCellDef="let element"> {{element.celular}} </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Acción </th>
          <td mat-cell *matCellDef="let element">
            <!-- <button class="btn btn-sm" matTooltip="Editar Usuario" (click)="editUser(element)">
              <i class="fa-solid fa-pen-to-square" style="font-size: 1.3rem;"></i>
            </button> -->
            <button class="btn btn-sm" matTooltip="Eliminar Usuario" (click)="deleteUser(element.email)">
              <i class="fa-solid fa-trash" style="font-size: 1.2rem;"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="loading">
          <td mat-footer-cell *matFooterCellDef colspan="19" style="text-align: center">
            Cargando información ...
          </td>
        </ng-container>

        <ng-container matColumnDef="noUsers">
          <td mat-footer-cell *matFooterCellDef colspan="19" style="text-align: center">
            No hay usuarios creados
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide': !loading}"></tr>
        <tr mat-footer-row *matFooterRowDef="['noUsers']"
          [ngClass]="{'hide': !(dataSource!=null && dataSource.data.length == 0) }"></tr>

      </table>

    </div>
  </div>
</div>

<div #modalCreateUser class="modal fade" id="newUserModal" tabindex="-1" role="dialog"
  aria-labelledby="newUserModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newUserModalLabel">Agregar Nuevo Usuario</h5>
        <button class="btn" data-bs-dismiss="modal" aria-label="Close">
          <i style="font-size: 1.3rem;" class="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formCreateUser">

          <div class="form-group mb-2">
            <label for="nombre">Nombre</label>
            <input type="text" class="form-control" id="nombre" formControlName="nombre">
            <div *ngIf="formCreateUser.controls['nombre'].invalid && formCreateUser.controls['nombre'].touched"
              class="text-danger">
              Nombre es requerido
            </div>
          </div>

          <div class="form-group mb-2">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email">
            <div *ngIf="formCreateUser.controls['email'].invalid && formCreateUser.controls['email'].touched"
              class="text-danger">
              Email es requerido y debe ser válido
            </div>
          </div>

          <div class="form-group mb-2">
            <label for="rol">Rol</label>
            <div class="select-container">
              <select id="rol" formControlName="rol" class="form-control">
                <option value="">Seleccione un rol</option>
                <option value="Agente inmobiliario">Agente inmobiliario</option>
                <option value="Avaluador">Avaluador</option>
                <option value="Comercial">Comercial</option>
                <option value="Flipping">Flipping</option>
              </select>
              <i class="fas fa-chevron-down"></i>
            </div>
            <div *ngIf="formCreateUser.controls['rol'].invalid && formCreateUser.controls['rol'].touched"
              class="text-danger">
              Rol es requerido 
            </div>
          </div>

          <div class="form-group mb-2">
            <label for="ciudad">Ciudad</label>
            <input type="text" class="form-control" id="ciudad" formControlName="ciudad">
            <div *ngIf="formCreateUser.controls['ciudad'].invalid && formCreateUser.controls['ciudad'].touched"
              class="text-danger">
              Ciudad es requerida
            </div>
          </div>

          <div class="form-group mb-2">
            <div class="row">
              <label for="celular">Celular</label>
            </div>
            <input ng2TelInput class="form-control" type="text" id="celular" formControlName="celular"
              [ng2TelInputOptions]="telOptions" (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)">
            <div *ngIf="formCreateUser.controls['celular'].invalid && formCreateUser.controls['celular'].touched"
              class="text-danger">
              Celular es requerido y debe ser válido
            </div>
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-modal-close" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary btn-modal-save" (click)="createUser()">Guardar</button>
      </div>
    </div>
  </div>
</div>