import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { from, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {


    token: string | null | undefined

    constructor(private authService: AuthService) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (httpRequest.headers.get("skip"))
            return next.handle(httpRequest);

        return from(this.handle(httpRequest, next));

    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        await Auth.currentSession(
        ).then(async users => {
            this.token = users.getIdToken().getJwtToken();
        })

        let request = req;

        if (this.token) {
            request = req.clone({
                setHeaders: {
                    authorization: `Bearer ${this.token}`
                }
            });
        }

        return next.handle(request).toPromise()

    }

}