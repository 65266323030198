import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupEstateService {

  private readonly DONT_SHOW_AGAIN_KEY = 'dontShowAgain';

  getDontShowAgain() {
    return JSON.parse(localStorage.getItem(this.DONT_SHOW_AGAIN_KEY) ?? "false");
  }

  setDontShowAgain(value: boolean): void {
    localStorage.setItem(this.DONT_SHOW_AGAIN_KEY, JSON.stringify(value));
  }
}
