<!-- Banner Section Start -->
<link rel="stylesheet" href="https://use.typekit.net/biv3flv.css" />

<div class="hero-content pt-50 pb-50">
  <section class="banner pb-30">
    <div class="container">
      <h2 class="banner-title pb-20">
        <!-- Encuentra miles de propiedades en un solo lugar -->
        El tiempo es literalmente oro
      </h2>
      <h3 class="banner-subtitle pb-40">
        <!-- Y ahórrate horas valiosas de tu tiempo -->
        Realiza avalúos o estudios de mercado inmobiliarios con muestras más
        grandes y a la velocidad de la luz
      </h3>

      <!--       <div class="d-flex justify-content-center pb-30">
        <iframe width="560" height="315" src="https://player.vimeo.com/video/740890921?h=6fd1223524&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Vimeo video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div> -->
    </div>
  </section>

  <div class="container">
    <form>
      <div class="row animate__animated animate__fadeIn">
        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="municipio" class="form-label required">Municipio</label>
          <input
            class="form-select form-select-lg"
            list="cities"
            name="municipio"
            id="municipio"
            formControlName="municipio"
            [formControl]="municipioAutocompleteControl"
            [(ngModel)]="selectedMunicipio"
          />
          <datalist id="cities">
            <option *ngFor="let city of filteredCities | async" [value]="city">
              {{ city }}
            </option>
          </datalist>
          <div
            *ngFor="
              let validation of validation_msgs.municipioAutocompleteControl
            "
          >
            <div
              *ngIf="municipioAutocompleteControl.hasError(validation.type)"
              class="mt-2 alert alert-danger"
            >
              {{ validation.message }}
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="barrio" class="form-label">Barrio</label>
          <input
            type="text"
            class="form-control form-control-lg"
            id="barrio"
            name="barrio"
            [formControl]="barrio"
          />
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="propertyType" class="form-label required"
            >Tipo de inmueble</label
          >
          <select
            class="form-select form-select-lg"
            [(ngModel)]="selectedPropertyType"
            name="propertyType"
          >
            <option
              *ngFor="let propertyType of propertyTypes"
              [value]="propertyType.Inmueble"
            >
              {{ propertyType.Inmueble }}
            </option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-2">
          <label for="operacion" class="form-label required">Operación</label>
          <select
            class="form-select form-select-lg"
            [(ngModel)]="selectedOperation"
            name="operacion"
            (change)="updatePrices($event)"
          >
            <option value="venta">Venta</option>
            <option value="arriendo">Arriendo</option>
          </select>
        </div>

        <div class="mb-3 col-md-12 col-sm-12 col-lg-4 custom-slider">
          <label for="operacion" class="form-label required">Precio</label>
          <table class="table" cellspacing="0">
            <tr>
              <td>
                <div class="input-group min">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Min.</span
                  >
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    id="minValue"
                    name="minValue"
                    [formControl]="minValue"
                    [inputMask]="currencyInputMask"
                  />
                  <div
                    *ngIf="
                      minValue.errors && (minValue.dirty || minValue.touched)
                    "
                    class="mt-2 alert alert-danger"
                  >
                    <div *ngFor="let validation of validation_msgs.minValue">
                      <div *ngIf="minValue.hasError(validation.type)">
                        {{ validation.message }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group max">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Max.</span
                  >
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    id="maxValue"
                    name="maxValue"
                    [formControl]="maxValue"
                    [inputMask]="currencyInputMask"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          class="col-md-12 col-sm-12 col-lg-12 mt-32 d-flex justify-content-center"
        >
          <input
            class="submit-btn"
            type="submit"
            value="Buscar"
            (click)="searchProperties()"
          />
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container container-home pt-100">
  <div class="row">
    <div class="col-md-4">
      <div class="col-icon left">
        <picture>
          <source srcset="assets/images/home/1.webp" type="image/webp" />
          <source srcset="assets/images/home/1.png" type="image/png" />
          <img src="assets/images/home/1.webp" alt="" width="50" />
        </picture>
      </div>
      <div class="right col-text">
        <h4>Ahorrate cientos de horas valiosas</h4>
        <p>
          <!-- Encuentra la propiedad que buscas en segundos. -->
          Deja de perder tiempo buscando muestras para tus avalúos o estudio de
          mercado en distintos portales, aquí lo tienes todo.
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="col-icon left">
        <picture>
          <source srcset="assets/images/home/2.webp" type="image/webp" />
          <source srcset="assets/images/home/2.png" type="image/png" />
          <img src="assets/images/home/2.webp" alt="" width="50" />
        </picture>
      </div>
      <div class="right col-text">
        <h4>Miles de opciones en un solo lugar</h4>
        <p>
          <!-- Deja de buscar en varias páginas, aquí lo tienes todo. -->
          Indexamos cientos de miles de inmuebles de distintos portales
          inmobilarios para que tengas toda la información a tu mano.
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="col-icon left">
        <picture>
          <source srcset="assets/images/home/3.webp" type="image/webp" />
          <source srcset="assets/images/home/3.png" type="image/png" />
          <img src="assets/images/home/3.webp" alt="" width="50" />
        </picture>
      </div>
      <div class="right col-text">
        <h4>
          <!-- Compara precios y elige -->
          Incrementa tu productividad
        </h4>
        <p>
          <!-- Nosotros te decimos el precio más razonable y tú eliges la mejor
          opción. -->
          El tiempo es oro, con Homty realiza más rápido la busqueda inmuebles
          para tus avalúos o estudios de mercado y genera más dinero.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container container-home pt-100">
  <div class="row">
    <div class="col-md-6 pll-100 mt-50">
      <h5>
        <!-- Metabuscador inmobiliario -->
        Descarga, analiza y comparte
      </h5>
      <p>
        <!-- Un Metabuscador es un buscador de propiedades que trae la información de
        varias fuentes, por ejemplo, de decenas de portales o sitios web que
        tienen publicadas miles de propiedades. -->
        Descarga un archivo Excel con cientos de comparables del inmueble o del
        sector que buscas analizar. Encuentra el precio del mercado para tus
        avalúos, estudios de mercado o para compartirlo a tus clientes.
      </p>
      <!-- <ul class="fa-ul">
        <li>
          <fa-icon [icon]="faCheckCircle" class="mr-5 green"></fa-icon
          ><b>1 punto de consulta con más de 800mil propiedades</b>
        </li>
        <li>
          <fa-icon [icon]="faCheckCircle" class="mr-5 green"></fa-icon
          ><b>Propiedades verificadas e identificación de duplicados</b>
        </li>
        <li>
          <fa-icon [icon]="faCheckCircle" class="mr-5 green"></fa-icon
          ><b>Archivo excel descargable para presentar a tu cliente</b>
        </li>
      </ul> -->
      <div
        class="col-md-12 col-sm-12 col-lg-12 mt-32 d-flex justify-content-center"
      >
        <button type="submit" class="btn-search" (click)="search()">
          Comienza a buscar gratis
        </button>
      </div>
    </div>
    <div class="col-md-6">
      <picture>
        <source srcset="assets/images/home/4.webp" type="image/webp" />
        <source srcset="assets/images/home/4.png" type="image/png" />
        <img src="assets/images/home/4.webp" alt="" />
      </picture>
    </div>
  </div>
</div>

<div class="container container-home pt-100">
  <div class="row">
    <div class="col-md-6">
      <picture>
        <source srcset="assets/images/home/16.webp" type="image/webp" />
        <source srcset="assets/images/home/16.png" type="image/png" />
        <!-- <img src="assets/images/home/6.webp" alt="" /> -->
        <img src="assets/images/home/16.webp" alt="" />
      </picture>
    </div>
    <div class="col-md-6 pll-100 mt-50">
      <h5>
        <!-- Conoce el precio de tu inmueble en línea -->
        El poder del precio
      </h5>
      <p>
        <!-- Con nuestro módulo de análisis comparativo de mercado, podrás obtener el
        precio de referencia de venta de tu propiedad a partir del análisis de
        inmuebles similares al tuyo. -->
        La principal razón por la cual un inmueble tarda en venderse o
        arrendarse es por el precio. Descarga un listado de propiedades
        similares a la de tu cliente y obtén el promedio de precios de la zona.
      </p>
      <!-- <ul class="fa-ul">
        <li>
          <fa-icon [icon]="faCheckCircle" class="mr-5 green"></fa-icon
          ><b
            >Averigua el valor de tu inmueble y el valor del metro cuadrado en
            segundos</b
          >
        </li>
        <li>
          <fa-icon [icon]="faCheckCircle" class="mr-5 green"></fa-icon
          ><b>Obtén el listado de los inmuebles similares. </b>
        </li>
        <li>
          <fa-icon [icon]="faCheckCircle" class="mr-5 green"></fa-icon
          ><b>Exporta a PDF los resultados</b>
        </li>
      </ul> -->
      <div
        class="col-md-12 col-sm-12 col-lg-12 mt-32 d-flex justify-content-center"
      >
        <button type="submit" class="submit-btn" (click)="signUp()">
          Regístrate ahora
        </button>
      </div>
    </div>
  </div>
</div>

<div class="pink mt-100">
  <div class="container container-home">
    <h5 class="text-center">
      <!-- Más de 1000 usuarios se benefician de Homty todos los días -->
      Más de 600mil propiedades cargadas
    </h5>
    <p class="text-center">
      Funciona para toda Colombia en ciudades grandes y medianas.
    </p>
    <div
      class="col-md-12 col-sm-12 col-lg-12 mt-32 d-flex justify-content-center"
    >
      <input
        class="submit-btn"
        type="submit"
        value="Buscar"
        (click)="searchProperties()"
      />
    </div>
    <!-- <div class="row">
      <div class="col">
        <div class="card">
          <div class="ratio ratio-1x1 rounded-circle overflow-hidden">
            <img src="https://i.stack.imgur.com/fcbpv.jpg?s=256&g=1"
              class="card-img-top img-cover d-flex justify-content-center" alt="Raeesh">
          </div>
          <div class="card-body">
            <p class="p-card">“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
              consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.”</p>
            <h6 class="text-center">Camila Parra</h6>

            <p class="text-center mt-10">Agente inmobiliario independiente</p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card">
          <div class="ratio ratio-1x1 rounded-circle overflow-hidden">
            <img src="https://i.stack.imgur.com/fcbpv.jpg?s=256&g=1"
              class="card-img-top img-cover d-flex justify-content-center" alt="Raeesh">
          </div>
          <div class="card-body">
            <p class="p-card">“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
              consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.”</p>
            <h6 class="text-center">Camila Parra</h6>

            <p class="text-center mt-10">Agente inmobiliario independiente</p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card">
          <div class="ratio ratio-1x1 rounded-circle overflow-hidden">
            <img src="https://i.stack.imgur.com/fcbpv.jpg?s=256&g=1"
              class="card-img-top img-cover d-flex justify-content-center" alt="Raeesh">
          </div>
          <div class="card-body">
            <p class="p-card">“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
              consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.”</p>
            <h6 class="text-center">Camila Parra</h6>

            <p class="text-center mt-10">Agente inmobiliario independiente</p>
          </div>
        </div>
      </div>

    </div> -->
  </div>
</div>

<div class="container container-home pt-100">
  <div class="row">
    <div class="col-md-6 prr-100 mt-50">
      <h5>Estuvimos en Shark Tank</h5>
      <p>
        Conoce nuestra experiencia estando en uno de los programas más
        importantes de latinoamérica, donde pudimos dar a conocer nuestro
        producto y recibimos recomendaciones para poder ser cada vez mejores.
      </p>
    </div>

    <div class="col-md-6">
      <iframe
        src="https://www.youtube.com/embed/9CAxLCukrIg"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>

<div class="container container-home pt-80">
  <h5 class="text-center">Agencias y Portales inmobiliarios indexados</h5>
  <div class="row mt-50 text-center">
    <div class="col">
      <picture>
        <source srcset="assets/images/home/agencias1.webp" type="image/webp" />
        <source srcset="assets/images/home/agencias1.jpeg" type="image/jpeg" />
        <img src="assets/images/home/agencias1.webp" alt="" class="inner-img" />
      </picture>
    </div>
    <div class="col">
      <picture>
        <source srcset="assets/images/home/agencias2.webp" type="image/webp" />
        <source srcset="assets/images/home/agencias2.jpeg" type="image/jpeg" />
        <img src="assets/images/home/agencias2.webp" alt="" class="inner-img" />
      </picture>
    </div>
    <div class="col">
      <picture>
        <source srcset="assets/images/home/agencias3.webp" type="image/webp" />
        <source srcset="assets/images/home/agencias3.jpeg" type="image/jpeg" />
        <img src="assets/images/home/agencias3.webp" alt="" class="inner-img" />
      </picture>
    </div>
    <div class="col">
      <picture>
        <source srcset="assets/images/home/agencias4.webp" type="image/webp" />
        <source srcset="assets/images/home/agencias4.jpeg" type="image/jpeg" />
        <img src="assets/images/home/agencias4.webp" alt="" class="inner-img" />
      </picture>
    </div>
  </div>
  <div class="row mt-50 text-center">
    <div class="col">
      <picture>
        <source srcset="assets/images/home/agencias5.webp" type="image/webp" />
        <source srcset="assets/images/home/agencias5.jpeg" type="image/jpeg" />
        <img src="assets/images/home/agencias5.webp" alt="" class="inner-img" />
      </picture>
    </div>
    <div class="col">
      <picture>
        <source srcset="assets/images/home/agencias6.webp" type="image/webp" />
        <source srcset="assets/images/home/agencias6.jpeg" type="image/jpeg" />
        <img src="assets/images/home/agencias6.webp" alt="" class="inner-img" />
      </picture>
    </div>
    <div class="col">
      <picture>
        <source srcset="assets/images/home/agencias7.webp" type="image/webp" />
        <source srcset="assets/images/home/agencias7.jpeg" type="image/jpeg" />
        <img src="assets/images/home/agencias7.webp" alt="" class="inner-img" />
      </picture>
    </div>
  </div>
</div>

<!-- <div class="blue mt-100">
  <div class="container container-home">
    <h3 class="banner-subtitle">ACADEMIA ONLINE</h3>
    <h1 class="title text-center">¡Un buen agente nunca deja de aprender!</h1>
    <h3 class="banner-subtitle" style="margin-top: 30px;">Únete gratis a Homty Academy y aprende cómo duplicar tus
      resultados trabajando
      inteligentemente.</h3>
    <div class="d-flex justify-content-center mt-40">
      <button class="register-btn text-center" type="button"
        (click)="goToLink('https://t.me/+oZScRZLvOdZhNjdh')">Ingresar a homty
        academy</button>
    </div>
  </div>
</div> -->

<div class="copywrite alg-center mt-50">
  <div class="container alg-center text-center">
    Homty © 2023. Todos los derechos reservados
  </div>
</div>
<!--<app-info></app-info>-->

<amplify-authenticator [hidden]="!false"> </amplify-authenticator>
<!-- Start of HubSpot Embed Code -->
<script
  type="text/javascript"
  id="hs-script-loader"
  async
  defer
  src="//js.hs-scripts.com/8998298.js"
></script>
<!-- End of HubSpot Embed Code -->
