<section class="pricing py-5">
  <div class="container">
    <div class="row">
      <h1>Pagos por paquetes</h1>
      <p>Paga individualmente por los servicios que vayas a utilizar</p>
    </div>
    <!-- Paquetes -->
    <div class="row mt-4">
      <!-- ACMs -->
      <div class="col-12 col-md-6 col-lg-6">
        <div class="plans background1">
          <div class="card-body">
            <div class="m-4 mt-20">
              <h3 class="mt-20 ml-auto">ACMs</h3>
              <h2 class="h2-price color-pro">$10.000</h2>
              <div class="form-outline mt-2">
                <label class="form-label" for="quantityAcms"><strong>Cantidad</strong></label>
                <input class="form-control p-3" type="number" min="0" id="quantityAcms" [formControl]="quantityAcms" />
              </div>

              <p class="mt-3">Selecciona cuantos ACMs necesitas. <br><br> <span class="text-muted">* Sin periodo de
                  expiración</span></p>
            </div>
          </div>
        </div>
      </div>
      <!-- Propiedades -->
      <div class="col-12 col-md-6 col-lg-6">
        <div class="plans background2">
          <div class="card-body">
            <div class="m-4 mt-20">
              <h3 class="mt-20 ml-auto color-white">Propiedades</h3>
              <h2 class="h2-price color-white">$5.000</h2>
              <div class="form-outline mt-2">
                <label class="form-label color-white" for="quantityProperties"><strong>Cantidad</strong></label>
                <input class="form-control" type="number" min="0" id="quantityProperties"
                  [formControl]="quantityProperties" />
              </div>
              <p class="mt-3 color-white">Selecciona cuantas propiedades necesitas publicar. <br><br> <span class="color-white">* Periodo de dos meses.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <mat-card class="card-total background1 mt-4">
        <mat-card-content class="text-center">
          <div class="m-2">
            <label class="form-label card-desc"><strong>Total:</strong></label>
            <h4 class="h4-price color-pro">{{total | currency:'COP':'symbol-narrow':'1.0-0'}}</h4>
            <div class="row justify-content-center mt-4">
              <a (click)="openCheckout()" 
                class="col-8 btn btn-primary text-uppercase">Comprar</a>
                <!-- routerLink="/checkout" [queryParams]="{plan: '4', acms: quantityAcms.value, properties: quantityProperties.value}" -->
            </div>
          </div>

        </mat-card-content>
      </mat-card>
    </div>

    <!-- <div class="row mt-30">
      <div class="card mb-5 mb-lg-0">
        <div class="card-body">

          <h3>Beneficios extra de tu membresía PRO</h3>

          <p class="card-desc mt-20">
            <i class="fa-solid fa-check"></i>
            Hacer parte de la revolución del mercado inmobiliario
          </p>
          <p class="card-desc mt-20">
            <i class="fa-solid fa-check"></i>
            Acceso a los reportes de mercado inmobiliario mensualmente.
          </p>
          <p class="card-desc mt-20">
            <i class="fa-solid fa-check"></i>
            Descuento del 10% del Curso de formación inmobiliaria en alianza con CSI (1).
          </p>
          <p class="card-desc mt-20">
            <i class="fa-solid fa-check"></i>
            Descuento del 10% en pautas publicitarias en tu voz el Magazín Inmobiliario.
            (2)
          </p>
          <p class="card-desc mt-20">
            <i class="fa-solid fa-check"></i>
            El 15% de Descuento en consultoría empresarial inmobiliaria.
          </p>
          <p class="card-desc mt-20">
            <i class="fa-solid fa-check"></i>
            El 10% de descuento en consultoría juridica para la elaboración de documentos legales como
            compra-venta de inmuebles, estudios de titulos, o cualquier otro proceso jurídico
            inmobiliario
          </p>

          <div class="row">
            <span>
              (1) 123 horas de formación teórico práctico. El curso más completo del mercado
              inmobiliario,
              con
              los mejores instructores y certificado de asistencia
            </span>
          </div>

          <div class="row">
            <span>
              (2) Tu voz el Magazín Inmobiliario, 1.080 am todos los sábados y en vivo en el canal de
              Youtube.
              El único Magazín especializado en el Mercado inmobiliario del país
            </span>
          </div>
        </div>

      </div>
    </div> -->
  </div>
</section>