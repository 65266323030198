import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  faFacebook,
  faTwitter,
  faPinterest,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faSignInAlt,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { CognitoUserInterface, AuthState } from "@aws-amplify/ui-components";
import { Router } from "@angular/router";
import { Amplify, Auth } from "aws-amplify";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import awsExports from "../../../../src/aws-exports";
import Swal from "sweetalert2";
import { WebsocketService } from "src/app/services/websocket.service";
import { Notification } from "src/app/models/notification.model";
import { AdditionalsService } from "src/app/services/additionals.service";
declare var jQuery: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  authState: AuthState | undefined;
  @Input() userCog: CognitoUserInterface | undefined;
  @ViewChild("modalOpenNotification") modalOpenNotification:
    | ElementRef
    | undefined;
  @ViewChild("modalNotifications") modalNotifications: ElementRef | undefined;

  faBars = faBars;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faPinterest = faPinterest;
  faInstagram = faInstagram;
  faSignInAlt = faSignInAlt;
  faUser = faUser;
  faSignOutAlt = faSignOutAlt;
  faLinkedin = faLinkedin;
  faWhatsapp = faWhatsapp;
  token: string | undefined;

  selectedNotificationToModal: Notification | undefined;

  constructor(
    private ref: ChangeDetectorRef,
    private zone: NgZone,
    private router: Router,
    public authenticator: AuthenticatorService,
    public websocketService: WebsocketService,
    private additionalsService: AdditionalsService
  ) {
    try {
      Amplify.configure(awsExports);
    } catch {}
  }

  async ngOnInit(): Promise<void> {
    await Auth.currentUserInfo().then((user) => {
      this.userCog = user;
    });
  }

  hideBadgeNotification() {
    this.websocketService.showBadgeNotifications = false;
  }

  openNotification(notification: Notification) {
    this.selectedNotificationToModal = notification;
    if (!this.websocketService.viewedNotifications.includes(notification.id)) {
      this.additionalsService
        .markNotificationRead(notification.id, this.userCog!.attributes.email)
        .subscribe((resp: any) => {
          if (resp?.answer) {
            this.websocketService.viewedNotifications.push(notification.id);
          } else {
            console.error("An error occurred while updating the notification");
          }
        });
    }

    jQuery(this.modalNotifications?.nativeElement).modal("hide");
    jQuery(this.modalOpenNotification?.nativeElement).modal("show");
  }

  toggleProfileMenu() {
    const submenu = document.getElementById("submenu-wrap");
    submenu?.classList.toggle("open-menu");
  }

  goToProfile() {
    this.zone.run(() => {
      this.router.navigate(["perfil"]);
    });
  }

  goToFavorites() {
    this.zone.run(() => {
      this.router.navigate(["favoritos"]);
    });
  }

  goToStatistics() {
    this.zone.run(() => {
      this.router.navigate(["statistics/1"]);
    });
  }

  signOut() {
    Swal.fire({
      title: "¿Desea cerrar la sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#fc6641",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Auth.signOut();
        this.zone.run(() => {
          this.router.navigate(["/"]);
        });
        this.websocketService.disconnect();
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Ha cerrado sesión correctamente",
          width: 400,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  }

  goToSearch() {
    Auth.currentUserInfo().then((user) => {
      this.userCog = user;
      if (this.userCog) {
        this.router.navigate(["/propiedades"], {
          queryParams: {
            inmueble: "Apartamento",
            operacion: "venta",
            municipio: this.userCog["attributes"]["address"],
            barrio: "",
            preciomin: "20000000",
            preciomax: "70000000000",
          },
        });
      } else {
        let terms = Swal.fire({
          title: "No tienes un plan activo, ¿Deseas adquirir uno?",
          showCancelButton: true,
          confirmButtonColor: "#fc6641",
          cancelButtonColor: "#d33",
          confirmButtonText: "Comprar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(["pay-subscription"]);
          }
        });
      }
    });
  }

  closeSidebar() {
    const closeBtn = document.getElementById("btn-modal-mobile");
    closeBtn?.click();
  }

  goToInventory() {
    Auth.currentUserInfo().then((user) => {
      this.userCog = user;
      if (
        this.userCog &&
        (this.userCog!["attributes"]["custom:free_trial"] == 1 ||
          this.userCog["attributes"]["custom:active_plan"] != 0)
      ) {
        this.router.navigate(["/inventory"]);
      } else {
        let terms = Swal.fire({
          title: "No tienes un plan activo, ¿Deseas adquirir uno?",
          showCancelButton: true,
          confirmButtonColor: "#fc6641",
          cancelButtonColor: "#d33",
          confirmButtonText: "Comprar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(["pay-subscription"]);
          }
        });
      }
    });
  }

  goToUsersAdmin() {
    this.router.navigate(["/users-admin"]);
  }

  goToAvaluo() {
    Auth.currentUserInfo().then((user) => {
      this.userCog = user;
      if (
        this.userCog &&
        (this.userCog!["attributes"]["custom:free_trial"] == 1 ||
          this.userCog["attributes"]["custom:active_plan"] != 0)
      ) {
        this.router.navigate(["/avaluo"]);
      } else {
        let terms = Swal.fire({
          title: "No tienes un plan activo, ¿Deseas adquirir uno?",
          showCancelButton: true,
          confirmButtonColor: "#fc6641",
          cancelButtonColor: "#d33",
          confirmButtonText: "Comprar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(["pay-subscription"]);
          }
        });
      }
    });
  }

  closeMenu() {
    const navbar = jQuery(".navbar-collapse");
    navbar.slideUp();
    navbar.removeAttr("style");
  }
}
