<app-loading *ngIf="loading" class="animate__animated animate__fadeIn vertical-center">
</app-loading>

<div *ngIf="!loading" class="container">

  <mat-tab-group mat-align-tabs="center" [selectedIndex]="activeLink" dynamicHeight>
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fas fa-folder"></i>
        &nbsp; Historial de ACMs
      </ng-template>
      <app-acms-history></app-acms-history>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fas fa-eye"></i>
        &nbsp; Visualizaciones
      </ng-template>
      <app-property-statistics></app-property-statistics>
    </mat-tab>
    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <i class="fas fa-list"></i>
        &nbsp; Option 3
      </ng-template>
      Content 3
    </mat-tab> -->
  </mat-tab-group>

</div>