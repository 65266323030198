import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { InventoryModel } from "src/app/models/inventory.model";
import { InventoryService } from "src/app/services/inventory.service";
import { SharingService } from "src/app/services/sharing.service";
import { Cercania } from "../../models/cercania.model";

@Component({
  selector: "app-property360",
  templateUrl: "./property360.component.html",
  styleUrls: ["./property360.component.css"],
})

export class Property360Component implements OnInit {
  iconHomtyUrl = "assets/images/pins-map/property.png";

  constructor(
    private activatedRoute: ActivatedRoute,
    private sharingService: SharingService,
    private inventoryService: InventoryService,
    private title: Title,
    private meta: Meta 
  ) {}
  
  property: InventoryModel | undefined;
  loading: boolean = false;
  latitude: number = 0;
  longitude: number = 0;
  zoom: number = 16;
  valorizacion: number = 0;
  valorizacionpre: number = 0;
  gastos_notarial: number = 0;
  ingresobruto: number = 0;
  rentabilidad: number = 0;
  ingresaanual:  number = 0;
  gastos: number = 0;
  administracion: string = "";
  impuestos :  number = 0;
  gastosImprevistos :  number = 0;
  inmboliaria:  number = 0;
  seguro :  number = 0;

  private geoCoder!: {
    geocode: (
      arg0: { location: { lat: any; lng: any } },
      arg1: (results: any, status: any) => void
    ) => void;
  };
  address: string = "";

  dataCercanias: Cercania[] = [
    {
      title: "Bancos",
      checked: false,
      consulted: false,
      loading: false,
      icon: "fas fa-dollar-sign",
      iconUrl: "assets/images/pins-map/bank.png",
      color: "#017216",
      typeValue: "bank",
      items: [],
    },
    {
      title: "Farmacias",
      checked: false,
      consulted: false,
      loading: false,
      icon: "fas fa-briefcase-medical",
      iconUrl: "assets/images/pins-map/pharmacy.png",
      color: "#238AFF",
      typeValue: "pharmacy",
      items: [],
    },
    {
      title: "Gimnasios",
      checked: false,
      consulted: false,
      loading: false,
      icon: "fas fa-dumbbell",
      iconUrl: "assets/images/pins-map/gym.png",
      color: "#FEBD23",
      typeValue: "gym",
      items: [],
    },
    {
      title: "Hospitales",
      checked: false,
      consulted: false,
      loading: false,
      icon: "fas fa-hospital",
      iconUrl: "assets/images/pins-map/hospital.png",
      color: "#FB302F",
      typeValue: "hospital",
      items: [],
    },
    {
      title: "Iglesias",
      checked: false,
      consulted: false,
      loading: false,
      icon: "fas fa-church",
      iconUrl: "assets/images/pins-map/church.png",
      color: "#21C8D1",
      typeValue: "church",
      items: [],
    },
    {
      title: "Parques",
      checked: false,
      consulted: false,
      loading: false,
      icon: "fas fa-tree",
      iconUrl: "assets/images/pins-map/park.png",
      color: "#029F14",
      typeValue: "park",
      items: [],
    },
    {
      title: "Restaurantes",
      checked: false,
      consulted: false,
      loading: false,
      icon: "fas fa-utensils",
      iconUrl: "assets/images/pins-map/restaurant.png",
      color: "#790079",
      typeValue: "restaurant",
      items: [],
    },
  ];

  ngOnInit(): void {
    this.getPropertyById();
  }

  updateCercanias(newData: Cercania[]): void {
    this.dataCercanias = newData;
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getPropertyById() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.id) {
        this.property = this.sharingService.sharingValue;
        if (this.property != undefined) {
          this.latitude = Number(this.property.Latitud);
          this.longitude = Number(this.property.Longitud);
        } else {
          this.loading = true;
          this.inventoryService.getProperyById(params.id).subscribe((resp) => {
            this.property = resp.property;
            this.loading = false;
            this.latitude = Number(this.property?.Latitud);
            this.longitude = Number(this.property?.Longitud);
            const tamanoEntero: number = this.property?.Tamano ? parseInt(this.property.Tamano.toString(), 10) : 0;
            var porcentaje = 0;
            if(tamanoEntero  >= 150){
              porcentaje = 0.007;
           }else{
             if(tamanoEntero ==  140  || tamanoEntero == 130  || tamanoEntero == 120  || tamanoEntero == 110  || tamanoEntero == 100){
               porcentaje = 0.0064
             }else{
              if(tamanoEntero == 90  || tamanoEntero == 80  || tamanoEntero == 70  ){
                porcentaje = 0.006
              }else{
                if( tamanoEntero == 60  || tamanoEntero == 50 || tamanoEntero == 40  || tamanoEntero == 30){
                  porcentaje = 0.0056
                }else{
                  porcentaje = 0.005
                }
              }
             }
           }
            const valorConSigno = this.property?.Precio || '0'; 
            const valorinmueble = parseInt(valorConSigno.replace(/\D/g, ''), 10) || 0;
            const valorConSignoAdmin = this.property?.Valor_Admin || '0'; 
            const valorinmuebleAdmin = parseInt(valorConSignoAdmin.replace(/\D/g, ''), 10) || 0;
            const arriendomes = (valorinmueble * porcentaje)
            const arriendoano = arriendomes * 12
            const valorizacionano  = valorinmueble * 0.1;
            const inmobiliaria = 0.1;
            const seguro = 0.02;
            const admin = (valorinmuebleAdmin/arriendomes)
            const admin2 = admin === Infinity ? 0 : admin;
            const porcentajeGastos = inmobiliaria + seguro  + 0.06 + admin2 + porcentaje;
            const gastos = arriendoano * parseFloat(porcentajeGastos.toFixed(2));
            const administr = this.property?.Valor_Admin  == "$0" ? 0 :  valorinmuebleAdmin * 12
          
            this.gastos_notarial = valorinmueble * 0.025;
            this.valorizacion = ((valorinmueble * 0.1) / valorinmueble) * 100;
            this.valorizacionpre = valorinmueble * 0.1;
            this.ingresobruto = (arriendomes * 12);
            this.rentabilidad = (((arriendoano + valorizacionano) - (gastos))/ valorinmueble )* 100;
            this.gastos = (arriendoano * inmobiliaria) + (arriendoano * seguro) + (arriendoano / 12) + (arriendoano * 0.01) + (administr);
            this.ingresaanual = (arriendoano - gastos)
            this.administracion = administr === 0 ? "Administración no verificada" : administr.toString();
            this.seguro = arriendoano * seguro;
            this.gastosImprevistos = arriendoano * 0.01;
            this.inmboliaria = arriendoano * inmobiliaria;
            this.impuestos = arriendomes;
           
            //SEO
            this.title.setTitle(
              `Homty360 - ${this.property?.Inmueble} ${this.property?.Barrio}`
            );
            this.meta.addTag({
              name: "description",
              content: `Homty ${this.property?.Inmueble} ${this.property?.Barrio}, ${this.property?.Descripcion}`,
            });
            this.meta.addTag({
              name: "keywords",
              content: `homty, homty360, inmobiliario, propiedades, ${this.property?.Inmueble}, ${this.property?.Barrio}, ${this.property?.Operacion}, Piso ${this.property?.Piso}, ${this.property?.Habitaciones} Habitaciones`,
            });
            
            
          });
        }
      }
    });
  }

  markerDragEnd($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: any, status: any) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  obtenerPrecioComoEntero(): number | undefined {
    const precioConSigno = this.property?.Precio;

    if (precioConSigno) {
      // Eliminar el signo '$' y convertir el resto a un entero
      const precioSinSigno = parseInt(precioConSigno.replace(/\D/g, ""), 10);
      return isNaN(precioSinSigno) ? undefined : precioSinSigno;
    } else {
      return undefined;
    }
  }
}
